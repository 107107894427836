export const aaHealthColor = (health: number) => {
  if (!health) return '#888888';

  if (health < 80) {
    return '#F94F58';
  } else if (health < 90) {
    return '#FFC200';
  } else {
    return '#00C48C';
  }
};
