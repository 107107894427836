import React, { useState } from 'react';

import { Avatar, DialogActions, TextField } from '@material-ui/core';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import { Button } from 'primereact/button';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 5
  },
  centered: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const ApiKeySecretLink = ({ integrationName, supportUrl, institution, styles, onAccounts, handleMainClose }) => {
  const [open, setOpen] = useState(false);

  const [api_key, setKey] = useState('');
  const [api_secret, setSecret] = useState('');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const classes = useStyles();

  const onSubmit = async () => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/${institution.integration}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          api_key: api_key,
          api_secret: api_secret
        }
      })
    });
    const json = await response.json();
    if (json['data'] && onAccounts) {
      onAccounts(json['data']);
      setSuccess(true);
    } else {
      setError(`Unable to add your ${integrationName}. Please check your API Key and Secret and then try again.`);
    }
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const closePopup = () => {
    setSuccess(false);
    setKey('');
    setSecret('');
  };

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success && (
        <SuccessDialog
          page={'Liquid'}
          institution={institution?.name}
          handleClose={handleMainClose}
          onClose={closePopup}
        />
      )}
      <div style={styles} onClick={(e) => setOpen(true)}>
        {institution?.logo_url ? (
          <img
            style={{ paddingBottom: 5 }}
            className="link-bank-logo"
            src={institution.logo_url}
            alt={institution.name}
          />
        ) : (
          <>
            <Avatar className="link-bank-icon">
              <InsertChartIcon />
            </Avatar>
            <div>{institution.name}</div>
          </>
        )}
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitleX onClose={handleClose}>Connect your {integrationName}</DialogTitleX>
        <DialogContent>
          <div className={classes.centered}>
            <img src={institution.logo_url} alt={`${integrationName} Logo`} />
          </div>

          <TextField
            label="Key"
            name="api_key"
            value={api_key}
            onChange={(e) => setKey(e.target.value)}
            variant="outlined"
            className={classes.formControl}
            fullWidth
          />
          <TextField
            label="Secret"
            name="api_secret"
            value={api_secret}
            onChange={(e) => setSecret(e.target.value)}
            variant="outlined"
            type="password"
            className={classes.formControl}
            fullWidth
          />

          {supportUrl && (
            <div className={classes.centered} style={{ padding: '5px' }}>
              <a href={supportUrl} target="_blank" rel="noreferrer">
                Click here for detailed instructions
              </a>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button label="Submit" icon="pi pi-check" onClick={onSubmit} />
        </DialogActions>
      </Dialog>
    </>
  );
};
