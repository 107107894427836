import React from 'react';
import { BsPersonCircle } from 'react-icons/bs';

import { Avatar, Button, Card, Grid, makeStyles } from '@material-ui/core';
import * as d3 from 'd3';

import LegendVisualizationData from 'assetData/LegendVisualizationData';
import { StatementIcon, TileIcon } from 'components/assetDetails/PersonalFinancialStatement';
import HoveredDonut from 'components/charts/HoveredDonut';
import LegendList from 'components/charts/LegendList';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';
import { useNetWorthData } from 'contexts/NetWorthDataContext';
import { formatMoney } from 'utils/formatters';

import './NetWorthTile.css';

const useStyles = makeStyles((theme) => ({
  avatarImg: {
    fontSize: '85px;',
    height: '1em',
    width: '1em',
    marginTop: '20px'
  }
}));

function NetWorthTile({ view, setView }) {
  const { liqChange, persChange, altChange, liabilityChange } = useAccountsData();
  const change = liqChange.value + persChange.value + altChange.value - liabilityChange.value || 0;
  const { displayPhoto } = useAuth();
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const timeOfDay = hours < 12 ? 'Morning' : hours < 18 ? 'Afternoon' : 'Evening';
  const { currentUser } = useAuth();
  const { NetData } = useNetWorthData();
  const { LiabilitiesData } = useAccountsData();
  const classes = useStyles();

  const NetWorthValues = NetData.map((hash) => hash['value']);
  const summedNetWorth = NetWorthValues.reduce((acc = 0, currVal) => acc + currVal);
  const totalNetWorth = summedNetWorth - LiabilitiesData;

  const labels = NetData.map((hash) => {
    return hash['label'];
  });

  const colorScheme = LegendVisualizationData(NetData);
  const colors = d3.scaleOrdinal().range(colorScheme);
  const page = 'netWorthTile';
  const formatName = currentUser['displayName']
    ? currentUser['displayName'].split(' ')[0].charAt(0).toUpperCase() +
      currentUser['displayName'].split(' ')[0].slice(1)
    : 'User';

  // async function getChangeInWorth(PosIDs, NegIDs) {
  //   const ID = await currentUser.getIdToken();

  //   let currentDay = moment().format('YYYY-MM-DD');
  //   function yesterday() {
  //     return moment().subtract(1, 'day').format('YYYY-MM-DD');
  //   }

  //   let pos = 0;
  //   if (PosIDs) {
  //     const response1 = await fetch(
  //       `${url}/api/v1/account_timeseries.json?filter[date][gte]=${yesterday()}&filter[date][lte]=${currentDay}&filter[account_id][eq]=${PosIDs}`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${ID}`
  //         }
  //       }
  //     );

  //     const posjson = await response1.json();
  //     pos = posjson['data'][1]['balance'] - posjson['data'][0]['balance'];
  //   }

  //   let neg = 0;
  //   if (NegIDs) {
  //     const response2 = await fetch(
  //       `${url}/api/v1/account_timeseries.json?filter[date][gte]=${yesterday()}&filter[date][lte]=${currentDay}&filter[account_id][eq]=${NegIDs}`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${ID}`
  //         }
  //       }
  //     );

  //     const negjson = await response2.json();
  //     neg = negjson['data'][1]['balance'] - negjson['data'][0]['balance'];
  //   }
  //   setChange(pos - neg);
  // }

  // useEffect(() => {
  //     // console.log(liqChange.value + persChange.value + altChange.value - liabilityChange.value);
  //     setChange(liqChange.value + persChange.value + altChange.value - liabilityChange.value)
  //     // getChangeInWorth([...new Set([...LiqIds, ...AltIds, ...PersIds])].join(), LiabilityIds.join())
  // }, [])

  return (
    <Card style={{ borderRadius: '9px' }}>
      <Grid container justify="center">
        <Grid item xs={12} sm={6} lg={12}>
          <div>
            {displayPhoto ? (
              <Avatar className={classes.avatarImg} src={displayPhoto} />
            ) : (
              <BsPersonCircle className="avatar" />
            )}
            <div className="header">
              <p>
                Good {timeOfDay}, {currentUser['displayName'] ? formatName : 'User'}!
              </p>
            </div>
            <div className="net-worth-title">
              <p>Your Net Worth:</p>
              <div className=" total-net-worth">{formatMoney(totalNetWorth, 0)}</div>
              <p className="change-in-value">
                Daily Change:{' '}
                <span style={parseInt(change) >= 0 ? { color: 'green' } : { color: 'red' }}>{`$${change.toLocaleString(
                  'en',
                  {
                    maximumFractionDigits: 0
                  }
                )}`}</span>
              </p>
            </div>
          </div>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={6} lg={12} className="networth-info-container">
            <div>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={3} lg={4} style={{ marginRight: 8 }}>
                  <HoveredDonut
                    networthDonut={true}
                    donutData={NetData}
                    innerRadius={30}
                    outerRadius={50}
                    colorScheme={colorScheme}
                    width={100}
                    height={110}
                    totals={summedNetWorth}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={6} style={{ marginLeft: 8 }}>
                  {NetData.length > 0 && (
                    <LegendList
                      data={NetData[0]}
                      labels={labels}
                      colors={colors}
                      colorScheme={colorScheme}
                      page={page}
                    />
                  )}
                </Grid>
              </Grid>
              <div className="net-worth-totals-container">
                <div className="assets-and-liabilities">
                  <div className="asset-liabilities-labels">
                    <span style={{ marginBottom: 5 }}>Assets:</span>
                    <span>Liabilities:</span>
                  </div>
                  &nbsp;
                  <div className="asset-liabilities-values">
                    <span style={{ marginBottom: 5 }} className="asset-liability-total">
                      {` $${summedNetWorth.toLocaleString('en', {
                        maximumFractionDigits: 0
                      })}`}
                    </span>

                    <span className="asset-liability-total">
                      {` $${LiabilitiesData.toLocaleString('en', {
                        maximumFractionDigits: 0
                      })}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Button
              startIcon={view === 'tile' ? <StatementIcon /> : <TileIcon />}
              onClick={() => setView(view === 'tile' ? 'pfs' : 'tile')}
            >
              {view === 'tile' ? 'Statement View' : 'Tile View'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default NetWorthTile;
