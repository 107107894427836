import React, { useEffect, useState } from 'react';

import { Avatar, DialogActions, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { Button } from 'primereact/button';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

const currentYear = new Date().getFullYear();
let years = [];
let startYear = 1997;
while (startYear <= currentYear) {
  years.push(startYear++);
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

export const MarketcheckAutoLink = (props) => {
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState(currentYear);
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [trim, setTrim] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [makesWithModels, setMakesWithModels] = useState({});
  const { currentUser } = useAuth();
  const classes = useStyles();

  useEffect(() => {
    async function getMakesWithModels() {
      const ID = await currentUser.getIdToken();
      const response = await fetch(`${url}/api/v1/auto_models.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      });
      const json = await response.json();
      setMakesWithModels(json['data']);
    }
    getMakesWithModels();
  }, [currentUser]);

  const onSubmit = async () => {
    const ID = await currentUser.getIdToken();

    const body =
      trim === ''
        ? JSON.stringify({
            data: {
              year: year,
              make: make,
              model: model
            }
          })
        : JSON.stringify({
            data: {
              year: year,
              make: make,
              model: model,
              trim: trim
            }
          });
    const response = await fetch(`${url}/api/v1/marketcheck`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: body
    });
    if (response.ok) {
      setSuccess(true);
      handleClose();
    } else {
      setOpen(false);
      if (response.status === 404) {
        setError('Car not found');
      } else {
        setError('We were unable to add this car to your account.');
      }
    }
  };
  const handleClose = () => {
    setYear(currentYear);
    setMake('');
    setModel('');
    setTrim('');
    setOpen(false);
  };

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success ? (
        <SuccessDialog
          handleClose={props.handleMainClose}
          page={'Personal'}
          institution={'Auto'}
          onClose={() => setSuccess(false)}
        />
      ) : null}
      <div style={props.styles} onClick={(e) => setOpen(true)}>
        {props.institution?.logo_url ? (
          <img style={{ paddingBottom: 5 }} className="link-bank-logo" src={props.institution.logo_url} alt=" " />
        ) : (
          <>
            <Avatar className="link-bank-icon">
              <DriveEtaIcon />
            </Avatar>
            <div>{props.name}</div>
          </>
        )}
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitleX onClose={handleClose}>Select your Car</DialogTitleX>
        <DialogContent>
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel id="year">Year</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              label="Age"
              autoWidth={true}
            >
              <MenuItem value="" disabled>
                <em>Select Year</em>
              </MenuItem>
              {years.map((y, i) => (
                <MenuItem key={i} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel id="make">Make</InputLabel>
            <Select
              labelId="make-label"
              id="make"
              value={make}
              onChange={(e) => {
                setMake(e.target.value);
                setModel('');
                setTrim('');
              }}
              label="Make"
              autoWidth
              placeholder="Make"
            >
              <MenuItem value="" disabled>
                <em>Select Make</em>
              </MenuItem>
              {Object.keys(makesWithModels).map((y, i) => (
                <MenuItem key={i} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel id="model">Model</InputLabel>
            <Select
              labelId="model-label"
              id="model"
              value={model}
              onChange={(e) => {
                setModel(e.target.value);
                setTrim('');
              }}
              label="Model"
              minWidth={100}
              placeholder="Model"
              autoWidth
            >
              <MenuItem value="" disabled>
                <em>Select Model</em>
              </MenuItem>
              {(make ? Object.keys(makesWithModels[make]) : []).map((y, i) => (
                <MenuItem key={i} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="trim">Trim</InputLabel>
            <Select
              labelId="trim-label"
              id="trim"
              value={trim}
              onChange={(e) => setTrim(e.target.value)}
              label="trim"
              minWidth={100}
              placeholder="trim"
              autoWidth
            >
              <MenuItem value="" disabled>
                <em>Select trim</em>
              </MenuItem>
              {(make && model ? makesWithModels[make][model] : []).map((y, i) => (
                <MenuItem key={i} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button label="Submit" icon="pi pi-check" onClick={onSubmit} />
        </DialogActions>
      </Dialog>
    </>
  );
};
