import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

import { Collapse, Dialog, DialogContent } from '@material-ui/core';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import ExpensesDropdown from 'components/dropdowns/ExpensesDropdown';
import { formatDollars, formatGainLoss, formatPercent } from 'components/tables/GenericAssetsTable';
import { useAuth } from 'contexts/AuthContext';

import { GenericTransactionsTable } from './GenericTransactionsTable';

import './MainExpenseTable.css';

const url = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const splitDate = date.split('-');
  return splitDate[1] + '/' + splitDate[2] + '/' + splitDate[0].slice(2);
};

const IncomeExpenseRow = ({ cat, total, selectedMonth, setSelectedMonth }) => {
  const { currentUser } = useAuth();
  const [showChildren, setShowChildren] = useState(false);
  const [categoryTransactionsId, setCategoryTransactionsId] = useState(null);
  const [categoryTransactions, setCategoryTransactions] = useState(null);
  const [transactionListLoading, setTransactionListLoading] = useState(false);
  const [loadingMoreTransactions, setLoadingMoreTransactions] = useState(false);
  const [pageLimit, setPageLimit] = useState(20);
  const [updateTransactions, setUpdateTransactions] = useState(false);

  const getCategoryTransactions = useCallback(async () => {
    pageLimit === 20 ? setTransactionListLoading(true) : setLoadingMoreTransactions(true);
    const ID = await currentUser.getIdToken();
    const { startStr, endStr } = selectedMonth;

    const response = await fetch(
      `${url}/api/v1/transactions.json?filter[category_id][eq]=${categoryTransactionsId}&filter[date][gte]=${startStr}&filter[date][lte]=${endStr}&page[size]=${pageLimit}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      }
    );

    const json = await response.json();
    setCategoryTransactions(
      json.data.map((t) => ({
        ...t,
        amount: formatDollars(t.amount),
        date: formatDate(t.date)
      }))
    );
  }, [categoryTransactionsId, currentUser, pageLimit, selectedMonth]);

  const makeSublist = useCallback((subCats, total) => {
    return subCats
      .filter((s) => s.total !== 0)
      .map((sub, i) => {
        return (
          <div key={sub.id} className="expenses-tile-income-body" onClick={() => setCategoryTransactionsId(sub.id)}>
            <div className="expense-tile-body-left-sub">{sub.name}</div>
            <div className="expense-tile-body-right">
              <div className="expense-tile-total">{formatDollars(sub.total)}</div>
              <div>{formatPercent((sub.total / total) * 100)}</div>
            </div>
          </div>
        );
      });
  }, []);

  const closeTransactionDialog = () => {
    setCategoryTransactions(null);
    setCategoryTransactionsId(null);
    setTransactionListLoading(false);
  };

  useEffect(() => {
    if (categoryTransactions) {
      setTransactionListLoading(false);
      setLoadingMoreTransactions(false);
    }
  }, [categoryTransactions]);

  useEffect(() => {
    if (categoryTransactionsId && (!categoryTransactions || pageLimit > 20 || updateTransactions)) {
      getCategoryTransactions();
      setSelectedMonth(selectedMonth);
      setUpdateTransactions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryTransactionsId, pageLimit, updateTransactions]);

  const hasChildren = cat.children?.length > 0;
  const sublist = hasChildren ? makeSublist(cat.children, cat.total) : [];
  const itemAmount = sublist.length > 1 ? 'Items' : 'Item';

  return (
    <>
      <div
        className="expenses-tile-income-body"
        onClick={() => (hasChildren ? setShowChildren((show) => !show) : setCategoryTransactionsId(cat.id))}
      >
        <div className={`expense-tile-body-left`}>
          {hasChildren ? (
            <>
              <span>{cat.name}</span>
              <span style={{ paddingLeft: 4 }}>
                {showChildren ? <AiOutlineUp style={{ paddingTop: 2 }} /> : <AiOutlineDown style={{ paddingTop: 2 }} />}
              </span>
              {!showChildren && (
                <span className="expense-tile-sublist-count">{` (${sublist.length} ${itemAmount})`}</span>
              )}
            </>
          ) : (
            <div className="expense-tile-category-name">{cat.name}</div>
          )}
        </div>
        <div className="expense-tile-body-right">
          <div className="expense-tile-total">{formatDollars(cat.total)}</div>
          <div>{cat.total > 0 ? formatPercent((cat.total / total) * 100) : ''}</div>
        </div>
      </div>
      <Collapse in={showChildren}>{sublist}</Collapse>
      {(categoryTransactions || transactionListLoading) && (
        <Dialog
          maxWidth={'lg'}
          onClose={() => closeTransactionDialog()}
          open={!!categoryTransactions || transactionListLoading}
        >
          {categoryTransactions?.length && (
            <DialogTitleX className="category-transaction-list-title" onClose={() => closeTransactionDialog()}>
              {`${categoryTransactions[0].category_name} - ${
                selectedMonth.label || formatDate(selectedMonth.startStr) + ' - ' + formatDate(selectedMonth.endStr)
              }`}
            </DialogTitleX>
          )}
          <DialogContent style={{ paddingBottom: 20 }}>
            <GenericTransactionsTable
              transactions={categoryTransactions}
              loading={transactionListLoading}
              loadMore={
                categoryTransactions?.length === pageLimit ? () => setPageLimit((prevLimit) => prevLimit + 20) : null
              }
              loadingMore={loadingMoreTransactions}
              fieldsToHide={['category_name', 'description']}
              mapAccountData
              allowToggles
              allowCategoryEdit
              onEditTransaction={() => setUpdateTransactions(true)}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

function MainExpenseTable({ incomeObj, incomeTotal, expenseObj, expenseTotal, selectedMonth, setSelectedMonth }) {
  const incomesList = [];
  for (const inc of Object.entries(incomeObj)
    .sort((a, b) => b[1].total - a[1].total)
    .map((x) => x[1])) {
    if (inc.total !== 0) {
      incomesList.push(
        <IncomeExpenseRow
          key={inc.id}
          cat={inc}
          total={incomeTotal}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
      );
    }
  }

  const expensesList = [];
  for (const exp of Object.entries(expenseObj)
    .sort((a, b) => b[1].total - a[1].total)
    .map((x) => x[1])) {
    if (exp.total !== 0) {
      expensesList.push(
        <IncomeExpenseRow
          key={exp.id}
          cat={exp}
          total={expenseTotal}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
      );
    }
  }

  let netCashFlowTotal = incomeTotal - expenseTotal;

  return (
    <div className="main-expenses-tile">
      <div className="expenses-tile-income">
        <div className="expenses-tile-income-body">
          <div className="income-tag">Income</div>
          <ExpensesDropdown
            styles={{
              color: 'black',
              width: 175,
              height: 20,
              borderRadius: 50,
              backgroundColor: 'rgb(255, 255, 255)',
              textAlign: 'left'
            }}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
          />
        </div>
        {incomesList}
        <div className="expenses-tile-income-body" style={{ fontWeight: 'bold' }}>
          <div className="expense-tile-body-left">Total Income</div>
          <div className="expense-tile-body-right">
            <div className="expense-tile-total">{formatDollars(incomeTotal)}</div>
            <div>100.0%</div>
          </div>
        </div>
      </div>
      <div className="expenses-tile-expenses">
        <div className="expenses-tile-income-body">
          <div className="expense-tag">Expenses</div>
          <div className="expense-tag-msg">(based off bank debits and matched transactions)</div>
        </div>
        {expensesList}
        <div className="expenses-tile-income-body" style={{ fontWeight: 'bold' }}>
          <div className="expense-tile-body-left">Total Expenses</div>
          <div className="expense-tile-body-right">
            <div className="expense-tile-total">{formatDollars(expenseTotal)}</div>
            <div>100.0%</div>
          </div>
        </div>
        <div className="expenses-tile-income-body">
          <div className="expense-tile-body-left">
            <div className={netCashFlowTotal >= 0 ? 'positive-net-cash-flow-tag' : 'negative-net-cash-flow-tag'}>
              Net Cash Flow
            </div>
          </div>
          <div className="expense-tile-body-right" style={{ fontWeight: 'bold' }}>
            <div className="expense-tile-total">{formatGainLoss(netCashFlowTotal, formatDollars)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainExpenseTable;
