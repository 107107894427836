export const TransformationX = (i, width, LiquidLegend, RightLegend, NetWorthLegend, props) => {
  return LiquidLegend ? (i % 2) * 2000 + 120 : 450;
};

// function RightLegendFunc(width) {
//   if (width > 1170 && width < 1320) {
//     return 750;
//   } else {
//     return 725;
//   }
// }

// function NetWorthLegendFunc(width) {
//   if (width > 1285) {
//     return 385;
//   } else if (width > 1225 && width < 1285) {
//     return 275;
//   } else if (width > 1159 && width < 1225) {
//     return 215;
//   } else if (width > 1100 && width < 1159) {
//     return 125;
//   } else if (width < 1110 && width > 995) {
//     return 120;
//   } else if (width < 995) {
//     return 550;
//   } else {
//     return 225;
//   }
// }

export const TransformationY = (i, LiquidLegend, props) => {
  return props.page === 'liquidAsset' ? i * 285 : LiquidLegend ? Math.floor(i / 2) * 300 + 650 : i * 285;
};

export const LegendX = (LiquidLegend, NetWorthLegend) => {
  if (NetWorthLegend) {
    return 900;
  } else if (LiquidLegend) {
    return 1450;
  } else {
    return 1400;
  }
};
