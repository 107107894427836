import React, { useRef, useState } from 'react';
import { BsPersonCircle } from 'react-icons/bs';

import { Avatar, Button, Grid } from '@material-ui/core';

import PhoneDialog from 'components/dialogs/StandardPhoneDialog';
import { useAuth } from 'contexts/AuthContext';

import InputTextWithLabel from './InputTextWithLabel';

function maskPhone(phone) {
  if (!phone) {
    return 'Disabled';
  }
  return phone.toString().replace(/\d(?=\d{4})/g, '#');
}

function UpdateDetails() {
  const { currentUser, updateUser } = useAuth();
  const { displayPhoto, updatePhoto } = useAuth();
  const userEmail = currentUser.email;
  const userName = currentUser.displayName;
  const [values, setValues] = useState({ name: userName, email: userEmail });
  // const [errors, setErrors] = useState({});
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const inputFile = useRef(null);

  const handleImageSelected = (e) => {
    const file = e.target.files[0];
    //console.log(`filename: ${file.name}`);
    updatePhoto(file);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  function updateUserInfo() {
    setIsEditingName(false);
    setIsEditingEmail(false);
    updateUser(values);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imageClick = () => {
    inputFile.current.click();
  };

  const editProfileName = () => {
    setIsEditingName(true);
  };

  const editProfileEmail = () => {
    setIsEditingEmail(true);
  };

  return (
    <Grid container style={{ padding: 8 }}>
      <Grid item xxs={12} xs={4}>
        <div style={{ cursor: 'pointer' }} onClick={imageClick}>
          {displayPhoto ? <Avatar style={{ width: 100, height: 100 }} src={displayPhoto} /> : <BsPersonCircle />}
        </div>
        <div className="avatar-footer">
          <label className="avatar-footer-label" htmlFor="files" style={{ cursor: 'pointer' }}>
            Edit
          </label>
          <input ref={inputFile} style={{ display: 'none' }} id="files" type="file" onChange={handleImageSelected} />
        </div>
      </Grid>
      <Grid container item xs={8}>
        <Grid item xs={10}>
          <div className="p-fluid">
            <InputTextWithLabel
              label="Name"
              name="name"
              id="name"
              disabled={!isEditingName}
              onChange={handleChange}
              value={values.name}
            />
          </div>
          <div className="p-fluid">
            <InputTextWithLabel
              label="Email"
              name="email"
              id="email"
              disabled={!isEditingEmail}
              onChange={handleChange}
              value={values.email}
            />
          </div>
        </Grid>
        <Grid item container xs={2} alignItems="center" justify="center">
          {isEditingName ? (
            <Button
              variant="text"
              style={{
                textTransform: 'capitalize',
                color: '#0078f5'
              }}
              onClick={updateUserInfo}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="text"
              style={{
                textTransform: 'capitalize',
                color: '#0078f5'
              }}
              onClick={editProfileName}
            >
              Edit
            </Button>
          )}
          {isEditingEmail ? (
            <Button
              variant="text"
              style={{
                textTransform: 'capitalize',
                color: '#0078f5'
              }}
              onClick={updateUserInfo}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="text"
              style={{
                textTransform: 'capitalize',
                color: '#0078f5'
              }}
              onClick={editProfileEmail}
            >
              Edit
            </Button>
          )}
        </Grid>
        <Grid item xs={10}>
          <div className="p-fluid">
            <InputTextWithLabel
              label="Two-Factor"
              id="phone"
              disabled
              value={maskPhone(currentUser?.multiFactor?.enrolledFactors[0]?.phoneNumber)}
            />
          </div>
        </Grid>
        <Grid item container xs={2} alignItems="center" justify="center">
          <Button
            variant="text"
            style={{
              textTransform: 'capitalize',
              color: '#0078f5'
            }}
            onClick={handleClickOpen}
          >
            Edit
          </Button>
          <PhoneDialog open={open} closeCallback={handleClose} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UpdateDetails;
