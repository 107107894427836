import { useState } from 'react';

import { Card } from '@material-ui/core';

import AddAccountsDialog from 'components/dialogs/AddAccountsDialog';
import logo from 'logos/AddAccountLogo.png';

import './AddAccountsTile.css';

const styles = {
  color: '#ffffff',
  backgroundColor: 'rgb(23,120,250)',
  borderRadius: '9px',
  width: '100%',
  minHeight: '55px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: 'none',
  fontSize: '16pt',
  fontWeight: 'bold'
};

function AddAccountsTile() {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  };

  return (
    <Card style={{ borderRadius: '9px' }}>
      <div className="add-accounts-tile-container">
        <AddAccountsDialog open={clicked} setOpen={setClicked} />
        <div className="add-accounts-tile-header">Connect More Accounts</div>
        <div className="add-accounts-tile-body">
          <img src={logo} alt="" style={{ width: 200, height: 150 }} />
          <div className="add-accounts-tile-footer">
            The more accounts you add, the more <br /> accurate your cash flow and net worth.
          </div>
        </div>
        <div style={styles} onClick={handleClick}>
          <div>+ Add New Account</div>
        </div>
      </div>
    </Card>
  );
}

export default AddAccountsTile;
