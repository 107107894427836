import React, { useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import moment from 'moment';

import { AdvisorMatchThinCard, InvestorLayout, NewAccountSteps, RatingGauge, formatAge } from 'components';
import { Step } from 'components/cards/NewAccountSteps';
import { AdvisorNamePopup } from 'components/popups';
import { useAccountsData } from 'contexts';
import cfaLogo from 'logos/credential-platforms/cfa.jpeg';
import cfpLogo from 'logos/credential-platforms/cfp.png';
import { aaHealthColor } from 'utils';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '3.5rem 0 2.5rem',
    borderBottom: '1px solid',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '1.5rem 0 1.5rem'
    }
  },
  title: {
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem'
    }
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '0.5rem 0',
    fontSize: '14px'
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  sectionTitle: { margin: 0, width: '100%' },
  sectionText: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem',
    width: '100%',
    gap: '1rem'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    height: '100%',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  firstRow: {
    gap: '1.5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  gauge: {
    width: '40%'
  },
  colorIndicator: {
    width: '100%',
    height: '0.5rem',
    borderRadius: '1rem 1rem 0 0'
  },
  cardSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem 0 2rem'
  },
  cardFull: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    paddingBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  cardList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    padding: '0 1rem 0 1rem',
    margin: '0 2rem'
  },
  healthIcon: {
    marginTop: '0.2rem'
  },
  donutContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleWhite: {
    width: '100%',
    color: 'white'
  },
  table: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    margin: '1rem 2rem 0 2rem',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  tableRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0'
  },
  label: {
    fontWeight: 'bold',
    marginRight: '1rem'
  },
  firmNames: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem'
  },
  platforms: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem'
    }
  },
  platform: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    width: '30%',
    padding: '2rem',
    borderRadius: '0.5rem',
    color: '#333',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  platformImg: {
    height: '80px'
  },
  link: {
    color: '#333',
    textDecoration: 'underline'
  }
}));

export const CredentialsFactor = () => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const { investorUserData } = useAccountsData();
  const { score_compliance, advisor, advisor_searched_at } = investorUserData;

  const currentDate = new Date().toLocaleDateString();
  const scoreColor = useMemo(() => aaHealthColor(score_compliance), [score_compliance]);

  const designations = useMemo(() => {
    const designations = [];

    if (advisor?.is_cfp) designations.push('CFP');
    if (advisor?.is_cfa) designations.push('CFA');
    if (advisor?.is_cpa) designations.push('CPA');

    if (!designations.length) {
      if (advisor?.is_cfp === false && advisor?.is_cfa === false && advisor?.is_cpa === false) {
        return 'None';
      } else {
        return 'Not Verified';
      }
    }
    return designations.join(', ');
  }, [advisor]);

  let total_exams: string[] = [];
  if (advisor) {
    total_exams = total_exams.concat(advisor.principal_exam_categories_ia);
    total_exams = total_exams.concat(advisor.principal_exam_categories_bc);
    total_exams = total_exams.concat(advisor.product_exam_categories_ia);
    total_exams = total_exams.concat(advisor.product_exam_categories_bc);
    total_exams = total_exams.concat(advisor.state_exam_categories_ia);
    total_exams = total_exams.concat(advisor.state_exam_categories_bc);
  }

  const onDialogClose = () => {
    setOpenDialog(false);
  };
  const openTheDialog = () => {
    setOpenDialog(true);
  };

  let steps: [Step] = [
    {
      title: 'Who is your Advisor?',
      isComplete: score_compliance,
      actionText: 'Complete Now',
      action: 'BmBBBQyn',
      score_attribute: 'advisor_searched_at'
    }
  ];

  let advisorErrorMessage: string | undefined | JSX.Element = undefined;
  if (!advisor) {
    if (advisor_searched_at) {
      steps = [
        {
          title: 'Advisor not found',
          isComplete: !!advisor,
          actionText: 'Try Again',
          action: openTheDialog,
          score_attribute: 'advisor_searched_at'
        }
      ];
    }
  } else if (!score_compliance) {
    advisorErrorMessage = 'Calculating... check back soon.';
  }

  const insight = () => {
    if (!advisor || advisorErrorMessage) {
      return advisorErrorMessage || <NewAccountSteps steps={steps} />;
    }

    let insight = '';
    if (advisor.is_cfp || advisor.is_cfa || advisor.is_cpa) {
      const cert = advisor.is_cfp ? 'CFP' : advisor.is_cfa ? 'CFA' : advisor.is_cpa ? 'CPA' : '';
      insight += `${advisor.first_name} ${advisor.last_name} is a ${cert}, one of the premier designations. `;
    }
    if (advisor.is_ia) {
      if (insight.length > 0) {
        insight += ' In addition, ';
      }
      if (advisor.is_broker) {
        insight += ` ${
          advisor.first_name
        } is dually registered as both a Broker and as a Registered Investment Advisor since ${moment(
          advisor.industry_start_date,
          'YYYY-MM-DD'
        ).format(
          'MMMM YYYY'
        )}. As of 2023, the SEC has put dual-registered advisors on notice in a “Risk Alert” to assess their compliance with Regulation Best Interest.  As a client, it is important for you to know when your advisor is acting as a commission-based broker or a fee-based investment advisor.`;
      } else {
        insight += ` ${advisor.first_name} has been a Registered Investment Advisor since ${moment(
          advisor.industry_start_date,
          'YYYY-MM-DD'
        ).format('MMMM YYYY')} which means they must, at all times, act in the best interest of you, the client.`;
      }
    }
    if (advisor.num_disclosures > 0) {
      insight += ` ${advisor.first_name} ${advisor.last_name} has one or more disclosures which should be reviewed. `;
    }
    if (advisor.is_cfp === false && advisor.is_cfa === false && advisor.is_cpa === false) {
      if (insight.length > 0) {
        insight += ' In addition, ';
      }
      insight += ` ${advisor.first_name} does not hold one of the premier industry designations (CFP, CFA, CPA).`;
    }
    if (!advisor.is_ia) {
      if (insight.length > 0) {
        insight += ' Further, your';
      } else {
        insight += ' Your';
      }
      if (advisor.is_broker) {
        insight +=
          ' advisor is only registered as a Broker, not an Investment Advisor, which means they are only obligated to find suitable investments for you whereas a Registered Investment Advisor is required, at all times, to act in the best interest of you, the client.';
      } else {
        insight +=
          ' advisor is not a Registered Investment Advisor which is required, at all times, to act in the best interest of you, the client.';
      }
    }
    return <p>{insight}</p>;
  };

  return (
    <InvestorLayout>
      <div className={classes.header}>
        <h1 className={classes.title}>Independence &amp; Credentials</h1>
      </div>
      <div className={classes.subHeader}>
        <span>Calculated daily</span>
        <span>as of {currentDate}</span>
      </div>
      <div className={classes.section}>
        <div className={`${classes.row} ${classes.firstRow}`}>
          <div className={classes.gauge}>
            <RatingGauge score={score_compliance} size="lg" />
          </div>
          <div className={classes.sectionText}>
            {insight()}
            <AdvisorNamePopup open={openDialog} onClose={onDialogClose} />
          </div>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.section} id="rbaa-insights">
          <h2 className={classes.titleWhite}>Your Advisor's Work Experience</h2>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.table}>
                <div className={classes.tableRow}>
                  <div className={classes.label}>Name:</div>
                  <div>
                    {advisor?.first_name} {advisor?.last_name || '-'}
                  </div>
                </div>
                <div className={classes.tableRow}>
                  <div className={classes.label}>SEC Registered:</div>
                  <div>{advisor?.industry_start_date ? `${formatAge(advisor.industry_start_date)}` : '-'}</div>
                </div>
                <div className={classes.tableRow}>
                  <div className={classes.label}>Current Firms:</div>
                  <div className={classes.firmNames}>
                    {advisor?.current_firms_ia.length
                      ? advisor.current_firms_ia.map((f: { name: string }) => f.name).join(', ')
                      : '-'}
                  </div>
                </div>
                <div className={classes.tableRow}>
                  <div className={classes.label}>Is an Independent Advisor:</div>
                  <div>{advisor?.is_ia ? 'Yes' : 'No'}</div>
                </div>
                <div className={classes.tableRow}>
                  <div className={classes.label}>Exams:</div>
                  <div>{total_exams.length ? total_exams.join(', ') : '-'}</div>
                </div>
                <div className={classes.tableRow}>
                  <div className={classes.label}>Is a Broker:</div>
                  <div>{advisor?.is_broker ? 'Yes' : 'No'}</div>
                </div>
                <div className={classes.tableRow}>
                  <div className={classes.label}>Number of Disclosures:</div>
                  <div>
                    {advisor?.num_disclosures || 0}{' '}
                    {advisor?.num_disclosures > 0 && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://adviserinfo.sec.gov/individual/summary/${advisor.crd_number}`}
                      >
                        [View at SEC]
                      </a>
                    )}
                  </div>
                </div>
                <div className={classes.tableRow}>
                  <div className={classes.label}>Designations:</div>
                  <div>{designations}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <AdvisorMatchThinCard />
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.cardSection}>
                <h2>Important items to know about your financial advisor:</h2>
                <ul>
                  <li>Years of Experience and registration status.</li>
                  <li>Investment Advisor and/or Broker.</li>
                  <li>Disciplinary history of your advisor.</li>
                  <li>Designations obtained (CFP, CFA).</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.cardSection}>
                <h2>Look for one of the most respected designations:</h2>
                <div className={classes.platforms}>
                  <div className={classes.platform}>
                    <img src={cfpLogo} alt="cfp" className={classes.platformImg} />
                    <p>
                      CFP (Certified Financial Planner) requires several years of delivering financial planning services
                      in addition to passing the - comprehensive CFP Certification Exam. Learn more at{' '}
                      <a className={classes.link} href="https://www.letsmakeaplan.org" target="_blank" rel="noreferrer">
                        www.letsmakeaplan.org
                      </a>
                      .
                    </p>
                  </div>
                  <div className={classes.platform}>
                    <img src={cfaLogo} alt="cfa" className={classes.platformImg} />
                    <p>
                      CFA (Chartered Financial Analyst) is a highly selective process. Fewer than one in five candidates
                      become a CFA charter holder, which takes on average 1,000+ hours of rigorous study plus four years
                      of professional experience. Learn more at{' '}
                      <a className={classes.link} href="https://www.cfainstitute.org" target="_blank" rel="noreferrer">
                        www.cfainstitute.org
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InvestorLayout>
  );
};
