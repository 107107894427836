import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

export const isDate = (date) => {
  return moment(date, 'YYYY-MM-DD', true).isValid();
};

export const isFormFieldValid = (formik, name) => {
  return !!formik.errors[name];
};
export const getFormErrorMessage = (formik, name) => {
  return isFormFieldValid(formik, name) && <small className="p-error">{formik.errors[name]}</small>;
};

export const textField = (formik, name, label, style) => (
  <div className="p-field">
    <span className="p-float-label">
      <InputText
        id={name}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        className={classNames({
          'p-invalid': isFormFieldValid(formik, name)
        })}
        style={style}
      />
      <label
        htmlFor={name}
        className={classNames({
          'p-error': isFormFieldValid(formik, name)
        })}
      >
        {label}
      </label>
    </span>
    {getFormErrorMessage(formik, name)}
  </div>
);

export const currencyField = (formik, name, label) => (
  <div className="p-field">
    <span className="p-float-label">
      <InputNumber
        id={name}
        name={name}
        value={formik.values[name]}
        onValueChange={formik.handleChange}
        mode="currency"
        currency="USD"
        locale="en-US"
        className={classNames({
          'p-invalid': isFormFieldValid(formik, name)
        })}
      />
      <label
        htmlFor={name}
        className={classNames({
          'p-error': isFormFieldValid(formik, name)
        })}
      >
        {label}
      </label>
    </span>
  </div>
);

export const dateField = (formik, name, label) => (
  <>
    <KeyboardDatePicker
      disableToolbar
      autoOk
      fullWidth
      defaultValue={null}
      invalidDateMessage=""
      inputVariant="outlined"
      variant="inline"
      format="MM/DD/yyyy"
      margin="dense"
      id={name}
      label={label}
      value={formik.values[name]}
      onChange={(val) => {
        if (val && typeof val.format === 'function') {
          formik.setFieldValue(name, val.format('YYYY-MM-DD'));
        } else {
          formik.setFieldValue(name, val);
        }
      }}
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
    />
    {getFormErrorMessage(formik, name)}
  </>
);
