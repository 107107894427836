import React, { useState } from 'react';

import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
// import { DriveEta, Home } from '@material-ui/icons';
import { Dialog } from 'primereact/dialog';

import ConnectDropdown from 'components/dropdowns/ConnectDropdown';
import ManualEditAccountValue from 'components/forms/ManualEditAccountValue';
import TransactionTile from 'components/tiles/TransactionTile';
import zillowLogo from 'logos/zillow.png';
import { formatMoney } from 'utils/formatters';

import './BankTile.css';
import 'pages/wealthHub/ExpenseTracker.css';

function BankTile({ acct, assetPage = true, useTransactions = true, pageId }) {
  const [transactionList, setTransactionList] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [name, setName] = useState(acct.name);

  const [change] = useState({
    value: acct.day_change,
    range: 'day'
  });

  // async function getChangeInWorth(IDs) {
  //   const ID = await currentUser.getIdToken();

  //   let currentDay = moment().format('YYYY-MM-DD');
  //   let yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');

  //   const response = await fetch(
  //     `${url}/api/v1/account_timeseries.json?filter[date][gte]=${yesterday}&filter[date][lte]=${currentDay}&filter[account_id][eq]=${IDs}`,
  //     {
  //       // IDs
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${ID}`
  //       }
  //     }
  //   );

  //   const json = await response.json();
  //   setChange({
  //     value: json['data'][json['data'].length - 1]['balance'] - json['data'][0]['balance'],
  //     range: 'day'
  //   });
  // }

  // useEffect(() => {
  //     getChangeInWorth(acct.id)
  // }, [])

  const showTransactions = (e) => {
    if (useTransactions) {
      setTransactionList(true);
    }
  };
  const hideTransactions = () => {
    setTransactionList(false);
  };
  const zillowClick = () => {
    setShowDialog(true);
  };
  // const institutionName = acct["institution"]["name"]
  const logo = acct['institution']['logo_url'];
  // let icon = null;
  // if (acct.account_subtype === 'auto') icon = <DriveEta />;
  // if (acct.account_subtype === 'home') icon = <Home />;
  const style = {
    backgroundColor: acct['institution']['primary_color'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left'
  };

  // const decimalCount = acct.account_subtype == { maximumFractionDigits: 0 };
  const showBalance = acct.balance_current ? (
    acct.is_manual && (!useTransactions || acct.account_type === 'loan') ? (
      <ManualEditAccountValue account={acct} tile={true} useTransactions={useTransactions} />
    ) : (
      <div className="tile-balance-details">
        <div className="tile-balance-title">{useTransactions ? 'Balance' : 'Value'}</div>
        <div className="bank-account-balance">{formatMoney(acct.balance_current ? acct.balance_current : 0, 0)}</div>
        {/* <span className="tile-balance-title" style={{width: 'max-content'}}>{useTransactions ? 'Balance' : 'Value'} </span>
        <span className="bank-account-balance" style={{width: 'max-content'}}>{formatMoney(acct.balance_current ? acct.balance_current : 0)}</span> */}
      </div>
    )
  ) : null;
  // title={acct.name}

  const inst_name = acct.institution_name ? acct.institution_name : acct.institution.name;
  const logoTitle = (
    <div className="logo-container" onClick={showTransactions}>
      <img className="cash-flow-bank-logo" src={logo} alt="" />
    </div>
  );
  const nameTitle = (
    <div className="logo-container" onClick={showTransactions}>
      {inst_name}
    </div>
  );

  return (
    <Card style={{ borderRadius: '9px', height: '360' }}>
      <CardHeader
        title={logo ? logoTitle : nameTitle}
        titleTypographyProps={{ variant: 'subtitle1' }}
        action={
          <div className="dropdown">
            <ConnectDropdown account={acct} name={name} setName={setName} />
          </div>
        }
      />
      <CardContent align="center" justify="center" style={{ height: '320px' }}>
        <div
          style={useTransactions && acct.account_type !== 'loan' ? { cursor: 'pointer' } : { cursor: 'auto' }}
          onClick={showTransactions}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <div>
            <div className="bank-details-container" style={style}>
              <div className="bank-details-header">
                <div className="left-bank-details">
                  {/* {logo && <img className="cash-flow-bank-logo" src={logo} alt=" " />}
                                    {icon && <Avatar className="cash-flow-bank-icon">{icon}</Avatar>} */}
                  {name}
                </div>
                <div className="right-bank-details">{acct.number_display}</div>
              </div>
              <div>{assetPage && acct.balance_current ? showBalance : null}</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  minHeight: 50
                }}
              >
                {acct['institution']['integration'] === 'zillow' && (
                  <div>
                    <Button onClick={zillowClick}>
                      <img
                        src={zillowLogo}
                        alt="Zillow"
                        style={{
                          width: '100px',
                          padding: '10px'
                        }}
                      />
                    </Button>
                    <Dialog
                      header="Zillow Zestimate"
                      visible={showDialog}
                      onHide={() => setShowDialog(false)}
                      dismissableMask={true}
                    >
                      <p>The data is provided “as is” via the Zestimate API</p>
                      <p>
                        <a target="_blank" rel="noopener noreferrer" href={acct.url}>
                          See more details for this property on Zillow
                        </a>
                      </p>
                      <p>
                        © Zillow, Inc., 2006-2020. Use is subject to{' '}
                        <a target="_blank" rel="noopener noreferrer" href="https://www.zillow.com/corp/Terms.htm">
                          Terms of Use
                        </a>
                      </p>
                    </Dialog>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
      {useTransactions && acct.account_type !== 'loan' && transactionList && (
        <TransactionTile
          account={acct}
          open={transactionList}
          onClose={hideTransactions}
          change={change}
          pageId={pageId}
        />
      )}
    </Card>
  );
}

export default BankTile;
