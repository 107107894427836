import { useEffect, useMemo, useState } from 'react';

import { Button, IconButton, LinearProgress, Switch, withStyles } from '@material-ui/core';
import { grey, lightGreen } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import CategoryDropdown from 'components/dropdowns/CategoryDropdown';
import { formatDate, formatDollars } from 'components/tables/GenericAssetsTable';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

export const formatGenericTransactions = (transactions) => {
  return transactions.map((transaction) => {
    const transactionsObject = {
      date: formatDate(transaction.date),
      name: transaction.name.length > 53 ? transaction.name.substring(0, 50).trimEnd() + '...' : transaction.name,
      category_name: (transaction.category_name || transaction.tx_type || transaction.transaction_type),
      description: transaction.merchant_name,
      amount: formatDollars(transaction.amount)
    };
    return transactionsObject;
  });
};

export function GenericTransactionsTable({
  transactions,
  is_manual,
  setTransactionToEdit,
  setTransactionToDelete,
  loading,
  loadMore,
  loadingMore,
  fieldsToHide = [],
  mapAccountData,
  allowToggles,
  allowCategoryEdit,
  onEditTransaction
}) {
  const { currentUser } = useAuth();
  const { AllAccounts } = useAccountsData();

  const [recurring, setRecurring] = useState([]);
  const [excluded, setExcluded] = useState([]);

  useEffect(() => {
    if (!transactions) return;
    setExcluded(transactions.filter((t) => t.is_excluded).map((t) => t.id));
    setRecurring(transactions.filter((t) => t.is_recurring).map((t) => t.id));
  }, [transactions]);

  const transactionsWithAccounts = useMemo(
    () =>
      mapAccountData
        ? (transactions || []).map((t) => {
            return {
              ...t,
              account: AllAccounts.find((account) => account.id === t.account_id)?.name
            };
          })
        : [],
    [AllAccounts, mapAccountData, transactions]
  );

  const toggleRecurring = async (transaction) => {
    const { id } = transaction;
    const ID = await currentUser.getIdToken();
    const res = await fetch(`${url}/api/v1/transactions/${id}.json?`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          id: id,
          type: 'transactions',
          attributes: { is_recurring: !transaction.is_recurring }
        }
      })
    });
    const json = await res.json();
    const transactionId = json?.data?.id;
    setRecurring((recurring) =>
      recurring.includes(transactionId)
        ? recurring.filter((tid) => tid !== transaction.id)
        : [...recurring, transaction.id]
    );
  };

  const toggleExcluded = async (transaction) => {
    const { id } = transaction;
    const ID = await currentUser.getIdToken();
    const res = await fetch(`${url}/api/v1/transactions/${id}.json?`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          id: id,
          type: 'transactions',
          attributes: { is_excluded: !transaction.is_excluded }
        }
      })
    });
    const json = await res.json();
    const transactionId = json?.data?.id;
    setExcluded((excluded) =>
      excluded.includes(transactionId)
        ? excluded.filter((tid) => tid !== transaction.id)
        : [...excluded, transaction.id]
    );
    onEditTransaction();
  };

  const loadingBar = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        padding: 12
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <p>Fetching transactions...</p>
        <LinearProgress />
      </div>
    </div>
  );

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      '&$checked': {
        color: grey[300]
      },
      '&$checked + $track': {
        backgroundColor: lightGreen['A700']
      }
    },
    checked: {},
    track: {}
  })(Switch);

  return loading && !transactions?.length ? (
    loadingBar
  ) : (
    <>
      <DataTable
        className="data-table"
        value={mapAccountData ? transactionsWithAccounts : transactions}
        rows={transactions.length}
      >
        {!fieldsToHide.includes('date') && (
          <Column className="columns" field="date" header="Date" style={{ width: '100px', fontWeight: 'bold' }} />
        )}
        {mapAccountData && <Column className="columns" field="account" header="Account" />}
        {!fieldsToHide.includes('name') && <Column className="columns" field="name" header="Name" />}
        {!fieldsToHide.includes('category_name') && (
          <Column className="columns" field="category_name" header="Activity Type" />
        )}
        {!fieldsToHide.includes('description') && (
          <Column className="columns" field="description" header="Description" />
        )}
        {!fieldsToHide.includes('amount') && <Column className="columns" field="amount" header="Amount" />}
        {allowToggles && (
          <Column
            className="columns"
            header="Recurring"
            body={(row) => (
              <GreenSwitch key={row.id} checked={recurring.includes(row.id)} onChange={() => toggleRecurring(row)} />
            )}
          />
        )}

        {allowToggles && (
          <Column
            className="columns"
            header="Exclude"
            body={(row) => (
              <GreenSwitch key={row.id} checked={excluded.includes(row.id)} onChange={() => toggleExcluded(row)} />
            )}
          />
        )}
        {allowCategoryEdit && (
          <Column
            className="columns"
            header="Category"
            body={(row) => (
              <CategoryDropdown
                key={row.id}
                styles={{
                  color: 'rgb(146,148,151)',
                  backgroundColor: 'rgb(232,233,234)',
                  borderRadius: '20px',
                  width: '100px',
                  overscrollBehavior: 'none'
                }}
                transaction={row}
                onChange={onEditTransaction}
              />
            )}
          />
        )}
        {is_manual && (
          <Column
            className="columns"
            header="Edit"
            body={(row) => (
              <IconButton size="small" onClick={() => setTransactionToEdit(row.id)}>
                <EditIcon />
              </IconButton>
            )}
          />
        )}
        {is_manual && (
          <Column
            className="columns"
            header="Delete"
            body={(row) => (
              <IconButton
                size="small"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this transaction?')) {
                    setTransactionToDelete(row.id);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          />
        )}
      </DataTable>
      {loadMore && (
        <div style={{ width: '100%', padding: 4 }}>
          <Button onClick={loadMore}>Load More</Button>
        </div>
      )}
      {loadingMore && (
        <div style={{ width: '100%', padding: 20 }}>
          <LinearProgress />
        </div>
      )}
    </>
  );
}
