// 3rd party
import { useState } from 'react';

import { Dialog, DialogActions, DialogContent } from '@material-ui/core';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import PhoneDialog from 'components/dialogs/StandardPhoneDialog';
import AddAccountsManual from 'components/forms/AddAccountsManual';
import AddAccountsPopup from 'components/popups/AddAccountsPopup';
// local
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

export default function AddAccountsDialog({ open, setOpen, isBaa }) {
  // contexts
  const { currentUser } = useAuth();
  const { AllAccounts, AllFeaturedInstitutions, reloadDom } = useAccountsData();
  // local state
  const [showManual, setShowManual] = useState(false);
  const [requireSMS, setRequireSMS] = useState(
    AllAccounts.length === 0 &&
      (!currentUser?.multiFactor?.enrolledFactors || currentUser.multiFactor.enrolledFactors.length === 0)
  );
  // handlers
  const closeClick = () => {
    setOpen(false);
    reloadDom();
  };
  const smsClose = () => setOpen(false);
  const smsAdded = () => setRequireSMS(false);

  return (
    <>
      <PhoneDialog open={open && requireSMS} closeCallback={smsClose} addedCallback={smsAdded} />
      <Dialog
        open={open && !requireSMS}
        onClose={closeClick}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="simple-dialog-title"
        disableEnforceFocus={true}
      >
        <DialogTitleX onClose={closeClick}>
          {showManual ? 'Manually add an Account to Rainbook' : 'Select your Account to Connect to Rainbook'}
        </DialogTitleX>
        <DialogContent dividers={true}>
          {showManual ? (
            <AddAccountsManual handleMainClose={closeClick} />
          ) : (
            <AddAccountsPopup
              handleMainClose={closeClick}
              accounts={AllAccounts}
              institutions={AllFeaturedInstitutions}
            />
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', cursor: 'pointer' }}>
          {showManual ? (
            <div onClick={() => setShowManual(false)}>Back to Connect Accounts</div>
          ) : (
            <div onClick={() => setShowManual(true)}>Or Add Manual Accounts</div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
