import React, { useEffect, useState } from 'react';

import { Collapse, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import ManualAddAccount from 'components/forms/ManualAddAccount';
import {
  ApiKeySecretLink,
  CoinbaseLink,
  CoinbaseProLink,
  GeminiWalletLink,
  MarketcheckAutoLink,
  PlaidAccount,
  PlaidWeb3Link,
  SneakersLink,
  TDAmeritradeLink,
  ZaboLink,
  ZillowLink
} from 'components/integrations';
import addCollectable from 'logos/addCollectable.png';
import addOtherpersonal from 'logos/addOtherpersonal.png';
import addPrivateequity from 'logos/addPrivateequity.png';
import addRealestatefund from 'logos/addRealestatefund.png';
import addSneakers from 'logos/addSneakers.png';

import './AddAccountsPopup.css';

const isCrypto = (i) =>
  ['coinbase', 'coinbasepro', 'zabo', 'kraken', 'gemini', 'binance', 'ftx', 'plaid_web3'].indexOf(i.integration) > -1;
// TODO: should make this into a .class and remove style props in components where applicable
export const styles = {
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  backgroundColor: '#ffffff',
  textAlign: 'center',
  alignItems: 'center',
  fontSize: '8pt',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: 'none',
  marginRight: 'none'
};
const ameritradeIntegrationKey = 'ameritrade';
const bankIntegrationTypes = ['plaid', ameritradeIntegrationKey];
const filterManualInstitutions = (institutions) => institutions.filter((i) => i.integration !== 'manual');

function AddAccountsPopup(props) {
  // styling
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  // local states
  const [accounts, setAccounts] = useState([]);
  const [openBank, setOpenBank] = useState(!isSM);
  const [openCrypto, setOpenCrypto] = useState(!isSM);
  const [openRealEstate, setOpenRealEstate] = useState(!isSM);
  const [openPersonal, setOpenPersonal] = useState(!isSM);
  // filters
  const institutions = filterManualInstitutions(props.institutions);
  // handlers
  const addAccounts = (data) => {
    if (data) {
      setAccounts(accounts.concat(data));
    }
  };
  // open all collapsed sections if larger than sm
  useEffect(() => {
    setOpenBank(!isSM);
    setOpenCrypto(!isSM);
    setOpenRealEstate(!isSM);
    setOpenPersonal(!isSM);
  }, [isSM]);

  // TODO: should break this out into components, ex) banks, crypto, real estate
  return (
    <Grid container spacing={2} align="center">
      <Grid item xs={12} md={4} style={isSM ? {} : { borderRight: 'solid 1px black' }}>
        <div className="plaid-tile-header">
          <div
            className="plaid-tile-asset-class"
            style={isSM ? { cursor: 'pointer', verticalAlign: 'middle' } : {}}
            onClick={() => {
              if (isSM) setOpenBank((state) => !state);
            }}
          >
            {isSM &&
              (openBank ? (
                <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
              ) : (
                <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
              ))}
            Banks, Brokerages
          </div>
          <Collapse in={openBank} timeout="auto" unmountOnExit>
            <div className="plaid-link-disclaimer">
              Once you select your account, a pop up will open to connect securely.
            </div>
          </Collapse>
        </div>
        <Collapse in={openBank} timeout="auto" unmountOnExit>
          <Grid container spacing={0} alignContent="flex-start">
            {institutions
              .filter(
                (i) =>
                  bankIntegrationTypes.includes(i.integration) &&
                  i.page !== 'personal' &&
                  ['Fundrise', 'PeerStreet'].indexOf(i.name) === -1
              )
              .map((inst, idx) =>
                inst?.integration === ameritradeIntegrationKey ? (
                  <Grid item xs={6} key={`${idx}-ameritrade`} className="account-grid-item">
                    <div style={styles}>
                      <TDAmeritradeLink
                        institution={inst}
                        onAccounts={addAccounts}
                        handleMainClose={props.handleMainClose}
                      />
                    </div>
                  </Grid>
                ) : (
                  <PlaidAccount handleMainClose={props.handleMainClose} institution={inst} key={`${idx}-plaid`} />
                )
              )}
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={4} style={isSM ? {} : { borderRight: 'solid 1px black' }}>
        <div className="plaid-tile-header">
          <div
            className="plaid-tile-asset-class"
            style={isSM ? { cursor: 'pointer', verticalAlign: 'middle' } : {}}
            onClick={() => {
              if (isSM) setOpenCrypto((state) => !state);
            }}
          >
            {isSM &&
              (openCrypto ? (
                <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
              ) : (
                <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
              ))}
            Crypto
          </div>
        </div>
        <Collapse in={openCrypto} timeout="auto" unmountOnExit>
          <Grid container spacing={0} alignContent="flex-start">
            {institutions.filter(isCrypto).flatMap(
              (inst, idx) =>
                (inst.integration === 'coinbase' && (
                  <Grid item xs={6} key={`${idx}-coinbase`} className="account-grid-item">
                    <div style={styles}>
                      <CoinbaseLink
                        handleMainClose={props.handleMainClose}
                        institution={inst}
                        onAccounts={addAccounts}
                      />
                    </div>
                  </Grid>
                )) ||
                (inst.integration === 'coinbasepro' && (
                  <Grid item xs={6} key={`${idx}-coinbasepro`} className="account-grid-item">
                    <div style={styles}>
                      <CoinbaseProLink
                        handleMainClose={props.handleMainClose}
                        institution={inst}
                        name={inst.name}
                        styles={styles}
                        onAccounts={addAccounts}
                      />
                    </div>
                  </Grid>
                )) ||
                (inst.integration === 'ftx' && (
                  <Grid item xs={6} key={`${idx}-ftx`} className="account-grid-item">
                    <div style={styles}>
                      <ApiKeySecretLink
                        handleMainClose={props.handleMainClose}
                        institution={inst}
                        integrationName={'FTX Account'}
                        supportUrl={'https://rainbook.com/connect-ftx'}
                        styles={styles}
                        onAccounts={addAccounts}
                      />
                    </div>
                  </Grid>
                )) ||
                (inst.integration === 'kraken' && (
                  <Grid item xs={6} key={`${idx}-kraken`} className="account-grid-item">
                    <div style={styles}>
                      <ApiKeySecretLink
                        handleMainClose={props.handleMainClose}
                        institution={inst}
                        integrationName={'Kraken Wallet'}
                        supportUrl={'https://rainbook.com/connect-kraken'}
                        styles={styles}
                        onAccounts={addAccounts}
                      />
                    </div>
                  </Grid>
                )) ||
                (inst.integration === 'gemini' && (
                  <Grid item xs={6} key={`${idx}-gemini`} className="account-grid-item">
                    <div style={styles}>
                      <GeminiWalletLink
                        handleMainClose={props.handleMainClose}
                        institution={inst}
                        onAccounts={addAccounts}
                      />
                    </div>
                  </Grid>
                )) ||
                (inst.integration === 'binance' && (
                  <Grid item xs={6} key={`${idx}-binance`} className="account-grid-item">
                    <div style={styles}>
                      <ApiKeySecretLink
                        handleMainClose={props.handleMainClose}
                        institution={inst}
                        integrationName={'Binance Wallet'}
                        supportUrl={'https://rainbook.com/connect-binance'}
                        styles={styles}
                        onAccounts={addAccounts}
                      />
                    </div>
                  </Grid>
                )) ||
                (inst.integration === 'zabo' && (
                  <Grid item xs={6} key={`${idx}-zabo`} className="account-grid-item">
                    <ZaboLink
                      handleMainClose={props.handleMainClose}
                      styles={styles}
                      onAccounts={addAccounts}
                      institution={inst}
                    />
                  </Grid>
                )) ||
                (inst.integration === 'plaid_web3' && (
                  <Grid item xs={6} key={`${idx}-plaid_web3`} className="account-grid-item">
                    <PlaidWeb3Link
                      handleMainClose={props.handleMainClose}
                      styles={styles}
                      onAccounts={addAccounts}
                      institution={inst}
                    />
                  </Grid>
                ))
            )}
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={4} style={{ height: '100%' }}>
        <div className="plaid-right-top">
          <div className="plaid-tile-header">
            <div
              className="plaid-tile-asset-class"
              style={
                isSM
                  ? {
                      cursor: 'pointer',
                      verticalAlign: 'middle'
                    }
                  : {}
              }
              onClick={() => {
                if (isSM) setOpenRealEstate((state) => !state);
              }}
            >
              {isSM &&
                (openRealEstate ? (
                  <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
                ) : (
                  <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
                ))}
              Real Estate, Private Equity, Collectibles
            </div>
          </div>
          <Collapse in={openRealEstate} timeout="auto" unmountOnExit>
            <Grid container alignContent="flex-start">
              {institutions
                .filter((i) => i.integration === 'plaid' && ['Fundrise', 'PeerStreet'].indexOf(i.name) > -1)
                .map((inst, idx) => (
                  <PlaidAccount handleMainClose={props.handleMainClose} institution={inst} key={`${idx}-plaid-alt`} />
                ))}
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  handleMainClose={props.handleMainClose}
                  name="Manual Private Equity"
                  accountType="investment"
                  accountSubType="private equity"
                  logo={addPrivateequity}
                  styles={styles}
                />
              </Grid>
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  handleMainClose={props.handleMainClose}
                  name="Manual Real Estate Fund"
                  accountType="investment"
                  accountSubType="real estate"
                  logo={addRealestatefund}
                  styles={styles}
                />
              </Grid>

              {institutions
                .filter((i) => i.page === 'alternative')
                .flatMap((inst, idx) => {
                  return (
                    (inst.integration === 'sneakers' && (
                      <Grid item xs={6} className="account-grid-item" key={`${idx}-sneakers`}>
                        <SneakersLink
                          handleMainClose={props.handleMainClose}
                          institution={inst}
                          name={inst.name}
                          styles={styles}
                          onAccounts={addAccounts}
                          logo={inst.logo_url || addSneakers}
                        />
                      </Grid>
                    )) ||
                    []
                  );
                })}

              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  handleMainClose={props.handleMainClose}
                  name="Collectable"
                  accountType="other"
                  accountSubType="collectible"
                  logo={addCollectable}
                  styles={styles}
                />
              </Grid>
            </Grid>
          </Collapse>
        </div>
        <div className="plaid-right-bottom">
          <div className="plaid-tile-header">
            <div
              className="plaid-tile-asset-class"
              style={
                isSM
                  ? {
                      cursor: 'pointer',
                      verticalAlign: 'middle'
                    }
                  : {}
              }
              onClick={() => {
                if (isSM) setOpenPersonal((state) => !state);
              }}
            >
              {isSM &&
                (openPersonal ? (
                  <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
                ) : (
                  <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
                ))}
              Personal
            </div>
          </div>
          <Collapse in={openPersonal} timeout="auto" unmountOnExit>
            <Grid container alignContent="flex-start">
              {institutions
                .filter((i) => i.page === 'personal')
                .flatMap((inst, idx) => {
                  return (
                    (inst.integration === 'zillow' && (
                      <Grid item xs={6} key={`${idx}-zillow`} className="account-grid-item">
                        <ZillowLink
                          handleMainClose={props.handleMainClose}
                          institution={inst}
                          name="Add Home"
                          styles={styles}
                          onAccounts={addAccounts}
                        />
                      </Grid>
                    )) ||
                    (inst.integration === 'marketcheck' && (
                      <Grid item xs={6} key={`${idx}-marketcheck`} className="account-grid-item">
                        <MarketcheckAutoLink
                          handleMainClose={props.handleMainClose}
                          styles={styles}
                          institution={inst}
                          name="Add Vehicle"
                        />
                      </Grid>
                    )) ||
                    (inst.integration === 'plaid' && (
                      <PlaidAccount
                        handleMainClose={props.handleMainClose}
                        institution={inst}
                        key={`${idx}-plaid-account`}
                      />
                    )) ||
                    []
                  );
                })}
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount name="Other" accountType="other" logo={addOtherpersonal} styles={styles} />
              </Grid>
            </Grid>
          </Collapse>
        </div>
      </Grid>
    </Grid>
  );
}

export default AddAccountsPopup;
