import React, { useRef, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link, useHistory } from 'react-router-dom';

import { Tooltip, makeStyles } from '@material-ui/core';

import {
  AdvisorNamePopup,
  AdvisorScoreAction,
  InvestorLayout,
  MorningstarConnect,
  NewAccountSteps,
  ProcessingMessage,
  StatementsButton,
  TooltipIcon,
  formatPercent
} from 'components';
import {
  AdvisorMatchThinCard,
  RatingCardCredentials,
  RatingCardFees,
  RatingCardNoAdvisor,
  RatingCardPerformance
} from 'components/cards';
import { ScoreMeterSimple, TimeSeriesChart } from 'components/charts';
import { useAccountsData, useAuth } from 'contexts';
import LoadingImg from 'logos/RainbookLoading.gif';
import AdvisorImg from 'logos/flywheel.png';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '1.5rem'
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    height: '100%',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  topRow: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  title: {
    textAlign: 'left',
    borderBottom: 'solid 1px',
    display: 'inline',
    '& span': {
      fontWeight: 'normal'
    }
  },
  titleWhite: {
    width: '100%',
    color: 'white'
  },
  multititle: {
    textAlign: 'left',
    textDecoration: 'underline',
    textUnderlineOffset: '8px',
    '& span': {
      fontWeight: 'normal'
    }
  },
  ratings: {
    width: '100%',
    textAlign: 'left',
    fontSize: '1.25rem'
  },
  ratingCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  },
  advisorImgSection: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: 'white',
    padding: '3rem 0'
  },
  advisorImg: {
    width: '75%',
    height: '100%',
    objectFit: 'contain'
  },
  linkButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    padding: '0.8rem 1.5rem',
    color: 'rgb(21, 120, 246)',
    fontWeight: 700,
    border: '1px solid rgb(21, 120, 246)',
    borderRadius: '0.5rem',
    margin: '1rem',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      textDecoration: 'none',
      color: 'white',
      borderColor: 'white',
      backgroundColor: 'rgb(21, 120, 246)'
    },
    '&:active': {
      textDecoration: 'none'
    },
    '&:focus': {
      outline: 'none',
      textDecoration: 'none'
    }
  },
  otherFactors: {
    display: 'flex',
    flex: 1,
    maxWidth: '530px',
    flexDirection: 'column'
  },
  otherFactor: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '20px',
    paddingBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  otherFactorTitle: {
    fontWeight: 'bold',
    fontSize: '1.4em'
  },
  otherFactorSubTitle: {
    whiteSpace: 'nowrap',
    fontSize: 'small',
    paddingTop: '2px'
  },
  otherFactorAction: {},
  otherFactorTextContainer: {},
  timeseriesContainer: {
    display: 'flex',
    border: '3px solid rgb(21, 120, 246)',
    padding: '20px',
    borderRadius: '9px',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      border: 'none'
    }
  },
  timeseriesPending: {
    backgroundColor: '#a5a5a5',
    borderColor: '#a5a5a5'
  },
  trademark: {
    fontSize: 'small'
  },
  scoreContainer: {
    width: '100%',
    border: '3px solid rgb(21, 120, 246)',
    borderRadius: '9px',
    padding: '20px',
    background: 'rgb(21, 120, 246)',
    color: 'white',
    [theme.breakpoints.up('lg')]: {
      minWidth: '360px',
      maxWidth: '400px'
    }
  },
  matchLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5rem',
    color: 'white',
    fontWeight: 500,
    width: '22rem',
    padding: '0.8rem 1rem',
    borderStyle: 'solid',
    backgroundColor: 'rgb(21, 120, 246)',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(21, 120, 246, 0.75)'
    }
  },
  processingArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    '& img': {
      maxWidth: '100px',
      maxHeight: '100px',
      width: 'auto',
      height: 'auto'
    }
  },
  noScoreContainer: {
    border: '3px solid #a5a5a5',
    background: '#a5a5a5'
  }
}));

const currentDate = new Date().toLocaleDateString();

export const AdvisorAnalyzer = () => {
  const classes = useStyles();
  const history = useHistory();
  const mstarRef = useRef<HTMLInputElement>(null);

  const {
    baaUserData: { baa_investor_credentials },
    investorUserData: {
      advisor,
      advisor_searched_at,
      issue_types,
      score_overall,
      score_performance,
      score_cash,
      score_fees,
      score_alternatives,
      score_reporting,
      score_engagement,
      statement_in_progress,
      stats_breakdown
    },
    BaaAccounts,
    scoreSubmitted,
    investorTimeseriesData,
    investorPerformanceData,
    advisorScoreChartData,
    portfolioPerformanceData
  } = useAccountsData();

  const { noAdvisor } = useAuth();

  const setupComplete = noAdvisor
    ? BaaAccounts.length > 0 && score_performance
    : BaaAccounts.length > 0 && score_performance && advisor;
  const issueTypes: [string] = issue_types || [];
  const isDataIssue = issueTypes.includes('data');
  const performancePending = scoreSubmitted.includes('score_performance') && (noAdvisor || advisor);

  let benchmarkDetail = '';
  if (stats_breakdown?.benchmark_allocation_percentages?.equity) {
    const rounded = Math.round(stats_breakdown.benchmark_allocation_percentages.equity);
    benchmarkDetail = `${stats_breakdown.benchmark_name} (${rounded}%/${100 - rounded}%)`;
  }

  const [advisorNameDialogOpen, setAdvisorNameDialogOpen] = useState(false);
  const onAdvisorNameDialogClose = () => {
    setAdvisorNameDialogOpen(false);
  };
  const openAdvisorNameDialog = () => {
    setAdvisorNameDialogOpen(true);
  };
  const connectStep = baa_investor_credentials?.length
    ? {
        title: 'Connect Account or Upload Statements',
        isComplete: score_fees && score_cash,
        actionText: 'Fix Connection Issues',
        score_attribute: 'score_fees',
        action: () => mstarRef.current?.click()
      }
    : {
        title: 'Connect Account or Upload Statements',
        isComplete: score_fees && score_cash,
        isPending: BaaAccounts.length > 0,
        actionText: 'Connect or Upload',
        score_attribute: 'score_fees',
        action: () => history.push('/advisor-analyzer/add-account')
      };

  return (
    <InvestorLayout>
      <div className={classes.section}>
        <div className={classes.header}>
          <h2 className={classes.title}>
            RAIN<sup className={classes.trademark}>®</sup> Report
          </h2>
          <p>{currentDate}</p>
        </div>
        <div className={`${classes.row} ${classes.topRow}`}>
          <div className={`${classes.scoreContainer} ${!score_overall && classes.noScoreContainer}`}>
            <ScoreMeterSimple
              value={noAdvisor ? 0 : score_overall}
              change={0}
              mstarRef={mstarRef}
            />
          </div>
          {performancePending || statement_in_progress || isDataIssue ? (
            <ProcessingMessage {...{ statement_in_progress, isDataIssue }} />
          ) : (
            <div className={classes.timeseriesContainer}>
              {setupComplete ? (
                noAdvisor ? (
                  <div>
                    <h2>Rainbook Advisor Network</h2>
                    <p>
                      Our curated network of independent RIA firms collectively manage over $9B in assets. You are
                      matched with two (quality over quantity) vetted advisors who are all:
                    </p>
                    <ul>
                      <li>Fiduciary: legally bound to act in your best interest</li>
                      <li>Qualified: designated CFP®, CFA or CPA</li>
                      <li>Fees: Competitive pricing. No hidden fees.</li>
                    </ul>
                    <Link className={classes.matchLink} to="/advisor-analyzer/advisor-match">
                      No Obligation - Discover Your Matches
                      <AiOutlineArrowRight size={16} />
                    </Link>
                  </div>
                ) : (
                  <TimeSeriesChart
                    tabNames={[
                      <>
                        Portfolio Performance &nbsp;
                        <TooltipIcon
                          size={16}
                          title={
                            <div>
                              Portfolio is the historical performance of your current stock, cash, ETF, and mutual fund
                              holdings, extrapolated backward.
                            </div>
                          }
                        />
                      </>,
                      <>
                        Advisor Score &nbsp;
                        <TooltipIcon
                          size={16}
                          title={
                            <div>
                              Your RAIN® report advisor score is determined through the consideration of weighted
                              factors, encompassing elements such as your fees, relative performance, investment
                              offering, advisor independence, credentials, and the level of engagement exhibited by your
                              advisor in the client relationship.
                            </div>
                          }
                        />
                      </>
                    ]}
                    tabOneData={portfolioPerformanceData}
                    tabTwoData={noAdvisor ? [] : advisorScoreChartData}
                    tabOneDateAxis="week"
                    tabPosition="left"
                    tabOneChartClick={() => history.push('/advisor-analyzer/performance-factor')}
                    overlayOne={
                      !investorPerformanceData
                        ? 'Loading...'
                        : portfolioPerformanceData?.labels.length &&
                          portfolioPerformanceData?.labels.length < 30 &&
                          'Portfolio monitoring in progress. Updates occur daily.'
                    }
                    overlayTwo={
                      noAdvisor
                        ? 'Interested in using a financial advisor? Click Free Consultation to learn more.'
                        : !investorTimeseriesData
                        ? 'Loading...'
                        : !advisorScoreChartData?.labels.length
                        ? 'Finish your factors to calculate your Advisor Score!'
                        : undefined
                    }
                    note={
                      <>
                        <span>*{benchmarkDetail}</span>{' '}
                        <TooltipIcon
                          size={16}
                          title={
                            <div>
                              The benchmark is the Morningstar US Target Allocation Index that most closely aligns with
                              your current portfolio (Equity%/Fixed Income%). The Morningstar Target Allocation Index
                              family consists of indexes that offer a diversified mix of stocks and bonds for investors
                              to benchmark their allocation funds.
                            </div>
                          }
                        />
                      </>
                    }
                    noteTwo={
                      <>
                        <span>Advisor Score information</span>{' '}
                        <TooltipIcon
                          size={16}
                          title={
                            <div>
                              Your RAIN® report advisor score is determined through the consideration of weighted
                              factors, encompassing elements such as your fees, relative performance, investment
                              offering, advisor independence, credentials, and the level of engagement exhibited by your
                              advisor in the client relationship.
                            </div>
                          }
                        />
                      </>
                    }
                  />
                )
              ) : (
                <>
                  <NewAccountSteps
                    header="Complete the following to analyze your portfolio:"
                    steps={[
                      connectStep,
                      ...(noAdvisor
                        ? []
                        : [
                            {
                              title: 'Who is your Advisor?',
                              isComplete: advisor,
                              actionText: advisor_searched_at ? 'Try Again' : 'Complete Now',
                              action: advisor_searched_at ? openAdvisorNameDialog : 'BmBBBQyn',
                              score_attribute: 'advisor_searched_at'
                            }
                          ])
                    ]}
                  />
                  <AdvisorNamePopup open={advisorNameDialogOpen} onClose={onAdvisorNameDialogClose} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {noAdvisor && (
        <div className={classes.section}>
          <AdvisorMatchThinCard />
        </div>
      )}
      <div className={classes.section} style={{ paddingBottom: '4rem' }} id="rbaa-rating-summary">
        <div style={{ width: '100%', display: 'flex' }}>
          <h2 className={classes.title}>
            RAIN<sup className={classes.trademark}>®</sup> Report <span>Critical Factors</span>
          </h2>
        </div>
        <div style={{ paddingBottom: '20px' }}>{!noAdvisor && <AdvisorMatchThinCard />}</div>
        <div className={classes.ratingCards}>
          {noAdvisor ? (
            <>
              <RatingCardPerformance />
              <RatingCardNoAdvisor
                title="Overall Fees"
                description="Amount paid (inclusive of fund fees) and cost of relationship."
                contentHeader=""
              />
              <RatingCardNoAdvisor
                title="Independence"
                description="Broker or Investment Advisor? CFP, CPA, CFA?"
                contentHeader=""
              />
            </>
          ) : (
            <>
              <RatingCardPerformance />
              <RatingCardFees />
              <RatingCardCredentials />
            </>
          )}
        </div>
      </div>
      {!noAdvisor && (
        <div className={classes.sectionContainer} style={{ color: 'white', paddingTop: '1rem', paddingBottom: '2rem' }}>
          <div className={classes.section}>
            <div className={classes.row}>
              <div style={{ flex: 1, maxWidth: '530px' }}>
                <h2 className={classes.multititle}>
                  RAIN<sup className={classes.trademark}>®</sup> Report <span>Additional Factors</span>
                </h2>
                <p>
                  The importance of a quality relationship with your financial advisor cannot be understated. While the
                  above ‘Critical Factors’ are all quantitive, there are also key additional factors that you should
                  consider when making a decision on a financial advisor –– trust being one of the most critical.
                </p>
                <p>
                  Strengthen your RAIN® advisor score and improve the matching capability by integrating these crucial
                  soft factors. Complete all three reviews to fortify your score and enhance the accuracy of potential
                  advisor matches.
                </p>
              </div>
              <div className={classes.otherFactors}>
                <h2 className={classes.multititle}>
                  {[score_engagement, score_reporting, score_alternatives].filter((value) => value).length} of 3
                  Complete
                </h2>
                <div className={classes.otherFactor}>
                  <div className={classes.otherFactorTextContainer}>
                    <div className={classes.otherFactorTitle}>Engagement & Trust</div>
                    <div className={classes.otherFactorSubTitle}>
                      Frequency of meetings, responsiveness, trustworthiness
                    </div>
                  </div>
                  <div className={classes.otherFactorAction}>
                    <AdvisorScoreAction
                      score={score_engagement}
                      score_attribute="score_engagement"
                      typeformId="YUErtPSf"
                    />
                  </div>
                </div>
                <div className={classes.otherFactor}>
                  <div className={classes.otherFactorTextContainer}>
                    <div className={classes.otherFactorTitle}>Reporting Quality</div>
                    <div className={classes.otherFactorSubTitle}>
                      Clear reporting, benchmark inclusion, fee transparency.
                    </div>
                  </div>
                  <div className={classes.otherFactorAction}>
                    <AdvisorScoreAction
                      score={score_reporting}
                      score_attribute="score_reporting"
                      typeformId="RQ1dKN9C"
                    />
                  </div>
                </div>
                <div className={classes.otherFactor}>
                  <div className={classes.otherFactorTextContainer}>
                    <div className={classes.otherFactorTitle}>Access to Alternatives</div>
                    <div className={classes.otherFactorSubTitle}>
                      Inclusion of alternatives, beyond stocks and bonds.
                    </div>
                  </div>
                  <div className={classes.otherFactorAction}>
                    <AdvisorScoreAction
                      score={score_alternatives}
                      score_attribute="score_alternatives"
                      typeformId="i1Bhe3pc"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.row}>
              <AdvisorMatchThinCard />
            </div>
          </div>
        </div>
      )}
      <div className={`${classes.section}, ${classes.advisorImgSection}`}>
        <img className={classes.advisorImg} src={AdvisorImg} alt="advisor" />
      </div>
      <MorningstarConnect refForClick={mstarRef} />
    </InvestorLayout>
  );
};
