import { useState } from 'react';

import { Dialog, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import ManualAssetsPopup from 'components/popups/ManualAssetsPopup';
import { formatDollars, formatGainLoss, formatPercent } from 'components/tables/GenericAssetsTable';

export const formatCollectibleAssets = (assets) => {
  return assets.map((asset) => {
    const gainLossDollarDiff = asset.value - (asset.cost_basis || 0);
    const gainLossDollar = formatGainLoss(gainLossDollarDiff, formatDollars);

    const percentDifference = (asset.cost_basis || 0) !== 0 ? (gainLossDollarDiff / asset.cost_basis) * 100 : undefined;
    const gainLossPercent = formatGainLoss(percentDifference, formatPercent);

    const holdingsObject = {
      image_thumbnail: asset.logo_url,
      image: asset.logo_url_large,
      name: asset.name,
      quantity: asset.quantity,
      'cost/quantity': asset.cost_basis && asset.quantity ? formatDollars(asset.cost_basis / asset.quantity) : '',
      price: formatDollars(asset.price),
      value: formatDollars(asset.value),
      'gain/loss_$': asset.cost_basis ? gainLossDollar : '',
      'gain/loss_%': asset.cost_basis ? gainLossPercent : '',
      _asset: { ...asset, account_subtype: 'collectible' }
    };
    return holdingsObject;
  });
};

export function CollectibleAssetsTable({ holdings, is_manual, deleteAsset }) {
  const [show, setShow] = useState(false);
  const [asset, setAsset] = useState({});
  const [showImage, setShowImage] = useState(false);

  const imgCount = holdings.reduce((a, b) => a + (b.image_thumbnail ? 1 : 0), 0);

  return (
    <>
      <DataTable scrollable row className="data-table" value={holdings} rows={holdings.length}>
        <Column
          className="columns"
          field="image_thumbnail"
          header="Item"
          body={(row) =>
            row.image_thumbnail ? (
              <img
                className="holding-logo"
                src={row.image_thumbnail}
                alt=""
                onClick={() => {
                  setShowImage(row);
                }}
              />
            ) : (
              <div style={imgCount > 0 ? { minHeight: '60px' } : {}} />
            )
          }
        ></Column>
        <Column className="columns" field="name" header="Description"></Column>
        <Column className="columns" field="quantity" header="Quantity"></Column>
        <Column className="columns" field="cost/quantity" header="Cost/unit"></Column>
        <Column className="columns" field="price" header="Price/unit"></Column>
        <Column className="columns" field="value" header="Market Value"></Column>
        <Column className="columns" field="gain/loss_$" header="Gain / Loss $"></Column>
        <Column className="columns" field="gain/loss_%" header="Gain / Loss %"></Column>
        {is_manual && (
          <Column
            className="columns"
            header="Edit"
            style={{
              minWidth: '30px',
              width: '30px',
              padding: 'unset'
            }}
            body={(row) => (
              <IconButton
                size="small"
                onClick={() => {
                  setAsset(row._asset);
                  setShow(true);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          ></Column>
        )}
        {is_manual && (
          <Column
            className="columns"
            header="Delete"
            style={{
              minWidth: '30px',
              width: '30px',
              padding: 'unset'
            }}
            body={(row) => (
              <IconButton
                size="small"
                onClick={async () => {
                  if (window.confirm('Are you sure you want to delete this asset?')) {
                    deleteAsset(row._asset);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          ></Column>
        )}
      </DataTable>

      <Dialog open={showImage} maxWidth={'lg'}>
        <DialogTitleX onClose={() => setShowImage(false)}>{showImage.name || 'Image'}</DialogTitleX>
        <img src={showImage.image} alt="" />
      </Dialog>

      <ManualAssetsPopup show={show} setShow={setShow} toggle={() => {}} account={asset} edit={true} />
    </>
  );
}
