import React, { ReactNode, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  collapsibleTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.5rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
  },
  collapsibleText: {
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '1rem',
    textAlign: 'left',
    backgroundColor: '#FFF',
    borderRadius: '0.5rem',
    border: 'none',
    outline: 'none',
    cursor: 'pointer'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '1rem',
    backgroundColor: '#EFF2F5'
  },
  text: {
    width: '100%',
    margin: '0',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.6)'
  }
}));

export const CollapsibleText = ({ title, text }: { title: string | ReactNode; text: ReactNode }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.collapsibleTextContainer}>
      <button className={classes.collapsibleText} onClick={() => setOpen((open) => !open)}>
        {title}
        {open ? <BsChevronUp /> : <BsChevronDown />}
      </button>
      {open && (
        <div className={classes.textContainer}>
          <div className={classes.text}>{text}</div>
        </div>
      )}
    </div>
  );
};
