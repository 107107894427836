import React, { useEffect, useState } from 'react';

import { SneakersLink } from 'components/integrations';
import ManualAssetsPopup from 'components/popups/ManualAssetsPopup';
import { CollectibleAssetsTable, formatCollectibleAssets } from 'components/tables/CollectibleAssetsTable';
import { CryptoCurrencyAssetsTable, formatCryptoCurrencyAssets } from 'components/tables/CryptoCurrencyAssetsTable';
import { GenericAssetsTable, formatGenericAssets } from 'components/tables/GenericAssetsTable';
import { PrivateEquityAssetsTable, formatPrivateEquityAssets } from 'components/tables/PrivateEquityAssetsTable';
import { SneakerAssetsTable, formatSneakerAssets } from 'components/tables/SneakerAssetTable';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

function AssetHoldingsTable({ account }) {
  const { currentUser } = useAuth();

  const [assets, setAssets] = useState(account.assets.filter((a) => a.quantity && a.quantity !== '' && a.quantity > 0));
  const [holdings, setHoldings] = useState([]);

  const { reloadDom } = useAccountsData();

  useEffect(() => {
    setAssets(account.assets.filter((a) => a.quantity && a.quantity !== '' && a.quantity > 0));
  }, [account]);

  const deleteAsset = async (data) => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/assets/${data.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          type: 'assets',
          id: data.id,
          attributes: {
            is_deleted: true
          }
        }
      })
    });
    console.log('delete asset', response);
    console.log(account);
    reloadDom();
    return response;
  };

  let assetsTable = <GenericAssetsTable holdings={holdings} is_manual={account.is_manual} deleteAsset={deleteAsset} />;
  if (account.account_subtype === 'collectible') {
    assetsTable = (
      <CollectibleAssetsTable holdings={holdings} is_manual={account.is_manual} deleteAsset={deleteAsset} />
    );
  } else if (account.account_subtype === 'sneakers') {
    assetsTable = <SneakerAssetsTable holdings={holdings} is_manual={account.is_manual} deleteAsset={deleteAsset} />;
  } else if (['private equity', 'stock options'].indexOf(account.account_subtype) > -1) {
    assetsTable = (
      <PrivateEquityAssetsTable holdings={holdings} is_manual={account.is_manual} deleteAsset={deleteAsset} />
    );
  } else if (account.account_subtype === 'cryptocurrency') {
    assetsTable = (
      <CryptoCurrencyAssetsTable holdings={holdings} is_manual={account.is_manual} deleteAsset={deleteAsset} />
    );
  }

  useEffect(() => {
    let formatAssets = formatGenericAssets;
    if (account.account_subtype === 'collectible') {
      formatAssets = formatCollectibleAssets;
    } else if (account.account_subtype === 'sneakers') {
      formatAssets = formatSneakerAssets;
    } else if (['private equity', 'stock options'].indexOf(account.account_subtype) > -1) {
      formatAssets = formatPrivateEquityAssets;
    } else if (account.account_subtype === 'cryptocurrency') {
      formatAssets = formatCryptoCurrencyAssets;
    }
    setHoldings(formatAssets(assets));
  }, [account, assets]);

  async function toggle(asset) {
    setAssets(assets.concat(asset));
  }

  return (
    <div className="asset-holdings-container">
      <div className="asset-holdings-header">
        <div className="asset-holdings-header-left">
          <div className="holdings-title">Holdings</div>
        </div>
        <div className="asset-holdings-header-right">
          {account.is_manual && <ManualAssetsPopup toggle={toggle} account={account} />}
          {account.account_subtype === 'sneakers' && <SneakersLink addFromTable={true} />}
        </div>
      </div>
      {assetsTable}
    </div>
  );
}

export default AssetHoldingsTable;
