import React, { useMemo } from 'react';

import { useAccountsData } from 'contexts';

import { RatingCard } from './RatingCard';

export const RatingCardCredentials = () => {
  const {
    investorUserData: { advisor, advisor_searched_at, provided_advisor_name, score_compliance }
  } = useAccountsData();

  const advisorCredentialsErrorMessage = useMemo(() => {
    if (!advisor && advisor_searched_at) {
      return `Advisor ${provided_advisor_name} not found.`;
    }
    if (advisor && !score_compliance) {
      return 'Calculating... check back soon.';
    }
    return '';
  }, [advisor, provided_advisor_name, advisor_searched_at, score_compliance]);

  const content = () => {
    if (!advisor || advisorCredentialsErrorMessage) {
      return advisorCredentialsErrorMessage;
    }

    let insight = '';
    if (advisor.is_cfp || advisor.is_cfa || advisor.is_cpa) {
      const cert = advisor.is_cfp ? 'CFP' : advisor.is_cfa ? 'CFA' : advisor.is_cpa ? 'CPA' : '';
      insight += `${advisor.first_name} is a ${cert}`;
    }
    if (advisor.is_ia) {
      if (insight.length > 0) {
        insight += ', and ';
      } else {
        insight += `${advisor.first_name}`;
      }
      if (advisor.is_broker) {
        insight += ` is registered as both a Broker and Advisor`;
      } else {
        insight += ` is a Registered Investment Advisor`;
      }
    }
    if (advisor.num_disclosures > 0) {
      if (insight.length > 0) {
        insight += ', and ';
      } else {
        insight += `${advisor.first_name}`;
      }
      insight += ` has one or more disclosures which should be reviewed`;
    }
    if (advisor.is_cfp === false && advisor.is_cfa === false && advisor.is_cpa === false) {
      if (insight.length > 0) {
        insight += ', and ';
      } else {
        insight += `${advisor.first_name}`;
      }
      insight += `does not hold a designation (CFP, CFA, CPA)`;
    }
    if (!advisor.is_ia) {
      if (insight.length > 0) {
        insight += ', and';
      } else {
        insight += `${advisor.first_name}`;
      }
      if (advisor.is_broker) {
        insight += ' is only registered as a Broker not an Investment Advisor.';
      } else {
        insight += ' is not a Registered Investment Advisor.';
      }
    }
    return insight;
  };

  return (
    <RatingCard
      title="Independence"
      link="/advisor-analyzer/credentials-factor"
      score={score_compliance}
      contentHeader={content()}
      description="Broker or Investment Advisor? CFP, CPA, CFA?"
    />
  );
};
