import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent } from '@material-ui/core';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import { CollectibleAssetForm } from 'components/forms/CollectibleAssetForm';
import { InvestmentAssetForm } from 'components/forms/InvestmentAssetForm';
import { PrivateEquityAssetForm } from 'components/forms/PrivateEquityAssetForm';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

function ManualAssetsPopup({ account, toggle, show = false, setShow, edit = false }) {
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(show);
  const { reloadDom } = useAccountsData();

  const handleClose = () => {
    setOpen(false);
    if (setShow) setShow(false);
  };

  useEffect(() => {
    setOpen(show);
  }, [show, account]);

  const fetchImage = async (assetId) => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/assets/${assetId}?fields[assets]=logo_url`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      }
    });
    const json = await response.json();
    return json['data']['attributes']['logo_url'];
  };

  const formSubmit = async (data, account, logoFile) => {
    data['account_id'] = account.id;
    const ID = await currentUser.getIdToken();

    if (edit) {
      let _data = {
        name: data.name,
        value: data.value,
        ticker_symbol: data.ticker_symbol,
        quantity: data.quantity,
        price: data.price,
        cost_basis: data.cost_basis,
        date: data.data
      };

      await fetch(`${url}/api/v1/assets/${account.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({
          data: {
            type: 'assets',
            id: data.id,
            attributes: _data
          }
        })
      });
      reloadDom();
    } else {
      const response = await fetch(`${url}/api/v1/assets`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({
          data: {
            type: 'assets',
            attributes: data
          }
        })
      });
      if (response.status === 201 && logoFile.name) {
        const asset = await response.json();
        const formData = new FormData();
        formData.append(logoFile.name, logoFile.file);
        const file_response = await fetch(`${url}/api/v1/assets/${asset['data']['id']}/logo_file`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${ID}`
          },
          body: formData
        });
        if (file_response.status === 201) {
          let numberOfAttempts = 0;
          let logo_url = null;
          while (true) {
            try {
              //image saving happens background job
              logo_url = await fetchImage(asset['data']['id']);
              if (logo_url) break;
            } catch {
              numberOfAttempts++;
              if (numberOfAttempts > 2) break;
              await new Promise((r) => setTimeout(r, 1000));
            }
          }
          data['logo_url'] = logo_url;
        }
      }
      reloadDom();
    }
    toggle(data);
    handleClose();
  };

  const formComponent = (account) => {
    if (['private equity', 'stock options'].indexOf(account.account_subtype) > -1) {
      return <PrivateEquityAssetForm formSubmit={formSubmit} account={account} edit={edit} />;
    }
    if (['collectible'].indexOf(account.account_subtype) > -1) {
      return <CollectibleAssetForm formSubmit={formSubmit} account={account} edit={edit} />;
    }
    return <InvestmentAssetForm formSubmit={formSubmit} account={account} edit={edit} />;
  };

  return (
    <>
      {!edit && (
        <div className="manual-transaction-btn" onClick={(e) => setOpen(true)}>
          <div>Add Assets</div>
        </div>
      )}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitleX onClose={handleClose}>{!edit ? 'Manually add an Asset' : 'Manually edit an Asset'}</DialogTitleX>
        <DialogContent style={{ paddingTop: 30 }}>{formComponent(account)}</DialogContent>
      </Dialog>
    </>
  );
}

export default ManualAssetsPopup;
