import { InputText } from 'primereact/inputtext';

import 'primeflex/primeflex.css';

export default function InputTextWithLabel({ label, labelClass = 'p-md-4', inputDivClass = 'p-md-8', ...props }) {
  return (
    <div className="p-field p-grid" style={{ marginBottom: 0 }}>
      <label htmlFor={props.id} className={`p-col-12 ${labelClass}`} style={{ marginTop: 8, marginBottom: 0 }}>
        {label}
      </label>
      <div className={inputDivClass} style={{ padding: 5 }}>
        <InputText type="text" {...props} />
      </div>
    </div>
  );
}
