import React, { useEffect, useRef, useState } from 'react';

import { Avatar, CircularProgress, Grid } from '@material-ui/core';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import Home from '@material-ui/icons/Home';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import { textField } from 'components/forms/common';
import ImageButton from 'components/interaction/ImageButton';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 500,
    margin: 5
  },
  centered: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const SneakersLink = ({ name, institution, styles, onAccounts, handleMainClose, logo, addFromTable }) => {
  const [open, setOpen] = useState(false);

  // const [sku, setSku] = useState("")

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState();
  const [page, setPage] = useState(0);
  let searchData = useRef();

  const { reloadDom } = useAccountsData();

  const onSubmit = async (sku) => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/sneakers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          sku: sku
        }
      })
    });
    const json = await response.json();
    if (addFromTable) {
      setOpen(false);
      setSearch();
      reloadDom();
    } else {
      if (json['data'] && onAccounts) {
        onAccounts(json['data']);
        setSuccess(true);
      } else {
        setError(`Unable to add sneaker, please try again`);
      }
      setOpen(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const closePopup = () => {
    setSuccess(false);
    // setSku("");
    setSearch();
  };

  // sku, name, brand, gender, colorway, releaseYear

  const formSubmit = async (data) => {
    await setLoading(true);
    let accountData = {};
    for (const key of Object.keys(data)) {
      if (key.indexOf('asset-') !== 0) {
        accountData[key] = data[key];
      }
    }
    const ID = await currentUser.getIdToken();
    fetch(`${url}/api/v1/sneakers/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          query: data.query,
          page: page
        }
      })
    })
      .then((response) => response.json())
      .then(async (body) => {
        console.log(body);
        if (body.results) {
          await setSearch(body);
        } else {
          alert(body.message);
        }
        setLoading(false);
      });
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {},
    validate: (data) => {
      let errors = {};
      // if (!data.balance_current) {
      //     errors.balance_current = 'Value is required.';
      // }

      return errors;
    },
    onSubmit: async (data) => {
      searchData.current = data;
      await formSubmit(searchData.current);
      // reloadDom()
      // setSearch(data)
      // setOpen(false);
    }
  });

  useEffect(() => {
    if (page !== 0) {
      console.log({ ...searchData.current, page: page });
      formSubmit(searchData.current);
    }
  }, [page]);

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success && (
        <SuccessDialog
          page={institution.page || 'Personal'}
          institution={institution.name}
          handleClose={handleMainClose}
          onClose={closePopup}
        />
      )}
      <div style={styles} onClick={(e) => setOpen(true)}>
        {addFromTable ? (
          <div className="manual-transaction-btn" onClick={(e) => setOpen(true)}>
            <div>Add Sneakers</div>
          </div>
        ) : logo ? (
          <img className="link-bank-logo" src={logo} alt=" " />
        ) : (
          <>
            <Avatar className="link-bank-icon">
              <Home />
            </Avatar>
            <div>{name}</div>
          </>
        )}
        {/* <Avatar className="link-bank-icon"><Home /></Avatar><div>{name}</div> */}
      </div>
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
        <DialogTitleX onClose={handleClose}>Search for Sneakers</DialogTitleX>
        <DialogContent style={{ height: '100%' }}>
          {!search ? (
            <>
              <form onSubmit={formik.handleSubmit}>
                <br />
                <Grid container alignItems="flex-end" direction="column">
                  <Grid item style={{ width: '100%' }}>
                    {textField(formik, 'query', 'Enter Shoe Brand, Name and/or SKU Number', {
                      width: '100%'
                    })}
                  </Grid>
                  <Grid item>
                    <Button type="submit" label="Search" className="p-mt-2" />
                  </Grid>
                </Grid>
              </form>
              {loading && <CircularProgress />}
            </>
          ) : (
            <>
              <Grid container>
                {search.results.map((res) => (
                  <Grid
                    item
                    style={{
                      width: '33%',
                      minWidth: '100px'
                    }}
                  >
                    <ImageButton
                      buttonText={res.name}
                      imageUrl={res.image.thumbnail}
                      onClick={() => {
                        onSubmit(res.sku);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>

              <p>{`page ${page + 1} of ${Math.ceil(search.count / 12)}`}</p>
              <Button
                disabled={page === 0}
                onClick={() => {
                  setPage(page - 1);
                }}
              >
                prev page
              </Button>
              <Button
                onClick={() => {
                  setSearch();
                  setPage(0);
                }}
              >
                clear search
              </Button>
              <Button
                disabled={page + 1 === Math.ceil(search.count / 12)}
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                next page
              </Button>

              {loading && <CircularProgress />}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
