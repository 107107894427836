import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import poweredBy from 'logos/powered_by_morningstar.png';
import { useScript } from 'utils';

import { useAccountsData } from './AccountsContext';
import { useAuth } from './AuthContext';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'mstar-aggregation-consumer-accountsetup': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const env =
  process.env.NODE_ENV === 'development' ? 'local.' : process.env.REACT_APP_ENV === 'development' ? 'dev.' : '';

const cssUrl = `https://${env}app.rainbook.com/mstar-styles/mstar-close.css`;

interface MorningstarComponentsProps {
  visible: boolean;
  setup: boolean;
  institutionId?: string;
  onExit: () => void;
  onLoad?: () => void;
}

export const MorningstarComponents = ({
  visible,
  setup,
  institutionId,
  onExit,
  onLoad
}: MorningstarComponentsProps) => {
  useScript('/mstar-aggregation-consumer-accountsetup/mstar-aggregation-consumer-accountsetup.js');

  const { getMstarAuth } = useAuth();
  const { setScoreSubmitted, updateBaaAccounts } = useAccountsData();

  const [loading, setLoading] = useState(true);
  const [toggledReauth, setToggledReauth] = useState(false);
  const [mstarAuthToken, setMstarAuthToken] = useState({
    jsessionId: '',
    csrfToken: ''
  });
  const [showSetup, setShowSetup] = useState(false);

  const mstarSetupRef = useRef<any>();

  useEffect(() => {
    async function getMstarAuthToken() {
      const auth = await getMstarAuth();
      setMstarAuthToken(auth);
    }
    getMstarAuthToken();
  }, [getMstarAuth, toggledReauth]);

  const updateAccounts = useCallback(async () => {
    try {
      await updateBaaAccounts();
    } catch (e) {
      console.error(e);
      return;
    }
    setScoreSubmitted((scoreSubmitted: string[]) => [
      ...scoreSubmitted,
      'score_performance',
      'score_fees',
      'score_cash'
    ]);
  }, [updateBaaAccounts, setScoreSubmitted]);

  const toggleReauth = useCallback(() => {
    setToggledReauth(prevState => !prevState)
  },[])

  useEffect(() => {
    if (!mstarAuthToken?.jsessionId || !mstarAuthToken?.csrfToken || !mstarSetupRef.current) return;

    const authString = JSON.stringify(mstarAuthToken);

    const setupWidget = mstarSetupRef.current;

    setupWidget.setAttribute('auth-context', authString);

    setupWidget.addEventListener('userExit', onExit);
    setupWidget.addEventListener('dataChangedAsync', updateAccounts);
    setupWidget.addEventListener('badAuthentication', toggleReauth);

    setLoading(false);

    return () => {
      if (setupWidget) {
        setupWidget.removeEventListener('userExit', onExit);
        setupWidget.removeEventListener('dataChangedAsync', updateAccounts);
        setupWidget.removeEventListener('badAuthentication', toggleReauth);
      }
    };
  }, [mstarAuthToken, mstarSetupRef, onExit, updateAccounts]);

  useEffect(() => {
    if (!setup || !mstarSetupRef.current) return;
    mstarSetupRef.current.setAttribute('route', `/add-account;fiId=${institutionId};allowMultipleAdds=false`);
    setShowSetup(true);
  }, [institutionId, setup, mstarSetupRef]);

  useEffect(() => {
    if (!loading && onLoad) onLoad();
  }, [loading, onLoad]);

  return (
    <>
      {loading && institutionId && <CircularProgress style={{ color: '#ccc' }} />}
      <div
        id="account-setup"
        style={{
          display: !visible || !showSetup ? 'none' : 'block'
        }}
      >
        <mstar-aggregation-consumer-accountsetup ref={mstarSetupRef} override-css-file={cssUrl}/>
      </div>
      {!loading && visible && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'end', width: 660 }}>
            <img src={poweredBy} alt="powered by Morningstar" style={{ maxHeight: 20 }} />
          </div>
        </div>
      )}
    </>
  );
};
