import React, { useState } from 'react';
import OauthPopup from 'react-oauth-popup';

import { coinbaseUrl } from 'assetData/StaticValues';
import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

export const CoinbaseLink = ({ institution, onAccounts, handleMainClose, refForClick }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const { reloadDom } = useAccountsData();

  const closeSuccess = () => {
    setSuccess(false);
    reloadDom();
  };

  const onClose = () => {
    //warning: this happens even when unmounted
  };

  const onCode = async (code, params) => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/oauth/coinbase.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({ code: code })
    });
    const json = await response.json();

    if (json['data']) {
      onAccounts(json['data']);
      setSuccess(true);
      if (refForClick) {
        reloadDom();
      }
    }
  };

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success && !refForClick && (
        <SuccessDialog page={'Liquid'} institution={'Coinbase'} handleClose={handleMainClose} onClose={closeSuccess} />
      )}
      <OauthPopup url={`${coinbaseUrl}`} onCode={onCode} onClose={onClose}>
        {refForClick ? (
          <div ref={refForClick}></div>
        ) : institution.logo_url ? (
          <img className="link-bank-logo" src={institution.logo_url} alt=" " />
        ) : (
          <div>{institution.name}</div>
        )}
      </OauthPopup>
    </>
  );
};
