import React from 'react';
import { Link } from 'react-router-dom';

import { Card, CardHeader } from '@material-ui/core';

function ExpenseTrackerTile() {
  const styles = {
    backgroundColor: 'rgb(45, 155, 90)',
    borderRadius: '9px',
    minHeight: '60px',
    maxHeight: '60px',
    textDecoration: 'none'
  };

  return (
    <Card style={styles}>
      <Link to="/wealth-hub/expense-tracker">
        <CardHeader style={{ paddingBottom: 0, color: '#ffffff' }} title="Cash Flow" />
      </Link>
    </Card>
  );
}

export default ExpenseTrackerTile;
