import { useState } from 'react';

import Zabo from 'zabo-sdk-js';

import ErrorDialog from 'components/dialogs/ErrorDialog';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;
//const zaboClientId = process.env.REACT_APP_ZABO_CLIENT_ID;

export const ZaboReLink = ({ institution, styles, onSuccess, handleMainClose, localRef }) => {
  const { currentUser } = useAuth();
  const [error, setError] = useState(null);

  /*
    useEffect(() => {
        Zabo.init({
            clientId: zaboClientId,
            autoConnect: false,
            env: process.env.REACT_APP_ZABO_ENV
        })
    }, [])
    */

  const onZaboConnect = async (account, zaboInstance) => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/zabo.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({ zabo_account: account })
    });
    const json = await response.json();
    if (json['data']) {
      if (onSuccess) {
        onSuccess();
      }
    } else {
      setError('There was an error reauthorizing your account. Please try again later or contact support@rainbook.com');
    }
  };

  const zaboConnect = async (providerName) => {
    try {
      Zabo.instance
        .connect({ provider: providerName })
        .onConnection(onZaboConnect)
        .onError((err) => {
          console.error('Zabo account connection error:', err.message);
          setError(
            'There was an error reauthorizing your account. Please try again later or contact support@rainbook.com'
          );
        });
    } catch (err) {
      console.error('Zabo init error:', err.message);
      setError('There was an error reauthorizing your account. Please try again later or contact support@rainbook.com');
    }
  };

  return (
    Zabo.instance && (
      <>
        <ErrorDialog error={error} setError={setError} />
        <div ref={localRef} onClick={() => zaboConnect(institution?.zabo_provider_name)} style={styles}></div>
      </>
    )
  );
};
