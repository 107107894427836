import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

const PlaidOpen = ({ config }) => {
  const { open, ready, error } = usePlaidLink(config);
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open, config]);
  if (error) {
    return <div>{error.message}</div>;
  } /* else if (!ready) {
        return <></>
    }*/ else return <></>;
};

const PlaidLinkTile = (props) => {
  const [plaidConfig, setPlaidConfig] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [metadata, setMetaData] = useState({});
  const { currentUser } = useAuth();

  const onSuccess = (token, metadata) => {
    setMetaData(metadata);

    // Convert Plaid metadata to match other account format
    metadata.accounts.forEach((a) => {
      a.account_type = a.type;
      a.account_subtype = a.subtype;
    });
    createAccounts(token, metadata.institution.institution_id);
    setPlaidConfig(null);
    setSuccess(true);
  };

  const onExit = (plaidLinkError, exitMetadata) => {
    setPlaidConfig(null);
    if (plaidLinkError) {
      console.error(`Error connecting plaid account: ${plaidLinkError.display_message}`);
      setError('There was an error adding your account. Please try again later or contact support@rainbook.com');
    }
  };

  async function createAccounts(token, institution_id) {
    const ID = await currentUser.getIdToken();
    let tokRes = await fetch(`${url}/api/v1/plaid/access_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        public_token: token,
        institution_id: institution_id
      })
    }).catch((err) => console.log('create accounts fetch error --> ', err));
    if (tokRes.status === 409) {
      console.log('Plaid Item already exists for that user and institution!');
    }
  }

  const onClick = async () => {
    const ID = await currentUser.getIdToken();
    const token = await fetch(`${url}/api/v1/plaid/link_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        link_customization_name: props.linkCustomizationName
      })
    });
    const jsonToken = await token.json();
    const linkToken = jsonToken['link_token'];
    const config = {
      token: linkToken,
      onSuccess,
      onExit
    };
    setPlaidConfig(config);
  };

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success ? (
        <SuccessDialog
          accounts={metadata.accounts}
          metadata={metadata}
          page={props.page}
          institution={props.name}
          handleClose={props.handleMainClose}
        />
      ) : null}
      <div style={props.styles} onClick={onClick}>
        {props.addAccountIcon}
        {props.addAccountMessage}
        <div>{props.name}</div>
        {plaidConfig && <PlaidOpen config={plaidConfig} />}
      </div>
    </>
  );
};

export default PlaidLinkTile;
