import React, { useState } from 'react';

import { Button, Card } from '@material-ui/core';
import * as d3 from 'd3';

import LegendVisualizationData from 'assetData/LegendVisualizationData';
import { StatementIcon, TileIcon } from 'components/assetDetails/PersonalFinancialStatement';
import AccountLineChart from 'components/charts/AccountLineChart';
import HoveredDonut from 'components/charts/HoveredDonut';
import Legend from 'components/charts/Legend';
import AddAccountsTile from 'components/tiles/AddAccountsTile';
import InsightsTile from 'components/tiles/InsightsTile';
import TileContainer from 'components/tiles/TileContainer';
import { useAccountsData } from 'contexts/AccountsContext';
import { changeInValue, formatMoney, rangeToString } from 'utils/formatters';

import 'pages/AssetPages.css';

export const LIQUID_INVESTMENT_SUBTYPES = [
  'brokerage',
  'cash',
  'isa',
  'gic',
  'mutual fund',
  'stock plan',
  'variable annuity',
  'cryptocurrency'
];

export const isLiquid = (acct) =>
  acct.institution?.page === 'liquid' ||
  (!acct.institution?.page &&
    ((acct.account_type === 'depository' && acct.account_type !== 'hsa') ||
      (acct.account_type === 'investment' && LIQUID_INVESTMENT_SUBTYPES.indexOf(acct.account_subtype) > -1)));

export const liquidColorScheme = ['rgb(120,255,184)', 'rgb(40,232,136)', 'rgb(25,155,90)', 'rgb(19,107,61)'];

export const liquidMainColor = 'rgb(30, 197, 119)';

function Liquid(props) {
  const [view, setView] = useState('tile');

  const { LiqData, LiqAccounts, LiqIds, InsightsData, liqChange, setLiqChange } = useAccountsData();

  const liquidTotal = parseFloat(
    LiqData.reduce(function (sum = 0, current) {
      return sum + current.value;
    }, 0)
  );

  const labels = LiqData.map((hash) => {
    return hash['label'];
  });

  const colorScheme = LegendVisualizationData(LiqData);
  const colors = d3.scaleOrdinal().range(colorScheme);
  const page = 'liquidAsset';
  return (
    <div>
      <TileContainer
        pageId="liquid"
        rightAccounts={LiqAccounts}
        showPFS={view !== 'tile'}
        insights={InsightsData.filter((i) => i.page === 'liquid')}
        assetColorScheme={liquidColorScheme}
        mainColor={liquidMainColor}
        leftContent={
          <>
            <Card className="asset-totals-container" style={{ borderRadius: '9px' }}>
              <div className="liquid-asset-header">
                <h3 className="asset-title"> Assets: {formatMoney(liquidTotal, 0)}</h3>
                {liqChange && (
                  <p className="change-in-value" style={changeInValue(liqChange.value)}>
                    {formatMoney(liqChange.value, 0)}{' '}
                    <span style={{ color: 'lightslategray' }}>{rangeToString(liqChange.range)}</span>
                  </p>
                )}
              </div>
              <div
                className="liquid-asset-doughnut-and-keys"
                style={liquidTotal === 0 ? { marginBottom: 20 } : { marginBottom: 0 }}
              >
                <div className="liquid-asset-doughnut">
                  <HoveredDonut
                    showTotal={false}
                    page={'asset'}
                    donutData={LiqData}
                    innerRadius={55}
                    outerRadius={65}
                    colorScheme={liquidColorScheme}
                    width={150}
                    height={150}
                    totals={liquidTotal}
                    AccountName={'Liquid'}
                  />
                </div>
                <div className="liquid-asset-doughnut-keys">
                  <svg className="liquid-doughnut-key-svg">
                    {LiqData.length > 0 && (
                      <g transform="translate(0,15)">
                        <Legend
                          data={LiqData}
                          labels={labels}
                          colors={colors}
                          colorScheme={liquidColorScheme}
                          page={page}
                        />
                      </g>
                    )}
                  </svg>
                </div>
              </div>
              {LiqIds.length > 0 ? (
                <AccountLineChart
                  AssetsTotal={true}
                  colors={liquidMainColor}
                  accountsIds={LiqIds.join()}
                  change={liqChange}
                  setChange={setLiqChange}
                />
              ) : null}
              <Button
                startIcon={view === 'tile' ? <StatementIcon /> : <TileIcon />}
                onClick={() => setView(view === 'tile' ? 'pfs' : 'tile')}
              >
                {view === 'tile' ? 'Statement View' : 'Tile View'}
              </Button>
            </Card>
            <InsightsTile style={{ marginBottom: 20 }} page="liquid" />
            <Card style={{ borderRadius: '9px' }}>
              {' '}
              <AddAccountsTile />{' '}
            </Card>
          </>
        }
      />
    </div>
  );
}

export default Liquid;
