import React from 'react';

import { LegendX, TransformationX, TransformationY } from 'components/charts/TransformLegend';
import { useAccountsData } from 'contexts/AccountsContext';
import { useNetWorthData } from 'contexts/NetWorthDataContext';
import useWindowWidth from 'utils/useWindowWidth';

import './Legend.css';

const AssetLegend = (props) => {
  const { LiqData, PersData, AltData } = useAccountsData();
  const { NetData } = useNetWorthData();

  const colors = props.colorScheme;
  const labels = props.labels;

  let ColorHash = {};
  let i = 0;
  while (i < colors.length) {
    ColorHash[labels[i]] = colors[i];
    i++;
  }

  const width = useWindowWidth().width;
  const LiquidLegend = props.data['assetType'] === 'Liquid';
  const NetWorthLegend = props.data['assetType'] === 'NetWorth';
  const RightLegend =
    505 < width &&
    width < 1189 &&
    (props.data['assetType'] === 'Alternative' || props.data['assetType'] === 'Personal');

  const AssetData = props.values
    ? props.values
    : props.data['assetType'] === 'Liquid'
    ? LiqData
    : props.data['assetType'] === 'Alternative'
    ? AltData
    : props.data['assetType'] === 'Personal'
    ? PersData
    : NetData;

  const reducer = (acc = 0, currVal) => acc + (isNaN(currVal) ? 0 : currVal);
  const AssetValues = AssetData;
  const AssetTotal = AssetValues.reduce(reducer, 0);

  return labels.map((SubAssetType, i) => {
    return (
      <svg className="legend" key={i}>
        <g
          className="legend-line"
          key={i}
          transform={`scale(0.1) translate(${TransformationX(
            i,
            width,
            LiquidLegend,
            RightLegend,
            NetWorthLegend,
            props
          )},  
            ${TransformationY(i, LiquidLegend, props)})`}
        >
          <rect
            className="legend-square"
            transform={`translate(${-125},${-40})`}
            fill={ColorHash[SubAssetType]}
            x="5"
            y="50"
            rx="50"
            ry="50"
            width="225"
            height="225"
          />
          <g className="legend-text" key={i}>
            <text x="150" y="200">
              {SubAssetType}
            </text>
            <text x={LegendX(width, LiquidLegend, NetWorthLegend)} y="180">
              {AssetTotal === 0 ? 100 : Math.round((AssetData[i] / AssetTotal) * 100)}%
            </text>
          </g>
        </g>
      </svg>
    );
  });
};

export default AssetLegend;
