import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Tab, Tabs } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TabContext, TabPanel } from '@material-ui/lab';

import AssetDetailPopup from 'components/popups/AssetDetailPopup';
import { tileType } from 'components/tiles/AccountTile';
import TransactionTile from 'components/tiles/TransactionTile';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';
import { ReactComponent as _StatementIcon } from 'logos/statement.svg';
import { ReactComponent as _TileIcon } from 'logos/tiles.svg';
import { isAlternative } from 'pages/wealthHub/Alternative';
import { alternativeColorScheme, alternativeMainColor } from 'pages/wealthHub/Alternative';
import { isLiability } from 'pages/wealthHub/Liabilities';
import { liabilitiesColorScheme, liabilitiesMainColor } from 'pages/wealthHub/Liabilities';
import { isLiquid, liquidColorScheme, liquidMainColor } from 'pages/wealthHub/Liquid';
import { isPersonal } from 'pages/wealthHub/Personal';
import { personalColorScheme, personalMainColor } from 'pages/wealthHub/Personal';
import { formatAssetQuantity, formatMoney } from 'utils/formatters';

import { generateAssetDetails } from './utils';

const StatementIcon = () => <SvgIcon component={_StatementIcon} viewBox="0 0 400 495" />;

const TileIcon = () => <SvgIcon component={_TileIcon} viewBox="0 0 400 400" />;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const typeRowStyle = {
  cursor: 'pointer',
  backgroundColor: '#f0f0f0',
  borderBottom: '1px solid gray'
};

const mainRowStyle = {
  cursor: 'pointer',
  borderBottom: '1px solid lightgray'
};

const commonRowStyle = {
  borderBottom: '1px solid gray',
  borderTop: '1px solid lightgray'
};

const categoryTotalRowStyle = {
  borderTop: '1px solid gray',
  borderBottom: '2px solid gray'
};

const totalRowStyle = {
  borderTop: '1px solid gray',
  borderBottom: '2px solid black'
};

const formatPercentage = (percent, digits = 2) => {
  const final = percent.toLocaleString('en', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  });
  return `${final !== '0.00' ? final : '>0.01'}%`;
};

const reducer = (a, b) => a + b;

const HoldingsRow = ({ row, total, showAccountDetails }) => {
  const classes = useRowStyles();

  return (
    <TableRow className={classes.root} onClick={showAccountDetails} style={mainRowStyle}>
      <TableCell />
      <TableCell component="th" scope="row" style={{ paddingLeft: 16 + 32 + 32 + 32 }}>
        {row.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {formatMoney(row.value)}
      </TableCell>
      <TableCell component="th" scope="row">
        {formatPercentage((row.value / total) * 100)}
      </TableCell>
    </TableRow>
  );
};

const AccountRow = ({ row, quantity, total, showAccountDetails }) => {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={classes.root}
        onClick={() => (row.assets ? setOpen(!open) : showAccountDetails(row.id))}
        style={mainRowStyle}
      >
        <TableCell>
          {row.assets ? (
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ paddingLeft: 16 + 32 + 32 }}>
          <div style={{ textTransform: 'capitalize' }}>{row.name}</div>
        </TableCell>
        {!open ? (
          <>
            {quantity && (
              <TableCell component="th" scope="row">
                {formatAssetQuantity(quantity)}
              </TableCell>
            )}
            <TableCell component="th" scope="row">
              {formatMoney(row.balance)}
            </TableCell>
            <TableCell component="th" scope="row">
              {formatPercentage((row.balance / total) * 100)}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell />
            <TableCell />
          </>
        )}
      </TableRow>
      {open && (
        <>
          {row.assets
            .filter((a) => a.value > 0)
            .map((a, idx) => (
              <HoldingsRow key={idx} row={a} total={total} showAccountDetails={() => showAccountDetails(row.id)} />
            ))}
          <TableRow className={classes.root}>
            <TableCell style={commonRowStyle} />
            <TableCell style={commonRowStyle} />
            {quantity && (
              <TableCell component="th" scope="row" style={commonRowStyle}>
                {formatMoney(quantity)}
              </TableCell>
            )}
            <TableCell component="th" scope="row" style={commonRowStyle}>
              {formatMoney(row.balance)}
            </TableCell>
            <TableCell component="th" scope="row" style={commonRowStyle}>
              {formatPercentage((row.balance / total) * 100)}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

const InstitutionRow = ({ row, total, page, showAccountDetails }) => {
  const [open, setOpen] = useState(page ? true : false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root} onClick={() => setOpen(!open)} style={mainRowStyle}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ paddingLeft: 16 + 32 }}>
          <b style={{ textTransform: 'capitalize' }}>{row.institution.name}</b>
        </TableCell>
        {!open ? (
          <>
            <TableCell component="th" scope="row">
              {formatMoney(row.total)}
            </TableCell>
            <TableCell component="th" scope="row">
              {formatPercentage((row.total / total) * 100)}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell />
            <TableCell />
          </>
        )}
      </TableRow>
      {open && (
        <>
          {row.accounts
            .filter((a) => a.balance > 0)
            .map((a, idx) => (
              <AccountRow key={idx} row={a} total={total} showAccountDetails={showAccountDetails} />
            ))}
          <TableRow className={classes.root}>
            <TableCell style={commonRowStyle} />
            <TableCell style={commonRowStyle} />
            <TableCell component="th" scope="row" style={commonRowStyle}>
              {formatMoney(row.total)}
            </TableCell>
            <TableCell component="th" scope="row" style={commonRowStyle}>
              {formatPercentage((row.total / total) * 100)}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

const AssetRow = ({ row, quantity, total, page, showAccountDetails }) => {
  const [open, setOpen] = useState(page ? true : false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root} onClick={() => setOpen(!open)} style={mainRowStyle}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ paddingLeft: 16 + 32 }}>
          <b style={{ textTransform: 'capitalize' }}>{row.name}</b>
        </TableCell>
        {!open ? (
          <>
            <TableCell component="th" scope="row">
              {formatAssetQuantity(row.quantity)}
            </TableCell>
            <TableCell component="th" scope="row">
              {formatMoney(row.total)}
            </TableCell>
            <TableCell component="th" scope="row">
              {formatPercentage((row.total / total) * 100)}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell />
            <TableCell />
            <TableCell />
          </>
        )}
      </TableRow>
      {open && (
        <>
          {row.institutions
            .filter((i) => i.balance > 0)
            .map((i, idx) => (
              <AccountRow key={idx} row={i} quantity={i.quantity} total={total} showAccountDetails={showAccountDetails} />
            ))}
          <TableRow className={classes.root}>
            <TableCell style={commonRowStyle} />
            <TableCell style={commonRowStyle} />
            <TableCell component="th" scope="row" style={commonRowStyle}>
              {formatAssetQuantity(quantity)}
            </TableCell>
            <TableCell component="th" scope="row" style={commonRowStyle}>
              {formatMoney(row.total)}
            </TableCell>
            <TableCell component="th" scope="row" style={commonRowStyle}>
              {formatPercentage((row.total / total) * 100)}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

const AccountTypeRow = ({ row, total, page, showAccountDetails }) => {
  const [open, setOpen] = useState(true);
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root} onClick={() => setOpen(!open)} style={typeRowStyle}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <b style={{ textTransform: 'capitalize' }}>{row.type}</b>
        </TableCell>
        {!open ? (
          <>
            <TableCell component="th" scope="row">
              {formatMoney(row.total)}
            </TableCell>
            <TableCell component="th" scope="row">
              {formatPercentage((total === 0 ? 1 : row.total / total) * 100)}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell />
            <TableCell />
          </>
        )}
      </TableRow>
      {open && (
        <>
          {row.institutions
            .filter((a) => a.total > 0)
            .map((i, idx) => (
              <InstitutionRow key={idx} row={i} total={total} page={page} showAccountDetails={showAccountDetails} />
            ))}
          <TableRow className={classes.root}>
            <TableCell style={categoryTotalRowStyle} />
            <TableCell style={categoryTotalRowStyle} />
            <TableCell component="th" scope="row" style={categoryTotalRowStyle}>
              {formatMoney(row.total)}
            </TableCell>
            <TableCell component="th" scope="row" style={categoryTotalRowStyle}>
              {formatPercentage((total === 0 ? 1 : row.total / total) * 100)}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

const AssetTypeRow = ({ row, total, page, showAccountDetails }) => {
  const [open, setOpen] = useState(true);
  const classes = useRowStyles();
  if (!row) return null;

  const totalQuantity = row.assets.map((a) => a.quantity)?.reduce(reducer, 0) || 0;

  return (
    <>
      <TableRow className={classes.root} onClick={() => setOpen(!open)} style={typeRowStyle}>
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <b style={{ textTransform: 'capitalize' }}>{row.type}</b>
        </TableCell>
        {!open ? (
          <>
            <TableCell component="th" scope="row">
              {formatAssetQuantity(totalQuantity)}
            </TableCell>
            <TableCell component="th" scope="row">
              {formatMoney(row.total)}
            </TableCell>
            <TableCell component="th" scope="row">
              {formatPercentage((total === 0 ? 1 : row.total / total) * 100)}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell />
            <TableCell />
            <TableCell />
          </>
        )}
      </TableRow>
      {open && (
        <>
          {row.assets
            .filter((a) => a.total > 0)
            .map((i, idx) => (
              <AssetRow
                key={idx}
                row={i}
                quantity={row.quantity}
                total={total}
                page={page}
                showAccountDetails={showAccountDetails}
              />
            ))}
          <TableRow className={classes.root}>
            <TableCell style={categoryTotalRowStyle} />
            <TableCell style={categoryTotalRowStyle} />
            <TableCell component="th" scope="row" style={categoryTotalRowStyle}>
              {formatAssetQuantity(row.quantity)}
            </TableCell>
            <TableCell component="th" scope="row" style={categoryTotalRowStyle}>
              {formatMoney(row.total)}
            </TableCell>
            <TableCell component="th" scope="row" style={categoryTotalRowStyle}>
              {formatPercentage((total === 0 ? 1 : row.total / total) * 100)}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

const TotalRow = ({ text, total, percentage, extraColumn }) => {
  const classes = useRowStyles();

  return (
    <TableRow key={text} className={classes.root} style={totalRowStyle}>
      <TableCell />
      <TableCell component="th" scope="row">
        <b>{text}</b>
      </TableCell>
      {extraColumn && <TableCell component="th" scope="row" />}
      <TableCell component="th" scope="row">
        <b>{formatMoney(total)}</b>
      </TableCell>
      {percentage && (
        <TableCell component="th" scope="row">
          <b>{formatPercentage(percentage)}</b>
        </TableCell>
      )}
    </TableRow>
  );
};

const RenderAccountSection = (type, accounts, sectionTotal) => {
  let institutions = [...new Set(accounts.map((x) => x.institution.id))];

  return {
    type: type,
    total: sectionTotal,
    institutions: institutions.map((i) => {
      let _accounts = accounts.filter((x) => x.institution.id === i);
      let institution = _accounts[0].institution;
      let institutionTotal = _accounts.map((a) => a['balance_current'])?.reduce(reducer, 0) || 0;

      return {
        institution,
        total: institutionTotal,
        accounts: _accounts.map((a) => {
          return {
            id: a.id,
            name: a.name,
            balance: a['balance_current'],
            assets: a.assets.length > 0 ? a.assets : null
          };
        })
      };
    })
  };
};

const RenderAssetSection = (type, assets, sectionTotal) => {
  if (!assets) return null;

  let assetNames = [...new Set(assets.map((asset) => asset.name ?? asset.account_subtype ?? asset.asset_type))];

  return {
    type,
    total: sectionTotal,
    assets: assetNames.map((name) => {
      const _accounts = assets.filter(
        (asset) => asset.name === name ?? asset.account_subtype === name ?? asset.asset_type === name
      );
      const total = _accounts.map((a) => a.value ?? a['balance_current'])?.reduce(reducer, 0) || 0;
      const quantity = _accounts.map((a) => a.quantity ?? 1)?.reduce(reducer, 0) || 0;
      return {
        name,
        total,
        quantity,
        institutions: _accounts.map((i) => {
          const quantity =
            _accounts
              .filter((a) => a.institution === i.institution)
              .map((a) => a.quantity ?? 1)
              ?.reduce(reducer, 0) || 0;
          return {
            id: i.type === 'account' ? i.id : i.account_id,
            name: i.institution,
            quantity,
            balance: i.value ?? i['balance_current']
          };
        })
      };
    })
  };
};

const PersonalFinancialStatement = ({ page }) => {
  const { currentUser } = useAuth();
  const accounts = useAccountsData();

  const pfsRef = useRef(null);
  const [tab, setTab] = useState('account');
  const [accountDetail, setAccountDetail] = useState(null);

  const accountType = useMemo(() => (accountDetail ? tileType(accountDetail) : null), [accountDetail]);
  const accountDetailVals = useMemo(() => {
    if (!accountDetail) return null;

    const change = { value: accountDetail.day_change, range: 'day' };

    if (accountType !== 'asset') return { change };

    let colorScheme;
    let mainColor;

    switch (page) {
      case 'liquid':
        colorScheme = liquidColorScheme;
        mainColor = liquidMainColor;
        break;
      case 'alternative':
        colorScheme = alternativeColorScheme;
        mainColor = alternativeMainColor;
        break;
      case 'personal':
        colorScheme = personalColorScheme;
        mainColor = personalMainColor;
        break;
      case 'liabilities':
        colorScheme = liabilitiesColorScheme;
        mainColor = liabilitiesMainColor;
        break;

      default:
        colorScheme = alternativeColorScheme;
        mainColor = alternativeMainColor;
        break;
    }

    return {
      ...generateAssetDetails(accountDetail, accountDetail.name, colorScheme),
      name: accountDetail.name,
      mainColor,
      change,
      setChange: () => {}
    };
  }, [accountDetail, accountType, page]);

  const showAccountDetails = (accountId) => {
    setAccountDetail(accounts['AllAccounts'].find((a) => a.id === accountId));
  };

  const getType = useCallback((typeFilter) => accounts['AllAccounts'].filter((acct) => typeFilter(acct)), [accounts]);

  const filteredAccounts = useMemo(
    () => ({
      liq: getType(isLiquid),
      alt: getType(isAlternative),
      pers: getType(isPersonal),
      liabilities: getType(isLiability)
    }),
    [getType]
  );

  const [assetAccountTypes, assetTotals] = useMemo(() => {
    const [assetTypes, totals] = [
      { cash: [], liabilities: [], other: [] },
      { cash: 0, liabilities: 0, other: 0 }
    ];

    let pageAccts;

    switch (page) {
      case 'liquid':
        pageAccts = filteredAccounts.liq;
        break;
      case 'alternative':
        pageAccts = filteredAccounts.alt;
        break;
      case 'personal':
        pageAccts = filteredAccounts.pers;
        break;
      case 'liabilities':
        pageAccts = filteredAccounts.liabilities;
        break;

      default:
        pageAccts = accounts['AllAccounts'];
        break;
    }

    for (const account of pageAccts) {
      const institution = account.institution.name;
      if (!account.assets?.length) {
        const accountWithParent = {
          ...account,
          institution,
          type: 'account'
        };
        if (isLiability(account)) {
          totals.liabilities += account['balance_current'];
          assetTypes.liabilities.push(accountWithParent);
        } else {
          const type = account.account_subtype;
          if (account.account_type === 'other') {
            totals.other += account['balance_current'];
            assetTypes.other.push(accountWithParent);
          } else if (type && type !== 'checking' && type !== 'savings') {
            if (totals[type]) {
              totals[type] += account['balance_current'];
              assetTypes[type].push(accountWithParent);
            } else {
              totals[type] = account['balance_current'];
              assetTypes[type] = [accountWithParent];
            }
          } else {
            totals.cash += account['balance_current'];
            assetTypes.cash.push(accountWithParent);
          }
        }
      } else {
        for (const asset of account.assets) {
          const assetWithParent = {
            ...asset,
            account_id: account.id,
            institution,
            type: 'asset'
          };
          const type = asset.asset_type ?? 'other';
          if (assetTypes[type]) {
            assetTypes[type].push(assetWithParent);
          } else {
            assetTypes[type] = [assetWithParent];
          }
          if (totals[type]) {
            totals[type] += asset.value;
          } else {
            totals[type] = asset.value;
          }
        }
      }
    }

    Object.keys(assetTypes).forEach((type) => {
      if (assetTypes[type].length === 0) {
        delete assetTypes[type];
        delete totals[type];
      }
    });

    return [assetTypes, totals];
  }, [accounts, filteredAccounts, page]);

  const getTypeTotal = (type) => type.map((a) => a['balance_current'])?.reduce(reducer, 0) || 0;

  const accountTotals = useMemo(
    () => ({
      liq: getTypeTotal(filteredAccounts.liq),
      alt: getTypeTotal(filteredAccounts.alt),
      pers: getTypeTotal(filteredAccounts.pers),
      liabilities: getTypeTotal(filteredAccounts.liabilities)
    }),
    [filteredAccounts]
  );

  const grossTotal = useMemo(() => accountTotals.liq + accountTotals.alt + accountTotals.pers, [accountTotals]);

  useEffect(() => {
    pfsRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, []);

  return (
    <>
      <TableContainer component={Paper} ref={pfsRef}>
        <Typography
          style={{
            padding: '6px 16px 6px 16px',
            borderBottom: '1px solid black'
          }}
        >
          {currentUser.displayName} - Personal Financial Statement
        </Typography>
        <TabContext value={tab}>
          <Tabs
            centered
            value={tab}
            onChange={(_e, newTab) => setTab(newTab)}
            TabIndicatorProps={{
              style: { background: 'rgb(0,120,245)' }
            }}
          >
            <Tab label="By Account" value="account" style={{ flex: 1 }} />
            <Tab label="By Asset" value="asset" style={{ flex: 1 }} />
          </Tabs>
          <TabPanel value="account" style={{ paddingTop: 2 }}>
            <Table aria-label="Personal Financial Statement By Account" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width={10} />
                  <TableCell>Assets</TableCell>
                  <TableCell width={'15%'}>Value</TableCell>
                  <TableCell width={'15%'}>Portfolio %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Three Main sections */}
                {(!page || page === 'liquid') && (
                  <AccountTypeRow
                    row={RenderAccountSection(
                      'Liquid Assets',
                      filteredAccounts.liq,
                      accountTotals.liq,
                      page === 'liquid' ? accountTotals.liq : grossTotal
                    )}
                    total={page === 'liquid' ? accountTotals.liq : grossTotal}
                    page={page}
                    showAccountDetails={showAccountDetails}
                  />
                )}
                {(!page || page === 'alternative') && (
                  <AccountTypeRow
                    row={RenderAccountSection(
                      'Alternative Assets',
                      filteredAccounts.alt,
                      accountTotals.alt,
                      page === 'alternative' ? accountTotals.alt : grossTotal
                    )}
                    total={page === 'alternative' ? accountTotals.alt : grossTotal}
                    page={page}
                    showAccountDetails={showAccountDetails}
                  />
                )}
                {(!page || page === 'personal') && (
                  <AccountTypeRow
                    row={RenderAccountSection(
                      'Personal Assets',
                      filteredAccounts.pers,
                      accountTotals.pers,
                      page === 'personal' ? accountTotals.pers : grossTotal
                    )}
                    total={page === 'personal' ? accountTotals.pers : grossTotal}
                    page={page}
                    showAccountDetails={showAccountDetails}
                  />
                )}

                {/* Totals for all sections */}
                {!page && <TotalRow text={'Total Assets'} total={grossTotal} percentage={100} />}
                {page === 'liquid' && (
                  <TotalRow text={'Total Liquid Assets'} total={accountTotals.liq} percentage={100} />
                )}
                {page === 'alternative' && (
                  <TotalRow text={'Total Alternative Assets'} total={accountTotals.alt} percentage={100} />
                )}
                {page === 'personal' && (
                  <TotalRow text={'Total Personal Assets'} total={accountTotals.pers} percentage={100} />
                )}

                {/* Liabilities */}
                {(!page || page === 'liabilities') && (
                  <AccountTypeRow
                    row={RenderAccountSection(
                      'Liabilities',
                      filteredAccounts.liabilities,
                      accountTotals.liabilities,
                      accountTotals.liabilities
                    )}
                    total={accountTotals.liabilities}
                    showAccountDetails={showAccountDetails}
                  />
                )}
                {(!page || page === 'liabilities') && (
                  <TotalRow text={'Total Liabilities'} total={accountTotals.liabilities} percentage={100} />
                )}

                {/* Net Worth */}
                {!page && <TotalRow text={'Net Worth'} total={grossTotal - accountTotals.liabilities} />}
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value="asset" style={{ paddingTop: 2 }}>
            <Table aria-label="Personal Financial Statement By Asset" size="small">
              <TableHead>
                <TableRow>
                  <TableCell width={10} />
                  <TableCell>Assets</TableCell>
                  <TableCell width={'10%'}>Count</TableCell>
                  <TableCell width={'15%'}>Value</TableCell>
                  <TableCell width={'15%'}>Portfolio %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(assetAccountTypes).map((type, i) => {
                  return type === 'liabilities' || type === 'other' ? null : (
                    <AssetTypeRow
                      key={i}
                      row={RenderAssetSection(
                        type,
                        assetAccountTypes[type],
                        assetTotals[type],
                        page === type ? assetTotals[type] : grossTotal
                      )}
                      total={page === type ? assetTotals[type] : grossTotal}
                      page={page}
                      showAccountDetails={showAccountDetails}
                    />
                  );
                })}
                {/* Other */}
                <AssetTypeRow
                  key={'other-row'}
                  row={RenderAssetSection(
                    'other',
                    assetAccountTypes.other,
                    assetTotals.other,
                    page === 'other' ? assetTotals.other : grossTotal
                  )}
                  total={page === 'other' ? assetTotals.other : grossTotal}
                  page={page}
                  showAccountDetails={showAccountDetails}
                />
                {/* Totals for all sections */}
                {!page && <TotalRow extraColumn text={'Total Assets'} total={grossTotal} percentage={100} />}
                {page === 'liquid' && (
                  <TotalRow extraColumn text={'Total Liquid Assets'} total={accountTotals.liq} percentage={100} />
                )}
                {page === 'alternative' && (
                  <TotalRow extraColumn text={'Total Alternative Assets'} total={accountTotals.alt} percentage={100} />
                )}
                {page === 'personal' && (
                  <TotalRow extraColumn text={'Total Personal Assets'} total={accountTotals.pers} percentage={100} />
                )}
                {/* Liabilities */}
                <AssetTypeRow
                  key={'liabilities-row'}
                  row={RenderAssetSection(
                    'liabilities',
                    assetAccountTypes.liabilities,
                    assetTotals.liabilities,
                    page === 'liabilities' ? assetTotals.liabilities : grossTotal
                  )}
                  total={assetTotals.liabilities}
                  page={page}
                  showAccountDetails={showAccountDetails}
                />
                {(!page || page === 'liabilities') && (
                  <TotalRow extraColumn text={'Total Liabilities'} total={accountTotals.liabilities} percentage={100} />
                )}
                {/* Net Worth */}
                {!page && <TotalRow extraColumn text={'Net Worth'} total={grossTotal - accountTotals.liabilities} />}
              </TableBody>
            </Table>
          </TabPanel>
        </TabContext>
      </TableContainer>
      {accountDetail &&
        (accountType === 'asset' ? (
          <AssetDetailPopup
            acct={accountDetail}
            showAssetDetails={true}
            handleClose={() => setAccountDetail(null)}
            pageId={page}
            {...accountDetailVals}
          />
        ) : (
          <TransactionTile
            account={accountDetail}
            open={true}
            onClose={() => setAccountDetail(null)}
            pageId={page}
            {...accountDetailVals}
          />
        ))}
    </>
  );
};

export { TileIcon, StatementIcon };

export default PersonalFinancialStatement;
