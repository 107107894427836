import React from 'react';

import useWindowWidth from 'utils/useWindowWidth';

import { LegendX, TransformationX, TransformationY } from './TransformLegend';

const Legend = (props) => {
  const colors = props.colorScheme;
  const labels = props.labels;

  let ColorHash = {};
  let i = 0;
  while (i < colors.length) {
    ColorHash[labels[i]] = colors[i];
    i++;
  }

  const width = useWindowWidth().width;
  const LiquidLegend = props.page === 'LiquidTile';
  const NetWorthLegend = props.page === 'netWorthTile';
  const RightLegend =
    505 < width &&
    width < 1189 &&
    (props.data['assetType'] === 'Alternative' ||
      props.data['assetType'] === 'Personal' ||
      props.page === 'liquidAsset');

  // const AssetData = props.data["assetType"] === "Liquid" ? LiqData :
  //     props.data["assetType"] === "Alternative" ? AltData :
  //         props.data["assetType"] === "Personal" ? PersData :
  //             NetData

  const AssetData = props.data;

  const reducer = (acc = 0, currVal) => acc + currVal;
  const AssetValues = AssetData.map((hash) => hash['value']);
  const AssetTotal = AssetValues.reduce(reducer, 0);

  return labels.map((SubAssetType, i) => {
    return (
      <svg className="legend" key={i}>
        <g
          className="legend-line"
          key={i}
          transform={`scale(0.1) translate(${TransformationX(i, width, LiquidLegend, RightLegend, NetWorthLegend)},  
            ${TransformationY(i, LiquidLegend, props)})`}
        >
          <rect
            className="legend-square"
            transform={`translate(${-125},${-40})`}
            fill={ColorHash[SubAssetType]}
            x="5"
            y="50"
            rx="50"
            ry="50"
            width="225"
            height="225"
          />
          <g className="legend-text" key={Date.now()}>
            <text x="150" y="200">
              {SubAssetType}
            </text>
            <text x={LegendX(LiquidLegend, NetWorthLegend)} y="180">
              {AssetTotal === 0
                ? 100
                : !isNaN(Math.round((AssetData[i]['value'] / AssetTotal) * 100))
                ? Math.round((AssetData[i]['value'] / AssetTotal) * 100)
                : 0}
              %
            </text>
          </g>
        </g>
      </svg>
    );
  });
};

export default Legend;
