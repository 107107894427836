import React from 'react';

import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles } from '@material-ui/core/styles';

import './DotsRow.css';

// import Button from '@material-ui/core/Button';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    backgroundColor: '#fff'
  }
});

export default function DotsRow({ steps, activeStep }) {
  const classes = useStyles();
  //const theme = useTheme();
  //const [activeStep, setActiveStep] = React.useState(0);

  return (
    <MobileStepper variant="dots" steps={steps} position="static" activeStep={activeStep} className={classes.root} />
  );
}
