import React from 'react';

import { makeStyles } from '@material-ui/core';

import { StatementsButton } from 'components/integrations';
import LoadingImg from 'logos/RainbookLoading.gif';

const useStyles = makeStyles((theme) => ({
  processingArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    border: '3px solid rgb(21, 120, 246)',
    padding: '20px',
    borderRadius: '9px',
    width: '100%',
    backgroundColor: '#a5a5a5',
    borderColor: '#a5a5a5',
    '& img': {
      maxWidth: '100px',
      maxHeight: '100px',
      width: 'auto',
      height: 'auto'
    }
  }
}));

export const ProcessingMessage = ({
  statement_in_progress,
  isDataIssue
}: {
  statement_in_progress?: boolean;
  isDataIssue?: boolean;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.processingArea}>
      <img alt="Processing" src={LoadingImg} />
      {statement_in_progress ? (
        <div>Processing your statements, please allow one or two business days.</div>
      ) : isDataIssue ? (
        <div>
          <p>
            We’ve detected some data issues and are investigating to ensure the accuracy of your RAIN report.
            Alternatively, you can expedite things by uploading a recent monthly statement here.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StatementsButton />
          </div>
        </div>
      ) : (
        <div>Processing, please wait...</div>
      )}
    </div>
  );
};
