import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Switch,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { grey, lightGreen } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import CategoryDropdown from 'components/dropdowns/CategoryDropdown';
import ManualEditAccountValue from 'components/forms/ManualEditAccountValue';
import ManualTransactionsPopup from 'components/popups/ManualTransactionsPopup';
import { useAuth } from 'contexts/AuthContext';
import rotateIcon from 'logos/mobile-rotate-rotation.png';
import { changeInValue, formatMoney, rangeToString } from 'utils/formatters';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';

const url = process.env.REACT_APP_API_URL;

function TransactionTile({ account, open, onClose, change, pageId }) {
  const { currentUser } = useAuth();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [recurring, setRecurring] = useState({});
  const [excluded, setExcluded] = useState({});
  const [stateUpdate, setStateUpdate] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(null);
  const [limit, setLimit] = useState(60);
  const [total, setTotal] = useState(-1);
  const [accountId,] = useState(account.id);

  useEffect(() => {
    async function getTransactions(accountId) {
      setLoadingMore(true);
      const ID = await currentUser.getIdToken();
      const response = await fetch(
        `${url}/api/v1/transactions.json?filter[account_id][eq]=${accountId}&page[size]=${limit}&stats[total]=count`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ID}`
          }
        }
      );
      const json = await response.json();
      let ex = {};
      let rec = {};
      json['data'].forEach((tx) => {
        ex[tx.id] = tx.is_excluded;
        rec[tx.id] = tx.is_recurring;
      });

      setTotal(json['meta']['stats']['total']['count']);

      setExcluded(ex);
      setRecurring(rec);
      setTransactions(json['data']);

      setLoading(false);
      setLoadingMore(false);
    }
    getTransactions(accountId);
  }, [currentUser, stateUpdate, limit, accountId]);

  useEffect(() => {
    const productArray = transactions.map((transaction) => {
      const splitDate = transaction.date.split('-');
      const formatDate = splitDate[1] + '/' + splitDate[2] + '/' + splitDate[0].slice(2);
      const objName = transaction.merchant_name
        ? transaction.merchant_name.substring(0, 15)
        : transaction.name.substring(0, 15);
      const objDate = formatDate;
      const objAmount = transaction?.amount?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      });

      if (transaction['account_id'] === parseInt(account['id'])) {
        return {
          transaction: transaction,
          name: objName,
          date: objDate,
          amount: objAmount
        }; // , "recurring": objRecurring, "exclude": objExclude, "category": objCategory
      } else {
        return null;
      }
    });

    setProducts(productArray.filter((p) => !!p));
  }, [account, transactions]);

  const deleteTransaction = async (transactionId) => {
    const ID = await currentUser.getIdToken();

    await fetch(`${url}/api/v1/transactions/${transactionId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      }
    });

    setTransactions((transactions) => transactions.filter((t) => t.id !== transactionId));
  };

  function loadMore() {
    setLimit(limit + 30);
  }

  async function toggle(tx) {
    let arr = transactions;
    arr.push(tx);
    setTransactions(arr);
    setStateUpdate(!stateUpdate);
  }

  const toggleRecurring = (transaction) => {
    setRecurring({
      ...recurring,
      [transaction.id]: !transaction.is_recurring
    });
    toggleRec(transaction);
  };

  const toggleRec = async (transaction) => {
    const { id } = transaction;
    const ID = await currentUser.getIdToken();
    const res = await fetch(`${url}/api/v1/transactions/${id}.json?`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          id: id,
          type: 'transactions',
          attributes: { is_recurring: !transaction.is_recurring }
        }
      })
    });
    const json = await res.json();
    setTransactions((transactions) => transactions.map((t) => (t.id === id ? { ...json.data.attributes, id } : t)));
  };

  const toggleExcluded = (transaction) => {
    setExcluded({
      ...excluded,
      [transaction.id]: !transaction.is_excluded
    });
    toggleEx(transaction);
  };

  const toggleEx = async (transaction) => {
    const { id } = transaction;
    const ID = await currentUser.getIdToken();
    const res = await fetch(`${url}/api/v1/transactions/${id}.json?`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          id: id,
          type: 'transactions',
          attributes: { is_excluded: !transaction.is_excluded }
        }
      })
    });
    const json = await res.json();
    setTransactions((transactions) => transactions.map((t) => (t.id === id ? { ...json.data.attributes, id } : t)));
  };

  const loadingBar = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <p>One moment while we fetch your account info...</p>
        <LinearProgress />
      </div>
    </div>
  );

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      '&$checked': {
        color: grey[300]
      },
      '&$checked + $track': {
        backgroundColor: lightGreen['A700']
      }
    },
    checked: {},
    track: {}
  })(Switch);

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Dialog fullWidth={true} maxWidth={'xl'} onClose={onClose} open={open}>
        <DialogTitleX onClose={onClose}>
          {open && isSM && !window.matchMedia('(orientation: landscape)').matches && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                zIndex: 9
              }}
            >
              <img src={rotateIcon} alt="rotate device" style={{ width: '5vw', minWidth: 50 }} />
            </div>
          )}
          <Grid container>
            <Grid item md={4} sm={6} xs={12}>
              <div className="header-account-title">
                {account.institution.logo_url ? (
                  <img className="detailed-view-logo" src={account.institution.logo_url} alt=" " />
                ) : (
                  account.name
                )}
              </div>
              {account.number_display ? (
                <div className="transaction-detail-ending"> Account ending in: {account.number_display}</div>
              ) : null}
            </Grid>
            <Grid item container md={8} sm={6} xs={12} direction="column">
              {account.is_manual ? (
                <ManualEditAccountValue account={account} manualWithTransactions={true} />
              ) : (
                <div>
                  <span
                    className="asset-account-value"
                    style={{
                      width: 'max-content',
                      paddingRight: 4
                    }}
                  >
                    Account Value
                  </span>
                  <span
                    className="asset-account-value"
                    style={{
                      width: 'max-content',
                      fontWeight: 'unset'
                    }}
                  >
                    {formatMoney(account.balance_current ? account.balance_current : 0)}
                  </span>
                </div>
              )}
              {change && (
                <p
                  className="dialog-change-in-value"
                  style={{
                    margin: 'unset',
                    ...changeInValue(change.value, pageId)
                  }}
                >
                  {formatMoney(change.value, 0)}{' '}
                  <span style={{ color: 'lightslategray' }}>{rangeToString(change.range)}</span>
                </p>
              )}
            </Grid>
          </Grid>
        </DialogTitleX>
        <DialogContent style={{ minHeight: '500px' }}>
          {account.is_manual ? <ManualTransactionsPopup toggle={toggle} account={account} /> : null}
          {loading ? (
            loadingBar
          ) : products.length > 0 ? (
            <DataTable scrollable scrollHeight="100%" className="data-table" rows={products.length} value={products}>
              <Column className="name-column" field="name" header="Name" />
              <Column className="columns" field="date" header="Date" />
              <Column className="columns" field="amount" header="Amount" />
              <Column
                className="columns"
                header="Recurring"
                body={(row) => (
                  <GreenSwitch
                    checked={recurring[row.transaction.id]}
                    onChange={() => toggleRecurring(row.transaction)}
                  />
                )}
              />
              <Column
                className="columns"
                header="Exclude"
                body={(row) => (
                  <GreenSwitch
                    checked={excluded[row.transaction.id]}
                    onChange={() => toggleExcluded(row.transaction)}
                  />
                )}
              />
              <Column
                className="columns"
                header="Category"
                body={(row, i) => (
                  <CategoryDropdown
                    key={i}
                    styles={{
                      color: 'rgb(146,148,151)',
                      backgroundColor: 'rgb(232,233,234)',
                      borderRadius: '20px',
                      width: '100px',
                      overscrollBehavior: 'none'
                    }}
                    transaction={row.transaction}
                  />
                )}
              />
              {account.is_manual && (
                <Column
                  className="columns"
                  header="Edit"
                  style={{ width: 50, marginLeft: 10 }}
                  body={(row) => (
                    <IconButton size="small" onClick={() => setTransactionToEdit(row.transaction)}>
                      <EditIcon />
                    </IconButton>
                  )}
                />
              )}
              {account.is_manual && (
                <Column
                  className="columns"
                  header="Delete"
                  style={{ width: 60 }}
                  body={(row) => (
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (window.confirm('Are you sure you want to delete this transaction?')) {
                          deleteTransaction(row.transaction.id);
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                />
              )}
            </DataTable>
          ) : (
            <div align="center">No Data</div>
          )}
          {products.length > 0 && products.length < total && !loading && (
            <Grid container justify="center">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                  padding: 4
                }}
              >
                {!loadingMore && (
                  <Button
                    variant="text"
                    style={{
                      color: '#0078f5',
                      width: '100%'
                    }}
                    onClick={loadMore}
                    disabled={loadingMore}
                  >
                    View More
                  </Button>
                )}
                {loadingMore && (
                  <LinearProgress
                    style={{
                      width: '100%',
                      marginTop: 16
                    }}
                  />
                )}
              </div>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      <ManualTransactionsPopup
        account={transactionToEdit}
        show={!!transactionToEdit}
        toggle={(editedTransaction) =>
          setTransactions((transactions) =>
            transactions.map((t) => (t.id === editedTransaction.id ? editedTransaction : t))
          )
        }
        onClose={() => setTransactionToEdit(null)}
        edit={true}
      />
    </>
  );
}

export default TransactionTile;
