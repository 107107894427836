import GenericAssetDetails from 'components/assetDetails/GenericAssetDetails.js';
import PrivateEquityDetails from 'components/assetDetails/PrivateEquityDetails.js';
import RealEstateDetails from 'components/assetDetails/RealEstateDetails.js';

function AssetDetailPopup({
  acct,
  showAssetDetails,
  handleClose,
  chartData,
  mainColor,
  chartOptions,
  change,
  setChange,
  pageId
}) {
  return acct.account_subtype && acct.account_subtype === 'real estate' ? (
    <RealEstateDetails account={acct} open={showAssetDetails} onClose={handleClose} />
  ) : acct.account_subtype && ['private equity', 'stock options'].includes(acct.account_subtype) ? (
    <PrivateEquityDetails account={acct} open={showAssetDetails} onClose={handleClose} />
  ) : (
    <GenericAssetDetails
      account={acct}
      open={showAssetDetails}
      onClose={handleClose}
      chartData={chartData}
      mainColor={mainColor}
      options={chartOptions}
      change={change}
      setChange={setChange}
      pageId={pageId}
    />
  );
}

export default AssetDetailPopup;
