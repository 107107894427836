import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import { useAuth } from 'contexts/AuthContext';

import './ResetPassword.css';

function ResetPassword() {
  const { sendPasswordResetEmail } = useAuth();
  const [values, setValues] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const resetPassword = async () => {
    setLoading(true);
    const emailAddress = values.email;
    await sendPasswordResetEmail(emailAddress);
    setConfirm(true);
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
      <DialogTitleX onClose={handleClose} className="reset-password-header">
        Reset Password
      </DialogTitleX>
      {confirm ? (
        <DialogContent style={{ padding: 20 }}>An email has been sent.</DialogContent>
      ) : (
        <>
          <DialogContent>
            <DialogContentText>You will receive an email with a link to reset your password.</DialogContentText>
            <div className="reset-password-form-input-field">
              <input
                autoFocus
                type="email"
                name="email"
                className="reset-password-form-input-box"
                value={values.email || ''}
                onChange={handleChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <button
              disabled={loading}
              className="reset-password-button"
              style={{ opacity: loading ? 0.5 : 1 }}
              onClick={resetPassword}
            >
              {loading ? <CircularProgress size={20} /> : 'Reset Password'}
            </button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default ResetPassword;
