import React from 'react';

import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { currencyField, textField } from './common';

import './forms.css';

export const InvestmentAssetForm = ({ formSubmit, account, edit }) => {
  console.log('formik', account);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: edit
      ? {
          ...account
        }
      : {},
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'Name is required.';
      }

      return errors;
    },
    onSubmit: async (data) => {
      await formSubmit(data, account, {});
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'name', 'Asset/Holding Name')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'value', 'Asset Value')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'ticker_symbol', 'Ticker Symbol')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'quantity', 'Number of Shares')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'price', 'Current Market Price')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'cost_basis', 'Total Cost Basis')}
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" label="Submit" className="p-mt-2" />
        </Grid>
      </Grid>
    </form>
  );
};
