import React, { useEffect, useState, useCallback } from 'react';

import { Grid } from '@material-ui/core';

import MainExpenseTable from 'components/tables/MainExpenseTable';
import AddAccountsTile from 'components/tiles/AddAccountsTile';
import ExpensesTile from 'components/tiles/ExpensesTile';
import IncomeTile from 'components/tiles/IncomeTile';
import InsightsTile from 'components/tiles/InsightsTile';
import NetCashFlowTile from 'components/tiles/NetCashFlowTile';
import TileContainer from 'components/tiles/TileContainer';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

import './ExpenseTracker.css';

export const isExpense = (acct) =>
  acct.institution?.page === 'expenses' || ['credit', 'depository'].indexOf(acct.account_type) > -1;

function ExpenseTracker() {
  const {
    ExpenseAccounts,
    CategoryData,
    CategoryTotals,
    InsightsData,
    selectedMonth,
    setSelectedMonth,
    sortOtherLast
  } = useAccountsData();
  const { currentUser } = useAuth();
  const [incomeObj, setIncomes] = useState({});
  const [expenseObj, setExpenses] = useState({});
  const [incomeTotal, setIncomeTotal] = useState(0);
  const [expenseTotal, setExpenseTotal] = useState(0);

  const addCategoryToData = useCallback(
    (data, category, total) => {
      if (category.parent_id == null) {
        if (data[category.id] == null) {
          data[category.id] = {
            id: category.id,
            name: category.name,
            total: total,
            children: []
          };
        } else {
          data[category.id].total += total;
        }
      } else {
        if (data[category.parent_id] == null) {
          data[category.parent_id] = {
            id: category.parent.id,
            name: category.parent.name,
            total: 0,
            children: []
          };
        }
        data[category.parent_id].children.push({
          id: category.id,
          name: category.name,
          total: total
        });
        data[category.parent_id].children.sort(sortOtherLast);
        data[category.parent_id].total += total;
      }
    }, [sortOtherLast]
  )

  useEffect(() => {
    let incomeTotal = 0;
    let incomeData = {};
    let expenseTotal = 0;
    let expenseData = {};

    CategoryTotals.sort((a, b) => a.id - b.id).forEach((cat) => {
      let category = CategoryData.find((c) => c.id === cat.id.toString());
      if (!category) return;
      if (category?.category_type === 'income') {
        addCategoryToData(incomeData, category, cat.amount);
        incomeTotal += cat.amount;
      } else {
        //display expenses as positive numbers
        addCategoryToData(expenseData, category, cat.amount * -1);
        expenseTotal += cat.amount * -1;
      }
    });
    setIncomes(incomeData);
    setExpenses(expenseData);
    setIncomeTotal(incomeTotal);
    setExpenseTotal(expenseTotal);
  }, [currentUser, CategoryTotals, CategoryData, addCategoryToData]);

  return (
    <div>
      <TileContainer
        pageId="expense"
        rightAccounts={ExpenseAccounts}
        insights={InsightsData.filter((i) => i.page === 'expenses')}
        leftContent={
          <>
            <NetCashFlowTile
              incomeTotal={incomeTotal}
              expenseTotal={expenseTotal}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
            <div>
              <MainExpenseTable
                incomeObj={incomeObj}
                incomeTotal={incomeTotal}
                expenseObj={expenseObj}
                expenseTotal={expenseTotal}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
              />
            </div>
            <InsightsTile page="expenses" />
            <AddAccountsTile />
          </>
        }
        rightContent={
          <Grid item xs={12} align="center" container spacing={1}>
            <Grid item xs={12} md={6}>
              <ExpensesTile
                expenses={expenseObj}
                expenseTotal={expenseTotal}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <IncomeTile
                incomes={incomeObj}
                incomeTotal={incomeTotal}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
}

export default ExpenseTracker;
