import React, { useEffect, useRef, useState } from 'react';
// import { Chart } from 'primereact/chart';
import Chart from 'react-apexcharts';

import { LinearProgress } from '@material-ui/core';
import moment from 'moment';

import { useAuth } from 'contexts/AuthContext';

import 'components/assetDetails/AssetDetails.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';

// import { Skeleton } from '@material-ui/lab';

const url = process.env.REACT_APP_API_URL;

function AccountLineChart({ account, nonUsdAssets, accountsIds, AssetsTotal, colors, change, setChange }) {
  const { currentUser } = useAuth();
  const [dateRange, setDateRange] = useState([{ balance: null, id: '' }]);
  const [dateLength, setDateLength] = useState(change?.range || 'day');
  const [getDate, setGetDate] = useState(yesterday());
  const [holdingsToggle, setHoldingsToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [loader, setLoader] = useState(true)

  async function getChangeInWorth(IDs, date) {
    const ID = await currentUser.getIdToken();

    // let currentDay = moment().format('YYYY-MM-DD')

    const response = await fetch(
      `${url}/api/v1/account_timeseries.json?filter[date][gte]=${date}&filter[date][lte]=${currentDay}&filter[account_id][eq]=${IDs}`,
      {
        // IDs
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      }
    );

    const json = await response.json();

    setChange({
      value: json['data'][json['data'].length - 1]['balance'] - json['data'][0]['balance'],
      range: dateLength
    });
  }

  let currentDay = moment().format('YYYY-MM-DD');

  function yesterday() {
    return moment().subtract(1, 'day').format('YYYY-MM-DD');
  }

  function pastWeek() {
    return moment().subtract(1, 'week').format('YYYY-MM-DD');
  }

  const dayClick = () => {
    setDateLength('day');
    const setDate = yesterday();
    setGetDate(setDate);
  };

  const weekClick = () => {
    setDateLength('week');
    const setDate = pastWeek();
    setGetDate(setDate);
  };

  const monthClick = () => {
    setDateLength('month');
    const setDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
    setGetDate(setDate);
  };

  const ytdClick = () => {
    setDateLength('ytd');
    let today = new Date();
    const setDate = parseInt(today.getFullYear()) + '-' + '01' + '-' + '01';
    setGetDate(setDate);
  };

  const yearClick = () => {
    setDateLength('year');
    const setDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
    setGetDate(setDate);
  };

  const maxClick = () => {
    setDateLength('max');
    let today = new Date();
    const setDate = parseInt(today.getFullYear() - 5) + '-' + '01' + '-' + '01';
    setGetDate(setDate);
  };

  const holdingsClick = () => {
    setHoldingsToggle(!holdingsToggle);
  };

  useEffect(() => {
    async function getDateRange() {
      const ID = await currentUser.getIdToken();
      const response = !holdingsToggle
        ? await fetch(
            `${url}/api/v1/asset_timeseries.json?filter[date][gte]=${getDate}&filter[date][lte]=${currentDay}&filter[account_id][eq]=${account.id}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ID}`
              }
            }
          )
        : await fetch(
            `${url}/api/v1/asset_timeseries.json?filter[date][gte]=${getDate}&filter[date][lte]=${currentDay}&filter[account_id][eq]=${account.id}&filter[asset_id][not_eq]=${nonUsdAssets}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ID}`
              }
            }
          );

      const json = await response.json();
      setDateRange(json['data']);
    }

    async function getAllAccountsDateRange() {
      const ID = await currentUser.getIdToken();
      const response = await fetch(
        `${url}/api/v1/account_timeseries.json?filter[date][gte]=${getDate}&filter[date][lte]=${currentDay}&filter[account_id][eq]=${accountsIds}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ID}`
          }
        }
      );

      const json = await response.json();
      setDateRange(json['data']);
    }

    setLoading(true);
    AssetsTotal ? getAllAccountsDateRange() : getDateRange();
    setLoading(false);

    // setLoader(false)

    // console.log('get change in worth')
    // if (accountsIds) getChangeInWorth(accountsIds, getDate)
    // else if (account.id) getChangeInWorth(account.id, getDate)
  }, [currentUser, getDate, holdingsToggle]); //[currentUser, getDate, holdingsToggle, currentDay, AssetsTotal, account.id, accountsIds, nonUsdAssets])

  let first = useRef(true);
  useEffect(() => {
    if (first.current == true) {
      first.current = false;
      return;
    }

    if (accountsIds) getChangeInWorth(accountsIds, getDate);
    else if (account.id) getChangeInWorth(account.id, getDate);
  }, [getDate]);

  const MONTHS = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'Aug',
    '09': 'Sept',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  };

  const labels = dateRange.map((obj) => {
    if (obj.id) {
      let time = obj.id.split('-');
      let month = time[1];
      let day = time[2];
      let formatted = MONTHS[month] + ' ' + day;
      return formatted;
    }
  });

  const datum = dateRange.map((obj) => {
    // const balance = obj.balance ? parseInt(obj.balance) : obj.value ? parseInt(obj.value) : 0
    const balance = obj.balance ? parseFloat(obj.balance).toFixed(2) : obj.value ? parseFloat(obj.value).toFixed(2) : 0;
    if ((obj.id && obj.balance) || (obj.id && obj.value)) {
      return balance;
    }
    return 0;
  });

  const options = {
    chart: {
      height: 100,
      type: 'area',
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    series: [
      {
        name: '',
        data: datum
      }
    ],
    fill: {
      colors: [colors],
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [10, 90, 100]
      }
    },
    xaxis: {
      categories: labels,
      hideOverlappingLabels: true,
      tickAmount: 4,
      tickPlacement: 'on',
      labels: {
        rotate: 0
      }
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return (
            '$' +
            parseFloat(value.toFixed(2)).toLocaleString('en', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
          );
        }
      }
    },
    stroke: {
      curve: 'smooth',
      colors: [colors]
    },
    tooltip: {
      marker: {
        show: false
      },
      y: {
        formatter: function (value) {
          return (
            '$' +
            parseFloat(value.toFixed(2)).toLocaleString('en', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
          );
        }
      }
    }
  };

  const series = [
    {
      name: '',
      data: datum
    }
  ];

  const placeholder = [{ name: '', data: [0, 0, 0, 0, 0] }];

  const loadingBar = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <p>One moment while we fetch your account info...</p>
        <LinearProgress />
      </div>
    </div>
  );

  return (
    <div>
      <div className="card">
        <div className="line-chart-header">
          <div className={AssetsTotal ? 'hide' : 'line-chart-headers'}>
            <div
              className={holdingsToggle ? 'line-chart-header-balance' : 'clicked-line-chart-header-balance'}
              onClick={holdingsClick}
            >
              Account Balance
            </div>
            <div
              className={holdingsToggle ? 'clicked-line-chart-header-holdings' : 'line-chart-header-holdings'}
              onClick={holdingsClick}
            >
              Holdings
            </div>
          </div>
          <ul className="line-time-toggle">
            <li className={dateLength === 'day' ? 'hovered' : 'line-toggle-item'} onClick={dayClick}>
              1 Day
            </li>
            <li className={dateLength === 'week' ? 'hovered' : 'line-toggle-item'} onClick={weekClick}>
              1 Week
            </li>
            <li className={dateLength === 'month' ? 'hovered' : 'line-toggle-item'} onClick={monthClick}>
              1 Month
            </li>
            <li className={dateLength === 'ytd' ? 'hovered' : 'line-toggle-item'} onClick={ytdClick}>
              YTD
            </li>
            <li className={dateLength === 'year' ? 'hovered' : 'line-toggle-item'} onClick={yearClick}>
              1 Year
            </li>
            <li className={dateLength === 'max' ? 'hovered' : 'line-toggle-item'} onClick={maxClick}>
              Max
            </li>
          </ul>
        </div>
        {dateRange.slice(-1)[0] !== undefined && (dateRange.slice(-1)[0].value || dateRange.slice(-1)[0].balance) ? (
          <Chart options={options} series={series} type="area" height={175} />
        ) : loading ? (
          loadingBar
        ) : (
          <div style={{ padding: 10 }}>No Data</div>
        )}
      </div>
    </div>
  );
}

export default AccountLineChart;
