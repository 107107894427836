import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import * as d3 from 'd3';

import {
  AdvisorAssetsTable,
  AdvisorEquitiesTable,
  AdvisorMatchThinCard,
  CashAssetsTable,
  GenericTransactionsTable,
  InsightCard,
  InvestorLayout,
  MorningstarConnect,
  NewAccountSteps,
  ProcessingMessage,
  ScoreMeter,
  TimeSeriesChart,
  TooltipIcon,
  formatAdvisorAssets,
  formatCashAssets,
  formatDollars,
  formatGenericTransactions
} from 'components';
import HoveredDonut from 'components/charts/HoveredDonut';
import LegendList from 'components/charts/LegendList';
import { formatPercent } from 'components/tables/GenericAssetsTable';
import { useAccountsData, useAuth } from 'contexts';

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '3.5rem 0 2.5rem',
    borderBottom: '1px solid',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '1.5rem 0 1.5rem'
    }
  },
  title: {
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem'
    }
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '0.5rem 0',
    fontSize: '14px'
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    height: '100%',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  firstRow: {
    gap: '1.5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  donutContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2rem'
  },
  titleWhite: {
    width: '100%',
    color: 'white'
  },
  assets: {
    display: 'block',
    marginTop: '2rem'
  },
  tableContainer: {
    width: '100%',
    height: '20rem',
    overflowX: 'auto',

    borderRadius: '0.5rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
  },
  tableContainerNoScroll: {
    width: '100%',
    borderRadius: '0.5rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
  },
  buttonArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
    cursor: 'pointer',
    padding: '0.8rem 1.5rem',
    fontWeight: 700,
    margin: '1rem'
  }
}));

const labelMapping: { [key: string]: string } = { equity: 'Equity', fixed: 'Bonds', cash: 'Cash', other: 'Other' };

export const PerformanceFactor = () => {
  const classes = useStyles();
  const history = useHistory();
  const mstarRef = useRef<HTMLInputElement>(null);

  const { currentUser, noAdvisor } = useAuth();
  const {
    BaaAccounts,
    baaUserData,
    investorUserData,
    scoreSubmitted,
    portfolioPerformanceData,
    investorPerformanceData
  } = useAccountsData();
  const { baa_investor_credentials } = baaUserData;
  const { score_performance, score_cash, score_growth, statement_in_progress, stats_breakdown } = investorUserData;
  const [transactions, setTransactions] = useState([]);
  const issueTypes: [string] = stats_breakdown?.issue_types || [];
  const isDataIssue = issueTypes.includes('data');
  const isPending = !score_cash && (BaaAccounts.length > 0 || scoreSubmitted.includes('score_fees'));

  const allocations = useMemo(() => {
    if (!stats_breakdown) return [];

    return Object.entries(stats_breakdown?.allocation_values)
      .map(([type, value]: any) => {
        if (type === 'total_value') return null;
        const typeName = type.replace(/total_/g, '');
        const label = labelMapping[typeName];
        if (!label) return null;
        return {
          AssetType: label,
          label,
          value: Number(value.toFixed(2))
        };
      })
      .filter((a) => a);
  }, [stats_breakdown]);

  const holdings = useMemo(
    () =>
      BaaAccounts.map((account: { assets: any[] }) =>
        account.assets.filter((a) => a.quantity && a.quantity !== '' && a.quantity > 0)
      )
        .flat()
        .reduce(
          (cum: any, x: any) => {
            if (x.fund_category_group === 'Alternatives') {
              cum['alternatives'].push(x);
            } else if (x.asset_type === 'equity' || x.asset_type === 'mutual fund') {
              cum['equities'].push(x);
            } else if (x.asset_type === 'bond') {
              cum['fixed'].push(x);
            } else if (x.asset_type === 'cash' || x.is_cash_equivalent) {
              cum['cash'].push(x);
            } else {
              cum['other'].push(x);
            }
            return cum;
          },
          { alternatives: [], equities: [], fixed: [], cash: [], other: [] }
        ),
    [BaaAccounts]
  );

  const getTransactions = useCallback(
    async (accts: [{ id: string }]) => {
      const ID = await currentUser.getIdToken();
      const response = await fetch(
        `${url}/api/v1/transactions.json?filter[account_id][eq]=${accts.map((a: any) => a.id).join(',')}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ID}`
          }
        }
      );
      const json = await response.json();
      setTransactions(formatGenericTransactions(json['data']));
    },
    [currentUser]
  );

  useEffect(() => {
    if (BaaAccounts && BaaAccounts.length) getTransactions(BaaAccounts);
  }, [getTransactions, BaaAccounts]);

  const labels = allocations.map((a) => a?.label || '');
  const labelTooltips = {
    Other:
      'Other includes any asset that Rainbook could not identify as a stock, ETF, mutual fund, bond, or cash.  Other also includes any Alternative investments in your connected account.'
  };
  const colorScheme = ['rgb(0,65,114)', 'rgb(14,92,178)', 'rgb(0,120,243)', 'rgb(0,176,255)', 'rgb(0,212,255)'];
  const colors = d3.scaleOrdinal().range(colorScheme);

  const currentDate = new Date().toLocaleDateString();
  const connectStep = baa_investor_credentials?.length
    ? {
        title: 'Connect Account or Upload Statements',
        isComplete: score_cash,
        isPending: BaaAccounts.length > 0,
        actionText: 'Fix Connection Issues',
        score_attribute: 'score_fees',
        action: () => mstarRef.current?.click()
      }
    : {
        title: 'Connect Account or Upload Statements',
        isPending: BaaAccounts.length > 0,
        isComplete: score_cash,
        actionText: 'Connect or Upload',
        score_attribute: 'score_fees',
        action: () => history.push('/advisor-analyzer/add-account')
      };

  const NoScore = (
    // have accounts but no score yet
    <>{!score_cash && BaaAccounts.length > 0 && 'Collecting data... check back soon.'}</>
  );

  let benchmarkDetail = '';
  if (stats_breakdown?.benchmark_allocation_percentages?.equity) {
    const rounded = Math.round(stats_breakdown.benchmark_allocation_percentages.equity);
    benchmarkDetail = `${stats_breakdown.benchmark_name} (${rounded}%/${100 - rounded}%)`;
  }

  return (
    <InvestorLayout>
      <div className={classes.header}>
        <h1 className={classes.title}>Portfolio Performance</h1>
        <h1 className={classes.title}>{formatDollars(stats_breakdown?.allocation_values?.total_value, true)}</h1>
      </div>
      <div className={classes.subHeader}>
        <span>Calculated daily</span>
        <span>as of {currentDate}</span>
      </div>
      <div className={classes.section}>
        <div className={`${classes.row} ${classes.firstRow}`}>
          {!noAdvisor && (
            <ScoreMeter
              value={score_performance}
              change={0}
              noScore={NoScore}
              tooltip="Your Portfolio Performance rating is derived by evaluating weighted factors, which encompass aspects such as cash optimization and relative performance in comparison to the corresponding Morningstar US Target Allocation Index.  Based on these factors, the rating can fall into categories of Good, Fair, or Poor."
              mstarRef={mstarRef}
              useFixedPosition
            />
          )}
          {statement_in_progress || isDataIssue || isPending ? (
            <ProcessingMessage {...{ statement_in_progress, isDataIssue }} />
          ) : score_performance ? (
            <>
              <TimeSeriesChart
                tickCallback={formatPercent}
                tabNames={['Your Portfolio']}
                tabPosition="top"
                tabOneData={portfolioPerformanceData}
                tabOneDateAxis="week"
                note={
                  <>
                    <span>*{benchmarkDetail}</span>{' '}
                    <TooltipIcon
                      size={16}
                      title={
                        <div>
                          <div>
                            Portfolio is the historical performance of your current stock, cash, ETF, and mutual fund
                            holdings, extrapolated backward.
                          </div>
                          <p>
                            The benchmark closely aligns with your current portfolio allocation. (Equity%/Fixed Income%)
                          </p>
                        </div>
                      }
                    />
                  </>
                }
                overlayOne={
                  !investorPerformanceData
                    ? 'Loading...'
                    : portfolioPerformanceData?.labels.length &&
                      portfolioPerformanceData?.labels.length < 30 &&
                      'Portfolio monitoring in progress. Updates occur daily.'
                }
              />
              <div className={classes.donutContainer}>
                <HoveredDonut
                  showTotal={false}
                  donutData={allocations}
                  colorScheme={colorScheme}
                  width={120}
                  height={120}
                  totals={stats_breakdown?.allocation_values?.total_value}
                  page={'asset'}
                  AccountName={'Your Portfolio'}
                />
                <LegendList
                  data={allocations}
                  labels={labels}
                  labelTooltips={labelTooltips}
                  colors={colors}
                  colorScheme={colorScheme}
                  baa={true}
                />
              </div>
            </>
          ) : (
            <NewAccountSteps header="Complete the following to analyze your portfolio:" steps={[connectStep]} />
          )}
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.section} id="rbaa-insights">
          <h2 className={classes.titleWhite}>RBAA Insights</h2>
          <div className={classes.row}>
            {noAdvisor ? (
              <InsightCard type="allocation" health={score_growth} isPending={isPending} />
            ) : (
              <InsightCard type="growth" health={score_growth} isPending={isPending} />
            )}
            <InsightCard type="cash" health={score_cash} isPending={isPending} />
          </div>
          <div className={classes.row}>
            <AdvisorMatchThinCard />
          </div>
        </div>
      </div>
      <div className={`${classes.section} ${classes.assets}`}>
        {holdings['equities'].length > 0 && (
          <div>
            <h3>Equities</h3>
            <div className={classes.tableContainerNoScroll}>
              <AdvisorEquitiesTable holdings={formatAdvisorAssets(holdings['equities'])} />
            </div>
          </div>
        )}
        {holdings['fixed'].length > 0 && (
          <div>
            <h3>Fixed Income</h3>
            <div className={classes.tableContainerNoScroll}>
              <AdvisorAssetsTable holdings={formatAdvisorAssets(holdings['fixed'])} />
            </div>
          </div>
        )}
        {holdings['cash'].length > 0 && (
          <div>
            <h3>Cash, Money Market Funds</h3>
            <div className={classes.tableContainerNoScroll}>
              <CashAssetsTable holdings={formatCashAssets(holdings['cash'])} />
            </div>
          </div>
        )}
        {holdings['alternatives'].length > 0 && (
          <div className={classes.tableContainerNoScroll}>
            <h4>Alternatives</h4>
            <AdvisorAssetsTable holdings={formatAdvisorAssets(holdings['alternatives'])} />
          </div>
        )}
        {holdings['other'].length > 0 && (
          <div>
            <h3>Other</h3>
            <div className={classes.tableContainerNoScroll}>
              <AdvisorAssetsTable holdings={formatAdvisorAssets(holdings['other'])} />
            </div>
          </div>
        )}
        {transactions && transactions.length > 0 && (
          <div>
            <h3>Portfolio Transactions</h3>
            <div className={classes.tableContainer}>
              <GenericTransactionsTable
                transactions={transactions}
                is_manual={false}
                setTransactionToEdit={undefined}
                setTransactionToDelete={undefined}
                loading={undefined}
                loadMore={undefined}
                loadingMore={undefined}
                mapAccountData={undefined}
                allowToggles={undefined}
                allowCategoryEdit={undefined}
                onEditTransaction={undefined}
              />
            </div>
          </div>
        )}
      </div>
      <MorningstarConnect refForClick={mstarRef} />
    </InvestorLayout>
  );
};
