import React from 'react';

import * as d3 from 'd3';

import Arc from './Arc';

const Doughnut = (props) => {
  const createDoughnut = d3
    .pie()
    .value((d) => d.value)
    .sort(null);
  const createArc = d3.arc().innerRadius(props.innerRadius).outerRadius(props.outerRadius);
  const colors = d3.scaleOrdinal().range(props.colors);
  const data = createDoughnut(props.data);
  const AssetTotal = props.AssetTotal;

  return (
    <div className="doughnut-container" style={{ position: 'relative', zIndex: 0 }}>
      <div className="doughnut">
        <svg width={props.width} height={props.height}>
          <g transform={`translate(${props.outerRadius}, ${props.outerRadius})`} key={Date.now()}>
            {data.map((d, i) => (
              <Arc createArc={createArc} data={d} index={i} colors={colors} key={i} />
            ))}
            <g key={Date.now()}>
              <text>
                <tspan
                  fill={props.assetPage ? 'rgb(37,100,93)' : 'white'}
                  textAnchor="middle"
                  y="6"
                  className="doughnut-total"
                >
                  {AssetTotal}
                </tspan>
              </text>
              <text>
                {props.text && (
                  <tspan
                    fill={props.assetPage ? 'rgb(37,100,93)' : 'white'}
                    textAnchor="middle"
                    y="26"
                    className="doughnut-total"
                  >
                    {props.text}
                  </tspan>
                )}
              </text>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Doughnut;
