import React from 'react';

import ExpensesDropdown from 'components/dropdowns/ExpensesDropdown';

import './NetCashFlowTile.css';

function NetCashFlowTile({ incomeTotal, expenseTotal, selectedMonth, setSelectedMonth }) {
  // let max = Math.max(expenseTotal, incomeTotal)
  // let min = Math.min(expenseTotal, incomeTotal)
  let netCashFlowTotal = incomeTotal - expenseTotal;
  const sign = netCashFlowTotal < 0 ? '-' : '+';
  // const direction = netCashFlowTotal < 0 ? 'Down' : 'Up';
  const dropdownBackgroundColor = netCashFlowTotal < 0 ? 'rgb(236,27,36)' : 'rgb(30,197,119)';

  return (
    <div className={netCashFlowTotal < 0 ? 'net-cash-flow-tile-neg' : 'net-cash-flow-tile-pos'}>
      <div className="net-cash-flow-header">
        <div className="net-cash-header-left">Net Cash Flow</div>
        <div className="net-cash-header-right">
          <div>
            <ExpensesDropdown
              styles={{
                width: 175,
                height: 20,
                borderRadius: 50,
                backgroundColor: dropdownBackgroundColor,
                border: '1px solid #FFFFFF'
              }}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
          </div>
        </div>
      </div>
      <div className="net-cash-flow-tile-body">
        <div className="net-change">
          {sign}$
          {(netCashFlowTotal < 0 ? netCashFlowTotal * -1 : netCashFlowTotal).toLocaleString('en', {
            minimumFractionDigits: 2
          })}
        </div>
        {/*
                <div className={ netCashFlowTotal < 0 ? "net-change-tag-neg" : "net-change-tag-pos"}>
                    {`Trending ${direction}, ${sign} XX% Over Last Month`}
                </div>
                */}
      </div>
    </div>
  );
}

export default NetCashFlowTile;
