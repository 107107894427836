import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

import { AdvisorMatchCard, InvestorLayout, NewAccountSteps, RatingGauge } from 'components';
import { useAccountsData } from 'contexts';
import { aaHealthColor } from 'utils';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '3.5rem 0 2.5rem',
    borderBottom: '1px solid'
  },
  title: {
    margin: 0
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '0.5rem 0',
    fontSize: '14px'
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  sectionTitle: { margin: 0, width: '100%' },
  sectionText: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem',
    width: '100%',
    gap: '1rem'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    height: '100%',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  firstRow: {
    gap: '1.5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  gauge: {
    width: '40%'
  },
  colorIndicator: {
    width: '100%',
    height: '0.5rem',
    borderRadius: '1rem 1rem 0 0'
  },
  cardSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem 2rem 2rem'
  },
  cardText: {
    margin: 0
  },
  cardFull: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    paddingBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  healthIcon: {
    marginTop: '0.2rem'
  },
  donutContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleWhite: {
    width: '100%',
    color: 'white'
  }
}));

export const EngagementFactor = () => {
  const classes = useStyles();
  const { investorUserData } = useAccountsData();
  const { score_engagement, advisor } = investorUserData;

  const scoreColor = useMemo(() => aaHealthColor(score_engagement), [score_engagement]);

  const insight = useMemo(() => {
    if (!score_engagement) {
      return (
        <NewAccountSteps
          steps={[
            {
              title: 'Provide feedback on your advisor.',
              isComplete: score_engagement,
              actionText: 'Complete Now',
              action: 'YUErtPSf',
              score_attribute: 'score_engagement'
            }
          ]}
        />
      );
    }
    const advisorName = advisor ? `${advisor.first_name} ${advisor.last_name}` : 'Your advisor';
    const advisorShortName = advisor ? advisor.first_name : 'your advisor';
    let insight = '';
    if (score_engagement < 80) {
      insight += `${advisorName} is not providing the level of communcaiton you deserve. Rainbook believes great communication and transparency is table stakes for a financial advisor.  Your advisor is working for you and this is unacceptable.`;
    } else if (score_engagement < 90) {
      insight += `${advisorName} has room for improvement in their level of communication with you, the client. Rainbook believes this is table stakes and you should feel comfortable sharing your feedback with ${advisorShortName}.  Your advisor is working for you, be sure to let them know how and when you want to meet.  Encourge them to improve on their communication relating to your investments and financial plan.`;
    } else {
      insight += `${advisorName} is delivering a high level of attention and service to you, the client. Rainbook believes this is table stakes and it is great see ${advisorShortName} is meeting or exceeding expectations.`;
    }
    return <p>{insight}</p>;
  }, [score_engagement, advisor]);

  const currentDate = new Date().toLocaleDateString();

  return (
    <InvestorLayout>
      <div className={classes.header}>
        <h1 className={classes.title}>Engagement & Trust</h1>
      </div>
      <div className={classes.subHeader}>
        <span>Calculated daily</span>
        <span>as of {currentDate}</span>
      </div>
      <div className={classes.section}>
        <div className={`${classes.row} ${classes.firstRow}`}>
          <div className={classes.gauge}>
            <RatingGauge score={score_engagement} size="lg" />
          </div>
          <div className={classes.sectionText}>
            {insight}
          </div>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.section} id="rbaa-insights">
          <div className={classes.row}>
            <AdvisorMatchCard general />
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.sectionText}>
                <h3 className={classes.sectionTitle}>Important to know about working with your advisor:</h3>
                <p>
                  You, the client, should always feel comfortable reaching out to your financial advisor and your
                  advisor should be readily available to address your concerns.
                </p>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.cardSection}>
                <h4>Active Communication:</h4>
                <p className={classes.cardText}>
                  Does your advisor regularly reach out to you to provide portfolio updates, investment strategies
                  and/or any concerns?
                </p>
                <h4>Personalized Service:</h4>
                <p className={classes.cardText}>
                  Does your advisor offer you a tailored service vs a one size fits all? Are they responsive to your
                  risk tolerance and work hard to understand your specific needs? Will they actively engage with your
                  questions, even if it is a topic such as speculative investments which they would never recommend?
                </p>
                <h4>Putting You, the Client, First:</h4>
                <p className={classes.cardText}>
                  A financial advisor who acts as a fiduciary prioritizes recommending investments and strategies that
                  are in the best interest of the client, even if it means foregoing potential personal gains. This
                  client-centric approach reinforces the advisor's dedication to achieving the client's financial goals.
                </p>
                <h4>Professional Competence:</h4>
                <p className={classes.cardText}>
                  Does your advisor stay updated on industry trends, regulations, and best practices?
                </p>
                <h4>Transparent Fee Structure:</h4>
                <p className={classes.cardText}>
                  Your financial advisor should proactively and clearly explain their fee structure, including advisory
                  fees and any other charges associated with the account. Your advisor should be upfront about potential
                  conflicts of interest and disclose any incentives or compensation they may receive for recommending
                  specific investments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InvestorLayout>
  );
};
