import React, { useState } from 'react';

import { Avatar, DialogActions, TextField } from '@material-ui/core';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import Home from '@material-ui/icons/Home';
import { Button } from 'primereact/button';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 5
  },
  centered: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const CoinbaseProLink = ({ name, institution, styles, onAccounts, handleMainClose }) => {
  const [open, setOpen] = useState(false);

  const [key, setKey] = useState('');
  const [secret, setSecret] = useState('');
  const [passphrase, setPassphrase] = useState('');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const classes = useStyles();

  const onSubmit = async () => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/coinbasepro`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          key: key,
          secret: secret,
          passphrase: passphrase
        }
      })
    });
    const json = await response.json();
    if (json['data'] && onAccounts) {
      onAccounts(json['data']);
      setSuccess(true);
    } else {
      setError(`Unable to add your Coinbase Pro account. Please check your Key, Secret, and Passphrase and try again.`);
    }
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const closePopup = () => {
    setSuccess(false);
    setKey('');
    setSecret('');
    setPassphrase('');
  };

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success && (
        <SuccessDialog
          page={'Liquid'}
          institution={'Coinbase Pro'}
          handleClose={handleMainClose}
          onClose={closePopup}
        />
      )}
      <div style={styles} onClick={(e) => setOpen(true)}>
        {institution?.logo_url ? (
          <img style={{ paddingBottom: 5 }} className="link-bank-logo" src={institution.logo_url} alt=" " />
        ) : (
          <>
            <Avatar className="link-bank-icon">
              <Home />
            </Avatar>
            <div>{name}</div>
          </>
        )}
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitleX onClose={handleClose}>Add your API Key for Coinbase Pro</DialogTitleX>
        <DialogContent>
          <div className={classes.centered}>
            <img src={institution.logo_url} alt={'Coinbase Pro Logo'} />
          </div>

          <TextField
            label="Passphrase"
            name="passphrase"
            value={passphrase}
            onChange={(e) => setPassphrase(e.target.value)}
            variant="outlined"
            className={classes.formControl}
            fullWidth
          />
          <TextField
            label="Secret"
            name="secret"
            value={secret}
            onChange={(e) => setSecret(e.target.value)}
            variant="outlined"
            className={classes.formControl}
            fullWidth
          />
          <TextField
            label="Key"
            name="key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            variant="outlined"
            className={classes.formControl}
            fullWidth
          />

          <div className={classes.centered} style={{ padding: '5px' }}>
            <a href="https://rainbook.com/connect-coinbase-pro" target="_blank" rel="noreferrer">
              Click here for detailed instructions
            </a>
          </div>
        </DialogContent>
        <DialogActions>
          <Button label="Submit" icon="pi pi-check" onClick={onSubmit} />
        </DialogActions>
      </Dialog>
    </>
  );
};
