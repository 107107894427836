import React, { useMemo, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { FiMenu } from 'react-icons/fi';
import { GoSignOut } from 'react-icons/go';
import { LiaTimesSolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';

import { Avatar, ClickAwayListener, makeStyles } from '@material-ui/core';

import { SupportDialog } from 'components/dialogs';
import { useAccountsData, useAuth } from 'contexts';
import { AdvisorAnalyzerLinks, NoAdvisorLinks } from 'utils/navItems';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginRight: '0.5rem',
    [theme.breakpoints.down('md')]: {
      marginRight: 0
    }
  },
  main: {
    position: 'relative',
    height: '100%'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    height: '100%',
    padding: '0 1rem',
    color: 'white',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'all 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  },
  imgContainer: {
    padding: '1rem 1rem'
  },
  dropdown: {
    position: 'absolute',
    top: '3.5rem',
    right: 0,
    width: '20rem',
    paddingBottom: '0.5rem',
    maxHeight: 'calc(100vh - 3.5rem)',
    backgroundColor: 'rgb(31,158,249)',
    boxShadow: '0.3rem 0.3rem 0.3rem rgba(0,0,0,0.20)',
    borderRadius: '0 0 0.5rem 0.5rem',
    zIndex: 10,
    overflow: 'scroll',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100vw'
    }
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 1rem',
    margin: 0,
    textDecoration: 'none',
    fontSize: '0.9rem',
    fontWeight: 500,
    cursor: 'pointer',
    color: 'white',
    backgroundColor: 'transparent',
    userSelect: 'none',
    transition: 'all 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '1rem 2rem'
    }
  },
  callToAction: {
    backgroundColor: '#1ec577',
    //backgroundColor: '#007afe',
    color: '#fff',
    border: '0.5px solid #fff',
    borderRadius: '6px',
    marginLeft: '20px',
    padding: '3px 30px',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  callToActionMenu: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#1ec577',
      color: '#fff'
    }
  }
}));

interface LinkItem {
  title: string;
  path?: string;
  className?: string;
  subItems?: LinkItem[];
  initialOpen?: boolean;
}

export const NavDropdown = () => {
  const classes = useStyles();
  const { displayPhoto, logout, noAdvisor } = useAuth();

  const { investorUserData } = useAccountsData();
  const stats_breakdown = investorUserData?.stats_breakdown;
  const provided_account_size_max = investorUserData?.provided_account_size_max;
  const active_meeting_advisor_profile_ids = investorUserData?.active_meeting_advisor_profile_ids || [];

  const allowMatching = !!(
    !active_meeting_advisor_profile_ids.length &&
    (stats_breakdown?.total_balance || provided_account_size_max)
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);

  const supportOpen = () => {
    setSupportDialogOpen(true);
    setDropdownOpen(false);
  };

  const supportClose = () => {
    setSupportDialogOpen(false);
  };

  const options: LinkItem[] = useMemo(() => {
    let arr: LinkItem[] = [
      {
        title: 'Home',
        path: '/'
      },
      {
        title: 'Advisor Analyzer',
        initialOpen: true,
        subItems: noAdvisor ? NoAdvisorLinks : AdvisorAnalyzerLinks
      }
    ];
    if (allowMatching) {
      arr.push({
        title: 'Receive Portfolio Review',
        path: '/advisor-analyzer/advisor-match',
        className: classes.callToActionMenu
      });
    } else {
      arr.push({
        title: 'View Advisors',
        path: '/advisor-analyzer/advisor-match'
      });
    }
    arr = arr.concat([
      {
        title: '+ Add Advisor Managed Account',
        path: '/advisor-analyzer/add-account'
      },
      {
        title: 'Profile',
        path: '/profile'
      }
    ]);
    return arr;
  }, [noAdvisor, stats_breakdown, provided_account_size_max, classes.callToActionMenu]);

  const DropdownItem = ({
    item,
    level = 1,
    initialOpen = false
  }: {
    item: LinkItem;
    level?: number;
    initialOpen?: boolean;
  }) => {
    const [open, setOpen] = useState(initialOpen);

    if (item.subItems) {
      return (
        <>
          <div
            className={classes.item}
            key={item.title}
            onClick={() => setOpen((open) => !open)}
            style={{ paddingLeft: level * 20 }}
          >
            <div>{item.title}</div>
            {open ? <BsChevronUp /> : <BsChevronDown />}
          </div>
          {open && (
            <div>
              {item.subItems.map((subItem: LinkItem) => (
                <DropdownItem key={subItem.title} item={subItem} level={level + 1} />
              ))}
            </div>
          )}
        </>
      );
    } else if (item.path) {
      return (
        <Link
          to={item.path}
          className={`${classes.item} ${item.className || ''}`}
          key={item.title}
          style={{ paddingLeft: level * 20 }}
          onClick={() => setDropdownOpen(false)}
        >
          <div>{item.title}</div>
        </Link>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
        <div className={classes.container}>
          {allowMatching && (
            <Link to="/advisor-analyzer/advisor-match" className={classes.callToAction}>
              Schedule Portfolio Review
            </Link>
          )}
          <Link to="/profile" className={classes.imgContainer}>
            <Avatar style={{ width: 30, height: 30 }} src={displayPhoto} />
          </Link>
          <div className={classes.main}>
            <button className={classes.button} onClick={() => setDropdownOpen((open) => !open)}>
              {dropdownOpen ? <LiaTimesSolid size={30} /> : <FiMenu size={30} />}
            </button>
            {dropdownOpen && (
              <div className={classes.dropdown}>
                <>
                  {options.map((option) => (
                    <DropdownItem key={option.title} item={option} initialOpen={option.initialOpen} />
                  ))}
                  <div className={classes.item} onClick={supportOpen} style={{ paddingLeft: 20 }}>
                    Support
                  </div>
                  <Link className={classes.item} to="/login" onClick={logout} style={{ paddingLeft: 20 }}>
                    Sign Out <GoSignOut />
                  </Link>
                </>
              </div>
            )}
          </div>
        </div>
      </ClickAwayListener>
      <SupportDialog open={supportDialogOpen} onClose={supportClose} />
    </>
  );
};
