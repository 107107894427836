import React, { useMemo } from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';

import { makeStyles } from '@material-ui/core';

import { AdvisorMatchCard, InvestorLayout, NewAccountSteps, RatingGauge } from 'components';
import { useAccountsData } from 'contexts';
import { aaHealthColor } from 'utils';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '3.5rem 0 2.5rem',
    borderBottom: '1px solid'
  },
  title: {
    margin: 0
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    flexDirection: 'row'
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '0.5rem 0',
    fontSize: '14px'
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  sectionTitle: { margin: 0, padding: '1rem 0 0 0', width: '100%' },
  sectionText: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem',
    width: '100%',
    gap: '1rem'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    height: '100%',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1.5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  gauge: {
    width: '40%'
  },
  colorIndicator: {
    width: '100%',
    height: '0.5rem',
    borderRadius: '1rem 1rem 0 0'
  },
  cardSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem 2rem 2rem'
  },
  cardText: {
    margin: 0
  },
  cardFull: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    paddingBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  healthIcon: {
    marginTop: '0.2rem'
  },
  donutContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleWhite: {
    width: '100%',
    color: 'white'
  },
  list: {
    gap: '0.5rem'
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    alignItems: 'start',
    justifyContent: 'start',
    columnGap: '0.5rem',
    '&::before': {
      content: '"•"',
      fontSize: '1.5rem',
      lineHeight: 0.8
    }
  }
}));

export const StatementsFactor = () => {
  const classes = useStyles();
  const { investorUserData } = useAccountsData();
  const { score_reporting, advisor } = investorUserData;

  const scoreColor = useMemo(() => aaHealthColor(score_reporting), [score_reporting]);

  const insight = useMemo(() => {
    if (!score_reporting) {
      return (
        <NewAccountSteps
          steps={[
            {
              title: 'Provide feedback on your statements.',
              isComplete: score_reporting,
              actionText: 'Complete Now',
              action: 'RQ1dKN9C',
              score_attribute: 'score_reporting'
            }
          ]}
        />
      );
    }
    const advisorName = advisor ? advisor.first_name : 'your advisor';

    let insight = '';
    if (score_reporting < 80) {
      insight = `Your statements are lacking sufficient information on your investment account.  Statements should be more than account balance. Don't hesitate to ping ${advisorName} with your feedback, questions and concerns.  If there are technology restrictions on what's included in the standard statement delivered to you, additional information should be provided separately until the technological restrictions can be overcome.`;
    } else if (score_reporting < 90) {
      insight = `Your statements are providing a sufficient amount of information on your investment account, with room to improve.  If you notice anything, don't hesitate to ping ${advisorName} with any feedback, questions or concerns.  Additional information should be provided separately if there are technology restrictions on what's included in the standard statement delivered to you.`;
    } else {
      insight = `Your statements are providing a clear picture of your investment account.  Take advantage to review your statements (at least) quarterly and don't hesitate to ping ${advisorName} with any feedback, questions or concerns.`;
    }
    return <p>{insight}</p>;
  }, [score_reporting, advisor]);

  const currentDate = new Date().toLocaleDateString();

  return (
    <InvestorLayout>
      <div className={classes.header}>
        <h1 className={classes.title}>Reporting Quality</h1>
      </div>
      <div className={classes.subHeader}>
        <span>Calculated daily</span>
        <span>as of {currentDate}</span>
      </div>
      <div className={classes.section}>
        <div className={`${classes.row} ${classes.firstRow}`}>
          <div className={classes.gauge}>
            <RatingGauge score={score_reporting} size="lg" />
          </div>
          <div className={classes.sectionText}>{insight}</div>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.section} id="rbaa-insights">
          <div className={classes.row}>
            <AdvisorMatchCard general />
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.sectionText}>
                <h3 className={classes.sectionTitle}>Important items to know about your statements:</h3>
                <ul>
                  <li>Portfolio allocation and performance</li>
                  <li>Fees and expenses</li>
                  <li>Account activity</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.cardSection}>
                <h4>Portfolio Holdings & Performance:</h4>
                <p className={classes.cardText}>
                  Detailed breakdown of the investments held within the your portfolio. It includes information such as
                  the names of securities, ticker symbols, number of shares/units, and the current market value of each
                  holding. Performance presents the account's performance during the reporting period. It may include
                  metrics such as the beginning and ending account values, rate of return for the period, and the
                  overall gain or loss on investments. This helps you assess how your portfolio has performed over time.
                </p>
                <h4>Asset Allocation:</h4>
                <p className={classes.cardText}>
                  Your statement and/or reporting dashboard should show your portfolio by investment type, providing the
                  percentage held in cash, equities (stock), fixed income (bonds), mutual funds, and other types of
                  securities.
                </p>
                <h4>Fees & Expenses:</h4>
                <p className={classes.cardText}>
                  Your statement should transparently disclose any fees or expenses associated with the account. This
                  includes the advisory fee charged by your financial advisor, as well as any other account-related fees
                  such as custodial fees or transaction fees. It's important for you, the client, to have a clear
                  understanding of the costs associated with their investments.
                </p>
                <h4>Transaction & Account Activity:</h4>
                <p className={classes.cardText}>
                  Your statement should include transaction history and account activity that lists all cash flow
                  movement, investment purchases, sales, dividends received, interest earned, and any other relevant
                  transactions.
                </p>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.cardSection}>
                <h2 className={classes.subTitle}>
                  <span className={classes.healthIcon}>{<AiFillExclamationCircle color="#F94F58" size={35} />}</span>
                  Important
                </h2>
                <ul className={classes.list}>
                  <li className={classes.listItem} style={{ marginBottom: 10 }}>
                    Ask your financial professional to explain or provide more detail about any information in your
                    account statement. This is what you are paying for.
                  </li>
                  <li className={classes.listItem}>
                    Promptly let your financial professional know of changes in your investment objectives, risk
                    tolerance, or financial situation.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InvestorLayout>
  );
};
