import React, { useMemo, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { RatingGauge } from 'components/charts';
import LoadingImg from 'logos/RainbookLoading.gif';

interface RatingCardProps {
  title: string;
  score: number;
  score_attribute?: string;
  impact?: 'low' | 'medium' | 'high';
  description: string;
  content?: string;
  contentHeader?: string;
  link: string;
  isPending?: boolean;
  noAdvisor?: boolean;
  action?: JSX.Element;
  displayValue?: string;
  errorMessage?: string;
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0 0 0.5rem rgba(0,0,0,0.25)',
    textDecoration: 'none',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  colorIndicator: {
    width: '100%',
    height: '0.5rem',
    borderRadius: '1rem 1rem 0 0'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    margin: '0',
    padding: '0 0.5rem',
    height: '2rem'
  },
  impact: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.25rem',
    backgroundColor: 'rgb(191,225,235)',
    padding: '0.25rem 0.75rem',
    fontWeight: 500,
    fontSize: '0.8rem'
  },
  score: {
    fontSize: '1.5rem',
    fontWeight: 500,
    margin: 0
  },
  description: {
    textAlign: 'center',
    fontSize: '0.8rem',
    margin: 0,
    padding: '10px 0px 20px 0px'
  },
  content: {
    padding: '1rem',
    color: '#111',
    margin: '1rem',
    fontSize: '.8rem',
    borderRadius: '5px'
  },
  contentHeader: {
    fontSize: '1rem',
    fontStyle: 'italic',
    paddingBottom: '10px',
    fontWeight: 'bold',
    color: '#fff'
  },
  pendingImg: {
    maxWidth: '100px',
    maxHeight: '100px',
    width: 'auto',
    height: 'auto'
  },
  viewDetails: {
    position: 'absolute',
    bottom: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    borderRadius: '0.25rem',
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: 'rgb(21, 120, 246)',
    border: 'none',
    cursor: 'pointer',
    transition: 'padding 0.25s ease-in-out'
  },
  viewDetailsHovered: {
    paddingLeft: '1.5rem'
  },
  btn: {
    color: '#ffffff',
    backgroundColor: 'rgb(21, 120, 246)',
    borderRadius: '9px',
    width: 200,
    display: 'inline-block',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    fontSize: '12pt',
    padding: 12,
    textAlign: 'center',
    marginBottom: 20,
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 0.5s ease'
    },
    '&.sub': {
      color: 'rgb(21, 120, 246)',
      backgroundColor: '#ffffff',
      borderStyle: 'solid',
      borderColor: 'rgb(21, 120, 246)'
    }
  }
}));

export const RatingCard = ({
  title,
  score,
  description,
  content,
  contentHeader,
  link,
  isPending,
  noAdvisor
}: RatingCardProps) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  const healthToInitialColor = useMemo(() => {
    if (isPending || noAdvisor) {
      return '#1578f6';
    }
    if (!score) {
      return '#a5a5a5';
    }
    if (score < 80) {
      return '#F94F58';
    } else if (score < 90) {
      return '#FFC200';
    } else {
      return '#1DC577';
    }
  }, [score, isPending, noAdvisor]);

  const scoreContent = (
    <div className={`${classes.viewDetails} ${isHovered ? classes.viewDetailsHovered : ''}`}>
      <span>View Details</span>
      <AiOutlineArrowRight />
    </div>
  );

  const noScoreContent = (
    <div className={classes.viewDetails + ' sub'}>
      <span>Get Your Score</span>
      <AiOutlineArrowRight />
    </div>
  );

  return (
    <Link
      to={link}
      className={classes.card}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ backgroundColor: healthToInitialColor }}
    >
      <div>
        <h3 className={classes.title}>{title}</h3>
        <p className={classes.description}>{description}</p>
      </div>
      {isPending ? (
        <img className={classes.pendingImg} src={LoadingImg} />
      ) : (
        <RatingGauge white score={isPending ? 0 : score} size="sm" />
      )}
      <div className={classes.content}>
        <div className={classes.contentHeader}>{isPending ? 'Processing, check back shortly.' : contentHeader}</div>
        {/*content*/}
      </div>
      {isPending || noAdvisor || score ? scoreContent : noScoreContent}
    </Link>
  );
};
