import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { IoIosAlert, IoIosStar } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { Tooltip, makeStyles } from '@material-ui/core';

import { formatDollars } from 'components/tables';
import { useAccountsData, useAuth } from 'contexts';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    marginTop: '1.2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  colorIndicator: {
    width: '100%',
    height: '0.5rem',
    borderRadius: '1rem 1rem 0 0',
    backgroundColor: '#1FC277'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: 'rgb(249, 79, 88)',
    borderWidth: '3px',
    borderRadius: '0.5rem',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 2rem'
  },
  leftCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'rgb(249, 79, 88)',
    fontSize: '2rem',
    padding: '0 1rem',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  signPost: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.8rem',
    padding: '1rem',
    backgroundColor: 'rgb(21, 120, 246)'
  },
  advisorIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.8rem',
    padding: '1rem'
  },
  matchRec: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 500
  },
  matchText: {
    color: 'black'
  },
  rightCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    maxWidth: '150px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  matchLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5rem',
    color: 'white',
    fontWeight: 500,
    width: '18rem',
    padding: '0.8rem 1rem',
    borderStyle: 'solid',
    backgroundColor: 'rgb(21, 120, 246)',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(21, 120, 246, 0.75)'
    }
  }
}));

export const AdvisorMatchThinCard = ({ issueTypes = [] }: { issueTypes?: string[] }) => {
  const classes = useStyles();
  const {
    investorUserData: {
      score_overall,
      score_fees,
      stats_breakdown,
      provided_account_size_max,
      active_meeting_advisor_profile_ids
    }
  } = useAccountsData();
  const { noAdvisor } = useAuth();

  if (active_meeting_advisor_profile_ids.length || (!stats_breakdown?.total_balance && !provided_account_size_max)) {
    if (!issueTypes.length) {
      return <></>;
    }
  }

  let message = '';
  let asAlert = true;

  if (noAdvisor) {
    asAlert = false;
    message =
      'Based on your information provided, we have matched you with two premier fiduciary advisors on the Rainbook Advisor Network for a free consultation. Rainbook members make the first move.  Strict no spam policy.';
  } else if (issueTypes.length) {
    if (issueTypes.includes('aum_fees_higher')) {
      message = 'Your Advisory Fees are higher than you thought! Schedule a free consultation to take a closer look.';
    }
  } else if (score_fees && score_fees < 80) {
    if (stats_breakdown.fee_potential && Math.floor(stats_breakdown.fee_potential / 500) * 500) {
      message = `You could be avoiding more than ${formatDollars(
        Math.floor(stats_breakdown.fee_potential / 500) * 500
      )} in fees over the next five years while receiving comprehensive financial planning services.  Receive a no-obligation consultation today.`;
    } else {
      message =
        'Based on our independent analysis, you are paying too much in fees and and should speak with one of our advisors to explore how you can pay less. We have matched you with two of our vetted advisors.';
    }
  } else if (score_overall && score_overall < 85) {
    message =
      'Based on our independent analysis, you might consider working with a new advisor. We have matched you with two of our vetted advisors for a free consultation.';
  } else {
    asAlert = false;
    message =
      'If you’re not completely satisfied with your current advisor, Rainbook Members receive a complimentary consultation with a fiduciary advisor from our vetted network. No strings attached.';
  }

  return (
    <div className={classes.card}>
      <div className={classes.container}>
        <div>
          <div className={classes.matchRec}>
            <div className={classes.leftCol} style={!asAlert ? { color: 'rgb(21, 120, 246)' } : {}}>
              {asAlert ? <IoIosAlert /> : <IoIosStar />}
            </div>
            <p className={classes.matchText}>
              {message}
              {issueTypes.includes('bundled') && (
                <>
                  {message && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  Your portfolio includes mutual fund(s) that have{' '}
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                    style={{ borderBottomWidth: '1px', borderBottomStyle: 'dotted' }}
                    title="Load fees are charged when you buy or sell certain mutual funds.  A front end load fee is paid at the time of purchase, typically 3.75% to 5.75% of the total purchase price.  The 12b-1 fee is often used as a commission to the broker for selling you the fund. There are many alternatives to load fee funds.  Speak with a true fiduciary advisor to learn more."
                  >
                    <span>load and 12b-1 fees</span>
                  </Tooltip>{' '}
                  which can be up to 5.75%! These excessive fees are paid when you buy (or sell) the fund and a portion
                  of the fee is often used as a commission to the broker. This is in addition to the recurring annual
                  fund expense fee. You could have avoided these indiscriminate fees by speaking with a true fiduciary
                  advisor.
                </>
              )}
            </p>
          </div>
        </div>
        <div className={classes.rightCol}>
          <Link className={classes.matchLink} to="/advisor-analyzer/advisor-match">
            Learn More
            <AiOutlineArrowRight size={16} />
          </Link>
        </div>
      </div>
    </div>
  );
};
