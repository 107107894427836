import React, { useState } from 'react';

import { Button, Card } from '@material-ui/core';
import * as d3 from 'd3';

import LegendVisualizationData from 'assetData/LegendVisualizationData';
import { StatementIcon, TileIcon } from 'components/assetDetails/PersonalFinancialStatement';
import AccountLineChart from 'components/charts/AccountLineChart';
import HoveredDonut from 'components/charts/HoveredDonut';
import Legend from 'components/charts/Legend';
import AddAccountsTile from 'components/tiles/AddAccountsTile';
import InsightsTile from 'components/tiles/InsightsTile';
import TileContainer from 'components/tiles/TileContainer';
import { useAccountsData } from 'contexts/AccountsContext';
import { changeInValue, formatMoney, rangeToString } from 'utils/formatters';

import 'pages/AssetPages.css';

export const ALTERNATIVE_INVESTMENT_SUBTYPES = ['private equity', 'real estate', 'stock options'];

export const isAlternative = (acct, approximate = false) =>
  acct.institution?.page === 'alternative' ||
  (!acct.institution?.page &&
    acct.account_type === 'investment' &&
    ALTERNATIVE_INVESTMENT_SUBTYPES.indexOf(acct.account_subtype) > -1);

export const alternativeColorScheme = ['rgb(118,209,255)', 'rgb(44,165,255)', 'rgb(14,92,178)', 'rgb(0,65,114)'];

export const alternativeMainColor = 'rgb(0,120,245)';

function Alternative() {
  const { AltData, AltAccounts, AltIds, InsightsData, altChange, setAltchange } = useAccountsData();

  const [view, setView] = useState('tile');

  const alternativeTotal = AltData.reduce(function (sum = 0, current) {
    return sum + current.value;
  }, 0);

  const labels = AltData.map((hash) => {
    return hash['label'];
  });

  const colorScheme = LegendVisualizationData(AltData);
  const colors = d3.scaleOrdinal().range(colorScheme);
  const page = 'alternativeAsset';

  return (
    <div>
      <TileContainer
        pageId="alternative"
        rightAccounts={AltAccounts}
        showPFS={view !== 'tile'}
        insights={InsightsData.filter((i) => i.page === 'alternative')}
        assetColorScheme={alternativeColorScheme}
        mainColor={alternativeMainColor}
        leftContent={
          <>
            <Card className="asset-totals-container" style={{ borderRadius: '9px' }}>
              <div className="liquid-asset-header">
                <h3 className="asset-title">Alternative Assets: {formatMoney(alternativeTotal, 0)}</h3>
                {altChange && (
                  <p className="change-in-value" style={changeInValue(altChange.value)}>
                    {formatMoney(altChange.value, 0)}{' '}
                    <span style={{ color: 'lightslategray' }}>{rangeToString(altChange.range)}</span>
                  </p>
                )}
              </div>
              <div
                className="liquid-asset-doughnut-and-keys"
                style={alternativeTotal === 0 ? { marginBottom: 20 } : { marginBottom: 0 }}
              >
                <div className="liquid-asset-doughnut">
                  <HoveredDonut
                    showTotal={false}
                    page={'asset'}
                    donutData={AltData}
                    innerRadius={55}
                    outerRadius={65}
                    colorScheme={alternativeColorScheme}
                    width={150}
                    height={150}
                    totals={alternativeTotal}
                    AccountName={'Alternative'}
                  />
                </div>
                <div className="liquid-asset-doughnut-keys">
                  <svg className="liquid-doughnut-key-svg">
                    {AltData.length > 0 && (
                      <g transform="translate(0,15)">
                        <Legend
                          data={AltData}
                          labels={labels}
                          colors={colors}
                          colorScheme={alternativeColorScheme}
                          page={page}
                        />
                      </g>
                    )}
                  </svg>
                </div>
              </div>
              {AltIds.length > 0 ? (
                <AccountLineChart
                  AssetsTotal={true}
                  colors={alternativeMainColor}
                  accountsIds={AltIds.join()}
                  change={altChange}
                  setChange={setAltchange}
                />
              ) : null}
              <Button
                startIcon={view === 'tile' ? <StatementIcon /> : <TileIcon />}
                onClick={() => setView(view === 'tile' ? 'pfs' : 'tile')}
              >
                {view === 'tile' ? 'Statement View' : 'Tile View'}
              </Button>
            </Card>
            <InsightsTile style={{ marginBottom: 20 }} page="alternative" />
            <Card style={{ borderRadius: '9px' }}>
              {' '}
              <AddAccountsTile />{' '}
            </Card>
          </>
        }
      />
    </div>
  );
}

export default Alternative;
