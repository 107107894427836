import { Doughnut } from 'react-chartjs-2';

import { formatMoney } from 'utils/formatters';

// Wrapper for Chart.js Doughnut
const Donut = (props) => {
  let { data, options, AssetTotal, AccountName, change, assetPage } = props;

  // Catches edge case of a value of zero
  // replace zeros with 5e-324 which is treated as 0 but does not have the same issues of dividing by zero
  let _data = {
    ...data,
    datasets: data.datasets.map((data) => ({
      ...data,
      data: data.data.map((x) => (x === 0 ? 5e-324 : x))
    }))
  };

  // If there are no values in data set, make a element for account value
  if (_data.datasets[0].data.length === 0) {
    _data.datasets[0].data = [AssetTotal === 0 || AssetTotal == null ? 5e-324 : AssetTotal];
    _data.labels = [AccountName || ''];
  }

  // console.log(_data, _data.labels)

  const plugins = [
    {
      beforeDraw: function (chart) {
        if (isNaN(AssetTotal)) return;

        var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

        ctx.restore();
        // var fontSize = (height / 150).toFixed(2);
        var fontSize = 16;
        ctx.font = fontSize + 'px sans-serif';
        ctx.textBaseline = 'middle';

        var text = chart.options.plugins.centerText || '$0',
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;

        ctx.fillStyle = chart.options.plugins.assetPage ? '#25645d' : '#ffffff';
        ctx.fillText(text, textX, textY);

        // Draw arrow under value showing change
        if (!isNaN(chart.options.plugins.changeValue)) {
          let metrics = ctx.measureText(text);

          var arrow =
              chart.options.plugins.changeValue !== 0 ? (chart.options.plugins.changeValue > 0 ? '▲' : '▼') : '~',
            arrowX = Math.round((width - ctx.measureText(arrow).width) / 2),
            arrowY = height / 2 + (1.2 * metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent);

          ctx.fillStyle =
            chart.options.plugins.changeValue !== 0
              ? chart.options.plugins.changeValue > 0
                ? 'green'
                : 'red'
              : 'lightgray';
          ctx.fillText(arrow, arrowX, arrowY);
        }

        ctx.save();
      }
    }
  ];

  return (
    <Doughnut
      data={_data}
      options={{
        ...options,
        plugins: {
          centerText: formatMoney(AssetTotal, 0, 1e6),
          changeValue: change?.value,
          assetPage: assetPage
        },
        // Formatters for displaying tooltips
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => `${data['labels'][tooltipItem[0]['index']] || AccountName || ''}`,
            label: (tooltipItem, data) => {
              let val = data['datasets'][0]['data'][tooltipItem['index']];
              return `${formatMoney(val === 5e-324 ? 0 : val, 0, false)}`;
            }
          }
        }
      }}
      plugins={plugins}
      width={180}
      height={180}
    />
  );
};

export { Donut };
