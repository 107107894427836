import React from 'react';

import { makeStyles } from '@material-ui/core';

interface FaFooterProps {
  additionalTextTop?: string;
}

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: '1rem 1.5rem',
    color: 'rgba(255, 255, 255, 0.6)',
    background: 'linear-gradient(to left, rgb(42,191,252), rgb(21, 120, 246))'
  },
  footerLinks: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  copyright: {
    margin: '0'
  },
  link: {
    color: 'rgba(255, 255, 255, 0.6)',
    textDecoration: 'underline',
    '&:hover': {
      color: 'white'
    }
  }
}));

export const FaFooter = ({ additionalTextTop }: FaFooterProps) => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      {additionalTextTop && <p>{additionalTextTop}</p>}
      <p>
        Rainbook Advisors, LLC a wholly owned subsidiary of Rainbook Holdings Inc., is an SEC registered investment
        advisor.
        <br />
        Registration with the U.S. Securities and Exchange Commission as an investment advisor does not imply a certain
        level of skill or training. Rainbook Advisors does not participate in the management of any user's account by an
        Advisor or provide advice regarding specific investments. Rainbook Advisors also does not establish advisory
        accounts or manage any client assets. The Advisors are responsible for determining and implementing specific
        investment strategies for the users. It is solely the responsibility of the user to determine whether to
        continue working with its Current Advisor and/or whether to meet with and/or retain any Referred Advisor with
        whom the user is matched with through the platform.
        <br />
        For more details, see&nbsp;
        <a
          className={classes.link}
          href="https://reports.adviserinfo.sec.gov/crs/crs_325905.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Rainbook's CRS
        </a>
        <span> and </span>
        <a
          className={classes.link}
          href="https://files.adviserinfo.sec.gov/IAPD/Content/Common/crd_iapd_Brochure.aspx?BRCHR_VRSN_ID=848652"
          target="_blank"
          rel="noreferrer"
        >
          Rainbook's Form ADV Part 2
        </a>
        .
      </p>
      <div className={classes.footerLinks}>
        <p className={classes.copyright}>&copy; {new Date().getFullYear()} Rainbook | All rights reserved</p>
        <p>
          <a className={classes.link} href="https://www.rainbook.com/terms-of-use" target="_blank" rel="noreferrer">
            Terms of Use
          </a>
          <span> | </span>
          <a className={classes.link} href="https://www.rainbook.com/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  );
};
