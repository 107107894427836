import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent } from '@material-ui/core';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import { PrivateEquityTransactionForm } from 'components/forms/PrivateEquityTransactionForm';
import { TransactionForm } from 'components/forms/TransactionForm';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

import './ManualTransactionsPopup.css';

const url = process.env.REACT_APP_API_URL;

function ManualTransactionsPopup({ account, toggle, show, onClose, edit, isPrivateEquity }) {
  const { currentUser } = useAuth();
  const { reloadDom } = useAccountsData();
  const [open, setOpen] = useState(false);

  const formSubmit = async (data) => {
    const ID = await currentUser.getIdToken();
    if (edit) {
      const attributes = {
        amount: data.amount,
        category_id: data.category_id,
        date: data.date,
        description: data.description,
        merchant_name: data.merchant_name,
        name: data.name,
        price: data.price,
        quantity: data.quantity
      };
      await fetch(`${url}/api/v1/transactions/${data.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({
          data: {
            type: 'transactions',
            id: data.id,
            attributes
          }
        })
      });
    } else {
      data['account_id'] = account.id;
      const category_name = data['category_name'];
      delete data['category_name'];
      const response = await fetch(`${url}/api/v1/transactions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({
          data: {
            type: 'transactions',
            attributes: data
          }
        })
      });
      const newTransaction = await response.json();
      data.id = newTransaction?.data.id;
      data['category_name'] = category_name;
    }
    toggle(data);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    reloadDom();
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <>
      {!edit && (
        <div className="manual-transaction-btn" onClick={(e) => setOpen(true)}>
          <div>Add Transactions</div>
        </div>
      )}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitleX onClose={handleClose}>
          {!edit ? 'Manually Add Transaction' : 'Edit Manual Transaction'}
        </DialogTitleX>
        <DialogContent style={{ paddingTop: 30 }}>
          {['real estate', 'private equity', 'stock options'].indexOf(account?.account_subtype) > -1 ||
          (edit && isPrivateEquity) ? (
            <PrivateEquityTransactionForm formSubmit={formSubmit} transactionToEdit={edit ? account : null} />
          ) : (
            <TransactionForm formSubmit={formSubmit} transactionToEdit={edit ? account : null} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ManualTransactionsPopup;
