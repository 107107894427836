import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

import { AdvisorMatchCard, InvestorLayout, NewAccountSteps, RatingGauge } from 'components';
import { useAccountsData } from 'contexts';
import caiaLogo from 'logos/alternative-platforms/caia.png';
import caisLogo from 'logos/alternative-platforms/cais.png';
import iCapitalLogo from 'logos/alternative-platforms/icapital.png';
import optoLogo from 'logos/alternative-platforms/opto.jpeg';
import { aaHealthColor } from 'utils';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '3.5rem 0 2.5rem',
    borderBottom: '1px solid'
  },
  title: {
    margin: 0
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    flexDirection: 'row',
    padding: '0 0.5rem',
    textAlign: 'center'
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '0.5rem 0',
    fontSize: '14px'
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  sectionTitle: { margin: 0, width: '100%' },
  sectionText: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem',
    width: '100%',
    gap: '1rem'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    height: '100%',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  firstRow: {
    gap: '1.5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  gauge: {
    width: '40%'
  },
  colorIndicator: {
    width: '100%',
    height: '0.5rem',
    borderRadius: '1rem 1rem 0 0'
  },
  cardSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem 0 2rem'
  },
  cardFull: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    width: '100%',
    paddingBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  healthIcon: {
    marginTop: '0.2rem'
  },
  donutContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleWhite: {
    width: '100%',
    color: 'white'
  },
  cardList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '95%',
    gap: '0.5rem',
    padding: '1rem',
    margin: '0 2rem'
  },
  platforms: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    padding: '0 1rem 1rem 1rem',
    margin: '0 2rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem'
    }
  },
  platform: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    width: '30%',
    padding: '2rem',
    borderRadius: '0.5rem',
    color: '#333',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  platformImg: {
    height: '40px'
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    margin: '0 0.3rem',
    '&:hover': {
      color: 'rgb(21, 120, 246)'
    }
  },
  caiaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem 0'
  }
}));

export const AlternativesFactor = () => {
  const classes = useStyles();
  const { investorUserData } = useAccountsData();
  const { score_alternatives, advisor } = investorUserData;

  const scoreColor = useMemo(() => aaHealthColor(score_alternatives), [score_alternatives]);

  const insight = useMemo(() => {
    if (!score_alternatives) {
      return (
        <NewAccountSteps
          steps={[
            {
              title: 'Provide feedback on investment offerings.',
              isComplete: score_alternatives,
              actionText: 'Complete Now',
              action: 'i1Bhe3pc',
              score_attribute: 'score_alternatives'
            }
          ]}
        />
      );
    }
    const advisorName = advisor ? advisor.first_name : 'your advisor';
    let insight = '';
    if (score_alternatives < 80) {
      insight = `Professional financial advisors should be providing value to clients when it comes to education and investment opportunities.  Alternatives represent at least 50% of most institutional investor portfolios and is a growing piece of the individual investor portfolio.  While Alternative investments might not be appropriate for every investor, your advisor should still be able to offer a wide array of investment opportunities and insights. *Important that investors understand the lack of liquidity with most Alternative investments.`;
    } else if (score_alternatives < 90) {
      insight = `Learning and gaining knowledge on different investment opportunities, such as Alternatives, is one benefit of working with a financial advisor. Great to hear that you and ${advisorName} have discussed Alternatives.  One of the most important aspects to understand with Alternative investments is the lack of liquidity which is associated with most Alternative investments, hence the so called "liquidity premium".  In addition, the quality of the alternative investment manager cannot be understated. To help with this selection process, nearly 100,000 financial advisors leverage platforms such as iCapital and CAIS who curate alternative investment opportunities. How does your advisor source Alternative investments?`;
    } else {
      insight = `Your advisor is providing value in regards to different investment opportunities. You indicated that you have discussed Alternative investments with ${advisorName} as well as invest Alternatives. One of the most important aspects to understand with Alternative investments is the typical lack of liquidity, hence the so called "liquidity premium".  In addition, the quality of the Alternative investment manager cannot be understated. To help with this selection process, nearly 100,000 financial advisors leverage platforms such as iCapital and CAIS who curate alternative investment opportunities. How does your advisor source Alternative investments?`;
    }
    return <p>{insight}</p>;
  }, [score_alternatives, advisor]);

  const currentDate = new Date().toLocaleDateString();

  return (
    <InvestorLayout>
      <div className={classes.header}>
        <h1 className={classes.title}>Alternatives - Investment Offering</h1>
      </div>
      <div className={classes.subHeader}>
        <span>Calculated daily</span>
        <span>as of {currentDate}</span>
      </div>
      <div className={classes.section}>
        <div className={`${classes.row} ${classes.firstRow}`}>
          <div className={classes.gauge}>
            <RatingGauge score={score_alternatives} size="lg" />
          </div>
          <div className={classes.sectionText}>{insight}</div>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.section} id="rbaa-insights">
          <div className={classes.row}>
            <AdvisorMatchCard general />
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />

              <h3 className={classes.subTitle}>Important items to know about Alternative investments:</h3>
              <ul className={classes.cardList}>
                <li>Alternatives are not typically correlated with stocks and bonds, providing diversification.</li>
                <li>Often exhibit lower volatility compared to stocks.</li>
                <li>Alternatives are often less liquid than traditional investments.</li>
                <li>Quality of the alternative manager/sponsor is crucial.</li>
              </ul>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <ul className={classes.cardList}>
                <li>
                  Access to Alternatives Alternatives can be complex and one benefit of working with an experienced
                  financial advisor is the ability to invest confidently in an asset class that makes up 50% or more of
                  many Institutional investor portfolios.
                </li>
                <li>
                  The quality of the alternative investment manager cannot be understated. To help with this selection
                  process, financial advisors have the ability to work with three well known alternative platforms that
                  vet the alternative deals and managers.
                </li>
              </ul>
              <h3 className={classes.subTitle}>Does your Advisor provide access to one of these platforms?</h3>
              <div className={classes.platforms}>
                <div className={classes.platform}>
                  <img src={iCapitalLogo} alt="iCapital" className={classes.platformImg} />
                  <p>
                    Provides access to private equity, private credit, real estate, and hedge funds. iCapital offers a
                    streamlined investment process, including fund due diligence.
                  </p>
                </div>
                <div className={classes.platform}>
                  <img src={caisLogo} alt="CAIS" className={classes.platformImg} />
                  <p>
                    Offers a wide range of investment products, including hedge funds, private equity, real estate,
                    managed futures, and structured notes. CAIS provides a centralized platform for research, due
                    diligence, and investment management tools.
                  </p>
                </div>
                <div className={classes.platform}>
                  <img src={optoLogo} alt="Opto" className={classes.platformImg} />
                  <p>
                    Offers access to a variety of alternative investment options, including private equity, hedge funds,
                    venture capital, and real estate. Opto aims to simplify the investment process by providing robust
                    research, analytics, and technology-driven solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.cardSection}>
                <h2>Does your Advisor have the the CAIA designation?</h2>
                <div className={classes.caiaContainer}>
                  <img
                    src={caiaLogo}
                    alt="CAIA"
                    style={{
                      width: '250px'
                    }}
                  />
                </div>
                <p>
                  In addition to Financial Advisors who earned the CAIA designation showcase an increased understanding
                  of Alternative investments and a greater ability to vet potential opportunities. Learn more at
                  <a className={classes.link} href="https://caia.org/" target="_blank" rel="noreferrer">
                    CAIA.org
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InvestorLayout>
  );
};
