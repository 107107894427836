import React from 'react';
import { Link } from 'react-router-dom';

import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import * as d3 from 'd3';

import HoveredDonut from 'components/charts/HoveredDonut';
import LegendList from 'components/charts/LegendList';
import { useAccountsData } from 'contexts/AccountsContext';

import './Tiles.css';

function AlternativeAssetTile() {
  const { AltData } = useAccountsData();

  const labels = AltData.map((hash) => {
    return hash['label'];
  });

  const reducer = (acc = 0, currVal) => acc + currVal;
  const AssetValues = AltData.map((hash) => hash['value']);
  const AssetTotal = AssetValues.reduce(reducer, 0);

  const colorScheme = ['rgb(118,209,255)', 'rgb(44,165,255)', 'rgb(14,92,178)', 'rgb(0,65,114)'];
  const colors = d3.scaleOrdinal().range(colorScheme);

  const page = 'alternativeTile';

  const styles = {
    color: '#ffffff',
    backgroundColor: 'rgb(0,120,245)',
    borderRadius: '9px'
  };

  return (
    <Link to="/wealth-hub/alternative">
      <Card style={styles}>
        <CardHeader style={{ paddingBottom: 0 }} title="Alternative Assets" />
        <CardContent>
          <div>
            <Grid container>
              <Grid item xs={6} lg={5}>
                <HoveredDonut
                  showTotal={true}
                  donutData={AltData}
                  innerRadius={65}
                  outerRadius={75}
                  colorScheme={colorScheme}
                  width={150}
                  height={150}
                  totals={AssetTotal}
                  AccountName={'Alternative'}
                />
              </Grid>
              <Grid item xs lg>
                {AltData.length > 0 && (
                  <LegendList data={AltData[0]} labels={labels} colors={colors} colorScheme={colorScheme} page={page} />
                )}
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </Link>
  );
}

export default AlternativeAssetTile;
