import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Card, CardContent, CardHeader, Container, Grid } from '@material-ui/core';

import InsightsTags from 'components/layouts/InsightsTags';
import { useAccountsData } from 'contexts/AccountsContext';
import useWindowWidth from 'utils/useWindowWidth';

import './Insights.css';
import 'pages/AssetPages.css';

const pagetocss = (page) => {
  if (page === 'liquid') return 'insights-liq-color';
  else if (page === 'alternative') return 'insights-alt-color';
  else if (page === 'personal') return 'insights-pers-color';
  else if (page === 'expenses') return 'insights-exp-color';
  else if (page === 'liabilities') return 'insights-lia-color';
};

const months = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};
const formatDate = (date) => {
  let dates = date.split('-').slice(1);
  let mm = months[dates[0]];
  let dd = dates[1].slice(0, 2);
  return `${mm}  ${dd}`;
};

function Insights() {
  const width = useWindowWidth().width;
  const styles = width > 1494 ? { marginTop: 30, marginLeft: 76 } : { marginTop: 30 };
  const { InsightsData, TagsData } = useAccountsData();
  const [sort, setSort] = useState({});

  const articleComponent = (article) => {
    return (
      <div className="insights-page-article-item">
        <h2 className="insights-page-article-item-title">{article.title}</h2>
        <div className="insights-page-article-item-blurb">{article.blurb}</div>
        <div style={{ marginTop: 10 }} className="insights-page-article-item-blurb">
          {`${formatDate(article.published_at)}${article.read_time ? ` • ${article.read_time} minute read` : ''} `}
          {article.tags.length > 0 && (
            <span className={`insights-page-tag ${pagetocss(article.tags[0].page)}`}>{article.tags[0].name}</span>
          )}
        </div>
      </div>
    );
  };

  const isInFilter = (sort, page, tags) => {
    const tag_names = tags.map((t) => t.name);

    if (sort[page] && sort[page].size === 0) {
      return true;
    } else if (sort[page] && [...sort[page]]?.some((tag) => tag_names.includes(tag))) {
      return true;
    }

    return false;
  };
  const FilterSpan = ({ sort }) => {
    return Object.keys(sort).map((s) => {
      if (sort[s].size === 0) return <span className={`insights-view-tag ${pagetocss(s)}`}>{s}</span>;

      return [...sort[s]].map((t) => {
        return <span className={`insights-view-tag ${pagetocss(s)}`}>{t}</span>;
      });
    });
  };

  const filteredData =
    Object.keys(sort).length === 0
      ? InsightsData
      : InsightsData.filter((article) => isInFilter(sort, article.page, article.tags));

  const InsightDisplay = ({ article, a }) => (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
      <Grid item xs>
        {a}
      </Grid>
      {article.image_url && (
        <Grid item xs="auto">
          <img
            src={article.image_thumbnail_url}
            alt="Insight"
            width="256"
            height="144"
            style={{ objectFit: 'contain' }}
          />
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      <Container style={styles}>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={8}>
            <Card className="insights-page-articles" style={{ width: '100%', borderRadius: '9px' }}>
              <CardHeader
                style={{
                  paddingBottom: 10,
                  borderBottom: '1px solid #000'
                }}
                title={
                  <div>
                    Portfolio Insights and Discovery <FilterSpan sort={sort} />
                  </div>
                }
              />
              <CardContent>
                {filteredData.slice(0, 10).map((article, i) => {
                  const a = articleComponent(article);
                  return (
                    <div className="insights-page-article-list" key={article.id} style={{ width: '100%' }}>
                      {article.has_content ? (
                        <Link to={`/wealth-hub/insights/${article.permalink}`}>
                          <InsightDisplay a={a} article={article} />
                        </Link>
                      ) : article.url ? (
                        <a target="_blank" rel="noopener noreferrer" href={article.url}>
                          <InsightDisplay a={a} article={article} />
                        </a>
                      ) : (
                        a
                      )}
                    </div>
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
          <InsightsTags tags={TagsData} sort={sort} setSort={(s) => setSort(s)} />
        </Grid>
      </Container>
    </>
  );
}

export default Insights;
export { pagetocss, formatDate };
