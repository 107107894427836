import React, { useCallback, useEffect, useRef, useState } from 'react';
import HubspotForm from 'react-hubspot-form';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BsFillShieldLockFill } from 'react-icons/bs';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Institution } from 'types/institutions';
import { useDebounce } from 'use-debounce';

import { presetInstitutions } from 'assetData';
import { BaaInstitutionDropdown, PlaidLink, StatementsButton } from 'components';
import { StatementsDialog } from 'components/dialogs';
import { useAuth } from 'contexts';
import { MorningstarComponents } from 'contexts/MorningstarComponents';

const url = process.env.REACT_APP_API_URL;

interface StartProps {
  onClose: () => void;
  standalone?: boolean;
}

const useStyles = makeStyles((theme) => ({
  setupContainer: (props: { standalone: boolean | undefined }) => ({
    display: 'block',
    width: '100%',
    textAlign: 'center',
    backgroundColor: props.standalone ? 'transparent' : '#fdfdfd',
    borderRadius: '0.5rem',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      padding: '0.5rem'
    }
  }),
  setup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
    width: '100%',
    color: '#333',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: '1rem'
    }
  },
  setupText: {
    gap: '2rem',
    width: '100%',
    maxWidth: '600px',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  setupTextLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '1rem'
  },
  connect: {
    border: '10px solid',
    borderColor: '#dddfee',
    borderRadius: '4px',
    maxWidth: '650px',
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      padding: '5px'
    }
  },
  suggestedFis: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    height: '40px',
    marginTop: '10px',
    overflow: 'hidden',
    '& img': {
      maxWidth: '140px',
      maxHeight: 80
    }
  },
  fiContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.2rem',
    cursor: 'pointer',
    gap: '2rem',
    color: '#333',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  btn: {
    color: '#ffffff',
    backgroundColor: 'rgb(21, 120, 246)',
    borderRadius: '9px',
    width: 200,
    display: 'inline-block',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    fontSize: '12pt',
    padding: 12,
    marginBottom: 20,
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 0.5s ease'
    }
  },
  dropdownContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2
  },
  backBtnContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 10
  },
  backBtn: {
    color: 'rgb(21, 120, 246)',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: 'all 0.5s ease'
    }
  },
  orUpload: {
    paddingRight: '10px',
    [theme.breakpoints.down('xs')]: {
      '& span': {
        display: 'none'
      }
    }
  },
  statements: {
    paddingTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      '& .hs-form-iframe': {
        border: 'solid 1px #eee !important',
        height: '400px !important',
        width: '480px !important',
        padding: '20px'
      }
    }
  },
  missingIntContainer: { width: '100%', textAlign: 'center', padding: '2rem' },
  missingIntBtn: {
    width: '100%',
    textAlign: 'right',
    cursor: 'pointer',
    color: '#333',
    marginTop: '1.5rem',
    position: 'relative'
  }
}));

export const AddAdvisorAccount = ({ onClose, standalone }: StartProps) => {
  const classes = useStyles({ standalone });
  const { currentUser } = useAuth();

  const [fiSearch, setFiSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [fid, setFid] = useState<string>();
  const [plaidLoading, setPlaidLoading] = useState(false);
  const [statements, setStatements] = useState(false);
  const [debouncedSearch] = useDebounce(fiSearch, 500);
  const [currentInstitutions, setCurrentInstitutions] = useState<Institution[]>([]);
  const [selectedInstitution, setSelectedInstitution] = useState<Institution>();
  const [noResults, setNoResults] = useState(false);
  const [missingDialogOpen, setMissingDialogOpen] = useState(false);
  const [unsureDialogOpen, setUnsureDialogOpen] = useState(false);
  const refForClick = useRef<HTMLInputElement>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  //const fid = selectedInstitution?.integration === 'baa' ? selectedInstitution?.native_id : undefined;

  const handleSearchChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setFiSearch(e.target.value);
    setNoResults(false);
  };

  const selectInstitution = (item: Institution) => {
    setSelectedInstitution(item);
    setCurrentInstitutions([]);
  };

  const clearSelected = () => {
    setSelectedInstitution(undefined);
    setCurrentInstitutions([]);
    setFiSearch('');
    setSearching(false);
  };
  const plaidSuccess = () => {
    onClose();
    setPlaidLoading(false);
  };
  const plaidExit = () => {
    clearSelected();
    setPlaidLoading(false);
  };

  const contactClick = () => {
    setUnsureDialogOpen(false);
    setMissingDialogOpen(true);
  };

  useEffect(() => {
    if (selectedInstitution?.integration === 'plaid') {
      refForClick.current?.click();
      setPlaidLoading(true);
    } else {
      setPlaidLoading(false);
    }
    if (selectedInstitution?.integration === 'baa') {
      setFid(selectedInstitution.native_id);
    } else {
      setFid(undefined);
    }
    if (selectedInstitution?.integration === 'vrgl') {
      setStatements(true);
    } else {
      setStatements(false);
    }
  }, [selectedInstitution]);

  const getBaaFinancialInstitutions = useCallback(
    async (name) => {
      const ID = await currentUser.getIdToken();
      const response = await fetch(`${url}/api/v1/investor_institutions.json?filter[name][search]=${name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      });
      const json = await response.json();
      setCurrentInstitutions(json['data']);
      if (!json['data'].length) setNoResults(true);
    },
    [currentUser]
  );

  useEffect(() => {
    if (debouncedSearch.length) {
      getBaaFinancialInstitutions(debouncedSearch);
    } else {
      setCurrentInstitutions([]);
    }
  }, [debouncedSearch, getBaaFinancialInstitutions]);

  return (
    <div className={classes.setupContainer}>
      <div>
        {!selectedInstitution && (
          <div className={classes.setup} onClick={clearSelected}>
            <div className={classes.setupText}>
              <div className={classes.setupTextLine}>
                <div>
                  <h3>Connect Your Investment Account</h3>
                  <div style={{ textAlign: 'left' }}>
                    For a complete and accurate analysis of fees, fund expenses, and holdings.
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.connect}>
              <div
                onClick={(e) => {
                  setSearching(true);
                  e.stopPropagation();
                }}
                className={classes.dropdownContainer}
              >
                <BaaInstitutionDropdown
                  hasInput={!!fiSearch.length}
                  noResults={noResults}
                  onChange={handleSearchChange}
                  onClick={(e, item) => {
                    e.stopPropagation();
                    selectInstitution(item);
                  }}
                  items={currentInstitutions}
                  searching={searching}
                />
              </div>
              <div>
                <div className={classes.suggestedFis}>
                  {(isMobile ? presetInstitutions : presetInstitutions).map((institution) => (
                    <div
                      key={institution.native_id}
                      className={classes.fiContainer}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectInstitution(institution);
                      }}
                    >
                      <img src={institution.logo} alt={institution.name} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3 className={classes.orUpload}>
                or <span>Upload Monthly Statements:</span>{' '}
              </h3>
              {!selectedInstitution && <StatementsButton onSubmit={onClose} />}
            </div>
            <a href="https://www.rainbook.com/security" target="_blank" rel="noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center',
                background: '#dfffdf',
                padding: '10px',
                borderRadius: '2px',
                color: 'inherit'
              }}
            >
              <BsFillShieldLockFill size={20} />
              <div style={{ paddingLeft: '10px' }}>All data is privately and securely stored.</div>
            </a>
          </div>
        )}
        {!selectedInstitution && !standalone && (
          <>
            <div onClick={() => setUnsureDialogOpen(true)} className={classes.missingIntBtn}>
              <div>Add Later?</div>
            </div>
          </>
        )}
        {(fid || statements) && (
          <div className={classes.backBtnContainer}>
            <button onClick={clearSelected} className={classes.backBtn}>
              <AiOutlineArrowLeft size={30} />
            </button>
          </div>
        )}
        {plaidLoading && <CircularProgress />}
        {statements && (
          <div className={classes.statements}>
            <StatementsDialog open={false} onSubmit={onClose} embedded />
          </div>
        )}
        <MorningstarComponents setup={!!fid} institutionId={fid} onExit={onClose} visible={!!fid} />
        <PlaidLink
          institution={selectedInstitution}
          onExit={plaidExit}
          onSuccess={plaidSuccess}
          handleMainClose={null}
          refForClick={refForClick}
        />
        <Dialog
          open={unsureDialogOpen}
          onClose={() => setUnsureDialogOpen(false)}
          maxWidth="xs"
          fullWidth
          style={{ zIndex: 2000 }}
        >
          <DialogContent>
            <p>
              Without account holdings and transactions we will be unable to provide your complete RAIN Report based on
              a data-driven analysis.
            </p>
            <p>
              If you are having trouble finding your institution's connection or statements, please contact support.
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={contactClick} color="primary" variant="contained">
              Contact
            </Button>
            <Button onClick={onClose} variant="contained" color="default">
              Add Later
            </Button>
            <Button onClick={() => setUnsureDialogOpen(false)} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={missingDialogOpen}
          onClose={() => setMissingDialogOpen(false)}
          maxWidth="xs"
          fullWidth
          style={{ zIndex: 2000 }}
        >
          <div className={classes.missingIntContainer}>
            <HubspotForm
              portalId="9455422"
              style={{ background: 'red' }}
              formId="68ae64f7-416d-48db-9e38-2f447e822e92"
              onSubmit={() => setMissingDialogOpen(false)}
              loading={<CircularProgress />}
              inlineMessage="Thank you for your submission!"
            />
          </div>
        </Dialog>
      </div>
    </div>
  );
};
