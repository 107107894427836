import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Grid, Hidden, Input } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import firebase from 'firebase/app';

import VerifyEmailPopup from 'components/popups/VerifyEmailPopup';
import { useAuth } from 'contexts/AuthContext';
import logo from 'logos/RainbookBlueLogo.png';
import footerLogo from 'logos/RainbookFullLogoWhite.png';
import WindingRoad from 'logos/WindingRoad.jpg';
import validate from 'utils/ValidateLogIn';

import { auth } from '../../firebase';
import ResetPassword from './ResetPassword';
import SsoButtons from './SsoButtons';

import './LogIn.css';

const LogIn = () => {
  const history = useHistory();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [mainError, setMainError] = useState('');
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [verified, setVerified] = useState(true);
  const [openPhone, setOpenPhone] = useState(false);
  const [captchaComplete, setCaptchaComplete] = useState();
  const [verificationId, setVerificationId] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [mfaResolver, setMfaResolver] = useState();
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const recaptchaRef = useRef();
  const location = useLocation();

  const resetRecaptcha = () => {
    window.recaptchaVerifier.clear();
    recaptchaRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
  };

  const goToStartOrRedirect = useCallback(() => {
    const { redirectToPathname, redirectToSearch } = location.state || {};
    if (redirectToPathname) {
      console.log('redirectTo', redirectToPathname);
      history.push({ pathname: redirectToPathname, search: redirectToSearch });
    } else {
      history.push('/start');
    }
  }, []);

  useEffect(() => {
    auth
      .getRedirectResult()
      .then((result) => {
        if (result && result.user) {
          goToStartOrRedirect();
        }
      })
      .catch((e) => {
        if (e.code === 'auth/multi-factor-auth-required') {
          setMainError('Multi-Factor SMS required');
          setOpenPhone(true);
          setCaptchaComplete(false);
          setMfaResolver(e.resolver);
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible',
            callback: (response) => {
              setCaptchaComplete(true);
            },
            'expired-callback': () => {
              console.error('expired captcha');
              clear2FA('CAPTCHA expired. Please try again.');
              resetRecaptcha();
            }
          });
          handleSubmit2FA(e.resolver);
        } else {
          console.error('RedirectResult: ', e.message);
          setMainError(e.message);
        }
      });
    document.title = 'Sign in to Rainbook';
  }, [goToStartOrRedirect]);

  async function handleSubmit(e) {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        setMainError('');
        setLoading(true);
        const userCredential = await login(emailRef.current.value, passwordRef.current.value);
        const user = userCredential.user;

        if (user.emailVerified) {
          goToStartOrRedirect();
        } else {
          setVerified(false);
        }
      } catch (e) {
        if (e.code === 'auth/multi-factor-auth-required') {
          setMainError('Multi-Factor SMS required');
          setOpenPhone(true);
          setCaptchaComplete(false);
          setMfaResolver(e.resolver);
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible',
            callback: (response) => {
              setCaptchaComplete(true);
            },
            'expired-callback': () => {
              console.error('expired captcha');
              clear2FA('CAPTCHA expired. Please try again.');
              resetRecaptcha();
            }
          });
          handleSubmit2FA(e.resolver);
        } else {
          console.error(e.message);
          if (e.message.indexOf('TOO_MANY_ATTEMPTS_TRY_LATER') > -1) {
            setMainError(
              'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
            );
          } else {
            setMainError('Invalid Email or Password');
          }
        }
      }
    }
    setLoading(false);
  }

  const handleSubmit2FA = (resolver) => {
    // Show UI to let user select second factor if multiple?
    const multiFactorHints = resolver.hints;
    const selectedHint = multiFactorHints[0];
    //phone
    if (selectedHint.factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOptions = {
        multiFactorHint: selectedHint,
        session: resolver.session
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      return phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
        .then((verificationId) => {
          setVerificationId(verificationId);
        })
        .catch((e) => {
          resetRecaptcha();
          console.error(e.message);
          clear2FA('There was an issue with CAPTCHA or SMS. Please try again.');
        });
    } else {
      clear2FA('Unsupported second factor.');
    }
  };

  const handleSubmitCode = (e) => {
    e.preventDefault();
    if (!verificationCode || verificationCode.length === 0) return;
    const resolver = mfaResolver;
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    resolver
      .resolveSignIn(multiFactorAssertion)
      .then((userCredential) => {
        // User successfully signed in with the second factor phone number.
        setMainError('');
        setLoading(true);
        goToStartOrRedirect();
      })
      .catch((e) => {
        console.log(e);
        if (e.code === 'auth/invalid-verification-code') {
          clear2FA('Invalid SMS code, try again.');
        } else {
          clear2FA(e.message);
        }
      });
  };

  const clear2FA = (message) => {
    setMainError(message);
    setLoading(false);
    setOpenPhone(false);
    setCaptchaComplete(false);
    setVerificationId(null);
    setVerificationCode(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const toggleForgot = () => {
    setForgotPassword(true);
  };

  const closePhoneDialog = () => {
    setOpenPhone(false);
  };

  return (
    <div className="log-in-container">
      <Grid container className="log-in-wrapper">
        {forgotPassword && <ResetPassword />}
        {!verified && <VerifyEmailPopup newAccount={false} okClick={goToStartOrRedirect} />}
        <Grid item xs={12} md={6} className="log-in-left">
          {/* <div className="log-in-page-msg" > rainb%k </div> */}
          <a href="https://www.rainbook.com">
            <img
              style={{
                width: 180,
                height: 'auto',
                marginBottom: 35
              }}
              className="logo-header"
              src={logo}
              alt="rainb%k"
            />
          </a>
          <div className="log-in-form-content">
            <form className="log-in-form" onSubmit={handleSubmit} noValidate>
              <div className="log-in-form-input">
                <div className="log-in-form-input-field">
                  <input
                    type="email"
                    name="email"
                    className="log-in-form-input-box"
                    value={values.email || ''}
                    ref={emailRef}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  {errors.email && <p className="log-in-error-msg">{errors.email}</p>}
                </div>
              </div>
              <div className="log-in-form-input">
                <div className="log-in-form-input-field">
                  <input
                    type="password"
                    name="password"
                    className="log-in-form-input-box"
                    value={values.password || ''}
                    ref={passwordRef}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  {errors.password && <p className="log-in-error-msg">{errors.password}</p>}
                </div>
              </div>
              {mainError && <p className="log-in-error-msg">{mainError}</p>}
              <div className="log-in-forgot-password-container">
                <div className="log-in-forgot-password" onClick={toggleForgot}>
                  Forgot Password?
                </div>
              </div>
              <button disabled={loading} className="log-in-form-input-btn" type="submit">
                Log In
              </button>
            </form>
            <div className="sso-area">
              <div style={{ paddingBottom: '10px' }}>-or-</div>
              <SsoButtons />
            </div>
            <div className="log-in-create-account-msg">
              <div className="log-in-create-account">
                <div className="log-in-sign-up-msg">Don't have an account?</div>
                <Link className="log-in-msg-button" to="/signup">
                  Sign Up Here
                </Link>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="log-in-right">
          <Hidden smDown>
            <div className="logo-text-container">
              <div style={{ fontSize: 52 }}>ELEVATE</div>
              <div style={{ fontSize: 80, paddingTop: 12 }}>YOUR</div>
              <div style={{ fontSize: 48, paddingTop: 10 }}>ADVISOR</div>
              <div style={{ fontSize: 30, marginTop: -6 }}>RELATIONSHIP</div>
            </div>
            <div className="log-in-logo-wrapper">
              <img className="log-in-logo" src={WindingRoad} alt="" />
            </div>
            {/* <div className="logo-footer">rainb%k</div> */}
            <img style={{ width: 80 }} className="logo-footer" src={footerLogo} alt="rainb%k" />
          </Hidden>
        </Grid>
      </Grid>
      <Dialog open={openPhone} onClose={closePhoneDialog} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmitCode}>
          <DialogTitle id="form-dialog-title">SMS Code</DialogTitle>
          <div ref={recaptchaRef}>
            <div id="recaptcha-container"></div>
          </div>
          {captchaComplete && verificationId && (
            <DialogContent>
              <DialogContentText>
                A verification code was sent to your phone number. Please enter it here:
              </DialogContentText>
              <Input
                name=""
                inputMode="numeric"
                type="text"
                autoFocus
                inputProps={{ pattern: '\\d*', maxLength: 6 }}
                placeholder="123456"
                onChange={handleCodeChange}
                style={{
                  width: '135px',
                  fontFamily: 'monospace',
                  MozAppearance: 'textfield',
                  borderRadius: '6px',
                  border: '1px solid',
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
                  margin: '4px',
                  paddingLeft: '8px',
                  paddingRight: 0,
                  height: '42px',
                  fontSize: '32px',
                  boxSizing: 'border-box'
                }}
              />
            </DialogContent>
          )}
          {!captchaComplete && (
            <DialogContent>
              <DialogContentText>Please wait for verification...</DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={closePhoneDialog} color="primary">
              Cancel
            </Button>
            {captchaComplete && verificationId && (
              <Button type="submit" id="submit-code" color="primary">
                Submit
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default LogIn;
