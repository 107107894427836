import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { formatDollars } from './GenericAssetsTable';

export const formatPrivateEquityTransactions = (transactions) => {
  return transactions.map((transaction) => {
    const transactionsObject = {
      id: transaction.id,
      date: transaction.date,
      name: transaction.name.substring(0, 25),
      category_name: transaction.category_name,
      description: `${transaction.name.substring(0, 25)} ${transaction.category_name || ''}`,
      amount: formatDollars(transaction.amount)
    };
    return transactionsObject;
  });
};

export const formatStockOptionsTransactions = (transactions) => {
  return transactions.map((transaction) => {
    const transactionsObject = {
      id: transaction.id,
      date: transaction.date,
      name: transaction.name.substring(0, 25),
      category_name: transaction.category_name,
      description: `${transaction.name.substring(0, 25)} Stock Options`,
      amount: formatDollars(transaction.amount)
    };
    return transactionsObject;
  });
};

export function PrivateEquityTransactionsTable({
  transactions,
  is_manual,
  setTransactionToEdit,
  setTransactionToDelete
}) {
  return (
    <>
      <DataTable className="data-table" value={transactions} rows={transactions.length}>
        <Column className="columns" field="date" header="Date" />
        <Column className="columns" field="name" header="Investment" />
        <Column className="columns" field="category_name" header="Activity Type" />
        <Column className="columns" field="description" header="Description" />
        <Column className="columns" field="amount" header="Amount" />
        {is_manual && (
          <Column
            className="columns"
            header="Edit"
            style={{ width: 20 }}
            body={(row) => (
              <IconButton size="small" onClick={() => setTransactionToEdit(row.id)}>
                <EditIcon />
              </IconButton>
            )}
          />
        )}
        {is_manual && (
          <Column
            className="columns"
            header="Delete"
            style={{ width: 20 }}
            body={(row) => (
              <IconButton
                size="small"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this transaction?')) {
                    setTransactionToDelete(row.id);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          />
        )}
      </DataTable>
    </>
  );
}
