function LegendVisualizationData(props) {
  if (props.length === 0) return [];
  const tileType = props[0].assetType;
  const colorScheme =
    tileType === 'Liquid'
      ? ['rgb(120,255,184)', 'rgb(40,232,136)', 'rgb(25,155,90)', 'rgb(19,107,61)']
      : tileType === 'Alternative'
      ? ['rgb(255,255,255)', 'rgb(44,165,255)', 'rgb(14,92,178)', 'rgb(0,65,114)']
      : tileType === 'Personal'
      ? ['rgb(255,255,255)', 'rgb(0,211,195)', 'rgb(0,132,125)', 'rgb(0,99,92)']
      : ['rgb(30,197,119)', 'rgb(0,120,245)', 'rgb(30,166,164)'];

  return colorScheme;
}

export default LegendVisualizationData;
