import React, { useState } from 'react';

import { FormControl, Grid, Select } from '@material-ui/core';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

import { isNum } from 'components/tables/GenericAssetsTable';
import { useAccountsData } from 'contexts/AccountsContext';

import AutoCompleteInstitutionSelector from './AutoCompleteInstitutionSelector';
import { currencyField, dateField, getFormErrorMessage, isDate, textField } from './common';

import './forms.css';

export const PrivateEquityForm = ({ accountType, accountSubType, formSubmit }) => {
  const { selectableInstitutions } = useAccountsData();
  const [institutionValues, setInstitutionValues] = useState({
    institution_id: null,
    institution_name: null,
    account_type: accountType,
    account_subtype: accountSubType
  });
  const [logoFile, setLogoFile] = useState({});
  const [addAsset, setAddAsset] = useState(false);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      date: null,
      'asset-date': null
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'Name is required.';
      }
      if (!institutionValues.institution_id && !institutionValues.institution_name) {
        errors.institution = 'Institution is required';
      }

      if (
        !data['asset-name'] &&
        (data['asset-quantity'] || data['asset-price'] || data['asset-cost_basis'] || data['asset-date'])
      ) {
        errors['asset-name'] = 'Name is required.';
      }

      if (data.date && !isDate(data.date)) {
        errors.date = 'Date is invalid.';
      }
      if (data['asset-date'] && !isDate(data['asset-date'])) {
        errors['asset-date'] = 'Date is invalid.';
      }

      return errors;
    },
    onSubmit: async (data) => {
      if (isNum(data['asset-price']) && isNum(data['asset-quantity'])) {
        data['asset-value'] = Number(data['asset-price']) * Number(data['asset-quantity']);
      }
      if (isNum(data['asset-cost_basis']) && isNum(data['asset-quantity'])) {
        data['asset-cost_basis'] = Number(data['asset-cost_basis']) * Number(data['asset-quantity']);
      }
      await formSubmit(data, logoFile, {}, institutionValues);
    }
  });

  const addFile = (event) => {
    setLogoFile({ name: event.target.id, file: event.target.files[0] });
  };

  const assetForm = (
    <>
      <Divider />
      <Grid item xs={12} sm={6}>
        {textField(formik, 'asset-name', 'Asset Name')}
      </Grid>
      <Grid item xs={12} sm={6}>
        {textField(formik, 'asset-quantity', 'Quantity')}
      </Grid>
      <Grid item xs={12} sm={6}>
        {currencyField(formik, 'asset-price', 'Stock Price/share')}
      </Grid>
      <Grid item xs={12} sm={6}>
        {currencyField(formik, 'asset-cost_basis', 'Cost basis/share')}
      </Grid>
      <Grid item xs={12} sm={6}>
        {dateField(formik, 'asset-date', 'Investment Date')}
      </Grid>
    </>
  );

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingBottom: 20 }}>
          <AutoCompleteInstitutionSelector
            institutions={selectableInstitutions}
            values={institutionValues}
            setValues={setInstitutionValues}
          />
          {getFormErrorMessage(formik, 'institution')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'name', 'Account Name')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'balance_current', 'Account Value')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl size="small" variant="outlined" fullWidth>
            <Select
              native
              value={formik.values.account_type_display}
              onChange={formik.handleChange}
              id="account_type_display"
            >
              <option aria-label="None">Select Investment Type</option>
              <option value="Venture Capital">Venture Capital</option>
              <option value="Startup">Startup</option>
              <option value="Stock Options">Stock Options</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'amount_invested', 'Amount Invested')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {dateField(formik, 'date', 'Date Invested')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'committed_capital', 'Committed Capital')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'investment_details', 'Investment Round, Valuation')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <span className="p-field">
            <label htmlFor="logo_file" style={{ color: '#555' }}>
              Account Image/Logo
            </label>
            <input id="logo_file" onChange={addFile} type="file" />
          </span>
        </Grid>
        {accountSubType !== 'private equity' ? (
          assetForm
        ) : addAsset ? (
          assetForm
        ) : (
          <Grid item xs={6} sm={4}>
            <Button
              onClick={() => {
                setAddAsset(true);
              }}
            >
              Add Asset
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button type="submit" label="Submit" className="p-mt-2" />
        </Grid>
      </Grid>
    </form>
  );
};
