import React from 'react';

import { makeStyles } from '@material-ui/core';
import { RatingGauge } from 'components/charts';

interface RatingCardProps {
  title: string;
  description: string;
  contentHeader?: string;
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    backgroundColor: '#a5a5a5',
    borderRadius: '0.5rem',
    boxShadow: '0 0 0.5rem rgba(0,0,0,0.25)',
    textDecoration: 'none',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    margin: '0',
    padding: '0 0.5rem',
    height: '2rem'
  },
  description: {
    textAlign: 'center',
    fontSize: '0.8rem',
    margin: 0,
    padding: '10px 0px 20px 0px'
  },
  content: {
    padding: '1rem',
    color: '#111',
    margin: '1rem',
    fontSize: '.8rem',
    borderRadius: '5px'
  },
  contentHeader: {
    fontSize: '1rem',
    fontStyle: 'italic',
    paddingBottom: '10px',
    fontWeight: 'bold',
    color: '#fff'
  },
}));

export const RatingCardNoAdvisor = ({title, description, contentHeader}: RatingCardProps) => {
  const classes = useStyles();

  return (
    <div
      className={classes.card}
    >
      <div>
        <h3 className={classes.title}>{title}</h3>
        <p className={classes.description}>{description}</p>
      </div>
      <RatingGauge white score={0} size="sm" />
      <div className={classes.content}>
        <div className={classes.contentHeader}>{contentHeader}</div>
      </div>
    </div>
  );
};
