import React, { useCallback, useMemo, useState } from 'react';
import ReactCodeInput from 'react-code-input';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { useMediaQuery, useTheme } from '@material-ui/core';
import firebase from 'firebase/app';
import MuiPhoneNumber from 'material-ui-phone-number';

import { useAuth } from 'contexts/AuthContext';
import SecureAuthImg from 'logos/SecureAuth.png';

import DialogTitleX from './DialogTitleX';

import './PhoneDialog.css';

function PhoneDialog({ open, addedCallback, onboarding, closeCallback, btnStyles }) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  const { currentUser, updateUserPhone } = useAuth();

  const [verificationId, setVerificationId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [verificationComplete, setVerificationComplete] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState(false);

  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();
  const [verificationFailed, setVerificationFailed] = useState();

  const has2FA = useMemo(() => currentUser?.multiFactor?.enrolledFactors?.length > 0, [currentUser]);

  const handlePhoneChange = (value) => {
    setPhoneNumber('+' + value.replace(/\D/g, ''));
  };

  const handleCodeChange = (value) => {
    setVerificationCode(value);
  };

  const reAuth = () => {
    currentUser
      .reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(currentUser.email, password))
      .then(() => {
        setPassword(null);
        setVerifyPassword(false);
        handleSubmit2FA();
      })
      .catch((e) => {
        setPasswordError(e.message);
      });
  };

  const handleClose = useCallback(() => {
    setVerificationFailed(null);
    setVerificationComplete(false);
    setVerificationCode(null);
    setVerificationId(null);
    closeCallback();
  }, [closeCallback]);

  const handleSubmit2FA = (e) => {
    if (e) e.preventDefault();

    if (!verificationId) {
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible'
      });
      //const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
      currentUser.multiFactor
        .getSession()
        .then(function (multiFactorSession) {
          // Specify the phone number and pass the MFA session.
          var phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: multiFactorSession
          };
          var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          // Send SMS verification code.
          return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
        })
        .then(function (vId) {
          setVerificationId(vId);
        })
        .catch((e) => {
          if (e.code === 'auth/argument-error') {
            setVerificationFailed('Invalid Phone Number');
          } else if (e.code === 'auth/requires-recent-login') {
            setVerifyPassword(true);
          } else if (e.code === 'auth/second-factor-already-in-use') {
            updateUserPhone(phoneNumber);
            setVerificationComplete(true);
            if (addedCallback) {
              addedCallback();
            }
          } else {
            setVerificationFailed(e.message);
          }
        });
    } else {
      var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
      var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      // Complete enrollment.
      currentUser.multiFactor
        .enroll(multiFactorAssertion, 'Phone')
        .then(() => {
          updateUserPhone(phoneNumber);
          setVerificationComplete(true);
          if (addedCallback) {
            addedCallback();
          }
        })
        .catch((e) => {
          setVerificationFailed(e.message);
        });
    }
  };

  const handleDisable2FA = async () => {
    var options = currentUser.multiFactor.enrolledFactors;
    // Ask user to select from the enrolled options.
    try {
      await Promise.all(
        options.map(async (o) => {
          await currentUser.multiFactor.unenroll(o);
        })
      );
      handleClose();
    } catch (e) {
      setVerificationFailed(e.message);
    }
  };

  const secureImg = <img width={isXS ? '200' : '300'} src={SecureAuthImg} alt="secure-auth-img" />;

  return (
    <>
      {!onboarding ? (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
          <DialogTitleX id="form-dialog-title" onClose={handleClose}>
            Two-Factor Authentication
          </DialogTitleX>
          {(verificationFailed && (
            <DialogContent>
              <DialogContentText>{verificationFailed}</DialogContentText>
            </DialogContent>
          )) ||
            (!verificationId && (
              <DialogContent>
                <DialogContentText>
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      {!has2FA && (
                        <Typography variant="h6" style={{ lineHeight: 1.2 }}>
                          Before we get started
                          <br />
                          Lets make your account safer.
                        </Typography>
                      )}
                      <p>
                        With two-factor authentication, you protect your Rainbook account with something you know (your
                        password) and something you have (your phone), adding an extra layer of security
                      </p>
                      <p>Your phone number is only used for verification and login.</p>
                      <div id="recaptcha-container" />
                      <Grid container>
                        <Grid item sm={6}>
                          <MuiPhoneNumber defaultCountry={'us'} onChange={handlePhoneChange} />
                        </Grid>
                        <Grid item sm={6}>
                          {!verificationComplete && !verificationFailed && (
                            <>
                              <Button onClick={handleSubmit2FA} variant="contained" color="primary">
                                Send Code
                              </Button>
                              {has2FA && <Button onClick={handleDisable2FA}>Disable 2FA</Button>}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12} align="center">
                      {secureImg}
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            )) ||
            (verificationId && !verificationComplete && (
              <DialogContent>
                <form onSubmit={handleSubmit2FA}>
                  <DialogContentText>
                    A verification code was sent to your phone number. Please enter it here:
                  </DialogContentText>
                  <ReactCodeInput type="numeric" fields={6} onChange={handleCodeChange} />
                  <DialogActions>
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            )) ||
            (verificationId && verificationComplete && (
              <DialogContent>
                <DialogContentText>
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="h6">Your Account is Secured!</Typography>
                      Each time you sign in to Rainbook, you will also need to enter a verification code sent to your
                      mobile phone number.
                    </Grid>
                    <Grid item sm={6} xs={12} align="center">
                      {secureImg}
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            ))}
        </Dialog>
      ) : (
        <Grid container>
          <Grid item sm={6} xs={12}>
            {(!verificationId && (
              <>
                <>
                  <Typography variant="h6" style={{ marginBlock: '1.5em' }}>
                    Verify your identity.
                  </Typography>
                  <p
                    style={{
                      marginBlock: '1.5em',
                      color: '#6c757d'
                    }}
                  >
                    With two-factor authentication, you protect your Rainbook account with something you know (your
                    password) and something you have (your phone).
                  </p>
                  <p
                    style={{
                      marginBottom: '4em',
                      color: '#6c757d'
                    }}
                  >
                    Once configured, you'll be required to enter both your password and an authentication code from your
                    mobile phone in order to sign-in, adding an extra level of security.
                  </p>
                </>
                <div id="recaptcha-container" />
                {verificationFailed && <p style={{ color: 'red', fontSize: 14 }}>{verificationFailed}</p>}

                <Grid container>
                  <form onSubmit={!verificationComplete ? handleSubmit2FA : null}>
                    <Grid item>
                      {!verificationComplete && (
                        <button style={btnStyles} type="submit">
                          <div>Enable Two Factor Auth</div>
                        </button>
                      )}
                    </Grid>
                  </form>
                </Grid>
              </>
            )) ||
              (verificationId && !verificationComplete && (
                <form onSubmit={handleSubmit2FA}>
                  <Typography variant="h6" style={{ marginBlock: '1.5em' }}>
                    Verifying your identity.
                  </Typography>
                  <p
                    style={{
                      marginBlock: '1.5em',
                      color: '#6c757d'
                    }}
                  >
                    Enter Your Verification Code
                  </p>
                  <p
                    style={{
                      marginBlock: '1.5em',
                      color: '#6c757d'
                    }}
                  >
                    We've sent a message to {phoneNumber} {<br />} Please enter the code you received.
                  </p>
                  <ReactCodeInput type="numeric" fields={6} onChange={handleCodeChange} />
                  <DialogActions className={'phone-dialog-verify'}>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        color: '#fff',
                        backgroundColor: 'rgb(23, 120, 250)'
                      }}
                    >
                      Verify
                    </Button>
                  </DialogActions>
                </form>
              )) ||
              (verificationId && verificationComplete && (
                <>
                  <Typography variant="h6">Your Account is Secured!</Typography>
                  <p>
                    Each time you sign in to Rainbook, you will also need to enter a verification code sent to your
                    mobile phone number.
                  </p>
                </>
              ))}
          </Grid>
          <Grid item sm={6} xs={12} align="center">
            {secureImg}
          </Grid>
        </Grid>
      )}
      <Dialog open={verifyPassword} fullWidth maxWidth="xs">
        <DialogTitleX
          onClose={() => {
            setVerifyPassword(false);
          }}
        >
          Confirm your password.
        </DialogTitleX>
        <DialogContent>
          <p style={{ color: 'red' }}>{passwordError}</p>
          <p></p>
          <TextField label="Password" type="password" onChange={(event) => setPassword(event.target.value)} />
          <DialogActions>
            <Button onClick={() => reAuth()}>Submit</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default PhoneDialog;
