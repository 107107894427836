import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineArrowRight, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import { TooltipIcon } from 'components/interaction';
import { formatPercent } from 'components/tables';
import { useAccountsData } from 'contexts';

interface CircularMeterProps {
  value: number;
  change: number;
  changeInverted?: boolean;
  size?: number;
  strokeWidth?: number;
  display?: string | number;
  noScore?: any;
  footer?: string;
  tooltip?: string;
  useFixedPosition?: boolean;
  mstarRef?: any;
}

type Rating = 'Poor' | 'Fair' | 'Good' | '';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  circularMeter: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  circularMeterSvg: {
    transform: 'rotate(-90deg)'
  },
  circularMeterBackground: {
    stroke: '#ccc',
    strokeLinecap: 'round'
  },
  circularMeterCircle: (props: { rating: Rating }) => ({
    stroke:
      props.rating === 'Poor'
        ? '#F54F59'
        : props.rating === 'Fair'
        ? '#F5A623'
        : props.rating === 'Good'
        ? '#1DC577'
        : '#eee',
    strokeLinecap: 'round'
  }),
  circularMeterValueDot: (props: { rating: Rating }) => ({
    stroke:
      props.rating === 'Poor'
        ? '#F54F59'
        : props.rating === 'Fair'
        ? '#F5A623'
        : props.rating === 'Good'
        ? '#1DC577'
        : '#eee',
    fill: '#eee'
  }),
  circularMeterChange: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '3rem',
    fontWeight: 700
  },
  circularMeterChangeArrow: {
    fontSize: '0.8rem'
  },
  circularMeterValue: {
    position: 'absolute',
    top: '5rem',
    fontSize: '3rem',
    fontWeight: 900
  },
  circularMeterLabel: {
    position: 'absolute',
    top: '10rem',
    fontSize: '1.2rem',
    fontWeight: 700
  },
  footerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: '-40px',
    fontSize: '0.8rem',
    textAlign: 'center'
  },
  linkButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    padding: '0.8rem 1.5rem',
    color: 'rgb(21, 120, 246)',
    fontWeight: 700,
    border: '1px solid rgb(21, 120, 246)',
    borderRadius: '0.5rem',
    margin: '1rem',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      textDecoration: 'none',
      color: 'white',
      borderColor: 'white',
      backgroundColor: 'rgb(21, 120, 246)'
    },
    '&:active': {
      textDecoration: 'none'
    },
    '&:focus': {
      outline: 'none',
      textDecoration: 'none'
    }
  }
});

export const ScoreMeter: React.FC<CircularMeterProps> = ({
  value,
  change,
  changeInverted,
  display,
  size = 220,
  strokeWidth = 10,
  noScore,
  footer,
  tooltip,
  useFixedPosition,
  mstarRef
}) => {
  const rating: Rating = useMemo(() => {
    if (value < 80) {
      return 'Poor';
    } else if (value < 90) {
      return 'Fair';
    } else if (value <= 100) {
      return 'Good';
    } else {
      return '';
    }
  }, [value]);

  const classes = useStyles({ rating });
  const { baaUserData, investorUserData } = useAccountsData();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleScrollToSummary = () => {
    const summary = document.getElementById('rbaa-rating-summary') || document.getElementById('rbaa-insights');
    if (summary) {
      summary.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const meterRef = useRef<SVGCircleElement>(null);
  const [meterLength, setMeterLength] = useState(0);
  useEffect(() => {
    if (meterRef.current) {
      setMeterLength(meterRef.current.getTotalLength());
    }
  }, [meterRef]);

  const fixConnection = () => {
    mstarRef.current?.click();
  };

  const padding = 10;
  const staticPercent = 0.665;
  const staticOffset = meterLength * (1 - staticPercent);

  const { dotX, dotY, offset } = useMemo(() => {
    const fixedValue = rating === 'Poor' ? 25 : rating === 'Fair' ? 50 : rating === 'Good' ? 90 : 0;
    const usedValue = useFixedPosition ? fixedValue : value;
    const scaledValue = usedValue ? usedValue * staticPercent : 0;
    const percent = scaledValue / 100;
    const offset = meterLength * (1 - percent);
    const angle = (scaledValue * 350) / 100 - 116;
    const dotX = size / 2 + (size / 2 - strokeWidth / 2) * Math.cos((angle * Math.PI) / 180);
    const dotY = size / 2 + (size / 2 - strokeWidth / 2) * Math.sin((angle * Math.PI) / 180);
    return { dotX, dotY, offset };
  }, [rating, useFixedPosition, value, meterLength, size, strokeWidth]);

  const currentDate = new Date().toLocaleDateString();

  const ratingWithTooltip = !value ? (
    <>
      Need Info <TooltipIcon title={tooltip} size={16} />
    </>
  ) : rating ? (
    <>
      {rating} <TooltipIcon title={tooltip} size={16} />
    </>
  ) : undefined;

  const fixConnectionButton = (
    <button
      className={classes.linkButton}
      style={{ color: '#333', backgroundColor: 'rgb(249,196,0)' }}
      onClick={fixConnection}
    >
      Review Connection Issues <AiOutlineArrowRight />
    </button>
  );

  return (
    <div className={classes.container}>
      <div className={classes.circularMeter}>
        {(value || !isSm) && (
          <svg className={classes.circularMeterSvg} width={size + padding * 2} height={size + padding * 2}>
            <circle
              className={classes.circularMeterBackground}
              r={size / 2 - strokeWidth / 2}
              cx={size / 2 + padding}
              cy={size / 2 + padding}
              strokeWidth={strokeWidth}
              strokeDasharray={meterLength}
              strokeDashoffset={staticOffset}
              fill="none"
              transform={`rotate(-120 ${size / 2 + padding} ${size / 2 + padding})`}
            />
            <circle
              ref={meterRef}
              className={classes.circularMeterCircle}
              r={size / 2 - strokeWidth / 2}
              cx={size / 2 + padding}
              cy={size / 2 + padding}
              strokeWidth={strokeWidth}
              fill="none"
              strokeDasharray={meterLength}
              strokeDashoffset={offset}
              transform={`rotate(-120 ${size / 2 + padding} ${size / 2 + padding})`}
            />
            <circle
              className={classes.circularMeterValueDot}
              r="10"
              cx={dotX + padding}
              cy={dotY + padding}
              strokeWidth={8}
            />
          </svg>
        )}
        {value ? (
          <>
            <div
              className={classes.circularMeterChange}
              style={{
                color: change === 0 ? '#333' : (changeInverted ? change * -1 : change) < 0 ? '#F54F59' : '#1DC577'
              }}
            >
              <div className={classes.circularMeterChangeArrow}>
                {change === 0 ? '' : change < 0 ? <AiOutlineDown /> : <AiOutlineUp />}
              </div>
              {change ? formatPercent(change) : ''}
            </div>
            <div className={classes.circularMeterValue}>{display || ratingWithTooltip}</div>
            <div className={classes.circularMeterLabel}>{display ? ratingWithTooltip : ''}</div>
            <div className={classes.footerText}>
              {!!investorUserData?.advisor && (
                <div>
                  Advisor:{' '}
                  {investorUserData.provided_advisor_name ||
                    `${investorUserData.advisor.last_name} ${investorUserData.advisor.last_name}`}
                </div>
              )}
              {footer && <div>{footer}</div>}
              <div>Next Check {currentDate}</div>
            </div>
            {!!mstarRef && !!baaUserData?.baa_investor_credentials?.length ? (
              fixConnectionButton
            ) : (
              <button className={classes.linkButton} onClick={handleScrollToSummary}>
                See What's Changed <AiOutlineArrowRight />
              </button>
            )}
          </>
        ) : (
          <>
            {!isSm && (
              <>
                <div className={classes.circularMeterValue}>?</div>
                <div className={classes.circularMeterLabel}>{ratingWithTooltip}</div>
                <div className={classes.footerText}>{noScore}</div>
              </>
            )}
            {!!mstarRef && !!baaUserData?.baa_investor_credentials?.length && fixConnectionButton}
          </>
        )}
      </div>
    </div>
  );
};
