// 3rd party
import React, { useState } from 'react';
import OauthPopup from 'react-oauth-popup';

import { ameritradeAuthUrl } from 'assetData/StaticValues';
import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
// local
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

const ameritradeOauthUrl = `${process.env.REACT_APP_API_URL}/api/v1/oauth/ameritrade.json`;
const authWindowDimension = 500;

export const TDAmeritradeLink = ({ institution, onAccounts, handleMainClose, refForClick }) => {
  // contexts
  const { currentUser } = useAuth();
  const { reloadDom } = useAccountsData();
  // local states
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  // handlers
  const onClose = () => {};
  const onCloseSuccess = () => {
    setSuccess(false);
    reloadDom();
  };
  const onCode = async (code) => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(ameritradeOauthUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({ code: code })
    });
    const json = await response.json();
    if (json?.data) {
      onAccounts(json?.data);
      setSuccess(true);
      if (refForClick) {
        reloadDom();
      }
    }
  };

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success && !refForClick && (
        <SuccessDialog page="Liquid" institution="Ameritrade" handleClose={handleMainClose} onClose={onCloseSuccess} />
      )}
      <OauthPopup
        url={ameritradeAuthUrl}
        onCode={onCode}
        onClose={onClose}
        title="TD Ameritrade Authentication"
        height={authWindowDimension}
        width={authWindowDimension}
      >
        {refForClick ? (
          <div ref={refForClick}></div>
        ) : institution.logo_url ? (
          <img className="link-bank-logo" src={institution.logo_url} alt="TD Ameritrade Logo" />
        ) : (
          <div>{institution.name}</div>
        )}
      </OauthPopup>
    </>
  );
};
