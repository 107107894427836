import React, { useEffect, useState } from 'react';

import ManualTransactionsPopup from 'components/popups/ManualTransactionsPopup';
import { GenericTransactionsTable, formatGenericTransactions } from 'components/tables/GenericTransactionsTable';
import {
  PrivateEquityTransactionsTable,
  formatPrivateEquityTransactions,
  formatStockOptionsTransactions
} from 'components/tables/PrivateEquityTransactionsTable';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

function AssetTransactionsTable({ account, accountTransactions, setParentTransactions }) {
  const { currentUser } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [transactionToEdit, setTransactionToEdit] = useState(null);

  const addNewTransaction = (t) => {
    setParentTransactions((prevTransactions) => [...prevTransactions, t]);
  };

  const updateTransaction = (editedTransaction) =>
    setParentTransactions((transactions) =>
      transactions.map((t) => (t.id === editedTransaction.id ? editedTransaction : t))
    );

  const deleteTransaction = async (transactionId) => {
    const ID = await currentUser.getIdToken();

    await fetch(`${url}/api/v1/transactions/${transactionId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      }
    });

    setParentTransactions((transactions) => transactions.filter((t) => t.id !== transactionId));
  };

  let formatTransactions = formatGenericTransactions;
  let table = (
    <GenericTransactionsTable
      transactions={transactions}
      is_manual={account.is_manual}
      setTransactionToEdit={setTransactionToEdit}
      setTransactionToDelete={deleteTransaction}
    />
  );
  if (['real estate', 'private equity'].indexOf(account.account_subtype) > -1) {
    formatTransactions = formatPrivateEquityTransactions;
    table = (
      <PrivateEquityTransactionsTable
        transactions={transactions}
        is_manual={account.is_manual}
        setTransactionToEdit={setTransactionToEdit}
        setTransactionToDelete={deleteTransaction}
      />
    );
  }
  if (['stock options'].indexOf(account.account_subtype) > -1) {
    formatTransactions = formatStockOptionsTransactions;
    table = (
      <PrivateEquityTransactionsTable
        transactions={transactions}
        is_manual={account.is_manual}
        setTransactionToEdit={setTransactionToEdit}
        setTransactionToDelete={deleteTransaction}
      />
    );
  }

  useEffect(() => {
    const t = formatTransactions(accountTransactions);
    setTransactions(t);
  }, [formatTransactions, accountTransactions]);

  return (
    <>
      <div className="asset-holdings-container">
        <div className="asset-holdings-header">
          <div className="asset-holdings-header-left">
            <div className="holdings-title">Transactions</div>
          </div>
          <div className="asset-holdings-header-right">
            {account.is_manual && <ManualTransactionsPopup account={account} toggle={addNewTransaction} />}
          </div>
        </div>
        {table}
      </div>
      <ManualTransactionsPopup
        account={accountTransactions.find((t) => t.id === transactionToEdit)}
        show={!!transactionToEdit}
        toggle={updateTransaction}
        onClose={() => setTransactionToEdit(null)}
        edit={true}
        isPrivateEquity={['real estate', 'private equity', 'stock options'].includes(account.account_subtype)}
      />
    </>
  );
}

export default AssetTransactionsTable;
