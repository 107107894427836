import React from 'react';

import { makeStyles } from '@material-ui/core';

interface TipCardProps {
  img: string;
  header: string;
  text: string;
  link: string;
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '35%',
    height: '16rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '80%',
    overflow: 'hidden'
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20%'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  details: {
    width: '100%',
    margin: '0',
    fontSize: '0.9rem',
    textAlign: 'left'
  },
  link: {
    color: '#1A73E8',
    textDecoration: 'none',
    fontWeight: 600,
    marginLeft: '0.25rem',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export const TipCard = ({ img, header, text, link }: TipCardProps) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.imgContainer}>
        <img src={img} alt="" style={{ width: '100%' }} />
      </div>
      <div className={classes.mainContent}>
        <h3 className={classes.title}>{header}</h3>
        <p className={classes.details}>
          {text}
          {link && (
            <a href={link} target="_blank" rel="noreferrer" className={classes.link}>
              Read more.
            </a>
          )}
        </p>
      </div>
    </div>
  );
};
