import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import { TextField, makeStyles } from '@material-ui/core';

import googleattr from 'logos/powered_by_google_on_white_hdpi.png';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 500
  }
}));

function getAddressObject(address_components) {
  var ShouldBeComponent = {
    home: ['street_number'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4'
    ],
    country: ['country']
  };

  var address = {
    home: '',
    postal_code: '',
    street: '',
    region: '',
    city: '',
    country: ''
  };
  address_components.forEach((component) => {
    for (var shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country') {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  return address;
}

function formatAddress(address_object) {
  return `${address_object.home} ${address_object.street}, ${address_object.city}, ${address_object.region} ${address_object.postal_code}`;
}

const options = {
  types: ['address'],
  componentRestrictions: {
    country: 'us'
  }
};

const LocationSearchInput = ({ address, setAddress }) => {
  const classes = useStyles();

  const handleChange = (address) => {
    // console.log('change', address)
    setAddress(address);
  };

  const handleSelect = (address, placeId, suggestion) => {
    geocodeByAddress(address)
      .then((results) => {
        setAddress(formatAddress(getAddressObject(results[0].address_components)));
      })
      .catch((error) => console.error('err', error));
  };

  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
    <div className="autocomplete-root">
      <TextField
        {...getInputProps()}
        autoFocus
        placeholder="123 Example St. Atlanta, GA"
        label="Address"
        name="address"
        variant="outlined"
        className={classes.formControl}
      />
      <div className="autocomplete-dropdown-container">
        <br />
        {loading && (
          <div
            style={{
              backgroundColor: '#ffffff',
              cursor: 'pointer',
              padding: '5px'
            }}
          >
            Loading...
          </div>
        )}
        {suggestions.map((suggestion) => {
          const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
          const style = suggestion.active
            ? {
                backgroundColor: '#fafafa',
                cursor: 'pointer',
                padding: '5px'
              }
            : {
                backgroundColor: '#ffffff',
                cursor: 'pointer',
                padding: '5px'
              };

          return (
            <div
              {...getSuggestionItemProps(suggestion, {
                className,
                style
              })}
            >
              <span>{suggestion.description}</span>
            </div>
          );
        })}
        {suggestions.length > 0 && (
          <div style={{ width: '100%' }}>
            {/* required to have attribution as said by https://developers.google.com/maps/documentation/places/web-service/policies */}
            <img src={googleattr} alt="powered by google" style={{ float: 'right', maxHeight: 20 }} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={options}
      googleCallbackName="placesAutocomplete"
    >
      {renderFunc}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
