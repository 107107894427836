export default function ValidateSignUp(values) {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = '* First name is required';
  }

  if (!values.lastName) {
    errors.lastName = '* Last name is required';
  }

  if (!values.email) {
    errors.email = '* Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = '* Email address is invalid';
  }

  // if (!values.phone) {
  //     errors.phone = "* Phone number is required"
  // } else if (!(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(values.phone))) {
  //     errors.phone = "* Please check the format of your phone number"
  // }

  if (!values.password) {
    errors.password = '* Password is required';
  } else if (values.password.length < 8) {
    errors.password = '* Password must be at least 8 characters long';
  }

  if (!values.agree) {
    errors.agree = 'check to agree * '
  }
  return errors;
}
