import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { useAccountsData } from 'contexts/AccountsContext';

import AutoCompleteInstitutionSelector from './AutoCompleteInstitutionSelector';
import { currencyField, getFormErrorMessage, textField } from './common';

import './forms.css';

export const OtherForm = ({ accountType, accountSubType, formSubmit }) => {
  const { selectableInstitutions } = useAccountsData();
  const [institutionValues, setInstitutionValues] = useState({
    institution_id: null,
    institution_name: null,
    account_type: accountType,
    account_subtype: accountSubType
  });

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {},
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'Name is required.';
      }
      if (!institutionValues.institution_id && !institutionValues.institution_name) {
        errors.institution = 'Institution is required';
      }

      return errors;
    },
    onSubmit: async (data) => {
      await formSubmit(data, {}, {}, institutionValues);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingBottom: 20 }}>
          <AutoCompleteInstitutionSelector
            institutions={selectableInstitutions}
            values={institutionValues}
            setValues={setInstitutionValues}
          />
          {getFormErrorMessage(formik, 'institution')}
        </Grid>
        <Grid item sm={6}>
          {textField(formik, 'name', 'Account Name')}
        </Grid>
        <Grid item sm={6}>
          {currencyField(formik, 'balance_current', 'Account Balance/Value')}
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" label="Submit" className="p-mt-2" />
        </Grid>
      </Grid>
    </form>
  );
};
