import { React, useEffect, useState } from 'react';

import { Collapse, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import {
  AccountBalance,
  AccountBalanceWallet,
  CreditCard,
  DirectionsCar,
  Home,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PhotoSizeSelectActual,
  School,
  Toll,
  TrendingUp,
  VpnLock
} from '@material-ui/icons';

import ManualAddAccount from './ManualAddAccount';

import 'components/popups/AddAccountsPopup.css';

const styles = {
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  backgroundColor: '#ffffff',
  textAlign: 'center',
  alignItems: 'center',
  fontSize: '8pt',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  border: 'none',
  marginRight: 'none'
};

function AddAccountsManual(props) {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [openBank, setOpenBank] = useState(!isSM);
  const [openCrypto, setOpenCrypto] = useState(!isSM);
  const [openRealEstate, setOpenRealEstate] = useState(!isSM);
  const [openPersonal, setOpenPersonal] = useState(!isSM);

  // Open all collapsed sections if larger than sm
  useEffect(() => {
    setOpenBank(!isSM);
    setOpenCrypto(!isSM);
    setOpenRealEstate(!isSM);
    setOpenPersonal(!isSM);
  }, [isSM]);

  return (
    <Grid container spacing={2} align="center">
      <Grid item xs={12} md={4} style={isSM ? {} : { borderRight: 'solid 1px black' }}>
        <div className="plaid-tile-header">
          <div
            className="plaid-tile-asset-class"
            style={isSM ? { cursor: 'pointer' } : {}}
            onClick={() => {
              if (isSM) setOpenBank((state) => !state);
            }}
          >
            {isSM &&
              (openBank ? (
                <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
              ) : (
                <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
              ))}
            Banks, Brokerages
          </div>
        </div>
        <Collapse in={openBank} timeout="auto" unmountOnExit>
          <Grid container spacing={0} alignContent="flex-start">
            <Grid item xs={6} className="account-grid-item">
              <ManualAddAccount
                name="Checking/Savings"
                accountType="depository"
                icon={<AccountBalance />}
                styles={styles}
                handleMainClose={props.handleMainClose}
              />
            </Grid>
            <Grid item xs={6} className="account-grid-item">
              <ManualAddAccount
                name="Brokerage"
                icon={<TrendingUp />}
                accountType="investment"
                accountSubType="brokerage"
                styles={styles}
                handleMainClose={props.handleMainClose}
              />
            </Grid>
            <Grid item xs={6} className="account-grid-item">
              <ManualAddAccount
                name="IRA/401K/529 Plan"
                icon={<TrendingUp />}
                accountType="investment"
                accountSubType="ira"
                styles={styles}
                handleMainClose={props.handleMainClose}
              />
            </Grid>
            <Grid item xs={6} className="account-grid-item">
              <ManualAddAccount
                name="Credit Card"
                icon={<CreditCard />}
                accountType="credit"
                styles={styles}
                handleMainClose={props.handleMainClose}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={4} style={isSM ? {} : { borderRight: 'solid 1px black' }}>
        <div className="plaid-tile-header">
          <div
            className="plaid-tile-asset-class"
            style={isSM ? { cursor: 'pointer' } : {}}
            onClick={() => {
              if (isSM) setOpenCrypto((state) => !state);
            }}
          >
            {isSM &&
              (openCrypto ? (
                <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
              ) : (
                <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
              ))}
            Crypto
          </div>
        </div>
        <Collapse in={openCrypto} timeout="auto" unmountOnExit>
          <Grid container spacing={0} alignContent="flex-start">
            <Grid item xs={6} className="account-grid-item">
              <ManualAddAccount
                name="Other Crypto"
                icon={<VpnLock />}
                accountType="investment"
                accountSubType="cryptocurrency"
                styles={styles}
                handleMainClose={props.handleMainClose}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={4} style={{ height: '100%' }}>
        <div className="plaid-right-top">
          <div className="plaid-tile-header">
            <div
              className="plaid-tile-asset-class"
              style={isSM ? { cursor: 'pointer' } : {}}
              onClick={() => {
                if (isSM) setOpenRealEstate((state) => !state);
              }}
            >
              {isSM &&
                (openRealEstate ? (
                  <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
                ) : (
                  <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
                ))}
              Real Estate, Private Equity, Collectibles
            </div>
          </div>
          <Collapse in={openRealEstate} timeout="auto" unmountOnExit>
            <Grid container alignContent="flex-start">
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Private Equity"
                  accountType="investment"
                  accountSubType="private equity"
                  icon={<AccountBalanceWallet />}
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Real Estate Fund"
                  accountType="investment"
                  accountSubType="real estate"
                  icon={<AccountBalanceWallet />}
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Stock Options"
                  accountType="investment"
                  accountSubType="stock options"
                  icon={<AccountBalanceWallet />}
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
            </Grid>
          </Collapse>
        </div>
        <div className="plaid-right-bottom">
          <div className="plaid-tile-header">
            <div
              className="plaid-tile-asset-class"
              style={isSM ? { cursor: 'pointer' } : {}}
              onClick={() => {
                if (isSM) setOpenPersonal((state) => !state);
              }}
            >
              {isSM &&
                (openPersonal ? (
                  <KeyboardArrowUp style={{ verticalAlign: 'middle' }} />
                ) : (
                  <KeyboardArrowDown style={{ verticalAlign: 'middle' }} />
                ))}
              Personal
            </div>
          </div>
          <Collapse in={openPersonal} timeout="auto" unmountOnExit>
            <Grid container alignContent="flex-start">
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Home"
                  icon={<Home />}
                  accountType="other"
                  accountSubType="home"
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Vehicle"
                  icon={<DirectionsCar />}
                  accountType="other"
                  accountSubType="auto"
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Artwork"
                  accountType="other"
                  accountSubType="collectible"
                  icon={<PhotoSizeSelectActual />}
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Jewelry"
                  icon={<Toll />}
                  accountType="other"
                  accountSubType="collectible"
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Mortgage"
                  accountType="loan"
                  accountSubType="mortgage"
                  icon={<Home />}
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Car Loan"
                  accountType="loan"
                  accountSubType="auto"
                  icon={<DirectionsCar />}
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
              <Grid item xs={6} className="account-grid-item">
                <ManualAddAccount
                  name="Student Loan"
                  accountType="loan"
                  accountSubType="student"
                  icon={<School />}
                  styles={styles}
                  handleMainClose={props.handleMainClose}
                />
              </Grid>
            </Grid>
          </Collapse>
        </div>
      </Grid>
    </Grid>
  );
}

export default AddAccountsManual;
