import { useEffect } from 'react';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import { useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/AuthContext';

export const LocationChange = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const { setPathPageView, setIdentity } = useTrackingCode();

  useEffect(() => {
    if (currentUser) {
      setIdentity(currentUser.email);
    }
  }, [currentUser, setIdentity]);

  useEffect(() => {
    if (location) {
      const pageName = location.pathname.split("/").pop();
      if(pageName){
        let words = pageName.split('-');
        words = words.map((w) => `${w[0].toUpperCase()}${w.slice(1)}`);
        document.title = `Rainbook | ${words.join(' ')}`;
      } else {
        document.title = "Rainbook";
      }
      setPathPageView(location.pathname + location.search);
    }
  }, [location, setPathPageView]);

  return null;
};
