import React, { useMemo } from 'react';

import { formatDollars, formatPercent } from 'components/tables/GenericAssetsTable';
import { useAccountsData } from 'contexts/AccountsContext';

import { RatingCard } from './RatingCard';

export const RatingCardFees = () => {
  const { investorUserData, scoreSubmitted } = useAccountsData();
  const { score_fees } = investorUserData;

  const isPending = useMemo(() => {
    return !score_fees && scoreSubmitted.indexOf('score_fees') > -1;
  }, [score_fees, scoreSubmitted]);

  const content = useMemo(() => {
    if (!score_fees || !investorUserData?.stats_breakdown) return '';
    const investorStats = investorUserData.stats_breakdown;
    let feeState = 'is in line with';
    if (score_fees < 80) {
      feeState = 'is much higher than';
    } else if (score_fees < 90) {
      feeState = 'is higher than';
    }
    return `Your overall fee of ${formatPercent(
      investorStats.total_fee_percent
    )} is ${feeState} Rainbook's benchmark for your account size. Overall fees include your AUM fee plus any fees associated with mutual funds or ETFs held in your portfolio.`;
  }, [score_fees, investorUserData]);

  const contentHeader = useMemo(() => {
    if (!score_fees) return 'Please connect an account to analyze your fees.';
    let feeState = 'aligned with';
    if (score_fees < 90) {
      feeState = 'higher than';
    }
    return `Your fees are ${feeState} industry norms.`;
  }, [score_fees]);

  return (
    <RatingCard
      title="Overall Fees"
      link="/advisor-analyzer/fees-factor"
      score={score_fees}
      isPending={isPending}
      contentHeader={contentHeader}
      description="Amount paid (inclusive of fund fees) and cost of relationship."
    />
  );
};
