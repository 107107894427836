import { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { Card, CardContent, Container, Grid } from '@material-ui/core';

import PersonalFinancialStatement from 'components/assetDetails/PersonalFinancialStatement';
import InsightTile from 'components/tiles/InsightTile';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';
import useWindowWidth from 'utils/useWindowWidth';

import AccountTile, { tileType } from './AccountTile';

const accountHeight = (acct) => {
  if (acct.id.indexOf('insight') > -1) {
    return 2;
  } else if (tileType(acct) === 'asset') {
    return 5;
  } else {
    return 4;
  }
};

const getLayouts = (accounts, insights) => {
  let all = mergeAccountsWithInsights(accounts, insights);

  let twoColHeights = [[0, 0]];

  for (var i = 0; i < all.length; i++) {
    const a = all[i];
    const col = i % 2;
    const row = i === 0 ? 0 : Math.round((i - 1) / 2);
    if (row > 0 && col === 0) {
      twoColHeights.push([0, 0]);
    }
    const height = accountHeight(a);
    let cellAbove = row === 0 ? 0 : twoColHeights[row - 1][col];
    twoColHeights[row][col] = cellAbove + height;
  }

  const xs = all.map((a, idx) => {
    const x = idx % 2;
    const row = idx === 0 ? 0 : Math.round((idx - 1) / 2);
    const h = accountHeight(a);
    if (a.id.indexOf('insight') > -1) {
      return { i: a.id, x, y: row, w: 1, h, isDraggable: false };
    } else {
      return { i: a.id, x, y: row, w: 1, h };
    }
  });

  const xxs = all.map((a, idx) => {
    const h = accountHeight(a);
    if (a.id.indexOf('insight') > -1) {
      return { i: a.id, x: 0, y: idx * h, w: 1, h, isDraggable: false };
    } else if (tileType(a) === 'asset') {
      return { i: a.id, x: 0, y: idx * h, w: 1, h };
    } else {
      return { i: a.id, x: 0, y: idx * h, w: 1, h };
    }
  });
  return {
    xs,
    xxs
  };
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const mergeAccountsWithInsights = (accounts, insights) => {
  const interval = 3;
  const offset = 2;

  let all = [];
  let i = 0;
  let x = 0;

  accounts.forEach((account) => {
    if (insights && i < insights.length && (x + interval - offset) % interval === 0) {
      all.push({ ...insights[i], id: 'insight' + insights[i].id });
      i++;
      x++;
    }
    all.push(account);
    x++;
  });

  const minInsights = Math.min(insights.length, 2);
  while (i < minInsights) {
    all.push({ ...insights[i], id: 'insight' + insights[i].id });
    i++;
  }

  return all;
};

const TileContainer = ({
  leftContent,
  leftItem,
  rightContent,
  insights,
  rightAccounts,
  assetColorScheme,
  mainColor,
  pageId,
  showPFS
}) => {
  const width = useWindowWidth().width;
  const { currentUser } = useAuth();
  const { sortAccounts } = useAccountsData();
  const [accountsWithInsights, setAccountsWithInsights] = useState(mergeAccountsWithInsights(rightAccounts, insights));
  const [layouts, setLayouts] = useState(getLayouts(rightAccounts, insights));

  useEffect(() => {
    setAccountsWithInsights(mergeAccountsWithInsights(rightAccounts, insights));
  }, [rightAccounts, insights]);

  useEffect(() => {
    setLayouts(getLayouts(rightAccounts, insights));
  }, [rightAccounts, insights]);

  const onSavePositions = async (layout, layouts) => {
    let x = layout.filter((a) => a.x === 0).sort((a, b) => a.y - b.y);
    let y = layout.filter((a) => a.x === 1).sort((a, b) => a.y - b.y);
    let xi = 0;
    let yi = 0;
    let ordered = [];
    for (var i = 0; i < layout.length; i++) {
      if (i % 2 === 0) {
        if (x[xi]) {
          ordered.push(x[xi]);
          xi += 1;
        } else if (y[yi]) {
          ordered.push(y[yi]);
          yi += 1;
        }
      } else {
        if (y[yi]) {
          ordered.push(y[yi]);
          yi += 1;
        } else if (x[xi]) {
          ordered.push(x[xi]);
          xi += 1;
        }
      }
    }
    let sorted = ordered.filter((a) => a.i.indexOf('insight') === -1);
    if (pageId && sorted.length > 0 && sorted[0].i) {
      const ID = await currentUser.getIdToken();
      const accountIds = sorted.map((a) => a.i);
      const existingAccountIds = rightAccounts.map((a) => a.id);
      if (accountIds.toString() === existingAccountIds.toString()) return; //no change
      await fetch(`${process.env.REACT_APP_API_URL}/api/v1/account_positions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({
          data: {
            type: 'account_positions',
            attributes: {
              page: pageId,
              account_ids: accountIds
            }
          }
        })
      });
      sortAccounts(pageId, accountIds);
    }
  };

  const styles = width > 1494 ? { marginTop: 10, marginLeft: 76 } : { marginTop: 10 };

  return (
    <Container style={styles}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4} align="center">
          <div style={{ maxWidth: 481 }}>
            {leftContent}
            {leftItem && (
              <Card>
                <CardContent align="center" justify="center">
                  {leftItem}
                </CardContent>
              </Card>
            )}
          </div>
        </Grid>
        <Grid item xs={12} lg={8}>
          {showPFS ? (
            <PersonalFinancialStatement page={pageId} />
          ) : (
            <>
              {rightContent && (
                <Grid item xs={12} align="center" container>
                  {rightContent}
                </Grid>
              )}
              {rightAccounts && (
                <ResponsiveGridLayout
                  rowHeight={90}
                  layouts={layouts}
                  breakpoints={{ xs: 776, xxs: 0, md: 750 }}
                  cols={{ xs: 2, xxs: 1, md: 2 }}
                  isBounded={true}
                  isResizable={false}
                  isDraggable={width >= 776}
                  onLayoutChange={onSavePositions}
                >
                  {accountsWithInsights.map((acct, idx) => {
                    return acct.id.indexOf('insight') === -1 ? (
                      <div key={acct.id}>{AccountTile(acct, assetColorScheme, mainColor, pageId)}</div>
                    ) : (
                      <div key={acct.id}>
                        <InsightTile key={acct.id} insight={acct} />
                      </div>
                    );
                  })}
                </ResponsiveGridLayout>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default TileContainer;
