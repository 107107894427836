import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

import FairImg from 'logos/meters/Fair-02.png';
import GoodImg from 'logos/meters/Good-01.png';
import NoneImg from 'logos/meters/Need_More_Info.png';
import PoorImg from 'logos/meters/Poor-03.png';

import FairImgWhite from 'logos/meters/gauge-fair.png';
import GoodImgWhite from 'logos/meters/gauge-good.png';
import PoorImgWhite from 'logos/meters/gauge-poor.png';

interface RatingGaugeProps {
  score: number;
  size: 'xs' | 'sm' | 'lg';
  white?: boolean
}

const useStyles = makeStyles(() => ({
  score: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    margin: 0,
    '& img': {
      minWidth: 50
    },
    '&.score_xs': {
      fontSize: '1.5rem',
      '& img': {
        width: 50
      }  
    },
    '&.score_sm': {
      fontSize: '3rem',
      '& img': {
        width: 150
      }  
    },
    '&.score_lg': {
      fontSize: '3rem',
      '& img': {
        width: 300
      }  
    },
  },
  scoreTxt: {
    paddingLeft:"20px"
  }
}));

export const RatingGauge = ({ score, size, white }: RatingGaugeProps) => {
  const classes = useStyles();

  const scoreImg = useMemo(() => {
    if (!score) {
      return white ? PoorImgWhite : NoneImg;
    }
    if (score < 80) {
      return white ? PoorImgWhite : PoorImg;
    } else if (score < 90) {
      return white ? FairImgWhite : FairImg;
    } else {
      return white ? GoodImgWhite : GoodImg;
    }
  }, [score, white]);


  const scoreTxt = useMemo(() => {
    if (!score) {
      return white ? "---" : "";
    }
    if (score < 80) {
      return "Poor";
    } else if (score < 90) {
      return "Fair";
    } else {
      return "Good";
    }
  }, [score]);

  return (
    <div className={`${classes.score} score_${size}` }>
      <img src={scoreImg} alt="gauge" />
      {white && <div className={classes.scoreTxt}>{scoreTxt}</div>}
    </div>
  );
};
