import React from 'react';

import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { currencyField, dateField, isDate, textField } from './common';

import './forms.css';

export const TransactionForm = ({ formSubmit, transactionToEdit }) => {
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: transactionToEdit || {},
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'Name is required.';
      }

      if (data.date && !isDate(data.date)) {
        errors.date = 'Date is invalid.';
      }

      return errors;
    },
    onSubmit: async (data) => {
      await formSubmit(data);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <Grid container spacing={2} style={{ paddingBottom: 20 }}>
        <Grid item sm={6}>
          {textField(formik, 'name', 'Description')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {dateField(formik, 'date', 'Date')}
        </Grid>
        <Grid item sm={6}>
          {textField(formik, 'merchant_name', 'Merchant Name')}
        </Grid>
        <Grid item sm={6}>
          {currencyField(formik, 'amount', 'Total $ Amount')}
        </Grid>
        <Grid item sm={6}>
          {textField(formik, 'quantity', 'Quantity')}
        </Grid>
        <Grid item sm={6}>
          {currencyField(formik, 'price', 'Price per')}
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" label="Submit" className="p-mt-2" />
        </Grid>
      </Grid>
    </form>
  );
};
