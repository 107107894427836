import React from 'react';
import { Link } from 'react-router-dom';

import { Card, CardContent, CardHeader } from '@material-ui/core';
import * as d3 from 'd3';

import HoveredDonut from 'components/charts/HoveredDonut';
import Legend from 'components/charts/Legend';
import { useAccountsData } from 'contexts/AccountsContext';

import './Tiles.css';

function LiquidAssetTile() {
  const { LiqData } = useAccountsData();

  const labels = LiqData.map((hash) => {
    return hash['label'];
  });

  const reducer = (acc = 0, currVal) => acc + currVal;
  const AssetValues = LiqData.map((hash) => hash['value']);
  const AssetTotal = AssetValues.reduce(reducer, 0);

  const colorScheme = ['rgb(120,255,184)', 'rgb(40,232,136)', 'rgb(25,155,90)', 'rgb(19,107,61)'];
  const colors = d3.scaleOrdinal().range(colorScheme);
  const page = 'LiquidTile';

  const styles = {
    color: '#ffffff',
    backgroundColor: 'rgb(30,197,119)',
    borderRadius: '9px',
    height: '475px'
  };

  return (
    <Link to="/wealth-hub/liquid">
      <Card style={styles} elevation={0}>
        <CardHeader title="Liquid Assets" />
        <CardContent>
          <div className="liquid-doughnut-and-keys">
            <HoveredDonut
              showTotal={true}
              liqDonut={true}
              donutData={LiqData}
              innerRadius={80}
              outerRadius={110}
              colorScheme={colorScheme}
              width={220}
              height={220}
              totals={AssetTotal}
              AccountName={'Liquid'}
            />
            <div className="liquid-doughnut-keys">
              <svg className="liquid-doughnut-key-svg">
                {LiqData.length > 0 && (
                  <g>
                    <Legend
                      data={LiqData}
                      labels={labels}
                      colors={colors}
                      colorScheme={['rgb(120,255,184)', 'rgb(40,232,136)', 'rgb(25,155,90)', 'rgb(19,107,61)']}
                      page={page}
                    />
                  </g>
                )}
              </svg>
            </div>
          </div>
        </CardContent>
      </Card>
    </Link>
  );
}

export default LiquidAssetTile;
