import React, { useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import moment from 'moment';

import Doughnut from 'components/charts/Doughnut';
import ExpensesDropdown from 'components/dropdowns/ExpensesDropdown';

import './IncomeTile.css';

function IncomeTile({ incomes, incomeTotal, selectedMonth, setSelectedMonth }) {
  const [counter, setCounter] = useState(0);

  const handleIncrement = () => {
    counter < 2 ? setCounter(counter + 1) : setCounter(0);
  };

  const handleDecrement = () => {
    counter > 0 ? setCounter(counter - 1) : setCounter(2);
  };

  let unsortedIncomes = [];
  Object.keys(incomes).forEach((ele) => {
    unsortedIncomes.push([incomes[ele].name, incomes[ele].total]);
  });

  const sortedIncomes = unsortedIncomes.sort((a, b) => parseFloat(b[1]) - parseFloat(a[1]));

  const topThree = sortedIncomes.slice(0, 3);
  const tops = [
    topThree.length > 0 ? [topThree[0][0], topThree[0][1]] : ['other income', 0],
    topThree.length > 1 ? [topThree[1][0], topThree[1][1]] : ['other income', 0],
    topThree.length > 2 ? [topThree[2][0], topThree[2][1]] : ['other income', 0]
  ];
  const values = [{ value: tops[0][1] }, { value: tops[1][1] }, { value: tops[2][1] }];

  const total =
    incomeTotal !== 0
      ? `${((values[counter]['value'] / incomeTotal) * 100).toLocaleString('en', { maximumFractionDigits: 1 })}%`
      : `${0}%`;

  const donutOne = (
    <div className="Incomes-doughnut">
      <Doughnut
        data={values}
        innerRadius={60}
        outerRadius={100}
        width={200}
        height={200}
        AssetTotal={total}
        colors={['rgb(255,255,255', 'rgb(19,107,61)', 'rgb(25,155,90)']}
      />
    </div>
  );

  const donutTwo = (
    <div className="incomes-doughnut">
      <Doughnut
        data={values}
        innerRadius={60}
        outerRadius={100}
        width={200}
        height={200}
        AssetTotal={total}
        colors={['rgb(19,107,61)', 'rgb(255,255,255', 'rgb(25,155,90)']}
      />
    </div>
  );

  const donutThree = (
    <div className="incomes-doughnut">
      <Doughnut
        data={values}
        innerRadius={60}
        outerRadius={100}
        width={200}
        height={200}
        AssetTotal={total}
        colors={['rgb(19,107,61)', 'rgb(25,155,90)', 'rgb(255,255,255']}
      />
    </div>
  );

  const placeholder = (
    <div className="incomes-doughnut">
      <Doughnut
        data={[{ value: 0 }, { value: 100 }, { value: 0 }]}
        innerRadius={60}
        outerRadius={100}
        width={200}
        height={200}
        AssetTotal={'0%'}
        colors={['rgb(255,255,255', 'rgb(19,107,61)', 'rgb(25,155,90)']}
      />
    </div>
  );

  let dateString = '';
  switch (selectedMonth.value) {
    case 0:
    case 1:
      dateString = moment(selectedMonth.startStr).format('MMMM YYYY');
      break;
    case 2:
      dateString = moment(selectedMonth.startStr).format('YYYY');
      break;
    case 3:
      dateString = `${moment(selectedMonth.startStr).format('MMM YYYY')} to ${moment(selectedMonth.endStr).format(
        'MMM YYYY'
      )}`;
      break;
    default:
      dateString = moment(selectedMonth.startStr).format('MMMM YYYY');
  }
  if (selectedMonth.value !== 3) {
    dateString = dateString + ' Income';
  }

  return (
    <div className="income-tile">
      <div className="income-header">
        <div className="income-header-left">{dateString}</div>
        <div className="income-header-right">
          <div className="dropdown">
            <div>
              <ExpensesDropdown
                styles={{
                  color: 'rgb(255,255,255)',
                  width: 175,
                  height: 20,
                  border: '1px solid #FFFFFF',
                  borderRadius: 50,
                  paddingBottom: 2,
                  backgroundColor: 'rgb(30,197,119)'
                }}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="income-tile-body">
        {incomes['total'] === 0 ? placeholder : counter === 0 ? donutOne : counter === 1 ? donutTwo : donutThree}
      </div>
      <div className="incomes-tile-footer">
        <AiOutlineLeft onClick={handleDecrement} className="toggle-icons" />
        <div className="income-items">
          <div className="income-item-label">
            {counter === 0 ? tops[0][0] : counter === 1 ? tops[1][0] : tops[2][0]}
          </div>
          <div className="income-item-value">
            $
            {counter === 0
              ? tops[0][1].toFixed(2).toLocaleString('en')
              : counter === 1
              ? tops[1][1].toFixed(2).toLocaleString('en')
              : tops[2][1].toFixed(2).toLocaleString('en')}
          </div>
        </div>
        <AiOutlineRight onClick={handleIncrement} className="toggle-icons" />
      </div>
    </div>
  );
}

export default IncomeTile;
