import React, { useState } from 'react';

import { Avatar, Dialog, DialogContent } from '@material-ui/core';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import ErrorDialog from 'components/dialogs/ErrorDialog';
import { CollectibleForm } from 'components/forms/CollectibleForm';
import { InvestmentForm } from 'components/forms/InvestmentForm';
import { OtherForm } from 'components/forms/OtherForm';
import { PrivateEquityForm } from 'components/forms/PrivateEquityForm';
import { RealEstateForm } from 'components/forms/RealEstateForm';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

const ManualAddAccount = ({ name, accountType, accountSubType, icon, styles, logo, handleMainClose }) => {
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [accounts, setAccounts] = useState([]);

  const formSubmit = async (data, logoFile, assetFile, institutionValues) => {
    let accountData = {};
    let assetData = {};
    if (!data.date) {
      delete data['date'];
    }
    if (!data['asset-date']) {
      delete data['asset-date'];
    }
    for (const key of Object.keys(data)) {
      if (key.indexOf('asset-') !== 0) {
        accountData[key] = data[key];
      } else {
        assetData[key.substr(6)] = data[key];
      }
    }
    const ID = await currentUser.getIdToken();
    let response = await fetch(`${url}/api/v1/accounts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          type: 'accounts',
          attributes: { ...accountData, ...institutionValues }
        }
      })
    });
    let accountId = null;
    if (response.status === 201) {
      const acct = await response.json();
      accountId = acct['data']['id'];
      assetData['account_id'] = accountId;

      // Create array of accounts to send to success dialog
      // should only be one account in array for a manual account
      setAccounts((accounts) => [...accounts, acct['data']['attributes']]);
    }
    if (accountId) {
      if (logoFile.name) {
        const formData = new FormData();
        formData.append(logoFile.name, logoFile.file);
        await fetch(`${url}/api/v1/accounts/${accountId}/logo_file`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${ID}`
          },
          body: formData
        });
      }
      if (assetData['name']) {
        response = await fetch(`${url}/api/v1/assets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ID}`
          },
          body: JSON.stringify({
            data: {
              type: 'assets',
              attributes: assetData
            }
          })
        });
        if (response.status === 201 && assetFile.name) {
          const asset = await response.json();
          const formData = new FormData();
          formData.append('logo_file', assetFile.file);
          await fetch(`${url}/api/v1/assets/${asset['data']['id']}/logo_file`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${ID}`
            },
            body: formData
          });
        }
      }
      setSuccess(true);
    } else {
      setError('There was an error adding your account. Please try again later or contact support@rainbook.com');
    }
    setOpen(false);
  };

  const formComponent = (type, subtype) => {
    if (['real estate'].indexOf(subtype) > -1) {
      return <RealEstateForm formSubmit={formSubmit} accountType={type} accountSubType={subtype} />;
    }
    if (['private equity', 'stock options'].indexOf(subtype) > -1) {
      return <PrivateEquityForm formSubmit={formSubmit} accountType={type} accountSubType={subtype} />;
    }
    if (['collectible'].indexOf(subtype) > -1) {
      return <CollectibleForm formSubmit={formSubmit} accountType={type} accountSubType={subtype} />;
    }
    if (['investment', 'brokerage'].indexOf(type) > -1) {
      return <InvestmentForm formSubmit={formSubmit} accountType={type} accountSubType={subtype} />;
    }
    return <OtherForm formSubmit={formSubmit} accountType={type} accountSubType={subtype} />;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success ? (
        <SuccessDialog accounts={accounts} institution={accountSubType || 'manual'} handleClose={handleMainClose} />
      ) : null}
      <div style={styles} onClick={(e) => setOpen(true)}>
        {logo ? (
          <img className="link-bank-logo" src={logo} alt=" " />
        ) : (
          <>
            <Avatar className="link-bank-icon">{icon}</Avatar>
            <div>{name}</div>
          </>
        )}
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitleX onClose={handleClose}>Add {name}</DialogTitleX>
        <DialogContent>
          {formComponent(accountType, accountSubType)}
          <br />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ManualAddAccount;
