import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent } from '@material-ui/core';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import { SneakerAssetForm } from 'components/forms/SneakerAssetForm';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;
function SneakerAssetsPopup({ account, toggle, show = false, setShow, edit = false }) {
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(show);
  const { reloadDom } = useAccountsData();

  const handleClose = () => {
    setOpen(false);
    if (setShow) setShow(false);
  };

  useEffect(() => {
    setOpen(show);
    // setAsset(asset);

    console.log(show, account);
  }, [show, account]);

  // const fetchImage = async (assetId) => {
  //   const ID = await currentUser.getIdToken();
  //   const response = await fetch(`${url}/api/v1/assets/${assetId}?fields[assets]=logo_url`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${ID}`
  //     }
  //   });
  //   const json = await response.json();
  //   return json['data']['attributes']['logo_url'];
  // };

  const formSubmit = async (data, account) => {
    data['account_id'] = account.id;
    const ID = await currentUser.getIdToken();

    let _data = {
      name: data.name,
      quantity: data.quantity,
      cost_basis: data.cost_basis
    };

    await fetch(`${url}/api/v1/assets/${data.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          type: 'assets',
          id: data.id,
          attributes: _data
        }
      })
    });
    reloadDom();

    toggle(data);
    handleClose();
  };

  const formComponent = (account) => {
    // if(['private equity', 'stock options'].indexOf(account.account_subtype) > -1){
    //     return <PrivateEquityAssetForm formSubmit={formSubmit} account={account} edit={edit}/>
    // }
    // if(['collectible'].indexOf(account.account_subtype) > -1){
    //     return <CollectibleAssetForm formSubmit={formSubmit} account={account} edit={edit}/>
    // }
    // return <InvestmentAssetForm formSubmit={formSubmit} account={account} edit={edit}/>

    return <SneakerAssetForm formSubmit={formSubmit} account={account} edit={edit} />;
  };

  return (
    <>
      {!edit && (
        <div className="manual-transaction-btn" onClick={(e) => setOpen(true)}>
          <div>Add Assets</div>
        </div>
      )}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitleX onClose={handleClose}>{!edit ? 'Manually add an Asset' : 'Manually edit an Asset'}</DialogTitleX>
        <DialogContent style={{ paddingTop: 30 }}>{formComponent(account)}</DialogContent>
      </Dialog>
    </>
  );
}

export default SneakerAssetsPopup;
