import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';

const InsightsTags = ({ tags, sort, setSort, isInsightView }) => {
  const [liquid, setLiquid] = useState([]);
  const [personal, setPersonal] = useState([]);
  const [alternative, setAlternative] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [liabilities, setLiabilities] = useState([]);

  const history = useHistory();

  useEffect(() => {
    let l = [],
      p = [],
      a = [],
      e = [],
      i = [];

    tags.forEach((tag) => {
      if (tag.page === 'liquid') {
        l.push(tag.name);
      } else if (tag.page === 'personal') {
        p.push(tag.name);
      } else if (tag.page === 'alternative') {
        a.push(tag.name);
      } else if (tag.page === 'expenses') {
        e.push(tag.name);
      } else if (tag.page === 'liabilities') {
        i.push(tag.name);
      }
    });

    setLiquid(l);
    setPersonal(p);
    setAlternative(a);
    setExpenses(e);
    setLiabilities(i);
  }, [tags]);

  const togglePage = (page) => {
    if (isInsightView) return history.push('.');
    if (sort?.[page]) {
      setSort((sort) => {
        delete sort[page];
        return { ...sort };
      });
    } else {
      setSort((sort) => {
        let arr = new Set();
        arr.page = true;
        return { ...sort, [page]: arr };
      });
    }
  };

  const toggleTag = (tag, page) => {
    if (isInsightView) return history.push('.');
    if (sort[page] && sort[page].has(tag)) {
      setSort((sort) => {
        sort?.[page]?.delete(tag);
        if (sort?.[page]?.size === 0 && !sort[page].page) delete sort[page];
        return { ...sort };
      });
    } else {
      setSort((sort) => {
        if (sort[page]) sort[page].add(tag);
        else sort[page] = new Set([tag]);
        return { ...sort };
      });
    }
  };

  return (
    <Grid item container spacing={3} xs={12} md={4} align="center" style={{ display: 'block' }}>
      <Grid item>
        <div className="insights-page-msg">Shortcut access to actionable</div>
        <div style={{ marginBottom: 15 }} className="insights-page-msg">
          insights that impact your portfolio.
        </div>
        <Card
          style={{
            padding: 10,
            backgroundColor: 'rgb(4,205,125)',
            color: 'white',
            borderRadius: '9px'
          }}
        >
          <CardHeader
            style={{
              textAlign: 'start',
              paddingBottom: 5,
              borderBottom: '1px solid #fff',
              fontSize: '5px',
              cursor: 'pointer'
            }}
            title="Liquid"
            onClick={() => togglePage('liquid')}
          />
          <CardContent style={{ textAlign: 'start' }}>
            <div className="insights-page-tags">
              {liquid.map((tag, idx) => (
                <div
                  className="insights-page-tag insights-liq-color"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleTag(tag, 'liquid')}
                  key={idx}
                >
                  {tag}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card
          style={{
            padding: 10,
            backgroundColor: 'rgb(0,174,173)',
            color: 'white',
            borderRadius: '9px'
          }}
        >
          <CardHeader
            style={{
              textAlign: 'start',
              paddingBottom: 5,
              borderBottom: '1px solid #fff',
              fontSize: '5px',
              cursor: 'pointer'
            }}
            title="Personal"
            onClick={() => togglePage('personal')}
          />
          <CardContent style={{ textAlign: 'start' }}>
            <div className="insights-page-tags">
              {personal.map((tag, idx) => (
                <div
                  className="insights-page-tag insights-pers-color"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleTag(tag, 'personal')}
                  key={idx}
                >
                  {tag}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card
          style={{
            padding: 10,
            backgroundColor: 'rgb(34,129,251)',
            color: 'white',
            borderRadius: '9px'
          }}
        >
          <CardHeader
            style={{
              textAlign: 'start',
              paddingBottom: 5,
              borderBottom: '1px solid #fff',
              fontSize: '5px',
              cursor: 'pointer'
            }}
            title="Alternative"
            onClick={() => togglePage('alternative')}
          />
          <CardContent style={{ textAlign: 'start' }}>
            <div className="insights-page-tags">
              {alternative.map((tag, idx) => (
                <div
                  className="insights-page-tag insights-alt-color"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleTag(tag, 'alternative')}
                  key={idx}
                >
                  {tag}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card
          style={{
            padding: 10,
            backgroundColor: 'rgb(45,155,90)',
            color: 'white',
            borderRadius: '9px'
          }}
        >
          <CardHeader
            style={{
              textAlign: 'start',
              paddingBottom: 5,
              borderBottom: '1px solid #fff',
              fontSize: '5px',
              cursor: 'pointer'
            }}
            title="Cash Flow"
            onClick={() => togglePage('expenses')}
          />
          <CardContent style={{ textAlign: 'start' }}>
            <div className="insights-page-tags">
              {expenses.map((tag, idx) => (
                <div
                  className="insights-page-tag insights-exp-color"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleTag(tag, 'expenses')}
                  key={idx}
                >
                  {tag}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card
          style={{
            padding: 10,
            backgroundColor: 'rgb(245,79,89)',
            color: 'white',
            borderRadius: '9px'
          }}
        >
          <CardHeader
            style={{
              textAlign: 'start',
              paddingBottom: 5,
              borderBottom: '1px solid #fff',
              fontSize: '5px',
              cursor: 'pointer'
            }}
            title="Liabilities"
            onClick={() => togglePage('liabilities')}
          />
          <CardContent style={{ textAlign: 'start' }}>
            <div className="insights-page-tags">
              {liabilities.map((tag, idx) => (
                <div
                  className="insights-page-tag insights-lia-color"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleTag(tag, 'liabilities')}
                  key={idx}
                >
                  {tag}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default InsightsTags;
