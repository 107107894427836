import React from 'react';

const Arc = ({ data, index, createArc, colors }) => (
  <g key={index}>
    <path d={createArc(data)} fill={colors(index)} />
    <text transform={`translate(${createArc.centroid(data)})`} textAnchor="middle" alignmentBaseline="middle"></text>
  </g>
);

export default Arc;
