import React from 'react';
import { Link } from 'react-router-dom';

import { useAccountsData } from 'contexts/AccountsContext';

import './Tiles.css';

const MAX_INSIGHTS = 5;

function InsightsTile({ page, style }) {
  const { InsightsData } = useAccountsData();

  const insights = page ? InsightsData.filter((val) => val.page === page) : InsightsData;
  const articles = insights.slice(0, MAX_INSIGHTS).map((insight, i) => {
    return (
      <div
        key={i}
        className={i === InsightsData.length - 1 || i === MAX_INSIGHTS - 1 ? 'last-article' : 'insights-articles'}
      >
        {i + 1}
        {insight.url ? (
          <a target="_blank" className="insights-article" rel="noopener noreferrer" href={insight.url}>
            {insight.title}
          </a>
        ) : (
          <Link className="insights-article" to={`/Insights/${insight.permalink}`}>
            {insight.title}
          </Link>
        )}
      </div>
    );
  });

  return (
    <div className="insights-tile" style={style}>
      <div className="insights-tile-header">
        <Link to="/wealth-hub/insights" className="insights-tile-title">
          Insights
        </Link>
      </div>
      <div className="insights-tile-body">{articles}</div>
    </div>
  );
}

export default InsightsTile;
