import React, { useRef, useState } from 'react';
import Chart from 'react-chartjs-2';

import { makeStyles } from '@material-ui/core';

interface TimeSeriesChartProps {
  tabNames: React.ReactNode[] | string[];
  tabPosition: 'top' | 'left';
  tabOneData: any;
  tabTwoData?: any;
  tabOneChartClick?: any;
  tabOneDateAxis?: string;
  note?: any;
  noteTwo?: any;
  overlayOne?: string;
  overlayTwo?: string;
  tickCallback?: Function;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: 700,
    height: 350,
    paddingBottom: '3rem',
    [theme.breakpoints.down('xs')]: {
      width: 380
    }
  },
  chartContainer: {
    width: '100%',
    height: '80%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: (props: { tabPosition: 'top' | 'left' }) => (props.tabPosition === 'top' ? 'column' : 'row'),
    alignItems: 'center',
    gap: '1rem',
    paddingBottom: '1rem',
    marginBottom: '1rem',
    borderBottom: '2px solid #B3C7D9'
  },
  headerColumn: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important'
    }
  },
  tabs: {
    display: 'flex',
    width: '100%', // TODO: change back to 28rem if using time range
    height: '2rem',
    border: '2px solid rgb(21, 120, 246)',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem'
    }
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    fontSize: '0.8rem',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'rgb(21, 120, 246)',
    fontWeight: 700,
    cursor: 'pointer',
    padding: '10px 20px',
    textDecoration: 'none',
    transition: '0.3s',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:active': {
      textDecoration: 'none'
    },
    '&:focus': {
      outline: 'none',
      textDecoration: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  selectedTab: {
    backgroundColor: 'rgb(21, 120, 246)',
    color: 'white'
  },
  timeRange: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.2rem',
    width: '26rem',
    height: '2rem',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  timeRangeOption: {
    backgroundColor: 'transparent',
    borderRadius: '0.5rem',
    border: 'none',
    cursor: 'pointer',
    padding: '4px 10px',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:active': {
      textDecoration: 'none'
    },
    '&:focus': {
      outline: 'none',
      textDecoration: 'none'
    }
  },
  selectedTimeRangeOption: {
    backgroundColor: '#B3C7D9'
  },
  tooltipContainer: {
    position: 'relative',
    width: '10%',
    height: '100%',
    marginTop: '4rem'
  },
  tooltip: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '0.5rem',
    fontSize: '0.7rem',
    backgroundColor: 'white',
    padding: '0.1rem',
    minWidth: '4.5rem'
  },
  benchmark: {
    backgroundColor: '#eee'
  },
  personal: {
    backgroundColor: 'rgb(21, 120, 246)',
    color: 'white'
  },
  tooltipPercent: {
    fontSize: '0.8rem',
    fontWeight: 800
  },
  note: {
    fontSize: '0.8rem',
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px'
  },
  chartTextOverlay: {
    textAlign: 'center',
    marginTop: '-150px'
  }
}));

export const TimeSeriesChart = ({
  tabNames,
  tabPosition,
  tabOneData,
  tabTwoData,
  tabOneChartClick,
  tabOneDateAxis,
  note,
  noteTwo,
  overlayOne,
  overlayTwo,
  tickCallback
}: TimeSeriesChartProps) => {
  const classes = useStyles({ tabPosition });

  const tabRef0 = useRef<any>(null);
  const tabRef1 = useRef<any>(null);

  const [tab, setTab] = useState<0 | 1>(0);

  const chartOptions = {
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const { usePercent, label } = dataset;
          const value = tooltipItem.yLabel;
          return `${label}: ${value}${usePercent ? '%' : ''}`;
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    legend: {
      display: true,
      position: 'bottom',
      onHover: (event: any) => {
        event.target.style.cursor = 'pointer';
      },
      onLeave: (event: any) => {
        event.target.style.cursor = 'default';
      }
    },
    elements: {
      point: {
        radius: 0
      },
      line: {
        tension: 0
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            padding: 10
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 25,
            padding: 10,
            callback: tickCallback ? tickCallback : (v: any) => v
          },
          gridLines: {
            drawBorder: false,
            drawTicks: false
          }
        }
      ]
    }
  } as any;

  let chartOptionsTab1 =
    tabOneData.datasets.length === 1
      ? {
          legend: { display: false }
        }
      : chartOptions;

  if (tabOneDateAxis) {
    chartOptionsTab1 = {
      ...chartOptionsTab1,
      scales: {
        xAxes: [
          {
            ticks: {
              padding: 10
            },
            gridLines: {
              display: false
            },
            type: 'time',
            time: {
              unit: tabOneDateAxis,
              tooltipFormat: 'MMM D YYYY',
              displayFormats: {
                week: 'MMM D'
              }
            }
          }
        ]
      }
    };
  }

  return (
    <div className={classes.container}>
      <div className={classes.chartContainer}>
        {!!tabTwoData && (
          <div className={`${classes.header} ${classes.headerColumn}`}>
            <div className={classes.tabs}>
              <button
                className={tab === 0 ? `${classes.tab} ${classes.selectedTab}` : classes.tab}
                onClick={() => setTab(0)}
              >
                {tabNames[0]}
              </button>
              <button
                className={tab === 1 ? `${classes.tab} ${classes.selectedTab}` : classes.tab}
                onClick={() => setTab(1)}
              >
                {tabNames[1]}
              </button>
            </div>
          </div>
        )}
        <div
          onClick={tabOneChartClick}
          style={{
            height: '100%',
            display: tab === 0 ? 'block' : 'none',
            cursor: tabOneChartClick ? 'pointer' : 'inherit'
          }}
        >
          <Chart ref={tabRef0} type="line" data={tabOneData} options={chartOptionsTab1} />
          {!!overlayOne && <div className={classes.chartTextOverlay}>{overlayOne}</div>}
        </div>
        {!!tabTwoData && (
          <div style={{ height: '100%', display: tab === 1 ? 'block' : 'none' }}>
            <Chart ref={tabRef1} type="line" data={tabTwoData} options={chartOptions} />
            {!!overlayTwo && <div className={classes.chartTextOverlay}>{overlayTwo}</div>}
          </div>
        )}
        <div className={classes.note} style={{ display: tab === 0 ? 'block' : 'none' }}>
          {note}
        </div>
        <div className={classes.note} style={{ display: tab === 1 ? 'block' : 'none' }}>
          {noteTwo}
        </div>
      </div>
    </div>
  );
};
