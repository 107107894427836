import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, Grid, useMediaQuery, useTheme } from '@material-ui/core';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import ManualEditAccountValue from 'components/forms/ManualEditAccountValue';
import AssetTransactionsTable from 'components/tables/AssetTransactionsTable';
import { formatDollars, formatPercent } from 'components/tables/GenericAssetsTable';
import { useAuth } from 'contexts/AuthContext';
import rotateIcon from 'logos/mobile-rotate-rotation.png';
import { formatMoney } from 'utils/formatters';

import './AssetDetails.css';

const url = process.env.REACT_APP_API_URL;

function RealEstateDetails({ account, open, onClose }) {
  const [transactions, setTransactions] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    async function getTransactions(acct) {
      const ID = await currentUser.getIdToken();
      const response = await fetch(`${url}/api/v1/transactions.json?filter[account_id][eq]=${acct.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      });
      const json = await response.json();
      setTransactions(json['data']);
    }
    getTransactions(account);
  }, [account, currentUser]);

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth={'lg'} onClose={onClose}>
        <DialogTitleX onClose={onClose}>
          {open && isSM && !window.matchMedia('(orientation: landscape)').matches && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                zIndex: 99
              }}
            >
              <img src={rotateIcon} alt="rotate device" style={{ width: '5vw', minWidth: 50 }} />
            </div>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="header-account-title">
                {account.institution.logo_url ? (
                  <img className="detailed-view-logo" src={account.institution.logo_url} alt=" " />
                ) : null}
              </div>
            </Grid>
            <Grid item lg={3} md={5} sm={6} xs={12}>
              <div className="detailed-acct-info">
                <span className="detailed-view-acct" style={{ whiteSpace: 'nowrap' }}>
                  {account.name}
                </span>
                <hr style={{ width: '100%' }} />
              </div>

              {account.logo_url ? <img className="detailed-view-logo" src={account.logo_url} alt="logo" /> : null}
              {account.number_display ? (
                <div className="detailed-ending"> Account ending in: {account.number_display}</div>
              ) : null}
            </Grid>
          </Grid>
        </DialogTitleX>
        <DialogContent className="asset-detail-tile-container">
          <Grid container spacing={2}>
            <Grid item md={7} sm={6} xs={12}>
              <div className="detailed-acct-info">
                <span className="detailed-view-acct">{account.account_type_display}</span>
              </div>

              <div>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <div className="asset-account-value">Amount Invested</div>
                    <div className="asset-account-value">
                      {formatMoney(account.amount_invested ? account.amount_invested : 0)}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    {account.is_manual && <ManualEditAccountValue account={account} />}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <div className="investment-details-view">Date Invested: {account.date}</div>
                    <div className="investment-details-view">
                      Distributions to Date: {formatDollars(account.total_distributions)}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="investment-details-view">
                      Cash on Cash Return (TTM):{' '}
                      {account.amount_invested && account.amount_invested > 0
                        ? formatPercent((account.year_dividends / account.amount_invested) * 100)
                        : ''}
                    </div>
                    <div className="investment-details-view">
                      Committed Capital: {formatDollars(account.committed_capital)}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <AssetTransactionsTable
                account={account}
                accountTransactions={transactions}
                setParentTransactions={setTransactions}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RealEstateDetails;
