import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dialog } from '@material-ui/core';

import { useAccountsData, useAuth } from 'contexts';
import { useScript } from 'utils';

const env =
  process.env.NODE_ENV === 'development' ? 'local.' : process.env.REACT_APP_ENV === 'development' ? 'dev.' : '';
const cssUrl = `https://${env}app.rainbook.com/mstar-styles/mstar-summary.css`;

interface Props {
  institution?: any;
  styles?: any;
  refForClick: any;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'mstar-aggregation-consumer-accountsetup': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'mstar-aggregation-consumer-accountsummary': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export const MorningstarConnect = ({ institution, styles, refForClick }: Props) => {
  useScript('/mstar-aggregation-consumer-accountsummary/mstar-aggregation-consumer-accountsummary.js');
  useScript('/mstar-aggregation-consumer-accountsetup/mstar-aggregation-consumer-accountsetup.js');
  const { getMstarAuth } = useAuth();
  const { reloadDom, getAAData, updateBaaAccounts } = useAccountsData();
  const [showMstar, setShowMstar] = useState(false);
  const [showMstarAccountSetup, setShowMstarAccountSetup] = useState(false);
  const [mstarAuthToken, setMstarAuthToken] = useState({
    jsessionId: '',
    csrfToken: ''
  });

  const mstarSetupRef = useRef<any>();
  const mstarRef = useRef<any>();

  const getMstarAuthAsync = async () => {
    const auth = await getMstarAuth();
    setMstarAuthToken(auth);
  };

  const updateAccounts = useCallback(async () => {
    try {
      await updateBaaAccounts();
    } catch (err) {
      console.log('Failed to update baa accounts: ', err);
      return;
    }
    reloadDom();
    getAAData();
  }, [updateBaaAccounts, reloadDom, getAAData]);

  useEffect(() => {
    if (!mstarAuthToken?.jsessionId || !mstarRef.current || !mstarSetupRef.current) {
      return;
    }
    const dataChangedFn = (event: any) => {
      updateAccounts();
    };
    const userExitFn = (event: any) => {
      setShowMstarAccountSetup(false);
    };
    const accountSetupFn = (event: any) => {
      setShowMstarAccountSetup(true);
      mstarSetupRef.current.setAttribute('route', event.detail);
    };
    const authString = JSON.stringify(mstarAuthToken);
    mstarRef.current.setAttribute('auth-context', authString);
    mstarSetupRef.current.setAttribute('auth-context', authString);

    mstarRef.current.addEventListener('accountSetup', accountSetupFn);
    mstarSetupRef.current.addEventListener('userExit', userExitFn);
    mstarSetupRef.current.addEventListener('dataChangedAsync', dataChangedFn);

    return () => {
      if (mstarRef.current) {
        mstarRef.current.removeEventListener('accountSetup', accountSetupFn);
      }
      if (mstarSetupRef.current) {
        mstarSetupRef.current.removeEventListener('userExit', userExitFn);
        mstarSetupRef.current.removeEventListener('dataChangedAsync', dataChangedFn);
      }
    };
  }, [mstarRef, mstarSetupRef, mstarAuthToken, updateAccounts]);

  const onMorningstarConnect = () => {
    getMstarAuthAsync();
    setShowMstar(true);
  };
  const onClose = () => {
    updateAccounts();
    setShowMstar(false);
    setShowMstarAccountSetup(false);
  };
  const onCloseAccountSetup = () => {
    setShowMstarAccountSetup(false);
  };

  return (
    <>
      <div onClick={() => onMorningstarConnect()} style={styles}>
        {refForClick ? (
          <div ref={refForClick}></div>
        ) : institution?.logo_url ? (
          <img className="link-bank-logo" src={institution.logo_url} alt=" " />
        ) : (
          <div>{institution?.name}</div>
        )}
      </div>
      <Dialog fullWidth={true} open={showMstar} keepMounted onClose={onClose}>
        <mstar-aggregation-consumer-accountsummary override-css-file={cssUrl} ref={mstarRef} />
      </Dialog>
      <Dialog fullWidth={true} open={showMstarAccountSetup} keepMounted onClose={onCloseAccountSetup}>
        <mstar-aggregation-consumer-accountsetup ref={mstarSetupRef} />
      </Dialog>
    </>
  );
};
