import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { Widget } from '@typeform/embed-react';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import { useAccountsData, useAuth } from 'contexts';
import { RatingGauge } from 'components/charts';

interface ActionProps {
  score_attribute: string;
  typeformId?: string;
  dialogTitle?: string;
  score?: number;
}

const useStyles = makeStyles({
  btn: {
    color: '#ffffff',
    backgroundColor: 'rgb(21, 120, 246)',
    borderRadius: '9px',
    width: 140,
    display: 'inline-block',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid rgb(21, 120, 246)',
    fontSize: '12pt',
    padding: 12,
    textAlign: 'center',
    transition: 'all 0.25s ease',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: 'rgb(21, 120, 246)'
    },
    '&.sub': {
      color: 'rgb(21, 120, 246)',
      backgroundColor: '#ffffff',
      borderStyle: 'solid',
      borderColor: 'rgb(21, 120, 246)',
      '&:hover': {
        backgroundColor: 'rgb(21, 120, 246)',
        color: '#ffffff'
      }
    }
  }
});

export const AdvisorScoreAction = ({ score_attribute, typeformId, score }: ActionProps) => {
  const classes = useStyles();
  const { investorUserData, scoreSubmitted, setScoreSubmitted } = useAccountsData();
  const { currentUser } = useAuth();
  const { advisor } = investorUserData;
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const submittedTypeform = () => {
    setOpen(false);
    setScoreSubmitted([...scoreSubmitted, score_attribute]);
  };

  // only load once to avoid iframe reload on state change
  const TypeformWidget = useMemo(() => {
    if (typeformId) {
      return (
        <Widget
          id={typeformId}
          onSubmit={submittedTypeform}
          style={{ width: '100%', height: '100%' }}
          hidden={{
            user_id: currentUser.uid,
            email: currentUser.email,
            advisor_first_name: advisor?.first_name || 'your advisor',
            advisor_last_name: advisor?.last_name || ''
          }}
        />
      );
    }
  }, []);

  return (
    <div style={{textAlign:'center'}}>
      {score ? <RatingGauge white score={score} size="xs" /> :
      scoreSubmitted.indexOf(score_attribute) > -1 ? (
        <div>Processing...</div>
      ) : typeformId ? (
        <>
          <button className={classes.btn + ' sub'} onClick={openDialog}>
            Begin Review
          </button>
          <Dialog fullScreen open={open} fullWidth={true} maxWidth={'lg'} onClose={closeDialog}>
            <DialogTitleX onClose={closeDialog} style={{ backgroundColor: 'rgb(0, 65, 114)' }}></DialogTitleX>
            <DialogContent style={{ backgroundColor: 'rgb(0, 65, 114)' }}>{TypeformWidget}</DialogContent>
          </Dialog>
        </>
      ) : (
        <Link to="/advisor-analyzer/add-account" className={classes.btn}>
          Connect Account
        </Link>
      )}
    </div>
  );
};
