import React from 'react';

import AssetTile from './AssetTile';
import BankTile from './BankTile';

export function tileType(account) {
  switch (account.account_type) {
    case 'depository':
    case 'credit':
    case 'loan':
      return 'bank';
    case 'investment':
      if (
        ['cash isa', 'health reimbursement arrangement', 'hsa', 'profit sharing plan', 'qshr'].indexOf(
          account.account_subtype
        ) > -1
      ) {
        return 'bank';
      }
      if (['fixed annuity', 'prif', 'rlif', 'variable annuity'].indexOf(account.account_subtype) > -1) {
        return 'real-estate';
      }
      return 'asset';
    case 'other':
      if (account.account_subtype === 'collectible' || account.account_subtype === 'sneakers') {
        return 'asset';
      }
      return 'other';
    default:
      return 'bank';
  }
}

export default function AccountTile(acct, assetColorScheme, mainColor, pageId) {
  const tt = tileType(acct);

  return (
    (tt === 'asset' && (
      <AssetTile key={acct.id} acct={acct} assetColorScheme={assetColorScheme} mainColor={mainColor} pageId={pageId} />
    )) ||
    (tt === 'other' && <BankTile key={acct.id} useTransactions={false} acct={acct} pageId={pageId} />) || (
      <BankTile key={acct.id} acct={acct} pageId={pageId} />
    )
  );
}
