import React, { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';



import { ClickAwayListener, makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginRight: '0.5rem',
    [theme.breakpoints.down('md')]: {
      marginRight: 0
    }
  },
  main: {
    position: 'relative',
    height: '100%'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    height: '100%',
    width: '12rem',
    padding: '0 1rem',
    color: 'white',
    backgroundColor: 'transparent',
    fontSize: '1rem',
    fontWeight: 500,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'all 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  },
  dropdown: {
    position: 'absolute',
    top: '3.5rem',
    left: 0,
    width: '12rem',
    paddingBottom: '0.5rem',
    maxHeight: 'calc(100vh - 3.5rem)',
    backgroundColor: 'rgb(38,182,251)',
    boxShadow: '0.3rem 0.3rem 0.3rem rgba(0,0,0,0.20)',
    borderRadius: '0 0 0.5rem 0.5rem',
    zIndex: 10,
    overflow: 'scroll',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100vw'
    }
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 1rem',
    margin: 0,
    textDecoration: 'none',
    fontSize: '0.9rem',
    fontWeight: 500,
    cursor: 'pointer',
    color: 'white',
    backgroundColor: 'transparent',
    userSelect: 'none',
    transition: 'all 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '1rem 2rem'
    }
  }
}));

interface LinksDropdownProps {
  title: string;
  links: LinkItem[];
}

interface LinkItem {
  title: string;
  path?: string;
  subItems?: LinkItem[];
}

export const LinksDropdown = ({ title, links }: LinksDropdownProps) => {
  const classes = useStyles();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const DropdownItem = ({ item, level = 1 }: { item: LinkItem; level?: number }) => {
    const [open, setOpen] = useState(false);

    if (item.subItems) {
      return (
        <>
          <div
            className={classes.item}
            key={item.title}
            onClick={() => setOpen((open) => !open)}
            style={{ paddingLeft: level * 20 }}
          >
            <div>{item.title}</div>
            {open ? <BsChevronUp /> : <BsChevronDown />}
          </div>
          {open && (
            <div>
              {item.subItems.map((subItem: LinkItem) => (
                <DropdownItem key={subItem.title} item={subItem} level={level + 1} />
              ))}
            </div>
          )}
        </>
      );
    } else if (item.path) {
      return (
        <Link
          to={item.path}
          className={classes.item}
          key={item.title}
          style={{ paddingLeft: level * 20 }}
          onClick={() => setDropdownOpen(false)}
        >
          <div>{item.title}</div>
        </Link>
      );
    } else {
      return null;
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
      <div className={classes.container}>
        <div className={classes.main}>
          <button className={classes.button} onClick={() => setDropdownOpen((open) => !open)}>
            {title} {dropdownOpen ? <BsChevronUp /> : <BsChevronDown />}
          </button>
          {dropdownOpen && (
            <div className={classes.dropdown}>
              <>
                {links.map((option) => (
                  <DropdownItem key={option.title} item={option} />
                ))}
              </>
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};