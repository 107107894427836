import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';

import 'pages/wealthHub/ExpenseTracker.css';
import 'primeflex/primeflex.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';

function ExpensesDropdown({ styles, selectedMonth, setSelectedMonth }) {
  const [open, setOpen] = useState(false);

  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const options = [
    {
      label: 'Current month',
      value: 0,
      startStr: moment().startOf('month').format('YYYY-MM-DD'),
      endStr: moment().format('YYYY-MM-DD')
    }, // start of month to today
    {
      label: 'This past month',
      value: 1,
      startStr: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      endStr: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    }, // start of last month to end of last month
    {
      label: 'Year to Date',
      value: 2,
      startStr: moment().startOf('year').format('YYYY-MM-DD'),
      endStr: moment().format('YYYY-MM-DD')
    }, // start of year to today
    {
      label: selectedMonth.value === 3 ? `${selectedMonth.startStr} to ${selectedMonth.endStr}` : 'Custom Range',
      value: 3,
      startStr: moment().startOf('month').format('YYYY-MM-DD'),
      endStr: moment().format('YYYY-MM-DD')
    }
  ];

  const onMonthChange = (e) => {
    if (e.value === 3) {
      setOpen(true);
    } else {
      setSelectedMonth(options.filter((o) => o.value === e.value)[0]);
    }
  };

  return (
    <div className="dropdown-demo">
      <div className="card">
        <Dropdown
          style={styles}
          value={selectedMonth.value}
          options={options}
          onChange={onMonthChange}
          optionLabel="label"
          placeholder="Current month"
        />
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Set custom start and end range</DialogTitle>
          <DialogContent>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              defaultValue={startDate}
              invalidDateMessage=""
              inputVariant="outlined"
              variant="inline"
              format="MM/DD/yyyy"
              margin="dense"
              id={'start'}
              label={'start'}
              value={startDate}
              onChange={(val) => setStartDate(val.format('YYYY-MM-DD'))}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              autoOk
              defaultValue={endDate}
              invalidDateMessage=""
              inputVariant="outlined"
              variant="inline"
              format="MM/DD/yyyy"
              margin="dense"
              id={'start'}
              label={'start'}
              value={endDate}
              onChange={(val) => setEndDate(val.format('YYYY-MM-DD'))}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
                setSelectedMonth({
                  value: 3,
                  startStr: startDate,
                  endStr: endDate
                });
              }}
              color="primary"
              autoFocus
            >
              Set Date Range
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default ExpensesDropdown;
