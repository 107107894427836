import React, { useMemo, useState } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

import { FormControl, InputLabel, ListSubheader, MenuItem, Select, createStyles, makeStyles } from '@material-ui/core';

import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';
import useWindowWidth from 'utils/useWindowWidth';

import './CategoryDropdown.css';
import 'pages/wealthHub/ExpenseTracker.css';
import 'primeflex/primeflex.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&.Mui-disabled': {
        color: 'black',
        opacity: 1
      }
    },
    labelContainer: {
      opacity: 1,
      padding: 0,
      margin: 0
    },
    collapsibleLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 0 10px 30px',
      lineHeight: 'normal',
      backgroundColor: 'transparent',
      color: 'black',
      width: '100%',
      height: '100%'
    }
  })
);

function CategoryDropdown({ transaction, onChange }) {
  const { currentUser } = useAuth();
  const { reloadDom, CategoryDropdownData } = useAccountsData();

  const width = useWindowWidth().width;
  const classes = useStyles();

  const [openAltAccounts, setOpenAltAccounts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    name: null,
    id: ''
  });

  const onCategoryChange = async (item) => {
    let newCategoryId = item.value;
    setSelectedCategory({
      name: item.name,
      id: item.value
    });

    const ID = await currentUser.getIdToken();
    const transaction_ID = parseInt(transaction.id);

    if (item.props?.isCustom) {
      if (item.props.existingId) {
        newCategoryId = item.props.existingId;
      } else {
        const res = await fetch(`${url}/api/v1/categories/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ID}`
          },
          body: JSON.stringify({
            data: {
              type: 'categories',
              attributes: {
                name: item.name,
                account_id: item.props.account,
                category_type: item.props.customSubtype === 'call' ? 'expense' : 'income',
                custom_subtype: item.props.customSubtype
              }
            }
          })
        });

        const json = await res.json();
        const newCustomCat = json['data'];
        newCategoryId = newCustomCat.id;
      }
    }

    await fetch(`${url}/api/v1/transactions/${transaction_ID}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          id: transaction_ID,
          type: 'transactions',
          attributes: { category_id: newCategoryId }
        }
      })
    });

    reloadDom();
    if (onChange) onChange();
  };

  function handleSubHeaderClick(e, item) {
    e.stopPropagation();
    setOpenAltAccounts((prevOpen) =>
      prevOpen.includes(item.altAccountLabel)
        ? prevOpen.filter((a) => a !== item.altAccountLabel)
        : prevOpen.concat([item.altAccountLabel])
    );
  }

  const label = useMemo(() => {
    const wordLen = width > 1195 ? 18 : width > 825 ? 15 : width > 725 ? 13 : 5;
    const subLen = width > 1195 ? 13 : width > 825 ? 10 : width > 725 ? 10 : 7;
    const nameLength = transaction?.category_name?.length;
    return nameLength > wordLen
      ? transaction.category_name.trim().substring(0, subLen) + '...'
      : nameLength <= wordLen
      ? transaction.category_name.trim()
      : 'Select';
  }, [transaction, width]);

  const dropdownWidth = width > 1195 ? 170 : width > 825 ? 110 : width > 725 ? 100 : 70;
  const styles = {
    width: dropdownWidth,
    marginLeft: width < 725 ? -12 : 0
  };

  return (
    <FormControl style={styles} variant="outlined" size="small">
      <InputLabel style={{ fontSize: 12 }}>{selectedCategory.name || label}</InputLabel>
      <Select name={label} label={selectedCategory.name || label} value={''}>
        <MenuItem key="placeholder" style={{ display: 'none' }} />
        {CategoryDropdownData.map((item, i) => {
          return item.altAccountLabel ? (
            <MenuItem disableGutters className={classes.labelContainer} key={i}>
              <ListSubheader
                className={classes.collapsibleLabel}
                disableGutters
                onClick={(e) => handleSubHeaderClick(e, item)}
              >
                {item.name}
                {openAltAccounts.includes(item.altAccountLabel) ? (
                  <AiOutlineUp style={{ marginRight: 10 }} />
                ) : (
                  <AiOutlineDown style={{ marginRight: 10 }} />
                )}
              </ListSubheader>
            </MenuItem>
          ) : !item.props?.isCustom || openAltAccounts.includes(item.props.account) ? (
            <MenuItem
              key={i}
              onClick={() => onCategoryChange(item)}
              className={classes.root}
              disabled={!item.value ? true : false}
              value={item.value}
            >
              {selectedCategory.id === item.value ? selectedCategory.name : item.label}
            </MenuItem>
          ) : null;
        })}
      </Select>
    </FormControl>
  );
}

export default CategoryDropdown;
