import React from 'react';

import { Grid } from '@material-ui/core';

import { plaidDefaultImage } from 'assetData/StaticValues';
import PlaidLinkTile from 'components/integrations/PlaidLinkTile';
import { styles } from 'components/popups/AddAccountsPopup';

export const PlaidAccount = ({ institution, handleMainClose }) => (
  <Grid item xs={6} className="account-grid-item account-grid-item-quarter">
    <PlaidLinkTile
      styles={styles}
      linkCustomizationName={institution.integration_config}
      addAccountIcon={
        <img className="link-bank-logo" src={institution.logo_url ? institution.logo_url : plaidDefaultImage} alt=" " />
      }
      name={institution.logo_url ? null : institution.name}
      key={institution.name}
      page={institution.page}
      handleMainClose={handleMainClose}
    />
  </Grid>
);
