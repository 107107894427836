import { useState } from 'react';

import { Avatar } from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import Zabo from 'zabo-sdk-js';

import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;
//const zaboClientId = process.env.REACT_APP_ZABO_CLIENT_ID;

export const ZaboLink = ({ institution, styles, onAccounts, handleMainClose }) => {
  const { currentUser } = useAuth();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  /*
    useEffect(() => {
        Zabo.init({
            clientId: zaboClientId,
            autoConnect: false,
            env: process.env.REACT_APP_ZABO_ENV
        })
    }, [])
    */

  const onZaboConnect = async (account, zaboInstance) => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/zabo.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({ zabo_account: account })
    });
    const json = await response.json();
    if (json['data']) {
      if (onAccounts) {
        onAccounts(json['data']);
      }
      setSuccess(true);
    } else {
      setError('There was an error adding your account. Please try again later or contact support@rainbook.com');
    }
  };

  const zaboConnect = async (providerName) => {
    try {
      Zabo.instance
        .connect({ provider: providerName })
        .onConnection(onZaboConnect)
        .onError((err) => {
          console.error('Zabo account connection error:', err.message);
          setError('There was an error adding your account. Please try again later or contact support@rainbook.com');
        });
    } catch (err) {
      console.error('Zabo init error:', err.message);
      setError('There was an error adding your account. Please try again later or contact support@rainbook.com');
    }
  };

  return (
    (Zabo.instance && (
      <>
        <ErrorDialog error={error} setError={setError} />
        {success ? <SuccessDialog institution={'Zabo'} handleClose={handleMainClose} /> : null}
        <div onClick={() => zaboConnect(institution?.zabo_provider_name)} style={styles}>
          {institution?.logo_url ? (
            <img className="link-bank-logo" src={institution.logo_url} alt=" " />
          ) : (
            <>
              <Avatar className="link-bank-icon">
                <InsertChartIcon />
              </Avatar>
              {institution?.name || 'More Crypto'}
            </>
          )}
        </div>
      </>
    )) || <>Loading...</>
  );
};
