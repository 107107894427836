export interface NavItem {
  title: string;
  path?: string;
  subItems?: NavItem[];
}

export const AdvisorAnalyzerLinks: NavItem[] = [
  { title: 'Dashboard', path: '/advisor-analyzer' },
  { title: 'Portfolio Performance', path: '/advisor-analyzer/performance-factor' },
  { title: 'Fees', path: '/advisor-analyzer/fees-factor' },
  { title: 'Independence', path: '/advisor-analyzer/credentials-factor' }
];

export const NoAdvisorLinks: NavItem[] = [
  { title: 'Dashboard', path: '/advisor-analyzer' },
  { title: 'Portfolio Performance', path: '/advisor-analyzer/performance-factor' }
]

export const WealthHubLinks: NavItem[] = [
  { title: 'Wealth Hub', path: '/wealth-hub/dashboard' },
  { title: 'Liquid', path: '/wealth-hub/liquid' },
  { title: 'Alternative', path: '/wealth-hub/alternative' },
  { title: 'Personal', path: '/wealth-hub/personal' },
  { title: 'Cash Flow', path: '/wealth-hub/expense-tracker' },
  { title: 'Liabilities', path: '/wealth-hub/liabilities' },
  { title: 'Insights', path: '/wealth-hub/insights' }
];

export const NestedWealthHubLinks: NavItem[] = [
  { title: 'Dashboard', path: '/wealth-hub/dashboard' },
  {
    title: 'Assets',
    subItems: [
      { title: 'Liquid', path: '/wealth-hub/liquid' },
      { title: 'Alternative', path: '/wealth-hub/alternative' },
      { title: 'Personal', path: '/wealth-hub/personal' }
    ]
  },
  { title: 'Cash Flow', path: '/wealth-hub/expense-tracker' },
  { title: 'Liabilities', path: '/wealth-hub/liabilities' },
  { title: 'Insights', path: '/wealth-hub/insights' }
];
