import React from 'react';

import { InvestorLayout } from 'components';
import { useAuth } from 'contexts';

import { LandingAdvisor } from './LandingAdvisor';
import { LandingNoAdvisor } from './LandingNoAdvisor';

export const Landing = () => {
  const { noAdvisor } = useAuth();

  return <InvestorLayout>{noAdvisor ? <LandingNoAdvisor /> : <LandingAdvisor />}</InvestorLayout>;
};
