import { useState } from 'react';

import { Dialog, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import SneakerAssetsPopup from 'components/popups/SneakerAssetsPopup';
import { formatMoney } from 'utils/formatters';

import { formatDollars, formatGainLoss, formatPercent } from './GenericAssetsTable';

export const formatSneakerAssets = (assets) => {
  return assets.map((asset) => {
    let cost_basis = asset.cost_basis || asset.sneaker.retail_price;
    let market_value = asset.price * (asset.quantity || 1);

    const gainLossDollarDiff = (market_value - (cost_basis || 0)) * (asset.quantity || 1);
    const gainLossDollar = formatGainLoss(gainLossDollarDiff, formatDollars);

    const percentDifference = (cost_basis || 0) !== 0 ? (gainLossDollarDiff / cost_basis) * 100 : undefined;
    const gainLossPercent = formatGainLoss(percentDifference, formatPercent);

    const holdingsObject = {
      image_thumbnail: asset.logo_url,
      image: asset.logo_url_large,
      name: asset.name,
      brand: asset.sneaker.brand,
      colorway: asset.sneaker.colorway,
      cost_basis: formatMoney(cost_basis, 0),
      market_value: formatMoney(market_value, 0),
      quantity: asset.quantity || 1,
      'cost/quantity': cost_basis && asset.quantity ? formatDollars(cost_basis / asset.quantity) : '',
      price: formatDollars(asset.price),
      'gain/loss_$': cost_basis ? gainLossDollar : '',
      'gain/loss_%': cost_basis ? gainLossPercent : '',
      sneaker: asset.sneaker,
      _asset: { ...asset, account_subtype: 'collectible' }
    };
    return holdingsObject;
  });
};

export function SneakerAssetsTable({ holdings, is_manual, deleteAsset }) {
  const [show, setShow] = useState(false);
  const [asset, setAsset] = useState({});
  const [showImage, setShowImage] = useState(false);

  const imgCount = holdings.reduce((a, b) => a + (b.image_thumbnail ? 1 : 0), 0);

  return (
    <>
      <DataTable scrollable row className="data-table" value={holdings} rows={holdings.length}>
        <Column
          className="columns"
          field="image_thumbnail"
          header="Item"
          body={(row) =>
            row.image_thumbnail ? (
              <img
                className="holding-logo"
                src={row.image_thumbnail}
                alt=""
                onClick={() => {
                  setShowImage(row);
                }}
              />
            ) : (
              <div style={imgCount > 0 ? { minHeight: '60px' } : {}} />
            )
          }
        ></Column>
        <Column className="columns" field="name" header="Name"></Column>
        <Column className="columns" field="brand" header="Brand"></Column>
        {/* <Column className="columns" field="colorway" header="Colorway" ></Column> */}
        <Column className="columns" field="cost_basis" header="Cost Basis"></Column>
        <Column className="columns" field="price" header="Price/Unit"></Column>
        <Column className="columns" field="quantity" header="Quantity"></Column>
        <Column className="columns" field="market_value" header="Market Value"></Column>
        <Column className="columns" field="gain/loss_$" header="Gain / Loss $"></Column>
        <Column className="columns" field="gain/loss_%" header="Gain / Loss %"></Column>
        <Column
          className="columns"
          header="Edit"
          style={{
            minWidth: '30px',
            width: '30px',
            padding: 'unset'
          }}
          body={(row) => (
            <IconButton
              size="small"
              onClick={() => {
                setAsset(row._asset);
                setShow(true);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        ></Column>
        <Column
          className="columns"
          header="Delete"
          style={{
            minWidth: '30px',
            width: '30px',
            padding: 'unset'
          }}
          body={(row) => (
            <IconButton
              size="small"
              onClick={async () => {
                if (window.confirm('Are you sure you want to delete this asset?')) {
                  deleteAsset(row._asset);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        ></Column>
      </DataTable>

      <Dialog open={showImage} maxWidth={'lg'}>
        <DialogTitleX onClose={() => setShowImage(false)}>{showImage.name || 'Image'}</DialogTitleX>
        <img src={showImage.image} alt="" />
      </Dialog>

      <SneakerAssetsPopup show={show} setShow={setShow} toggle={() => {}} account={asset} edit={true} />
    </>
  );
}
