import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

export default function AutoCompleteInstitutionSelector({ institutions, values, setValues }) {
  const [open, toggleOpen] = useState(false);
  const [localVal, setLocalVal] = useState(null);

  const handleClose = () => {
    setDialogValue({
      name: ''
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    name: ''
  });

  const setInstitutionName = (val) => {
    setLocalVal(val);
    let attrs = { ...values };
    if (val.name) {
      attrs.institution_name = val.name;
      attrs.institution_id = null;
    } else {
      attrs.institution_id = null;
      attrs.institution_name = null;
    }
    setValues(attrs);
  };

  const setInstitutionSelection = (instSel) => {
    setLocalVal(instSel);
    let attrs = { ...values };
    if (instSel) {
      attrs.institution_id = instSel.id;
      attrs.institution_name = null;
    } else {
      attrs.institution_id = null;
      attrs.institution_name = null;
    }
    setValues(attrs);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setInstitutionName({
      name: dialogValue.name
    });

    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={localVal}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue
            });
          } else {
            setInstitutionSelection(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: 'Add New'
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={institutions || []}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name}
        style={{ width: '100%' }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} size="small" label="Select or Enter Investment Firm/Institution" variant="outlined" />
        )}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add a new Institution</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value
                })
              }
              label="name"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
