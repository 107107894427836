import React, { useState } from 'react';

import { Button, Card } from '@material-ui/core';
import * as d3 from 'd3';

import LegendVisualizationData from 'assetData/LegendVisualizationData';
import { StatementIcon, TileIcon } from 'components/assetDetails/PersonalFinancialStatement';
import AccountLineChart from 'components/charts/AccountLineChart';
import HoveredDonut from 'components/charts/HoveredDonut';
import Legend from 'components/charts/Legend';
import AddAccountsTile from 'components/tiles/AddAccountsTile';
import InsightsTile from 'components/tiles/InsightsTile';
import TileContainer from 'components/tiles/TileContainer';
import { useAccountsData } from 'contexts/AccountsContext';
import { changeInValue, formatMoney, rangeToString } from 'utils/formatters';

import { ALTERNATIVE_INVESTMENT_SUBTYPES } from './Alternative';
import { LIQUID_INVESTMENT_SUBTYPES } from './Liquid';

import 'pages/AssetPages.css';

export const isPersonal = (acct) =>
  acct.institution?.page === 'personal' ||
  (!acct.institution?.page &&
    ((acct.account_type === 'depository' && acct.account_subtype === 'hsa') ||
      acct.account_type === 'other' ||
      (acct.account_type === 'investment' &&
        ALTERNATIVE_INVESTMENT_SUBTYPES.indexOf(acct.account_subtype) === -1 &&
        LIQUID_INVESTMENT_SUBTYPES.indexOf(acct.account_subtype) === -1)));

export const personalColorScheme = ['rgb(123,255,242)', 'rgb(0,211,195)', 'rgb(0,132,125)', 'rgb(0,99,92)'];

export const personalMainColor = 'rgb(0,165,163)';

function Personal() {
  const [view, setView] = useState('tile');

  const { PersAccounts, PersData, PersIds, InsightsData, persChange, setPersChange } = useAccountsData();

  const personalTotal = PersData.reduce(function (sum = 0, current) {
    return sum + current.value;
  }, 0);

  const labels = PersData.map((hash) => {
    return hash['label'];
  });

  const colorScheme = LegendVisualizationData(PersData);
  const colors = d3.scaleOrdinal().range(colorScheme);
  const page = 'personalAsset';

  return (
    <div>
      <TileContainer
        pageId="personal"
        rightAccounts={PersAccounts}
        showPFS={view !== 'tile'}
        insights={InsightsData.filter((i) => i.page === 'personal')}
        assetColorScheme={personalColorScheme}
        mainColor={personalMainColor}
        leftContent={
          <>
            <Card className="asset-totals-container" style={{ borderRadius: '9px' }}>
              <div className="liquid-asset-header">
                <h3 className="asset-title">Personal Assets: {formatMoney(personalTotal, 0)}</h3>
                {persChange && (
                  <p className="change-in-value" style={changeInValue(persChange.value)}>
                    {formatMoney(persChange.value, 0)}{' '}
                    <span style={{ color: 'lightslategray' }}>{rangeToString(persChange.range)}</span>
                  </p>
                )}
              </div>
              <div
                className="liquid-asset-doughnut-and-keys"
                style={personalTotal === 0 ? { marginBottom: 20 } : { marginBottom: 0 }}
              >
                <div className="liquid-asset-doughnut">
                  <HoveredDonut
                    showTotal={false}
                    page={'asset'}
                    donutData={PersData}
                    innerRadius={55}
                    outerRadius={65}
                    colorScheme={personalColorScheme}
                    width={150}
                    height={150}
                    totals={personalTotal}
                    AccountName={'Personal'}
                  />
                </div>
                <div className="liquid-asset-doughnut-keys">
                  <svg className="liquid-doughnut-key-svg">
                    {PersData.length > 0 && (
                      <g transform="translate(0,15)">
                        <Legend
                          data={PersData}
                          labels={labels}
                          colors={colors}
                          colorScheme={personalColorScheme}
                          page={page}
                        />
                      </g>
                    )}
                  </svg>
                </div>
              </div>
              {PersIds.length > 0 ? (
                <AccountLineChart
                  AssetsTotal={true}
                  colors={personalMainColor}
                  accountsIds={PersIds.join()}
                  change={persChange}
                  setChange={setPersChange}
                />
              ) : null}
              <Button
                startIcon={view === 'tile' ? <StatementIcon /> : <TileIcon />}
                onClick={() => setView(view === 'tile' ? 'pfs' : 'tile')}
              >
                {view === 'tile' ? 'Statement View' : 'Tile View'}
              </Button>
            </Card>
            <InsightsTile style={{ marginBottom: 20 }} page="personal" />
            <Card style={{ borderRadius: '9px' }}>
              {' '}
              <AddAccountsTile />{' '}
            </Card>
          </>
        }
      />
    </div>
  );
}

export default Personal;
