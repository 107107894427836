import React from 'react';
import { FaExclamationCircle, FaRegQuestionCircle } from 'react-icons/fa';

import { Tooltip } from '@material-ui/core';

interface TooltipIconProps {
  size?: string | number;
  title?: React.ReactNode;
  alert?: boolean;
}

export const TooltipIcon = (props: TooltipIconProps) => {
  return props.title ? (
    <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={props.title || ''}>
      <span style={{ borderBottomWidth: '0px' }}>
        {props.alert ? (
          <FaExclamationCircle style={{ color: 'rgb(249,196,0)' }} size={props.size || 20} />
        ) : (
          <FaRegQuestionCircle size={props.size || 20} />
        )}
      </span>
    </Tooltip>
  ) : (
    <></>
  );
};
