import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { isNum } from 'components/tables/GenericAssetsTable';

import { currencyField, textField } from './common';

import './forms.css';

export const CollectibleAssetForm = ({ formSubmit, account, edit }) => {
  const [logoFile, setLogoFile] = useState({});

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: edit
      ? {
          ...account
        }
      : {},
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'Name is required.';
      }

      return errors;
    },
    onSubmit: async (data) => {
      if (isNum(data['price']) && isNum(data['quantity'])) {
        data['value'] = Number(data['price']) * Number(data['quantity']);
      }
      if (isNum(data['cost_basis']) && isNum(data['quantity'])) {
        data['cost_basis'] = Number(data['cost_basis']) * Number(data['quantity']);
      }
      await formSubmit(data, account, logoFile);
    }
  });

  const addFile = (event) => {
    setLogoFile({ name: event.target.id, file: event.target.files[0] });
  };

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'name', 'Asset Name')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'quantity', 'Quantity')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'price', 'Price/unit')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'cost_basis', 'Cost Basis/unit')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <span className="p-field">
            <label htmlFor="logo_file" style={{ color: '#555' }}>
              Asset Image
            </label>
            <input id="logo_file" onChange={addFile} type="file" />
          </span>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" label="Submit" className="p-mt-2" />
        </Grid>
      </Grid>
    </form>
  );
};
