import React, { useState } from 'react';

import { Avatar, DialogActions } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import Home from '@material-ui/icons/Home';
import { Button } from 'primereact/button';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import PlacesAutocomplete from 'components/forms/PlacesAutoComplete';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

export const ZillowLink = ({ name, institution, styles, onAccounts, handleMainClose }) => {
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  const onSubmit = async () => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/zillow.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          address: address
        }
      })
    });
    const json = await response.json();
    if (json['data'] && onAccounts) {
      onAccounts(json['data']);
      setSuccess(true);
    } else {
      if (response.status === 404) {
        setError(`No Zestimate available, please add Home from the Manual Account section.`);
      } else {
        setError(`We were unable to add this property, please add Home from the Manual Account section.`);
      }
    }
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
    setAddress('');
  };

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success ? (
        <SuccessDialog
          page={'personal'}
          institution={'Home'}
          handleClose={handleMainClose}
          onClose={() => setSuccess(false)}
        />
      ) : null}
      <div style={styles} onClick={(e) => setOpen(true)}>
        {institution?.logo_url ? (
          <img style={{ paddingBottom: 5 }} className="link-bank-logo" src={institution.logo_url} alt=" " />
        ) : (
          <>
            <Avatar className="link-bank-icon">
              <Home />
            </Avatar>
            <div>{name}</div>
          </>
        )}
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitleX onClose={handleClose}>Add Home Address for Zestimate Value</DialogTitleX>
        <DialogContent>
          <PlacesAutocomplete address={address} setAddress={setAddress} />
        </DialogContent>
        <DialogActions>
          <Button label="Submit" icon="pi pi-check" onClick={onSubmit} />
        </DialogActions>
      </Dialog>
    </>
  );
};
