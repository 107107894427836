import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { formatDollars, formatPercent } from 'components/tables/GenericAssetsTable';

export const formatAdvisorAssets = (assets) => {
  return assets.map((asset) => {
    let dayChangeAmount = null;
    let dayChangePercent = null;
    let diff = null;
    if (asset.stock_change?.length && asset.stock_change[0].price) {
      diff = asset.stock_change[1].price - asset.stock_change[0].price;
      dayChangeAmount = formatDollars(diff * asset.quantity);
      dayChangePercent = formatPercent((diff / asset.stock_change[0].price) * 100);
    }
    const holdingsObject = {
      name: asset.ticker_symbol,
      description: asset.name,
      shares: asset.quantity,
      price: formatDollars(asset.price),
      market_value: formatDollars(asset.value),
      dayChangeAmount,
      dayChangePercent,
      diff
    };
    return holdingsObject;
  });
};

export function AdvisorAssetsTable({ holdings }) {
  return (
    <>
      <DataTable scrollable className="data-table" value={holdings} rows={holdings.length}>
        <Column className="columns" field="name" header="Name"></Column>
        <Column className="columns" field="description" header="Description"></Column>
        <Column className="columns" field="shares" header="Shares"></Column>
        <Column className="columns" field="price" header="Price"></Column>
        <Column className="columns" field="market_value" header="Market Value"></Column>
      </DataTable>
    </>
  );
}
