import { useState } from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import ManualAssetsPopup from 'components/popups/ManualAssetsPopup';

import { formatDollars, formatGainLoss, formatPercent } from './GenericAssetsTable';

export const formatPrivateEquityAssets = (assets) => {
  return assets.map((asset) => {
    const gainLossDollarDiff = asset.value - (asset.cost_basis || 0);
    const gainLossDollar = formatGainLoss(gainLossDollarDiff, formatDollars);

    const percentDifference = (asset.cost_basis || 0) !== 0 ? (gainLossDollarDiff / asset.cost_basis) * 100 : undefined;
    const gainLossPercent = formatGainLoss(percentDifference, formatPercent);

    const holdingsObject = {
      date: asset.date,
      name: asset.name,
      quantity: asset.quantity,
      'cost/share': asset.cost_basis && asset.quantity ? formatDollars(asset.cost_basis / asset.quantity) : '',
      price: formatDollars(asset.price),
      value: formatDollars(asset.value),
      'gain/loss_$': asset.cost_basis ? gainLossDollar : '',
      'gain/loss_%': asset.cost_basis ? gainLossPercent : '',
      _asset: { ...asset, account_subtype: 'private equity' }
    };
    return holdingsObject;
  });
};

export function PrivateEquityAssetsTable({ holdings, is_manual, deleteAsset }) {
  const [show, setShow] = useState(false);
  const [asset, setAsset] = useState({});

  return (
    <>
      <DataTable scrollable className="data-table" value={holdings} rows={holdings.length}>
        <Column className="columns" field="date" header="Investment Date"></Column>
        <Column className="columns" field="name" header="Investment"></Column>
        <Column className="columns" field="quantity" header="Shares"></Column>
        <Column className="columns" field="cost/share" header="Cost/Share"></Column>
        <Column className="columns" field="price" header="Price"></Column>
        <Column className="columns" field="value" header="Value"></Column>
        <Column className="columns" field="gain/loss_$" header="Gain / Loss $"></Column>
        <Column className="columns" field="gain/loss_%" header="Gain / Loss %"></Column>
        {is_manual && (
          <Column
            className="columns"
            header="Edit"
            body={(row) => (
              <IconButton
                size="small"
                onClick={() => {
                  setAsset(row._asset);
                  setShow(true);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          ></Column>
        )}
        {is_manual && (
          <Column
            className="columns"
            header="Delete"
            body={(row) => (
              <IconButton
                size="small"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this asset?')) {
                    deleteAsset(row._asset);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          ></Column>
        )}
      </DataTable>

      <ManualAssetsPopup show={show} setShow={setShow} toggle={() => {}} account={asset} edit={true} />
    </>
  );
}
