import React from 'react';
import HubspotForm from 'react-hubspot-form';

import { CircularProgress, Dialog, makeStyles } from '@material-ui/core';

import DialogTitleX from './DialogTitleX';

interface DialogProps {
  open: boolean;
  onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
}

const useStyles = makeStyles((theme) => ({
  supportLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
export const SupportDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth style={{ zIndex: 2000 }}>
      <DialogTitleX onClose={onClose} />
      <div style={{ padding: 20 }}>
        <HubspotForm
          portalId="9455422"
          formId="c75a091b-58e5-4625-9cfc-4d37d25da9ab"
          onSubmit={onClose}
          loading={
            <div className={classes.supportLoading}>
              <CircularProgress />
            </div>
          }
          inlineMessage="Thanks! We'll get back to you."
        />
      </div>
    </Dialog>
  );
};
