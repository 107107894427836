import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import { InsightBody } from 'pages/wealthHub/InsightView';

import './Tiles.css';

const tile = (insight) => (
  <div className="insights-tile" style={{ height: 185, marginBottom: 0 }}>
    <div className="insights-tile-header">
      <div className="insights-tile-title">{insight.title}</div>
    </div>
    <div className="insights-tile-body">{insight.blurb}</div>
  </div>
);

const InsightTile = ({ insight }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const showArticle = () => {
    async function getContent(permalink) {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/insight_content/${permalink}`, {
        method: 'GET'
      });
      const content = await response.text();
      setLoading(false);
      setContent(content);
    }
    setOpen(true);
    if (!content) {
      setLoading(true);
      getContent(insight.permalink);
    }
  };

  return insight.url ? (
    <a target="_blank" style={{ color: 'white' }} rel="noopener noreferrer" href={insight.url}>
      {tile(insight)}
    </a>
  ) : insight.has_content ? (
    <>
      <div style={{ cursor: 'pointer' }} onClick={showArticle}>
        {tile(insight)}
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitleX onClose={handleClose}>{insight.title}</DialogTitleX>
        <DialogContent style={{ width: '100%' }}>
          <DialogContentText>
            <InsightBody loading={loading} article={insight} content={content} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <Link to={`/wealth-hub/insights/${insight.permalink}`}>{tile(insight)}</Link>
  );
};

export default InsightTile;
