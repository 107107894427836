import { useState } from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import ManualAssetsPopup from 'components/popups/ManualAssetsPopup';

export function formatDollars(num, noCents) {
  if (isNum(num)) {
    var opts = {
      style: 'currency',
      currency: 'USD'
    };
    if (noCents) {
      opts['maximumFractionDigits'] = 0;
    }
    return parseFloat(num).toLocaleString('en-US', opts).replace('.00', '');
  }
  return '';
}
export function formatPercent(num) {
  if (isNum(num)) {
    return `${formatNum(num)}%`;
  }
  return '';
}
export function formatAge(date, onlyYears) {
  if (!date) return '';
  var a = moment(Date.new);
  var b = moment(date, 'YYYY-MM-DD');
  var years = a.diff(b, 'year');
  b.add(years, 'years');
  var months = a.diff(b, 'months');
  b.add(months, 'months');
  if (years && months && !onlyYears) return `${years} Years, ${months} Months`;
  if (years) return `${years} Years`;
  if (months) return `${months} Months`;
  return '';
}
export function formatNum(num) {
  if (isNum(num)) {
    return `${parseFloat(num).toLocaleString('en-US', {
      maximumFractionDigits: 2
    })}`;
  }
  return '';
}
export function formatGainLoss(num, fn) {
  if (isNum(num)) {
    const sym = num > 0 ? '+' : num < 0 ? '-' : '';
    return `${sym}${fn(Math.abs(num))}`;
  }
  return num;
}
export function isNum(num) {
  return !isNaN(num) && !isNaN(parseFloat(num));
}
export function formatDate(date) {
  if (date) {
    return moment(date).format('MM/DD/YYYY');
  }
  return '';
}

export const formatGenericAssets = (assets) => {
  return assets.map((asset) => {
    const gainLossDollarDiff = asset.value - (asset.cost_basis || 0);
    const gainLossDollar = formatGainLoss(gainLossDollarDiff, formatDollars);

    const percentDifference = (asset.cost_basis || 0) !== 0 ? (gainLossDollarDiff / asset.cost_basis) * 100 : undefined;
    const gainLossPercent = formatGainLoss(percentDifference, formatPercent);

    const holdingsObject = {
      name: asset.ticker_symbol,
      description: asset.name,
      shares: asset.quantity,
      'cost/share': asset.cost_basis && asset.quantity ? formatDollars(asset.cost_basis / asset.quantity) : '',
      price: formatDollars(asset.price),
      market_value: formatDollars(asset.value),
      'gain/loss_$': asset.cost_basis ? gainLossDollar : '',
      'gain/loss_%': asset.cost_basis ? gainLossPercent : '',
      _asset: asset
    };
    return holdingsObject;
  });
};

export function GenericAssetsTable({ holdings, is_manual, deleteAsset }) {
  const [show, setShow] = useState(false);
  const [asset, setAsset] = useState({});

  return (
    <>
      <DataTable
        scrollable
        className="data-table"
        value={holdings}
        sortField="category"
        sortOrder={-1}
        rows={holdings.length}
      >
        <Column className="columns" field="name" header="Name"></Column>
        <Column className="columns" field="description" header="Description"></Column>
        <Column className="columns" field="shares" header="Shares"></Column>
        <Column className="columns" field="cost/share" header="Cost/Share"></Column>
        <Column className="columns" field="price" header="Price"></Column>
        <Column className="columns" field="market_value" header="Market Value"></Column>
        <Column className="columns" field="gain/loss_$" header="Gain / Loss $"></Column>
        <Column className="columns" field="gain/loss_%" header="Gain / Loss %"></Column>
        {is_manual && (
          <Column
            className="columns"
            header="Edit"
            body={(row) => (
              <IconButton
                size="small"
                onClick={() => {
                  setAsset(row._asset);
                  setShow(true);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          ></Column>
        )}
        {is_manual && (
          <Column
            className="columns"
            header="Delete"
            body={(row) => (
              <IconButton
                size="small"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this asset?')) {
                    deleteAsset(row._asset);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          ></Column>
        )}
      </DataTable>

      <ManualAssetsPopup show={show} setShow={setShow} toggle={() => {}} account={asset} edit={true} />
    </>
  );
}
