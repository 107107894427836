import { DialogTitle, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

export default function DialogTitleX({ onClose, ...props }) {
  const classes = useStyles();
  const xOnly = !props.children ? { borderBottom: 'none', paddingBottom: 0 } : {};
  const style = {
    ...{ borderBottom: 'solid 1px #eee', paddingRight: '50px' },
    ...xOnly,
    ...props.style
  };
  return (
    <DialogTitle variant="h6" style={style}>
      {props.children}
      <IconButton aria-label="close" style={{ zIndex: 1 }} className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}
