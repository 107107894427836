import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { CircularProgress, Grid, Hidden } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { FaFooter } from 'components/layouts';
import VerifyEmailPopup from 'components/popups/VerifyEmailPopup';
import { useAuth } from 'contexts/AuthContext';
import aaLogo from 'logos/AdvisorAnalyzerLogoBlue.png';
import logo from 'logos/RainbookBlueLogo.png';
import validate from 'utils/ValidateSignUp';

import { auth } from '../../firebase';
import SsoButtons from './SsoButtons';

import './SignUp.css';
import 'components/popups/VerifyEmailPopup.css';

const SignUp = () => {
  const history = useHistory();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  // const phoneRef = useRef()
  const passwordRef = useRef();
  const agreeRef = useRef();
  const { signUp, sendVerificationEmail } = useAuth();

  useEffect(() => {
    auth
      .getRedirectResult()
      .then((result) => {
        if (result && result.user) {
          history.push('/start');
        }
      })
      .catch((e) => {
        console.error('RedirectResult: ', e.message);
        setErrors({ sso: e.message });
      });
    document.title = 'Sign up for Rainbook';
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const userData = await signUp(emailRef.current.value, passwordRef.current.value);
        if (userData?.user) {
          const { user } = userData;
          await user.updateProfile({
            displayName: wholeName,
            photoURL: ''
          });
          await sendVerificationEmail(user);
          setSendEmail(true);
        }
      } catch (e) {
        setErrors({ password: e.message });
      } finally {
        setLoading(false);
      }
    }
  }

  const wholeName = values.firstName + ' ' + values.lastName;

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    let v = value;
    if (name === 'agree') v = checked;
    setValues({
      ...values,
      [name]: v
    });
  };

  const okEmailClick = () => {
    history.push('/start');
  };

  const CustomListItem = ({ primary, secondary }) => (
    <ListItem>
      <ListItemIcon>
        <CheckCircleIcon htmlColor="rgb(0, 120, 245)" />
      </ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );

  return (
    <div>
      {sendEmail ? <VerifyEmailPopup newAccount={true} okClick={okEmailClick} /> : null}
      <Grid container className="sign-up-wrapper">
        <Grid item xs={12} md={6} className="sign-up-left">
          <div className="sign-up-logo-container">
            <a href="https://www.rainbook.com">
              <img
                style={{
                  width: 180,
                  height: 'auto'
                }}
                className="logo-header"
                src={logo}
                alt="rainb%k"
              />
            </a>
          </div>
          <div className="sign-up-form-content">
            <form className="sign-up-form" onSubmit={handleSubmit} noValidate>
              <h2 className="sign-up-sub-header">
                Create your Rainbook account to monitor your fees or receive a free consultation.
              </h2>
              <div className="sign-up-form-input">
                <div>
                  <input
                    type="text"
                    name="firstName"
                    className="sign-up-form-input-box"
                    value={values.firstName || ''}
                    ref={firstNameRef}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  {errors.firstName && <p className="sign-up-error-msg">{errors.firstName}</p>}
                </div>
              </div>
              <div className="sign-up-form-input">
                <div>
                  <input
                    type="text"
                    name="lastName"
                    className="sign-up-form-input-box"
                    value={values.lastName || ''}
                    ref={lastNameRef}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                  {errors.lastName && <p className="sign-up-error-msg">{errors.lastName}</p>}
                </div>
              </div>
              <div className="sign-up-form-input">
                <div>
                  <input
                    type="email"
                    name="email"
                    className="sign-up-form-input-box"
                    value={values.email || ''}
                    ref={emailRef}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  {errors.email && <p className="sign-up-error-msg">{errors.email}</p>}
                </div>
              </div>
              <div className="sign-up-form-input">
                <div>
                  <input
                    type="password"
                    name="password"
                    className="sign-up-form-input-box"
                    value={values.password || ''}
                    ref={passwordRef}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  {errors.password && <p className="sign-up-error-msg">{errors.password}</p>}
                </div>
              </div>
              <div className="sign-up-form-input">
                <div className="log-in-msg-text">
                  {errors.agree && (
                    <span style={{ display: 'inline-block' }} className="sign-up-error-msg">
                      {' '}
                      {errors.agree}
                    </span>
                  )}
                  <input
                    type="checkbox"
                    name="agree"
                    style={{ marginRight: 5 }}
                    value={values.agree}
                    ref={agreeRef}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <span>I agree to the </span>
                  <a href="https://www.rainbook.com/terms-of-use" target="_blank" rel="noreferrer">
                    Terms of Use
                  </a>
                  <span> and </span>
                  <a href="https://www.rainbook.com/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
              <button
                disabled={loading}
                className="sign-up-form-input-btn"
                style={{ opacity: loading ? 0.5 : 1 }}
                type="submit"
              >
                {loading ? <CircularProgress color="primary" size={25} /> : 'Create Account'}
              </button>
              <div className="sso-area">
                <div style={{ padding: '0px 0px 10px 0px' }}>-or-</div>
                {errors.sso && (
                  <span style={{ display: 'inline-block' }} className="sign-up-error-msg">
                    {' '}
                    {errors.sso}
                  </span>
                )}
                <SsoButtons isSignUp />
              </div>
              <div className="log-in-msg">
                <div className="go-to-log-in">
                  <div className="log-in-msg-text">Already have an account?</div>
                  <Link className="log-in-msg-button" to="/login">
                    Log In Here
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={6} className="sign-up-right">
            <Typography className="sign-up-right-title" variant="h5">
              Maximizing advisor value through radical transparency.
            </Typography>
            <List dense={true}>
              <CustomListItem
                primary="See your fees for free."
                secondary="Receive a detailed breakdown on the fees you are paying,
                  including those invisible expense ratio fees. Compare against the Rainbook fee benchmark."
              />
              <CustomListItem
                primary="Keep tabs on your portfolio performance and allocation."
                secondary="Visualize how your portfolio is performing against the comparable Morningstar Targeted Allocation Index."
              />
              <CustomListItem
                primary="Ability to upgrade your advisor while lowering your overall fees. *"
                secondary="Access to an elite fiduciary advisor (not a broker!) on the Rainbook Advisor Network who is a CFP®️️️️️️️️️️️️️️️️️️️️, CFA or CPA. You make the first move, no spam policy."
              />
            </List>
            <img src={aaLogo} alt="advisor-analyzer" style={{ width: '300px' }} />
          </Grid>
          <FaFooter additionalTextTop="* Each client's financial circumstances are unique, influencing the fee agreed upon between you and the advisor. Rainbook does not set fees; they are determined solely by mutual agreement between you and the advisor." />
        </Hidden>
      </Grid>
    </div>
  );
};

export default SignUp;
