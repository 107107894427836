import React from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

<script src="https://www.gstatic.com/firebasejs/8.2.4/firebase.js"></script>;

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: 'secure-portal-298618.appspot.com',
  projectId: 'secure-portal-298618',
  appId: '1:489580316775:web:6b9626ac1ae4b6626dacc2'
});

export const auth = app.auth();
export const storage = app.storage();
export default app;
