import React, { useMemo, useRef } from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { Tooltip, makeStyles } from '@material-ui/core';
import * as d3 from 'd3';

import {
  AdvisorMatchThinCard,
  InsightCard,
  InvestorLayout,
  MorningstarConnect,
  NewAccountSteps,
  ProcessingMessage,
  ScoreMeter,
  TimeSeriesChart,
  TooltipIcon,
  formatDollars,
  formatPercent
} from 'components';
import HoveredDonut from 'components/charts/HoveredDonut';
import LegendList from 'components/charts/LegendList';
import { useAccountsData } from 'contexts';
import { aaHealthColor } from 'utils';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '3.5rem 0 2.5rem',
    borderBottom: '1px solid',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '1.5rem 0 1.5rem'
    }
  },
  title: {
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem'
    }
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    flexDirection: 'row'
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    padding: '0.5rem 0',
    fontSize: '14px'
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: 'linear-gradient(to bottom left, rgb(21, 120, 246), rgb(42,191,252))'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
    width: '100%',
    height: '100%',
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  firstRow: {
    gap: '1.5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
    paddingBottom: '1.5rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  colorIndicator: {
    width: '100%',
    height: '0.5rem',
    borderRadius: '1rem 1rem 0 0'
  },
  cardSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem 0 2rem'
  },
  cardFull: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    paddingBottom: '2rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  healthIcon: {
    marginTop: '0.2rem'
  },
  donutContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleWhite: {
    width: '100%',
    color: 'white'
  },
  table: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '1rem',
    width: '100%',
    margin: '1rem'
  },
  tableLabel: {
    fontWeight: 'bold',
    '& span': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'dotted'
    }
  },
  cell: {
    textAlign: 'center'
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30%'
  },
  buttonArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
    cursor: 'pointer',
    padding: '0.8rem 1.5rem',
    fontWeight: 700,
    margin: '1rem'
  }
}));

const labelMapping: { [key: string]: string } = { exr: 'Expense Ratio', txn: 'Misc', fld: 'Fund Load', aum: 'AUM' };

export const FeesFactor = () => {
  const classes = useStyles();
  const history = useHistory();
  const mstarRef = useRef<HTMLInputElement>(null);
  const { baaUserData, investorUserData, BaaAccounts, scoreSubmitted, feesTimeseriesData, investorTimeseriesData } =
    useAccountsData();
  const { baa_investor_credentials } = baaUserData;
  const { score_fees, statement_in_progress, stats_breakdown, issue_types } = investorUserData;

  const issueTypes: string[] = issue_types || [];
  const isDataIssue = issueTypes.includes('data');
  const isPending = !score_fees && (BaaAccounts.length > 0 || scoreSubmitted.includes('score_fees'));

  const scoreColor = useMemo(() => aaHealthColor(score_fees), [score_fees]);

  const percentChange = useMemo(() => {
    const { data } = feesTimeseriesData?.datasets?.[0];
    const last = data[data.length - 1];
    const secondToLast = data[data.length - 2];

    return last - secondToLast;
  }, [feesTimeseriesData]);

  const feesBreakdown = useMemo(() => {
    if (!stats_breakdown) return [];
    return Object.keys(stats_breakdown?.fees)
      .map((k) => {
        const label = labelMapping[k];
        return {
          AssetType: label,
          label,
          value: Number(stats_breakdown?.fees[k].amount.toFixed(2))
        };
      })
      .filter((a) => a);
  }, [stats_breakdown]);

  const labels = feesBreakdown.map((a) => a?.label || '');
  const colorScheme = ['rgb(0,65,114)', 'rgb(14,92,178)', 'rgb(0,120,243)', 'rgb(0,176,255)', 'rgb(0,212,255)'];
  const colors = d3.scaleOrdinal().range(colorScheme);

  const currentDate = new Date().toLocaleDateString();

  const annualFees = stats_breakdown?.total_fees || 0;
  const annualFeePercent = stats_breakdown?.total_fee_percent;

  const NoScore = <>{BaaAccounts.length > 0 && 'Collecting data... check back soon.'}</>;

  const connectStep = baa_investor_credentials?.length
    ? {
        title: 'Connect Account or Upload Statements',
        isComplete: score_fees,
        isPending: BaaAccounts.length > 0,
        actionText: 'Fix Connection Issues',
        score_attribute: 'score_fees',
        action: () => mstarRef.current?.click()
      }
    : {
        title: 'Connect Account or Upload Statements',
        isComplete: score_fees,
        isPending: BaaAccounts.length > 0,
        score_attribute: 'score_fees',
        actionText: 'Connect or Upload',
        action: () => history.push('/advisor-analyzer/add-account')
      };

  return (
    <InvestorLayout>
      <div className={classes.header}>
        <h1 className={classes.title}>Total Fees</h1>
      </div>
      <div className={classes.subHeader}>
        <span>Calculated daily</span>
        <span>as of {currentDate}</span>
      </div>
      <div className={classes.section}>
        <div className={`${classes.row} ${classes.firstRow}`}>
          <ScoreMeter
            value={score_fees}
            display={formatPercent(annualFeePercent)}
            change={percentChange}
            changeInverted
            noScore={NoScore}
            tooltip={
              'Your RAIN report fee percentage is calculated by dividing your total dollar amount of fees by your current portfolio value.  Based on the value of your account and fee percentage, the rating can fall into categories of Good, Fair, or Poor.'
            }
            useFixedPosition
            mstarRef={mstarRef}
          />
          {statement_in_progress || isDataIssue || isPending ? (
            <ProcessingMessage {...{ statement_in_progress, isDataIssue }} />
          ) : score_fees ? (
            <>
              <TimeSeriesChart
                tickCallback={formatPercent}
                tabNames={['Your Fees']}
                tabPosition="top"
                tabOneData={feesTimeseriesData}
                note={
                  <>
                    Fees & Rainbook Fee Benchmark information.{' '}
                    <TooltipIcon
                      size={12}
                      title={
                        <>
                          <div>
                            The history of your RAIN report fee percentage is calculated by dividing your total dollar
                            amount of fees by your current portfolio value.
                          </div>
                          <p>
                            The Rainbook fee benchmark is linked to the portfolio value of your account, with fees
                            generally decreasing as the account size grows. Our proprietary fee benchmark draws insights
                            from the analysis of portfolios by the Advisor Analyzer™ totaling hundreds of millions of
                            dollars, contributing to its formulation.
                          </p>
                        </>
                      }
                    />
                  </>
                }
                overlayOne={!investorTimeseriesData ? 'Loading...' : undefined}
              />
              <div className={classes.donutContainer}>
                <HoveredDonut
                  showTotal={true}
                  donutData={feesBreakdown}
                  colorScheme={colorScheme}
                  width={120}
                  height={120}
                  totals={annualFees}
                  page={'asset'}
                  AccountName={'Your Portfolio'}
                  title={`Estimated Annualized Fees: ${formatDollars(annualFees, true)}`}
                />
                <LegendList data={feesBreakdown} labels={labels} colors={colors} colorScheme={colorScheme} baa={true} />
              </div>
            </>
          ) : (
            <NewAccountSteps header="Complete the following to analyze your portfolio:" steps={[connectStep]} />
          )}
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.section} id="rbaa-insights">
          {(issueTypes.includes('aum_fees_higher') || issueTypes.includes('bundled')) && (
            <div className={classes.row}>
              <AdvisorMatchThinCard issueTypes={issueTypes.filter((x) => ['aum_fees_higher', 'bundled'].includes(x))} />
            </div>
          )}
          <h2 className={classes.titleWhite}>Fee Breakdown</h2>
          <div className={classes.row}>
            <div className={classes.card}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.cardSection}>
                <h3 className={classes.subTitle} style={{ marginTop: '2rem' }}>
                  <div>
                    View your fees below:{' '}
                    <TooltipIcon title="Estimated annualized fees for your connected account. The fee percentage is calculated by dividing your total dollar amount of fees by your current portfolio value. " />
                  </div>
                </h3>
                <div>
                  <div className={classes.table}>
                    <div className={classes.tableLabel}>Misc Fees</div>
                    <div className={classes.cell}>{formatPercent(stats_breakdown?.fees?.txn?.percent) || '-'}</div>
                    <div className={classes.cell}>
                      {stats_breakdown?.fees?.txn?.amount ? formatDollars(stats_breakdown.fees.txn.amount) : '-'}
                    </div>

                    <div className={classes.tableLabel}>
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        title="Management fee based on the assets under management  (AUM)."
                      >
                        <span>AUM Fees</span>
                      </Tooltip>{' '}
                      {stats_breakdown?.fees?.aum?.calc_type === 'provided' && (
                        <TooltipIcon
                          alert
                          title="Rainbook could not detect any Management Fee transactions. The calculations have been derived from the AUM percentage amount you provided."
                        />
                      )}
                    </div>
                    <div className={classes.cell}>{formatPercent(stats_breakdown?.fees?.aum?.percent) || '-'}</div>
                    <div className={classes.cell}>
                      {stats_breakdown?.fees?.aum?.amount ? formatDollars(stats_breakdown.fees.aum.amount) : '-'}
                    </div>
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                      title="Fees associated with the assets held in your account.  Mutual Funds and ETF portfolios charge a separate management fee, sometimes called layered fees or expense ratio fees."
                    >
                      <div className={classes.tableLabel}>
                        <span>Fund Expenses</span>
                      </div>
                    </Tooltip>
                    <div className={classes.cell}>{formatPercent(stats_breakdown?.fees?.exr?.percent) || '-'}</div>
                    <div className={classes.cell}>
                      {stats_breakdown?.fees?.exr?.amount ? formatDollars(stats_breakdown.fees.exr.amount) : '-'}
                    </div>
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                      title="Load fees are charged when you buy or sell certain mutual funds.  A front end load fee is paid at the time of purchase, typically 3.75% to 5.75% of the total purchase price.  The 12b-1 fee is often used as a commission to the broker for selling you the fund. There are many alternatives to load fee funds.  Speak with a true fiduciary advisor to learn more."
                    >
                      <div className={classes.tableLabel}>
                        <span>Fund Loads</span>
                      </div>
                    </Tooltip>
                    {stats_breakdown?.fees?.fld?.avg_percent ? (
                      stats_breakdown?.fees?.fld?.amount ? (
                        <>
                          <div className={classes.cell}>{formatPercent(stats_breakdown.fees.fld.percent)}</div>
                          <div className={classes.cell}>{formatPercent(stats_breakdown.fees.fld.amount)}</div>
                        </>
                      ) : (
                        <>
                          <div className={classes.cell}>
                            <TooltipIcon
                              alert
                              title="Your portfolio includes mutual fund(s) that have load and 12b-1 fees, which can be up to 5.75%!  These excessive fees are paid when you buy (or sell) the fund and a portion of the fee is often used as a commission to the broker.  This is in addition to the recurring annual fund expense fee.  You could have avoided these indiscriminate fees by speaking with a true fiduciary advisor."
                            />
                          </div>
                          <div className={classes.cell}>-</div>
                        </>
                      )
                    ) : (
                      <>
                        <div className={classes.cell}>-</div>
                        <div className={classes.cell}>-</div>
                      </>
                    )}
                    <div className={classes.tableLabel}>Total Fees</div>
                    <div className={classes.cell}>{formatPercent(annualFeePercent) || '-'}</div>
                    <div className={classes.cell}>{formatDollars(annualFees) || '-'}</div>
                  </div>
                </div>
              </div>
              <div className={`${classes.cardSection} ${classes.footer}`}>
                <div className={classes.subTitle}>
                  <span className={classes.healthIcon}>
                    {<BsFillArrowRightCircleFill size={30} color="rgb(21, 120, 246)" />}
                  </span>
                  <h4>
                    Fund expenses are the costs associated for managing a Mutual Fund or ETF. Some funds have higher
                    costs than lower cost similarly available options.
                  </h4>
                </div>
              </div>
            </div>
            <InsightCard type="fees" health={score_fees} isPending={isPending} />
          </div>
          <div className={classes.row}>
            <div className={classes.cardFull}>
              <div className={classes.colorIndicator} style={{ backgroundColor: scoreColor }} />
              <div className={classes.cardSection}>
                <h2>After reviewing your total fees above, consider asking your Advisor:</h2>
                <ul>
                  <li>Are there ways that I can reduce or avoid some of the fees I pay?</li>
                  <li>
                    How do the fees and expenses of my investment product(s) compare to other investment products that
                    can help me meet my objectives?
                  </li>
                  <li>How much does the investment have to increase in value before I break even?</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MorningstarConnect refForClick={mstarRef} />
    </InvestorLayout>
  );
};
