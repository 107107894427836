import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';

import AddAccountsDialog from 'components/dialogs/AddAccountsDialog';
import DotsRow from 'components/interaction/DotsRow';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext.js';
import MiniSuccessImg from 'logos/mini-success.png';
import SetupAcctImg from 'logos/setup-account-image.jpg';
import SetupCongratsImg from 'logos/setup-congrats-image.jpg';
import SetupIntroImg from 'logos/setup-intro-image.jpg';

import PhoneDialog from './StandardPhoneDialog';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default function SetupDialog({ open, setOpen }) {
  const { currentUser } = useAuth();
  const { AllAccounts } = useAccountsData();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  const [accountsOpen, setAccountsOpen] = useState(false);
  const [contIdx, setContIdx] = useState(0);
  const [isDoneAdding, setIsDoneAdding] = useState(false);

  const handleClose = (reason) => {
    if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
    }
  };

  const wrappedSetAcctsOpen = (isOpen) => {
    if (!isOpen) {
      handleAcctsClose();
    } else {
      openAccountsDialog();
    }
  };

  const openAccountsDialog = () => {
    setAccountsOpen(true);
  }

  const handlePhoneClose = (zip, dob) => {
    if (currentUser?.multiFactor?.enrolledFactors[0]?.phoneNumber) {
      setContIdx((contIdx) => contIdx + 1);
    }
  };

  const handleAcctsClose = () => {
    setAccountsOpen(false);
    setIsDoneAdding(true);
  };

  useEffect(() => {
    if (!accountsOpen && contIdx === 2 && isDoneAdding && AllAccounts.length > 0) {
      setContIdx(contIdx + 1);
    }
  }, [accountsOpen, contIdx, isDoneAdding, AllAccounts]);

  const titleStyle = {
    textAlign: isXS ? 'center' : 'left'
  };

  const btnStyles = {
    color: '#ffffff',
    backgroundColor: 'rgb(21, 120, 246)',
    borderRadius: '9px',
    maxHeight: '40px',
    minWidth: '250px',
    padding: '10px 40px 10px 40px',
    display: 'inline-block',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    fontSize: '14pt',
    fontWeight: 'bold',
    marginBottom: '1.5em'
  };

  const stepStyles = {
    color: 'rgb(21, 120, 246)',
    fontSize: 16,
    fontWeight: 'bold'
  };

  const stepsStyles = {
    fontStyle: 'italic'
  };

  const has2FA = currentUser?.multiFactor?.enrolledFactors?.length > 0;

  useEffect(() => {
    ReactGA.event("tutorial_begin");
  }, []);

  const introPane = (
    <Grid container>
      <Grid item xs={12} md={7}>
        <Typography
          variant="h6"
          gutterBottom
          style={{
            fontWeight: 400,
            fontSize: 16,
            color: '#8B8C8F',
            marginBottom: 60
          }}
        >
          In 3 steps, we will help get your account set up.
        </Typography>
        <div style={stepStyles}>
          <Typography variant="h6" gutterBottom>
            <span style={stepsStyles}>Step 1:</span> Verify your identity
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom>
            <span style={stepsStyles}>Step 2:</span> Connect your accounts
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ paddingBottom: 30 }}>
            <span style={stepsStyles}>Step 3:</span> Insights delivered to your Rainbook portfolio
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={5}>
        <img
          src={SetupIntroImg}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            marginTop: '-20px'
          }}
          alt="intro"
        />
      </Grid>
      <Grid item xs={12}>
        <div
          style={btnStyles}
          onClick={() => {
            has2FA ? setContIdx(contIdx + 2) : setContIdx(contIdx + 1);
          }}
        >
          <div>Get Started</div>
        </div>
      </Grid>
    </Grid>
  );

  const addAccountsPane = (
    <Grid container>
      <Grid item xs={12} md={6}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 40
          }}
        >
          <img
            src={MiniSuccessImg}
            style={{
              width: '2em',
              height: '2em',
              marginRight: '1em'
            }}
            alt="success"
          />
          <div
            style={{
              fontWeight: 400,
              fontSize: 16,
              color: '#8B8C8F'
            }}
          >
            Your identity is verified, and your account is secured with Two-Factor Authentication.
          </div>
        </div>
        <Typography variant="h5" gutterBottom>
          Connecting your Accounts.
        </Typography>
        <Typography variant="h6" gutterBottom style={{ color: '#8B8C8F' }}>
          Next, let's connect your accounts. This includes all types: bank accounts, credit cards, alternative assets,
          real estate and more. We recommend connecting at least 5 accounts, so Rainbook can deliver tailored insights
          to maximize your investments.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <img
          src={SetupAcctImg}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%'
          }}
          alt="setup-accounts"
        />
      </Grid>
      <Grid item xs={12}>
        <div
          style={btnStyles}
          onClick={openAccountsDialog}
        >
          <div>Connect Accounts</div>
        </div>
      </Grid>
      <AddAccountsDialog open={accountsOpen} setOpen={wrappedSetAcctsOpen} />
    </Grid>
  );

  const congratsPane = (
    <Grid container>
      <Grid item xs={12} md={6} style={{ padding: 20 }}>
        <Typography gutterBottom style={{ fontSize: 14, color: '#8B8C8F' }}>
          Thank you for connecting your accounts. Remember, you can always add more accounts anytime by visiting your
          “Profile” page.
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          style={{
            paddingBottom: '1em',
            marginTop: '1em',
            fontSize: 18
          }}
        >
          Setup complete! Now Rainbook will begin creating insights based upon your portfolio.
        </Typography>
        <Typography gutterBottom style={{ fontSize: 14, color: '#8B8C8F' }}>
          Rainbook gives you top down analysis and insights on your portfolio by incorporating all your assets. It's the
          most fully integrated portfolio hub yet, and we are continuing to develop by regularly adding new features and
          types of investments.
        </Typography>
        <br />
        <Typography
          gutterBottom
          style={{
            fontSize: 14,
            marginBottom: '1em',
            color: '#8B8C8F'
          }}
        >
          If you have questions, please email support@rainbook.com
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <img
          src={SetupCongratsImg}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            paddingTop: 20
          }}
          alt="congrats"
        />
      </Grid>
      <Grid item xs={12}>
        <div style={btnStyles} onClick={handleClose}>
          <div>Enter Rainbook</div>
        </div>
      </Grid>
    </Grid>
  );

  const wealthHubPanes = [
    introPane,
    <PhoneDialog onboarding closeCallback={handlePhoneClose} addedCallback={handlePhoneClose} btnStyles={btnStyles} />,
    addAccountsPane,
    congratsPane
  ];
  const wealthHubTitles = ["Let's Get Started", 'Step 1 of 3:', 'Step 2 of 3:', 'Step 3 of 3:'];

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      fullScreen={isXS}
      disableEscapeKeyDown={true}
      open={open}
      onClose={handleClose}
    >
      <>
        <DialogTitle id="customized-dialog-title">
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={12} sm={5} style={titleStyle}>
              {wealthHubTitles[contIdx]}
            </Grid>
            <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <DotsRow steps={3} activeStep={contIdx - 1} />
            </Grid>
            <Hidden xsDown>
              <Grid item sm={5}></Grid>
            </Hidden>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 20 }} dividers>
          {wealthHubPanes[contIdx]}
        </DialogContent>
      </>
    </Dialog>
  );
}
