import { useState } from 'react';
import { HiCheckCircle } from 'react-icons/hi';

import { Dialog, DialogContent, Grid, Hidden } from '@material-ui/core';

import { accountTypes } from 'contexts/AccountsContext';
import logo from 'logos/SuccessLogo.png';

import DialogTitleX from './DialogTitleX';

import './SuccessDialog.css';

const styles = {
  color: '#ffffff',
  backgroundColor: 'rgb(23,120,250)',
  borderRadius: '9px',
  width: '100%',
  maxWidth: '300px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: 'none',
  fontSize: '12pt',
  fontWeight: 'bold',
  marginTop: 15
};
const styles2 = {
  color: '#88898C',
  backgroundColor: 'fff',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginTop: 30,
  marginLeft: 30
};
// Creates an oxford comma split string of words from an array
const arrayToSentence = (arr) => {
  if (arr.length === 1) return arr[0] ? arr[0].toString() : 'undefined';
  const last = arr.pop();
  return arr.length > 1 ? arr.join(', ') + ', and ' + last : arr[0] + ' and ' + last;
};
const _order = ['Liquid', 'Personal', 'Alternative', 'Cash Flow', 'Liabilities'];
/** Take in the input array and returns the first element in the order of the order parameter.
 * if order was [3,2,1] and array was [1,2,3,4,5,6], it would return [3,2,1,4,5,6].
 * It removes duplicates in order so [3,2,1] with the input of [1,1,2,2,3,3,4,4] would only return [3,2,1,4,4]. */
const order = (array = [], order = []) => {
  let ret = [];
  order.forEach((e) => {
    if (array.includes(e)) {
      ret.push(e);
      array = array.filter((a) => a !== e);
    }
  });
  return [...ret, ...array];
};

const SuccessDialog = (props) => {
  // local states
  const [open, setOpen] = useState(true);
  // handlers
  const handleClose = () => {
    setOpen(false);
    // custom onClose callback
    if (props.onClose) props.onClose();
  };
  // Headers that get shown in message
  // Institution is bank name or institution name
  // account pages is all the types of accounts that the account is
  const institution = props.metadata?.institution?.name ? props.metadata.institution.name : props.institution;
  const accountPages = new Set();
  // Just display the page if passed in
  if (props.page) {
    accountPages.add(props.page.replace(/\b\w/g, (l) => l.toUpperCase())); // .replace(...) fixes Caps
  }
  // or take in array of accounts and get type per account
  else if (props.accounts) {
    props.accounts.forEach((a) => {
      // console.log(a, accountTypes(a))
      accountTypes(a).forEach((t) => {
        accountPages.add(t);
      });
    });
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitleX onClose={handleClose}>Success! Your account is connected.</DialogTitleX>
        <DialogContent style={{ paddingBottom: 20 }}>
          <Grid container>
            <Grid container item sm={12} md={8}>
              <Grid item xs={1}>
                <HiCheckCircle className="small-check" />
              </Grid>
              <Grid item xs={11}>
                <div className="msg-styles">
                  Your <span className="bolded-info">{institution} </span>
                  account is now securely connected to Rainbook
                  {accountPages.size > 0 ? (
                    <>
                      {' '}
                      and can be viewed on your{' '}
                      <span className="bolded-info">{arrayToSentence(order([...accountPages], _order))}</span> dashboard
                      {accountPages.size > 1 && 's'}.
                    </>
                  ) : (
                    '.'
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <h3 className="connect-header">Connect More Accounts.</h3>
                <ul className="success-list">
                  <li>bank accounts</li>
                  <li>credit cards</li>
                  <li>alternative assets</li>
                  <li>real estate, and more</li>
                </ul>

                <div className="msg-reminder">
                  Reminder: We recommend connecting at least 5 accounts, so that Rainbook can deliver tailored insights
                  and maximize your investments.
                </div>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <div style={styles} onClick={handleClose}>
                    <div>Connect Next Account</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {props.handleClose && (
                    <div
                      style={styles2}
                      onClick={() => {
                        handleClose();
                        props.handleClose();
                      }}
                    >
                      <div>All accounts connected?</div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Hidden smDown>
                <img src={logo} alt="" style={{ width: 265, height: 265 }} />
              </Hidden>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SuccessDialog;
