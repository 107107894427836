import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FaUserTie } from 'react-icons/fa';
import { MdSignpost } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { useAccountsData } from 'contexts';

interface AdvisorMatchCardProps {
  general?: boolean;
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '0.5rem',
    backgroundColor: 'white',
    marginTop: '1rem'
  },
  colorIndicator: {
    width: '100%',
    height: '0.5rem',
    borderRadius: '1rem 1rem 0 0',
    backgroundColor: '#1FC277'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2rem',
    gap: '3rem'
  },
  leftCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem'
  },
  signPost: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.8rem',
    padding: '1rem',
    backgroundColor: 'rgb(21, 120, 246)'
  },
  advisorIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.8rem',
    padding: '1rem'
  },
  matchRec: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 500
  },
  matchText: {
    display: 'flex',
    width: '90%',
    flexDirection: 'row'
  },
  rightCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '75%'
    }
  },
  matchLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5rem',
    color: 'white',
    fontWeight: 500,
    width: '16rem',
    padding: '0.8rem 1rem',
    borderStyle: 'solid',
    borderColor: 'rgb(21, 120, 246)',
    backgroundColor: 'rgb(30,197,119)',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgb(30,197,119, 0.8)'
    }
  }
}));

export const AdvisorMatchCard = ({ general }: AdvisorMatchCardProps) => {
  const classes = useStyles();
  const {
    investorUserData: { stats_breakdown, provided_account_size_max }
  } = useAccountsData();

  if ( !stats_breakdown?.total_balance && !provided_account_size_max ) {
    return (<></>);
  }

  return (
    <div className={classes.card}>
      <div className={classes.colorIndicator} />
      <div className={classes.container}>
        <div className={classes.leftCol}>
          <div className={classes.signPost}>
            <MdSignpost size={48} color="white" />
          </div>
          <div className={classes.advisorIcon}>
            <FaUserTie size={64} />
          </div>
        </div>
        <div>
          <div className={classes.matchRec}>
            <p className={classes.matchText}>
              Rainbook has matched you with two of our vetted advisors, incorporating your feedback. No obligation, you
              the client, make the first move.
            </p>
          </div>
        </div>
        <div className={classes.rightCol}>
          <Link className={classes.matchLink} to="/advisor-analyzer/advisor-match">
            View Your Advisor Matches
            <AiFillCheckCircle size={16}  />
          </Link>
        </div>
      </div>
    </div>
  );
};
