import React, { useMemo } from 'react';

import { formatPercent } from 'components/tables/GenericAssetsTable';
import { useAccountsData, useAuth } from 'contexts';

import { RatingCard } from './RatingCard';

export const RatingCardPerformance = () => {
  const { investorUserData, scoreSubmitted } = useAccountsData();
  const { score_performance } = investorUserData;
  const { noAdvisor } = useAuth();

  const isPending = useMemo(() => {
    return !score_performance && scoreSubmitted.indexOf('score_performance') > -1;
  }, [score_performance, scoreSubmitted]);

  const content = useMemo(() => {
    if (!investorUserData || isPending) return '';

    const { score_performance, score_growth } = investorUserData;

    if (!investorUserData?.stats_breakdown || !score_performance) return '';
    const investorData = investorUserData.stats_breakdown;
    let cashMsg = '';
    cashMsg = 'In addition, your cash is earning an above average yield.';
    if (investorData.allocation_percentages.cash <= 5) {
      cashMsg = 'In addition, your account has less than 5% allocated to cash.';
    } else {
      const good_cash_percent = investorData.cash.good / investorData.cash.total;
      if (good_cash_percent < 0.95) {
        cashMsg = `In addition, you have excess idle cash which is not being optimized.`;
      }
    }
    if (investorData.allocation_percentages.cash <= 5) {
      cashMsg = 'In addition, your account has less than 5% allocated to cash.';
    } else {
      const good_cash_percent = investorData.cash.good / investorData.cash.total;
      if (good_cash_percent < 0.95) {
        cashMsg = `In addition, you have excess idle cash which is not being optimized.`;
      }
    }
    if (score_growth) {
      if (score_growth < 80) {
        return `Your portfolio performance is worse than the benchmark. ${cashMsg}`;
      } else if (score_growth < 90) {
        return `Your portfolio performance is on par with the benchmark. ${cashMsg}`;
      } else {
        return `Your portfolio performance exceeds the benchmark. ${cashMsg}`;
      }
    }
  }, [investorUserData, isPending]);

  const contentHeader = useMemo(() => {
    const { score_performance, score_cash, score_growth } = investorUserData;

    if (!investorUserData?.stats_breakdown) return '';
    const investorData = investorUserData.stats_breakdown;

    let cashMsg = '';
    if (score_cash && score_cash < 90) {
      const good_cash_percent = investorData.cash.good / investorData.cash.total;
      if (good_cash_percent < 0.95) {
        cashMsg = `cash is not optimized`;
      }
    }

    if (noAdvisor) {
      if (cashMsg) {
        return `Your ${cashMsg}.`;
      } else if (!score_cash) {
        return 'Please connect an account to analyze your performance.';
      } else {
        return '';
      }
    }

    if (!score_performance) {
      return 'Please connect an account to analyze your performance.';
    }
    if (score_performance >= 90) {
      return 'Your portfolio performance and cash look good.';
    }

    let growthMsg = '';
    if (score_growth < 85) {
      growthMsg = 'portfolio performance is below the benchmark';
    }
    if (growthMsg && cashMsg) {
      return `Your ${growthMsg} and ${cashMsg}.`;
    } else if (growthMsg) {
      return `Your ${growthMsg}.`;
    } else if (cashMsg) {
      return `Your ${cashMsg}.`;
    }
    return '';
  }, [investorUserData, noAdvisor]);

  return (
    <RatingCard
      title="Portfolio Performance"
      link="/advisor-analyzer/performance-factor"
      score={noAdvisor ? 0 : score_performance}
      isPending={isPending}
      noAdvisor={noAdvisor}
      contentHeader={contentHeader}
      content={content}
      description="Relative to benchmarks."
    />
  );
};
