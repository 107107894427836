import React from 'react';

import { List, ListItem, ListItemIcon, SvgIcon, Tooltip } from '@material-ui/core';

import { useAccountsData } from 'contexts/AccountsContext';
import { useNetWorthData } from 'contexts/NetWorthDataContext';

import './Legend.css';

const LegendList = ({ data, labels, labelTooltips = {}, colors, colorScheme, baa = false }) => {
  const { LiqData, PersData, AltData } = useAccountsData();
  const { NetData } = useNetWorthData();

  let ColorHash = {};
  let i = 0;
  while (i < colorScheme.length) {
    ColorHash[labels[i]] = colorScheme[i];
    i++;
  }

  const AssetData = React.useMemo(() => {
    if (baa) return data;

    return data['assetType'] === 'Liquid'
      ? LiqData
      : data['assetType'] === 'Alternative'
      ? AltData
      : data['assetType'] === 'Personal'
      ? PersData
      : NetData;
  }, [baa, data, LiqData, AltData, PersData, NetData]);

  const AssetTotal = React.useMemo(() => {
    const reducer = (acc = 0, currVal) => acc + currVal;
    const AssetValues = AssetData.map((hash) => hash['value']);
    return AssetValues.reduce(reducer, 0);
  }, [AssetData]);

  return (
    <List dense={true}>
      {labels.map((SubAssetType, i) => (
        <ListItem style={{ fontSize: 14 }} key={i}>
          <ListItemIcon style={{ minWidth: 28 }}>
            <SvgIcon>
              <rect fill={ColorHash[SubAssetType]} rx="5" ry="5" width="23" height="23" />
            </SvgIcon>
          </ListItemIcon>
          {labelTooltips[SubAssetType] ? (
            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={labelTooltips[SubAssetType]}>
              <span style={{ marginRight: '5px', borderBottomWidth: '1px', borderBottomStyle: 'dotted' }}>
                {SubAssetType}
              </span>
            </Tooltip>
          ) : (
            <span style={{ marginRight: '5px' }}>{SubAssetType}</span>
          )}
          <span style={{ marginLeft: 'auto' }}>
            {!isNaN(Math.round((AssetData[i]['value'] / AssetTotal) * 100))
              ? Math.round((AssetData[i]['value'] / AssetTotal) * 100)
              : 0}
            %
          </span>
        </ListItem>
      ))}
    </List>
  );
};

export default LegendList;
