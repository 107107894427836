import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card, CardContent, CardHeader, CircularProgress, Container, Grid } from '@material-ui/core';

import InsightsTags from 'components/layouts/InsightsTags';
import { useAccountsData } from 'contexts/AccountsContext';
import useWindowWidth from 'utils/useWindowWidth';

import { formatDate, pagetocss } from './Insights';

import './Insights.css';
import 'pages/AssetPages.css';

const InsightHeader = ({ title, blurb, tags, published_at, read_time }) => (
  <>
    <div style={{ marginBottom: '10px' }}>
      {title}
      {tags ? tags.map((tag) => <span className={`insights-view-tag ${pagetocss(tag.page)}`}>{tag.name}</span>) : null}
    </div>
    <div className="insights-page-article-item-blurb" style={{ fontSize: '1rem' }}>
      {blurb}
    </div>
    <div style={{ fontSize: '1rem' }}>
      {`${formatDate(published_at)}${read_time ? ` • ${read_time} minute${read_time !== 1 ? 's' : ''} read` : ''}`}
    </div>
  </>
);

const InsightBody = ({ loading, article, content }) => (
  <>
    {loading ? (
      <CircularProgress color="primary" />
    ) : article && content ? (
      <>
        {article.image_url && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 20
            }}
          >
            <img
              src={article.image_url}
              alt="Insight"
              style={{
                objectFit: 'contain',
                maxHeight: 300,
                width: '100%'
              }}
            />
          </div>
        )}
        <div
          className="article-content"
          dangerouslySetInnerHTML={{
            __html: content.replace(/href/g, "target='_blank' href")
          }}
        />
      </>
    ) : (
      <div>No article found</div>
    )}
  </>
);

function InsightView() {
  const { permalink } = useParams();
  const width = useWindowWidth().width;
  const styles = width > 1494 ? { marginTop: 30, marginLeft: 76 } : { marginTop: 30 };
  const { InsightsData, TagsData } = useAccountsData();
  const article = InsightsData.find((i) => i.permalink === permalink);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState();

  useEffect(() => {
    async function getContent(permalink) {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/insight_content/${permalink}`, {
        method: 'GET'
      });
      const text = await response.text();
      setLoading(false);
      setContent(text);
    }
    setLoading(true);
    getContent(permalink);
  }, [permalink]);

  return (
    <>
      <Container style={styles}>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={8}>
            <Card className="insights-page-articles" style={{ width: '100%', borderRadius: '9px' }}>
              <CardHeader
                style={{
                  paddingBottom: 10,
                  borderBottom: '1px solid #000'
                }}
                title={
                  article ? (
                    <>
                      <InsightHeader
                        title={article.title}
                        blurb={article.blurb}
                        tags={article.tags}
                        published_at={article.published_at}
                        read_time={article.read_time}
                      />
                    </>
                  ) : (
                    'Loading...'
                  )
                }
              />
              <CardContent>
                <InsightBody loading={loading} article={article} content={content} />
              </CardContent>
            </Card>
          </Grid>
          <InsightsTags tags={TagsData} isInsightView={true} />
        </Grid>
      </Container>
    </>
  );
}

export default InsightView;
export { InsightHeader, InsightBody };
