import React from 'react';

import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { isNum } from 'components/tables/GenericAssetsTable';

import { currencyField, dateField, isDate, textField } from './common';

import './forms.css';

export const PrivateEquityAssetForm = ({ formSubmit, account, edit }) => {
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: edit
      ? {
          ...account
        }
      : { date: null },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'Name is required.';
      }

      if (data.date && !isDate(data.date)) {
        errors.date = 'Date is invalid.';
      }

      return errors;
    },
    onSubmit: async (data) => {
      if (isNum(data['price']) && isNum(data['quantity'])) {
        data['value'] = Number(data['price']) * Number(data['quantity']);
      }
      if (isNum(data['cost_basis']) && isNum(data['quantity'])) {
        data['cost_basis'] = Number(data['cost_basis']) * Number(data['quantity']);
      }
      await formSubmit(data, account, {});
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'name', 'Asset Name')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'quantity', 'Quantity')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'price', 'Stock Price/share')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'cost_basis', 'Cost basis/share')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {dateField(formik, 'date', 'Investment Date')}
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" label="Submit" className="p-mt-2" />
        </Grid>
      </Grid>
    </form>
  );
};
