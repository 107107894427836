import React, { useEffect, useState } from 'react';

import { FormControl, Grid, Select, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { useAccountsData } from 'contexts/AccountsContext';

const removeNonNumRegex = /[^\d.]/g;

export const PrivateEquityTransactionForm = ({ formSubmit, transactionToEdit }) => {
  const [categories, setCategories] = useState([]);
  const { CategoryData } = useAccountsData();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: transactionToEdit || {},
    validate: (data) => {
      let errors = {};
      return errors;
    },
    onSubmit: async (data) => {
      const amount = !parseInt(data.amount) ? data.amount.replace(removeNonNumRegex, '') : data.amount;
      await formSubmit({ ...data, amount });
    }
  });

  const isFormFieldValid = (name) => !!formik.errors[name];
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };
  useEffect(() => {
    let categories = CategoryData;
    categories = categories.filter((c) => {
      for (const cat of ['Investment Income', 'Return of Capital', 'Capital Call']) {
        if (c.name.indexOf(cat) > -1) {
          return true;
        }
      }
      return false;
    });
    categories = categories.sort((a, b) => (a.name.indexOf('Investment Income') === -1 ? 1 : -1));
    setCategories(categories);
  }, [CategoryData]);

  const changeCategory = (event) => {
    formik.handleChange(event);
    const cat = categories.find((cat) => cat.id === event.target.value);
    formik.setFieldValue('category_name', cat ? cat.name : '');
  };

  const textField = (name, label) => (
    <>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        id={name}
        name={name}
        label={label}
        type="text"
        value={formik.values[name]}
        onChange={formik.handleChange}
      />
      {getFormErrorMessage(name)}
    </>
  );

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <Grid container spacing={2} style={{ paddingBottom: 20 }}>
        <Grid item sm={6}>
          {textField('name', 'Description')}
        </Grid>
        <Grid item sm={6}>
          <FormControl size="small" variant="outlined" fullWidth>
            <Select native value={formik.values.category_id} onChange={changeCategory} id="category_id">
              {[<option aria-label="None">Select Activity Type</option>].concat(
                categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="date"
            name="date"
            label="Date"
            type="date"
            value={formik.values.date}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: true
            }}
          />
          {getFormErrorMessage('date')}
        </Grid>
        <Grid item sm={6}>
          {textField('amount', '$ Amount')}
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" label="Submit" className="p-mt-2" />
        </Grid>
      </Grid>
    </form>
  );
};
