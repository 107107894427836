import React from 'react';

import { Donut } from './Donut';

function HoveredDonut({ showTotal, donutData, colorScheme, width, height, totals, page, AccountName, title = '' }) {
  const chartOptions = {
    options: {
      title: {
        text: title,
        display: !!title
      },
      legend: {
        display: false
      },
      tooltips: {
        position: 'nearest',
        backgroundColor: '#000'
      }
    }
  };

  const chartData = {
    labels: donutData.map((obj) => obj.label),
    datasets: [
      {
        label: '',
        data: donutData.map((obj) => obj.value),
        backgroundColor: colorScheme,
        borderWidth: 0
      }
    ]
  };

  const donut = (
    <div style={{ position: 'relative', minWidth: '0', zIndex: 2 }}>
      <Donut
        data={chartData}
        options={chartOptions.options}
        AssetTotal={showTotal ? totals : null}
        AccountName={AccountName}
        width={width}
        height={height}
      />
    </div>
  );

  const styles = {
    textAlign: 'center',
    position: 'relative'
  };

  const dashStyles = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  };

  return (
    <div className="hovered-donuts" style={page === 'asset' ? styles : dashStyles}>
      {donut}
    </div>
  );
}

export default HoveredDonut;
