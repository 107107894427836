import React from 'react';
import ReactDOM from 'react-dom';

import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

require('dotenv').config();

if (process.env.REACT_APP_DATADOG_ENV) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'rainbook-web-app',
    env: process.env.REACT_APP_DATADOG_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, //20
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
