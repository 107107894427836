import React, { useState } from 'react';

import { Button, Card } from '@material-ui/core';
import * as d3 from 'd3';

import LegendVisualizationData from 'assetData/LegendVisualizationData';
import { StatementIcon, TileIcon } from 'components/assetDetails/PersonalFinancialStatement';
import AccountLineChart from 'components/charts/AccountLineChart';
import HoveredDonut from 'components/charts/HoveredDonut';
import Legend from 'components/charts/Legend';
import AddAccountsTile from 'components/tiles/AddAccountsTile';
import InsightsTile from 'components/tiles/InsightsTile';
import TileContainer from 'components/tiles/TileContainer';
import { useAccountsData } from 'contexts/AccountsContext';
import { changeInValue, formatMoney, rangeToString } from 'utils/formatters';

import 'pages/AssetPages.css';

export const isLiability = (acct) =>
  acct.institution?.page === 'liabilities' || ['credit', 'loan'].indexOf(acct.account_type) > -1;

export const liabilitiesColorScheme = ['rgb(245,79,89)', 'rgb(222,31,31)', 'rgb(173,42,42)', 'rgb(219,96,96)'];

export const liabilitiesMainColor = 'rgb(196, 55, 55)';

function Liabilities() {
  const [view, setView] = useState('tile');

  const { LiabilityData, LiabilityAccounts, LiabilityIds, InsightsData, liabilityChange, setLiabilityChange } =
    useAccountsData();

  const LiabilityTotal = parseFloat(
    LiabilityData.reduce(function (sum = 0, current) {
      return sum + current.value;
    }, 0)
  );

  const labels = LiabilityData.map((hash) => {
    return hash['label'];
  });

  const colorScheme = LegendVisualizationData(LiabilityData);
  const colors = d3.scaleOrdinal().range(colorScheme);
  const page = 'LiabilitiesAsset';

  return (
    <div>
      <TileContainer
        pageId="liabilities"
        rightAccounts={LiabilityAccounts}
        showPFS={view !== 'tile'}
        insights={InsightsData.filter((i) => i.page === 'liabilities')}
        assetColorScheme={liabilitiesColorScheme}
        mainColor={liabilitiesMainColor}
        leftContent={
          <>
            <Card className="asset-totals-container" style={{ borderRadius: '9px' }}>
              <div className="liquid-asset-header">
                <h3 className="asset-title"> Liabilities: {formatMoney(LiabilityTotal, 0)}</h3>
                {liabilityChange && (
                  <p className="change-in-value" style={changeInValue(liabilityChange.value, 'liabilities')}>
                    {formatMoney(liabilityChange.value, 0)}{' '}
                    <span style={{ color: 'lightslategray' }}>{rangeToString(liabilityChange.range)}</span>
                  </p>
                )}
              </div>
              <div
                className="liquid-asset-doughnut-and-keys"
                style={LiabilityTotal === 0 ? { marginBottom: 20 } : { marginBottom: 0 }}
              >
                <div className="liquid-asset-doughnut">
                  <HoveredDonut
                    showTotal={false}
                    page={'asset'}
                    donutData={LiabilityData}
                    innerRadius={55}
                    outerRadius={65}
                    colorScheme={liabilitiesColorScheme}
                    width={150}
                    height={150}
                    totals={LiabilityTotal}
                    AccountName={'Liabilities'}
                  />
                </div>
                <div className="liquid-asset-doughnut-keys">
                  <svg className="liquid-doughnut-key-svg">
                    {LiabilityData.length > 0 && (
                      <g transform="translate(0,15)">
                        <Legend
                          data={LiabilityData}
                          labels={labels}
                          colors={colors}
                          colorScheme={liabilitiesColorScheme}
                          page={page}
                        />
                      </g>
                    )}
                  </svg>
                </div>
              </div>
              {LiabilityIds.length > 0 ? (
                <AccountLineChart
                  AssetsTotal={true}
                  colors={liabilitiesMainColor}
                  accountsIds={LiabilityIds.join()}
                  change={liabilityChange}
                  setChange={setLiabilityChange}
                />
              ) : null}
              <Button
                startIcon={view === 'tile' ? <StatementIcon /> : <TileIcon />}
                onClick={() => setView(view === 'tile' ? 'pfs' : 'tile')}
              >
                {view === 'tile' ? 'Statement View' : 'Tile View'}
              </Button>
            </Card>
            <InsightsTile style={{ marginBottom: 20 }} page="liabilities" />
            <Card style={{ borderRadius: '9px' }}>
              {' '}
              <AddAccountsTile />{' '}
            </Card>
          </>
        }
      />
    </div>
  );
}

export default Liabilities;
