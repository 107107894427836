const suffixes = ['', 'k', 'M', 'B', 'T', 'q', 'Q', 's', 'S'];

/**
 * Takes a number representing money and returns a well formatted printable string with n digits of decimals
 * @param {Number} money
 * @param {Number} digits how many values to include in abbreviation
 * @param {Boolean | Number} abbreviate value to abbreviate after, eg 1,000,000 would only abbreviate values larger than 1m
 * @returns {String} Formatted string representing dollar value of number
 */
export const formatMoney = (value, digits = 2, abbreviate = false) => {
  if (abbreviate && value > abbreviate) {
    return abbreviateMoney(value, 3);
  }
  return `${value < 0 ? '-' : ''}$${Math.abs(value).toLocaleString('en', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  })}`;
};

export const formatAssetQuantity = (value) => parseFloat(value?.toFixed(4)) || null;

const abbreviateMoney = (value, digits = 3) => {
  const _suffix = (Math.log10(Math.abs(value)) / 3) | 0; // Get Length of number as relates to scale/suffixes and round down to nearest whole number ( | 0 )
  const _scale = Math.pow(10, _suffix * 3); // Get scalar of value as related to

  const _value = Number((value / _scale).toFixed(digits));

  return `${value < 0 ? '-' : ''}$${_value}${suffixes[_suffix]}`;
};

export const changeInValue = (change, pageId = 'notliabilities') =>
  parseFloat(change) >= 0
    ? pageId !== 'liabilities'
      ? { color: 'green' }
      : { color: 'red' }
    : pageId !== 'liabilities'
    ? { color: 'red' }
    : { color: 'green' };

export const rangeToString = (range) => {
  if (range === 'day') return 'Past Day';
  else if (range === 'week') return 'Past Week';
  else if (range === 'month') return 'Past Month';
  else if (range === 'ytd') return '1st of Year';
  else if (range === 'year') return 'Past Year';
  else if (range === 'max') return 'Beginning of Time';
  else return range;
};
