import React from 'react';
import { Link } from 'react-router-dom';

import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import * as d3 from 'd3';

import HoveredDonut from 'components/charts/HoveredDonut';
import LegendList from 'components/charts/LegendList';
import { useAccountsData } from 'contexts/AccountsContext';

import './Tiles.css';

function PersonalAssetTile() {
  const { PersData } = useAccountsData();

  const labels = PersData.map((hash) => {
    return hash['label'];
  });

  const reducer = (acc = 0, currVal) => acc + currVal;
  const AssetValues = PersData.map((hash) => hash['value']);
  const AssetTotal = AssetValues.reduce(reducer, 0);

  const colorScheme = ['rgb(123,255,242)', 'rgb(0,211,195)', 'rgb(0,132,125)', 'rgb(0,99,92)'];
  const colors = d3.scaleOrdinal().range(colorScheme);
  const page = 'personalTile';

  const styles = {
    color: '#ffffff',
    backgroundColor: '#00a5a3',
    borderRadius: '9px'
  };

  return (
    <Link to="/wealth-hub/personal">
      <Card style={styles}>
        <CardHeader style={{ paddingBottom: 0 }} title="Personal Assets" />
        <CardContent>
          <div>
            <Grid container>
              <Grid item xs={6} lg={5}>
                <HoveredDonut
                  showTotal={true}
                  donutData={PersData}
                  innerRadius={65}
                  outerRadius={75}
                  colorScheme={colorScheme}
                  width={150}
                  height={150}
                  totals={AssetTotal}
                  AccountName={'Personal'}
                />
              </Grid>
              <Grid item xs lg>
                {PersData.length > 0 && (
                  <LegendList
                    data={PersData[0]}
                    labels={labels}
                    colors={colors}
                    colorScheme={colorScheme}
                    page={page}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </Link>
  );
}

export default PersonalAssetTile;
