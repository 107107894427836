import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAccountsData } from 'contexts/AccountsContext';

import { AddAdvisorAccount } from './AddAdvisorAccount';

export const AddAdvisorAccountWrapper = () => {
  const { reloadDom, getAAData } = useAccountsData();
  const history = useHistory();

  const redirect = async () => {
    try {
      reloadDom();
      getAAData();
    } catch (e) {
      console.error(e);
    }
    history.push('/');
  };

  return (
    <div>
      <AddAdvisorAccount onClose={redirect} standalone />
    </div>
  );
};
