import React from 'react';

import { Container, Grid } from '@material-ui/core';

import './AssetPages.css';

function Vault() {
  return (
    <div>
      <Container>
        <Grid item>Vault</Grid>
      </Container>
    </div>
  );
}

export default Vault;
