import React, { useEffect, useRef } from 'react';

import { Dialog, DialogContent, Grid, useMediaQuery, useTheme } from '@material-ui/core';

import AccountLineChart from 'components/charts/AccountLineChart';
import { Donut } from 'components/charts/Donut';
import DialogTitleX from 'components/dialogs/DialogTitleX';
import ManualEditAccountValue from 'components/forms/ManualEditAccountValue';
import AssetHoldingsTable from 'components/tables/AssetHoldingsTable';
import rotateIcon from 'logos/mobile-rotate-rotation.png';
import { changeInValue, formatMoney, rangeToString } from 'utils/formatters';

import './AssetDetails.css';

function GenericAssetDetails({
  account,
  open,
  onClose,
  mainColor,
  chartData,
  options,
  name,
  change,
  setChange,
  pageId
}) {
  let nonUsdAssets = useRef([]);
  useEffect(() => {
    account.assets.forEach((element) => {
      if (element.is_cash_equivalent === true) {
        nonUsdAssets.current.push(element.id);
      }
    });
  }, [account]);

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'} onClose={onClose}>
      <DialogTitleX onClose={onClose}>
        <Grid container spacing={2}>
          {open && isSM && !window.matchMedia('(orientation: landscape)').matches && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                zIndex: 99
              }}
            >
              <img src={rotateIcon} alt="rotate device" style={{ width: '5vw', minWidth: 50 }} />
            </div>
          )}
          <Grid item md={4} sm={6} xs={12}>
            <div className="header-account-title">
              {account.institution.logo_url ? (
                <img className="detailed-view-logo" src={account.institution.logo_url} alt=" " />
              ) : (
                account.name
              )}
            </div>
            {account.number_display ? (
              <div className="transaction-detail-ending"> Account {account.number_display}</div>
            ) : null}
          </Grid>
          <Grid item container md={8} sm={6} xs={12} spacing={1} direction="column">
            {account.is_manual ? (
              <ManualEditAccountValue account={account} />
            ) : (
              <div className="asset-detail-tile-header-account-value">
                <span className="asset-account-value" style={{ width: 'max-content' }}>
                  Account Value{' '}
                </span>
                <span
                  className="asset-account-value"
                  style={{
                    width: 'max-content',
                    fontWeight: 'unset'
                  }}
                >
                  {formatMoney(account.balance_current ? account.balance_current : 0)}
                </span>
              </div>
            )}
            {change && (
              <p
                className="dialog-change-in-value"
                style={{
                  margin: 'unset',
                  ...changeInValue(change.value, pageId)
                }}
              >
                {formatMoney(change.value, 0)}{' '}
                <span style={{ color: 'lightslategray' }}>{rangeToString(change.range)}</span>
              </p>
            )}
          </Grid>
        </Grid>
      </DialogTitleX>
      <DialogContent className="asset-detail-tile-container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="line-chart-container">
              <AccountLineChart
                colors={mainColor}
                nonUsdAssets={nonUsdAssets.current}
                account={account}
                AssetsTotal={false}
                change={change}
                setChange={setChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              className="asset-details-donut-container"
              style={{
                position: 'relative',
                backgroundColor: mainColor
              }}
            >
              <div className="asset-details-donut-header">
                <div className="asset-details-donut-header-left">
                  <div className="asset-details-donut-header-title">Holdings</div>
                </div>
              </div>
              <div className="asset-details-donut">
                <div className="asset-details-donut-inner-wrapper">
                  <div className="inner-wrapper">
                    <div style={{ position: 'absolute' }}>
                      <Donut
                        data={chartData}
                        options={options}
                        AssetTotal={account.balance_current}
                        change={change}
                        AccountName={name}
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <AssetHoldingsTable account={account} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default GenericAssetDetails;
