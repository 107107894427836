function getRemainingValues(accounts) {
  return accounts.map((acct) => {
    return acct.value;
  });
}

export const generateAssetDetails = (acct, name, colorScheme) => {
  const obj = [];
  const assetNames = [];
  const values = [];
  let flag = false;
  let i = 0;

  const chartOptions = {
    title: {
      display: false
    },
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return data['labels'][tooltipItem[0]['index']];
        },
        label: function (tooltipItem, data) {
          return '$' + parseFloat(data['datasets'][0]['data'][tooltipItem['index']].toFixed(0)).toLocaleString('en');
        }
      },
      position: 'nearest',
      backgroundColor: '#000'
    }
  };

  const chartData = {
    labels: assetNames,
    datasets: [
      {
        label: '',
        data: values,
        backgroundColor: colorScheme,
        borderWidth: 0
      }
    ]
  };

  const accountAssets = acct.assets
    ? acct.assets.length === 0
      ? [{ name: name, value: acct.balance_current }]
      : acct.assets
          .filter((a) => a.quantity && a.quantity !== '' && a.quantity > 0)
          .sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
    : [];

  accountAssets.forEach((ele) => {
    if (i < 3) {
      assetNames.push(
        ele.name && ele.name.length > 18 ? ele.name.substring(0, 18) + '...' : ele.name ? ele.name : 'Asset'
      );
      values.push(parseFloat(ele.value));
      obj.push({
        label: ele.name ? ele.name.substring(0, 21) : 'asset name',
        value: ele.value,
        assetType: 'Alternative'
      });
    } else if (i >= 3 && flag === false) {
      flag = true;
      assetNames.push('Other');
      let arr = getRemainingValues(accountAssets.slice(3));
      values.push(parseFloat(arr.reduce((a, b) => a + b, 0).toFixed(2)));
      obj.push({
        label: 'other',
        value: arr.reduce((a, b) => a + b, 0),
        assetType: 'Alternative'
      });
    }
    i++;
    return;
  });

  return { assetNames, values, obj, chartData, chartOptions };
};
