import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import { currencyField } from 'components/forms/common.js';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';
import { formatMoney } from 'utils/formatters';

const url = process.env.REACT_APP_API_URL;

function ManualEditAccountValue({ account, tile, useTransactions, manualWithTransactions }) {
  const { currentUser } = useAuth();
  const { reloadDom } = useAccountsData();
  const [open, setOpen] = useState(false);
  const [accountBalance, setAccountBalance] = useState(account.balance_current);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setAccountBalance(account.balance_current);
  }, [account]);

  const formSubmit = async (data) => {
    let accountData = {};
    for (const key of Object.keys(data)) {
      if (key.indexOf('asset-') !== 0) {
        accountData[key] = data[key];
      }
    }
    const ID = await currentUser.getIdToken();
    await fetch(`${url}/api/v1/accounts/${account.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          id: account.id,
          type: 'accounts',
          attributes: {
            balance_current: accountData['balance_current']
          }
        }
      })
    });
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      balance_current: accountBalance
    },
    validate: (data) => {
      let errors = {};
      if (!data.balance_current) {
        errors.balance_current = 'Value is required.';
      }

      return errors;
    },
    onSubmit: async (data) => {
      await formSubmit(data, account, {});
      reloadDom();
      setAccountBalance(data.balance_current);
      setOpen(false);
    }
  });

  return (
    <>
      {tile ? (
        <div className="manual-tile-balance-details" onClick={(e) => setOpen(true)}>
          {/* <div className="tile-balance-title">{useTransactions ? 'Balance' : 'Value'}</div>
                <div className="bank-account-balance">
                    {formatMoney(accountBalance)}
                </div> */}
          <span className="asset-account-value" style={{ width: 'max-content' }}>
            {useTransactions ? 'Balance' : 'Value'}{' '}
          </span>
          <span className="asset-account-value" style={{ width: 'max-content', fontWeight: 'unset' }}>
            {formatMoney(accountBalance ? accountBalance : 0)}
          </span>
        </div>
      ) : manualWithTransactions ? (
        <div className="edit-transaction-detail-header-middle" onClick={(e) => setOpen(true)}>
          {/* <div>Balance</div>
                    <div>{formatMoney(accountBalance)}</div> */}
          <span className="asset-account-value" style={{ width: 'max-content' }}>
            Balance{' '}
          </span>
          <span className="asset-account-value" style={{ width: 'max-content', fontWeight: 'unset' }}>
            {formatMoney(accountBalance ? accountBalance : 0)}
          </span>
        </div>
      ) : (
        <div className="manual-account-value" onClick={(e) => setOpen(true)} style={{ width: 'max-content' }}>
          <span className="asset-account-value" style={{ width: 'max-content' }}>
            Account Value{' '}
          </span>
          <span className="asset-account-value" style={{ width: 'max-content', fontWeight: 'unset' }}>
            {formatMoney(accountBalance ? accountBalance : 0)}
          </span>
        </div>
      )}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitleX onClose={handleClose}>Edit Account Value</DialogTitleX>
        <DialogContent style={{ padding: 30, paddingBottom: 20 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid item sm={8}>
              {currencyField(formik, 'balance_current', 'Account Value')}
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" label="Submit" className="p-mt-2" />
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ManualEditAccountValue;
