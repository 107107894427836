import React, { useEffect, useState } from 'react';
import { BsGraphUpArrow } from 'react-icons/bs';
import { IoIosAdd, IoIosLock } from 'react-icons/io';
import { RiQuestionnaireFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import { Container, Grid } from '@material-ui/core';

import ResetPassword from 'components/auth/ResetPassword';
import { SupportDialog, StatementsDialog } from 'components/dialogs';
import AddAccountsDialog from 'components/dialogs/AddAccountsDialog';
import UpdateDetails from 'components/forms/UpdateDetails';
import BankTile from 'components/tiles/BankTile';
import { useAccountsData } from 'contexts/AccountsContext';
import useWindowWidth from 'utils/useWindowWidth';

import './AssetPages.css';
import './Profile.css';

function Profile() {
  const width = useWindowWidth().width;
  const { AllAccounts } = useAccountsData();
  const [clicked, setClicked] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);
  const [statementsDialogOpen, setStatementsDialogOpen] = useState(false);

  const openSupport = () => {
    setSupportDialogOpen(true);
  };
  const closeSupport = () => {
    setSupportDialogOpen(false);
  };

  const openStatements = () => {
    setStatementsDialogOpen(true);
  };
  const closeStatements = () => {
    setStatementsDialogOpen(false);
  };

  const handleClick = () => setClicked(true);
  const styles = width > 1494 ? { marginTop: 10, marginLeft: 76 } : { marginTop: 10 };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }, []);

  return (
    <Container style={styles}>
      <Grid container spacing={0} align="center">
        <AddAccountsDialog open={clicked} setOpen={setClicked} />
        <Grid item xs={12} md={6}>
          <div className="edit-user-details-tile">
            <div className="edit-user-header">
              <div className="edit-user-header-title">Profile</div>
            </div>
            <UpdateDetails />
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="change-password-tile" onClick={() => setChangePassword(!changePassword)}>
            {changePassword ? <ResetPassword /> : null}
            <div className="change-password-header">Change Password</div>
            <div className="change-password-body">
              <div className="change-password-logo">
                <IoIosLock />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="help-tile" onClick={openSupport}>
            <div className="help-tile-header">Help</div>
            <div className="help-tile-logo">
              <RiQuestionnaireFill />
            </div>
          </div>
          <SupportDialog open={supportDialogOpen} onClose={closeSupport} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Link to="/advisor-analyzer/add-account">
            <div className="add-liquid-account">
              <div className="add-accounts-header">
                <div className="add-account-title">Add Advisor Managed Account</div>
              </div>
              <div className="add-accounts-body">
                <div className="liq-button-background">
                  <div className="liq-styles">{<IoIosAdd />}</div>
                </div>
              </div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={4}>
          <div className="add-liquid-account add-statements" onClick={openStatements}>
            <div className="add-accounts-header">
              <div className="add-account-title">Upload Your Statements</div>
            </div>
            <div className="add-accounts-body">
              <div className="liq-button-background">
                <div className="liq-styles">{<IoIosAdd />}</div>
              </div>
            </div>
          </div>
          <StatementsDialog open={statementsDialogOpen} onClose={closeStatements} />
        </Grid>
        <Grid item xs={3}>
          <Link to="/wealth-hub">
            <div className="add-personal-account">
              <div className="add-accounts-header">
                <div className="add-account-title">Wealth Hub (beta)</div>
              </div>
              <div className="icon-only">
                <div className="icon-only-icon">{<BsGraphUpArrow />}</div>
              </div>
            </div>
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <div className="connected-accounts-banner-container">
          <div className="banner-text-container">
            <div className="banner-text">Connected Accounts</div>
          </div>
          <div className="pseudo-container"></div>
        </div>
      </Grid>
      <Grid container spacing={1}>
        {AllAccounts.map((acct, idx) => {
          return (
            <Grid item xs={12} md={4} key={acct.id}>
              <BankTile assetPage={false} useTransactions={false} acct={acct} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default Profile;
