import { Tooltip } from '@material-ui/core';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { formatDollars, formatPercent } from 'components/tables/GenericAssetsTable';

export const formatCashAssets = (assets) => {
  return assets.map((asset) => {
    return {
      name: asset.ticker_symbol,
      description: asset.name,
      yield_7day: formatPercent(asset.yield_7day),
      value: formatDollars(asset.value),
      _asset: asset
    };
  });
};

const tooltipTemplate = (asset) => {
  return asset.yield_7day ? (
    <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="The seven-day yield serves as a conventional metric to assess the annualized yield of a money market mutual fund. Typically determined by considering the fund's average seven-day distribution, this measure facilitates straightforward comparisons among various money market funds.">
      <span>{asset.yield_7day}</span>
    </Tooltip>
  ) : (
    <></>
  );
};

export function CashAssetsTable({ holdings }) {
  return (
    <>
      <DataTable scrollable className="data-table" value={holdings} rows={holdings.length}>
        <Column className="columns" field="name" header="Name"></Column>
        <Column className="columns" field="description" header="Description"></Column>
        <Column
          className="columns"
          field="yield_7day"
          body={tooltipTemplate}
          header={
            <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title="The seven-day yield serves as a conventional metric to assess the annualized yield of a money market mutual fund. Typically determined by considering the fund's average seven-day distribution, this measure facilitates straightforward comparisons among various money market funds.">
              <span style={{ borderBottomWidth: '1px', borderBottomStyle: 'dotted' }}>7-Day Yield</span>
            </Tooltip>
          }
        ></Column>
        <Column className="columns" field="value" header="Value"></Column>
      </DataTable>
    </>
  );
}
