import { useState } from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import ManualAssetsPopup from 'components/popups/ManualAssetsPopup';
import { formatDollars, formatGainLoss, formatPercent } from 'components/tables/GenericAssetsTable';

export const formatCryptoCurrencyAssets = (assets) => {
  return assets.map((asset) => {
    const gainLossDollarDiff = asset.value - (asset.cost_basis || 0);
    const gainLossDollar = formatGainLoss(gainLossDollarDiff, formatDollars);

    const percentDifference = (asset.cost_basis || 0) !== 0 ? (gainLossDollarDiff / asset.cost_basis) * 100 : undefined;
    const gainLossPercent = formatGainLoss(percentDifference, formatPercent);

    let costBasis =
      asset.cost_basis && asset.cost_basis_quantity ? formatDollars(asset.cost_basis / asset.cost_basis_quantity) : '';
    if (!asset.cost_basis) {
      costBasis = '*';
    } else if (asset.cost_basis_quantity && asset.cost_basis_quantity !== asset.quantity) {
      costBasis += '*';
    }

    const holdingsObject = {
      name: asset.ticker_symbol,
      description: asset.name,
      quantity: asset.quantity,
      cost_basis: costBasis,
      price: formatDollars(asset.price),
      market_value: formatDollars(asset.value),
      'gain/loss_$': asset.cost_basis && costBasis !== '' ? gainLossDollar : '',
      'gain/loss_%': asset.cost_basis && costBasis !== '' ? gainLossPercent : '',
      _asset: asset
    };
    return holdingsObject;
  });
};

export function CryptoCurrencyAssetsTable({ holdings, is_manual, deleteAsset }) {
  const [show, setShow] = useState(false);
  const [asset, setAsset] = useState({});
  return (
    <>
      <DataTable
        scrollable
        className="data-table"
        value={holdings}
        sortField="category"
        sortOrder={-1}
        rows={holdings.length}
      >
        <Column className="columns" field="name" header="Name"></Column>
        <Column className="columns" field="description" header="Description"></Column>
        <Column className="columns" field="quantity" header="Quantity"></Column>
        <Column className="columns" field="cost_basis" header="Cost Basis"></Column>
        <Column className="columns" field="price" header="Price"></Column>
        <Column className="columns" field="market_value" header="Value"></Column>
        <Column className="columns" field="gain/loss_$" header="Gain / Loss $"></Column>
        <Column className="columns" field="gain/loss_%" header="Gain / Loss %"></Column>
        {is_manual && (
          <Column
            className="columns"
            header="Edit"
            body={(row) => (
              <IconButton
                size="small"
                onClick={() => {
                  setAsset(row._asset);
                  setShow(true);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          ></Column>
        )}
        {is_manual && (
          <Column
            className="columns"
            header="Delete"
            body={(row) => (
              <IconButton
                size="small"
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this asset?')) {
                    deleteAsset(row._asset);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          ></Column>
        )}
      </DataTable>
      <ManualAssetsPopup show={show} setShow={setShow} toggle={() => {}} account={asset} edit={true} />
    </>
  );
}
