import { React, useState } from 'react';

import { Card, CardContent, CardHeader } from '@material-ui/core';

import { generateAssetDetails } from 'components/assetDetails/utils';
import AssetLegend from 'components/charts/AssetLegend';
import { Donut } from 'components/charts/Donut';
import ConnectDropdown from 'components/dropdowns/ConnectDropdown';
import AssetDetailPopup from 'components/popups/AssetDetailPopup';

function AssetTile(props) {
  const { acct, colors, mainColor, assetColorScheme: colorScheme, pageId } = props;
  const [showAssetDetails, setShowAssetDetails] = useState(false);
  const [name, setName] = useState(acct.name);
  const [change, setChange] = useState({
    value: acct.day_change,
    range: 'day'
  });

  const handleClick = () => {
    setShowAssetDetails(true);
  };
  const handleClose = () => {
    setShowAssetDetails(false);
  };

  let total = parseFloat(acct.balance_current ? acct.balance_current.toFixed(2) : 0);
  const { assetNames, values, obj, chartData, chartOptions } = generateAssetDetails(acct, name, colorScheme);

  const logo = acct['institution']['logo_url'];
  const inst_name = acct.institution_name ? acct.institution_name : acct.institution.name;
  const logoTitle = (
    <div className="logo-container" onClick={handleClick}>
      <img className="cash-flow-bank-logo" src={logo} alt="" />
    </div>
  );
  const nameTitle = (
    <div className="logo-container" onClick={handleClick}>
      {inst_name}
    </div>
  );

  return (
    <Card style={{ borderRadius: '9px', height: '450' }}>
      <CardHeader
        style={{ height: 84 }}
        title={logo ? logoTitle : nameTitle}
        titleTypographyProps={{ variant: 'subtitle1' }}
        subheader={
          inst_name !== name ? (
            <div className="logo-container" onClick={handleClick}>
              {name}
            </div>
          ) : (
            ''
          )
        }
        action={
          <div className="dropdown">
            <ConnectDropdown account={acct} name={name} setName={setName} />
          </div>
        }
      />
      <CardContent align="center" justify="center" style={{ height: 400 }}>
        <div
          onClick={handleClick}
          onMouseDown={(e) => e.stopPropagation()}
          style={{ cursor: 'pointer' }}
          className="inner-wrapper"
        >
          <div style={{ position: 'absolute' }}>
            <Donut
              data={chartData}
              options={chartOptions}
              AssetTotal={total}
              assetPage={true}
              change={change}
              AccountName={name}
              width={180}
              height={180}
            />
          </div>
        </div>
        <svg className="alternative-asset-doughnut-keys-svg">
          {obj.length > 0 && (
            <g onClick={handleClick} style={{ cursor: 'pointer' }} transform="translate(55,5)">
              <AssetLegend
                data={obj[0]}
                labels={assetNames}
                colors={colors}
                colorScheme={colorScheme}
                values={values}
              />
            </g>
          )}
        </svg>
      </CardContent>
      <AssetDetailPopup
        acct={acct}
        showAssetDetails={showAssetDetails}
        handleClose={handleClose}
        chartData={chartData}
        mainColor={mainColor}
        chartOptions={chartOptions}
        change={change}
        setChange={setChange}
        pageId={pageId}
      />
    </Card>
  );
}

export default AssetTile;
