import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import ErrorDialog from 'components/dialogs/ErrorDialog';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

const PlaidOpen = ({ config }) => {
  const { open, ready } = usePlaidLink(config);
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open, config]);
  return null;
};

export const PlaidReAuth = (props) => {
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const { plaidLinkToken, setPlaidLinkToken, accountId, onReAuthSuccess } = props;

  const onSuccess = (token, metadata) => {
    console.log('metadata: ', metadata);
    console.log('token: ', token);
    reenableItem();
  };

  const onExit = (plaidLinkError, exitMetadata) => {
    setPlaidLinkToken(null);
    if (plaidLinkError) {
      console.error(`Error reauthorizing plaid account: ${plaidLinkError.display_message}`);
      setError(
        'There was an error reestablishing connection to your account. Please try again later or contact support@rainbook.com'
      );
    }
  };

  async function reenableItem() {
    const ID = await currentUser.getIdToken();
    const resp = await fetch(`${url}/api/v1/accounts/${accountId}.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        data: {
          id: accountId,
          type: 'accounts',
          attributes: { is_disabled: false }
        }
      })
    }).catch((err) => {
      console.log('Failed to reenable plaid item: ', err);
      return;
    });
    const jsonResp = await resp.json();
    console.log('jsonResp: ', jsonResp);

    setPlaidLinkToken(null);
    onReAuthSuccess();
  }

  const plaidConfig = plaidLinkToken
    ? {
        token: plaidLinkToken,
        onSuccess,
        onExit
      }
    : null;

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {plaidLinkToken && <PlaidOpen config={plaidConfig} />}
    </>
  );
};
