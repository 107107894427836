import React, { useContext, useEffect, useState } from 'react';

import { useAccountsData } from 'contexts';

const NetWorthContext = React.createContext();

export function useNetWorthData() {
  return useContext(NetWorthContext);
}

export function NetWorthProvider({ children }) {
  const { LiqData, PersData, AltData } = useAccountsData();
  const [NetData, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function setNet() {
      let LiqTotal = 0;
      let AltTotal = 0;
      let PersTotal = 0;

      let accounts = [];

      AltData.forEach((account) => {
        AltTotal += account.value;
        accounts.push(account);
      });

      LiqData.forEach((account) => {
        LiqTotal += account.value;
        accounts.push(account);
      });

      PersData.forEach((account) => {
        PersTotal += account.value;
        accounts.push(account);
      });

      await setData([
        {
          label: 'Liquid' + String.fromCharCode(160),
          value: LiqTotal,
          AssetType: 'Liquid'
        },
        {
          label: 'Alternative' + String.fromCharCode(160),
          value: AltTotal,
          AssetType: 'Alternative'
        },
        {
          label: 'Personal' + String.fromCharCode(160),
          value: PersTotal,
          AssetType: 'Personal'
        }
      ]);
      setLoading(false);
    }
    setNet();
  }, [AltData, PersData, LiqData]);

  const value = {
    NetData
  };

  return <NetWorthContext.Provider value={value}>{!loading && children}</NetWorthContext.Provider>;
}
