import { Institution } from 'types/institutions';

import allianceBernsteinLogo from 'logos/institution-logos/alliancebernstein.png';
import altruistLogo from 'logos/institution-logos/altruist.png';
import blackDiamondLogo from 'logos/institution-logos/blackdiamond.png';
import charlesSchwabLogo from 'logos/institution-logos/charlesschwab.png';
import citibankLogo from 'logos/institution-logos/citibank.png';
import fidelityLogo from 'logos/institution-logos/fidelity.png';
import goldmanSachsLogo from 'logos/institution-logos/goldmansachs.png';
import jpmorganLogo from 'logos/institution-logos/jpmorgan.png';
import lplFinancialLogo from 'logos/institution-logos/lplfinancial.png';
import merrillLogo from 'logos/institution-logos/merrill.png';
import northernTrustLogo from 'logos/institution-logos/northerntrust.png';
import orionLogo from 'logos/institution-logos/orion.png';
import raymondJamesLogo from 'logos/institution-logos/raymondjames.png';
import truistLogo from 'logos/institution-logos/truist.png';
import ubsLogo from 'logos/institution-logos/ubs.png';
import wellsFargoLogo from 'logos/institution-logos/wellsfargo.png';

export const presetInstitutions: Institution[] = [
  {
    native_id: '113552',
    integration: 'baa',
    name: 'Fidelity Investments',
    user_login_url: 'https://www.fidelity.com/',
    website_url: 'https://www.fidelity.com/',
    logo: fidelityLogo,
    icon_url: 'https://www.byallaccounts.net/WebPortfolio/vendorResources/Default/assets/fi/icons/Investment_Icon.svg'
  },
  {
    native_id: '112126',
    integration: 'baa',
    name: 'Charles Schwab',
    user_login_url: 'https://www.schwab.com/',
    website_url: 'https://www.schwab.com/',
    logo: charlesSchwabLogo,
    icon_url:
      'https://www.byallaccounts.net/WebPortfolio/vendorResources/Default/assets/fi/icons/charlesschwab_icon.svg'
  },
  {
    native_id: '112518',
    integration: 'baa',
    name: 'Wells Fargo',
    user_login_url: 'https://www.wellsfargo.com/',
    website_url: 'https://www.wellsfargo.com/',
    logo: wellsFargoLogo,
    icon_url: 'https://www.byallaccounts.net/WebPortfolio/vendorResources/Default/assets/fi/icons/wellsfargo_icon.png'
  },
  {
    native_id: '97352',
    integration: 'baa',
    name: 'Bank of America - Merrill Edge',
    user_login_url: 'https://www.merrilledge.com/m/pages/home.aspx',
    website_url: 'http://www.ml.com/',
    logo: merrillLogo,
    icon_url: 'https://www.byallaccounts.net/WebPortfolio/vendorResources/Default/assets/fi/icons/Investment_Icon.svg'
  }
];

export const presetInstitutionsLimited: Institution[] = [
  {
    native_id: '113552',
    integration: 'baa',
    name: 'Fidelity Investments',
    user_login_url: 'https://www.fidelity.com/',
    website_url: 'https://www.fidelity.com/',
    logo: fidelityLogo,
    icon_url: 'https://www.byallaccounts.net/WebPortfolio/vendorResources/Default/assets/fi/icons/Investment_Icon.svg'
  },
  {
    native_id: '112126',
    integration: 'baa',
    name: 'Charles Schwab',
    user_login_url: 'https://www.schwab.com/',
    website_url: 'https://www.schwab.com/',
    logo: charlesSchwabLogo,
    icon_url:
      'https://www.byallaccounts.net/WebPortfolio/vendorResources/Default/assets/fi/icons/charlesschwab_icon.svg'
  }
];
