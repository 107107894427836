import React, { useEffect, useState } from 'react';
import { BiHide } from 'react-icons/bi';
import { HiCheckCircle } from 'react-icons/hi';
import { IoIosAlert, IoIosCloseCircle } from 'react-icons/io';
import { MdSyncDisabled } from 'react-icons/md';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Dropdown } from 'primereact/dropdown';

import { plaidDefaultImage } from 'assetData/StaticValues';
import DialogTitleX from 'components/dialogs/DialogTitleX';
import {
  CoinbaseLink,
  GeminiWalletLink,
  MorningstarConnect,
  PlaidReAuth,
  TDAmeritradeLink,
  ZaboReLink
} from 'components/integrations';
import { useAccountsData } from 'contexts/AccountsContext';
import { useAuth } from 'contexts/AuthContext';

import './ConnectDropdown.css';

const url = process.env.REACT_APP_API_URL;

const getOptions = function (acct, inst = null) {
  inst = inst || acct.institution;
  let options = [];
  options.push({ label: 'Edit', value: 'edit' });
  if (acct.is_hidden) {
    options.push({ label: 'Show', value: 'show' });
  } else {
    options.push({ label: 'Hide', value: 'hide' });
  }
  if (acct.is_closed) {
    options.push({ label: 'Delete', value: 'unlink' });
  } else {
    options.push({ label: 'Unlink', value: 'unlink' });
  }
  if (acct.is_closed && !acct.is_disabled) {
    options.push({ label: 'Reconnect', value: 'reopen' });
  }
  if (acct.is_disabled && ['plaid', 'coinbase', 'zabo', 'gemini', 'ameritrade', 'baa'].includes(inst.integration)) {
    options.push({ label: 'Reconnect', value: 'reenable' });
  }
  return options;
};

const getPlaceholder = function (acct) {
  return acct.is_hidden
    ? 'Hidden'
    : acct.is_disabled
    ? 'Disconnected'
    : acct.is_closed
    ? 'Unlinked'
    : acct.is_deleted
    ? 'Deleted'
    : 'Connected';
};

const getAttributes = function (event) {
  switch (event.value) {
    case 'hide':
      return { is_hidden: true };
    case 'show':
      return { is_hidden: false };
    case 'close':
      return { is_closed: true };
    case 'reopen':
      return { is_closed: false };
    case 'reenable':
      return { is_closed: false, is_disabled: false };
    case 'delete':
      return { is_deleted: true };
    case 'save':
      return event.formValues;
    default:
      return {};
  }
};

function ConnectDropdown({ account, name, setName }) {
  const { currentUser } = useAuth();
  const [placeholder, setPlaceholder] = useState(getPlaceholder(account));
  const [unlinkOpen, setUnlinkOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [connects, setConnects] = useState(getOptions(account));
  const { reloadDom } = useAccountsData();
  const [plaidLinkToken, setPlaidLinkToken] = useState();
  const mstarRef = React.useRef(null);
  const coinbaseRef = React.useRef(null);
  const zaboChildRef = React.useRef(null);
  const geminiRef = React.useRef(null);
  const ameritradeRef = React.useRef(null);

  const onAccounts = (data) => {
    if (data) {
      const acct = data['attributes'] || data;
      if (acct) {
        setConnects(getOptions(acct, account.institution));
        setPlaceholder(getPlaceholder(acct));
      }
    }
    reloadDom();
  };

  const handleUnlinkClose = () => {
    setUnlinkOpen(false);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleEditSave = (event) => {
    event.preventDefault();
    const ev = {
      value: 'save',
      formValues: {
        name: event.target.name.value
      }
    };
    onConnectionChange(ev);
  };

  useEffect(() => {
    setPlaceholder(getPlaceholder(account));
    setConnects(getOptions(account));
    setName(account.name);
  }, [account, setName]);

  const onConnectionChange = (e) => {
    async function updateConnection(id, attributes) {
      const ID = await currentUser.getIdToken();
      const response = await fetch(`${url}/api/v1/accounts/${id}.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({
          data: {
            id: id,
            type: 'accounts',
            attributes: attributes
          }
        })
      });
      const json = await response.json();
      const acct = json && json['data'] ? json['data']['attributes'] : '';
      setConnects(getOptions(acct, account.institution));
      setPlaceholder(getPlaceholder(acct));
      setName(acct.name);
      reloadDom();
    }
    async function plaidReconnect(acct) {
      const ID = await currentUser.getIdToken();
      const token = await fetch(`${url}/api/v1/plaid/reauth_link_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({ acct_id: acct.id })
      }).catch((err) => {
        console.log('Failed to get reauth token from plaid: ', err);
        return;
      });
      const jsonToken = await token.json();
      const linkToken = jsonToken['link_token'];

      setPlaidLinkToken(linkToken);
    }

    switch (e.value) {
      case 'unlink':
        setUnlinkOpen(true);
        break;
      case 'edit':
        setEditOpen(true);
        break;
      case 'reenable':
        switch (account.institution.integration) {
          case 'plaid':
            plaidReconnect(account);
            break;
          case 'baa':
            mstarRef.current.click();
            break;
          case 'coinbase':
            coinbaseRef.current.click();
            break;
          case 'zabo':
            zaboChildRef.current.click();
            break;
          case 'gemini':
            geminiRef.current.click();
            break;
          case 'ameritrade':
            ameritradeRef.current.click();
            break;
          default:
            return;
        }
        break;
      default: //hide, show, close, reopen, delete, save
        const attributes = getAttributes(e);
        updateConnection(account.id, attributes);
        setUnlinkOpen(false);
        setEditOpen(false);
    }
  };

  const baseStyles = {
    width: 30,
    height: 30,
    marginTop: 9,
    marginLeft: 10
  };

  const checkStyles = {
    ...baseStyles,
    color: 'rgb(4,205,125)'
  };

  const hideStyles = {
    ...baseStyles,
    backgroundColor: 'rgb(249,196,0)',
    textAlign: 'center',
    borderRadius: 50
  };

  const hideIconStyles = {
    color: 'white',
    width: 25,
    height: 25,
    marginTop: 2,
    marginBottom: 2
  };

  const closeStyles = {
    ...baseStyles,
    color: 'rgb(249,79,89)'
  };

  const plaidReAuthStyles = {
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    alignItems: 'center',
    fontSize: '8pt',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    border: 'none',
    marginRight: 'none'
  };

  const logoElm = <img className="link-bank-logo" src={account.institution.logo_url} alt=" " />;

  const icon =
    placeholder === 'Hidden' ? (
      <div style={hideStyles}>
        {' '}
        <BiHide style={hideIconStyles} />
      </div>
    ) : placeholder === 'Closed' ? (
      <MdSyncDisabled style={baseStyles} />
    ) : placeholder === 'Connected' ? (
      <HiCheckCircle style={checkStyles} />
    ) : placeholder === 'Disabled' ? (
      <IoIosCloseCircle style={closeStyles} />
    ) : (
      <IoIosAlert style={closeStyles} />
    );

  return (
    <div className="connection-dropdown">
      <div className="connection-card">
        <Dropdown
          style={{
            borderRadius: 20,
            width: 125,
            height: 20,
            marginTop: 15
          }}
          options={connects}
          onChange={onConnectionChange}
          optionLabel="label"
          placeholder={placeholder}
        />
        {icon}
      </div>
      <Dialog onClose={handleUnlinkClose} open={unlinkOpen}>
        <DialogTitleX onClose={handleUnlinkClose}>Choose one option:</DialogTitleX>
        <DialogContent>
          <DialogContentText>
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={9}>
                Unlink but RETAIN data in Rainbook to keep most accurate historical performance. This account will no
                longer be included in your net worth calculation.
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" color="primary" onClick={() => onConnectionChange({ value: 'close' })}>
                  UNLINK
                </Button>
              </Grid>
              <Grid item xs={9}>
                Unlink and DELETE all historical data associated with this account. I understand this will impact
                historical performance and net worth calculation.
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onConnectionChange({ value: 'delete' })}
                  startIcon={<DeleteIcon />}
                >
                  DELETE
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog onClose={handleEditClose} open={editOpen}>
        <DialogTitleX onClose={handleEditClose}>Edit the display name for this account: </DialogTitleX>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <form id="editConnForm" onSubmit={handleEditSave}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Account Name"
              type="text"
              defaultValue={name}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit" form="editConnForm">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <PlaidReAuth
        styles={plaidReAuthStyles}
        linkCustomizationName={account.institution.integration_config}
        addAccountIcon={
          account.institution.logo_url ? logoElm : <img className="link-bank-logo" src={plaidDefaultImage} alt=" " />
        }
        name={account.institution.logo_url ? null : account.institution.name}
        page={account.institution.page}
        handleMainClose={() => {}}
        plaidLinkToken={plaidLinkToken}
        setPlaidLinkToken={setPlaidLinkToken}
        accountId={account.id}
        onReAuthSuccess={reloadDom}
      />
      <CoinbaseLink refForClick={coinbaseRef} onAccounts={onAccounts} institution={account.institution} />
      <ZaboReLink localRef={zaboChildRef} onSuccess={reloadDom} institution={account.institution} />
      <TDAmeritradeLink refForClick={ameritradeRef} onAccounts={onAccounts} institution={account.institution} />
      <GeminiWalletLink refForClick={geminiRef} onAccounts={onAccounts} />
      {account.institution?.integration === 'baa' && (
        <MorningstarConnect refForClick={mstarRef} onAccounts={onAccounts} institution={account.institution} />
      )}
    </div>
  );
}

export default ConnectDropdown;
