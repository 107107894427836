import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

import { isNum } from 'components/tables/GenericAssetsTable';
import { useAccountsData } from 'contexts/AccountsContext';

import AutoCompleteInstitutionSelector from './AutoCompleteInstitutionSelector';
import { currencyField, dateField, getFormErrorMessage, isDate, textField } from './common';

import './forms.css';

export const CollectibleForm = ({ accountType, accountSubType, formSubmit }) => {
  const { selectableInstitutions } = useAccountsData();
  const [institutionValues, setInstitutionValues] = useState({
    institution_id: null,
    institution_name: null,
    account_type: accountType,
    account_subtype: accountSubType
  });
  const [assetFile, setAssetFile] = useState({});

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      date: null
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'Name is required.';
      }
      if (!institutionValues.institution_id && !institutionValues.institution_name) {
        errors.institution = 'Institution is required';
      }

      if (
        !data['asset-name'] &&
        (assetFile.name || data['asset-quantity'] || data['asset-price'] || data['asset-cost_basis'])
      ) {
        errors['asset-name'] = 'Name is required.';
      }

      if (data.date && !isDate(data.date)) {
        errors.date = 'Date is invalid.';
      }

      return errors;
    },
    onSubmit: async (data) => {
      if (isNum(data['asset-price']) && isNum(data['asset-quantity'])) {
        data['asset-value'] = Number(data['asset-price']) * Number(data['asset-quantity']);
      }

      if (isNum(data['asset-cost_basis']) && isNum(data['asset-quantity'])) {
        data['asset-cost_basis'] = Number(data['asset-cost_basis']) * Number(data['asset-quantity']);
      }

      await formSubmit(data, {}, assetFile, institutionValues);
    }
  });

  const addAssetFile = (event) => {
    setAssetFile({ name: event.target.id, file: event.target.files[0] });
  };

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingBottom: 20 }}>
          <AutoCompleteInstitutionSelector
            institutions={selectableInstitutions}
            values={institutionValues}
            setValues={setInstitutionValues}
          />
          {getFormErrorMessage(formik, 'institution')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'name', 'Account Name')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'balance_current', 'Account Balance/Value')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {dateField(formik, 'date', 'Date Started')}
        </Grid>
        <Divider />
        <Grid item xs={12} sm={6}>
          {textField(formik, 'asset-name', 'Asset Name')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {textField(formik, 'asset-quantity', 'Quantity')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'asset-price', 'Price/unit')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {currencyField(formik, 'asset-cost_basis', 'Cost Basis/unit')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <span className="p-field">
            <label htmlFor="asset-logo_file" style={{ color: '#555' }}>
              Asset Image
            </label>
            <input id="asset-logo_file" onChange={addAssetFile} type="file" />
          </span>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" label="Submit" className="p-mt-2" />
        </Grid>
      </Grid>
    </form>
  );
};
