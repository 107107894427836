import React, { useState, useCallback } from 'react';

import { makeStyles } from '@material-ui/core';

import { StatementsDialog } from 'components/dialogs';

const useStyles = makeStyles(() => ({
  matchLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    borderRadius: '0.5rem',
    color: 'white',
    fontWeight: 500,
    width: '12rem',
    padding: '0.8rem 1rem',
    borderStyle: 'solid',
    backgroundColor: 'rgb(21, 120, 246)',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(21, 120, 246, 0.75)'
    }
  }
}));

export const StatementsButton = ({ onSubmit }: {onSubmit?: () => void}) => {
  const [statementsDialogOpen, setStatementsDialogOpen] = useState(false);
  const classes = useStyles();

  const onClose = useCallback(() => {
    setStatementsDialogOpen(false);
  }, [])

  return (
    <>
      <div className={classes.matchLink} onClick={() => setStatementsDialogOpen(true)}>
        Upload Statements
      </div>
      <StatementsDialog open={statementsDialogOpen} onSubmit={onSubmit} onClose={onClose} />
    </>
  );
};
