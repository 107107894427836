import React, { useEffect, useState } from 'react';

import { Container, Grid } from '@material-ui/core';

import PersonalFinancialStatement from 'components/assetDetails/PersonalFinancialStatement';
import SetupDialog from 'components/dialogs/SetupDialog';
import AddAccountsTile from 'components/tiles/AddAccountsTile';
import AlternativeAssetTile from 'components/tiles/AlternativeAssetTile';
import ExpenseTrackerTile from 'components/tiles/ExpenseTrackerTile';
import InsightsTile from 'components/tiles/InsightsTile';
import LiabilitiesTile from 'components/tiles/LiabilitiesTile';
import LiquidAssetTile from 'components/tiles/LiquidAssetTile';
import NetWorthTile from 'components/tiles/NetWorthTile';
import PersonalAssetTile from 'components/tiles/PersonalAssetTile';
import { useAccountsData } from 'contexts/AccountsContext';
import useWindowWidth from 'utils/useWindowWidth';

import './Dashboard.css';

function Dashboard() {
  const { AllAccounts } = useAccountsData();
  const [view, setView] = useState('tile');
  const [showSetup, setShowSetup] = useState(false);

  const width = useWindowWidth().width;
  const styles = width > 1494 ? { marginTop: 10, marginLeft: 76 } : { marginTop: 10 };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }, []);

  useEffect(() => {
    if (AllAccounts.length === 0) {
      setShowSetup(true);
    }
  }, [AllAccounts]);

  return (
    <>
      <Container style={styles}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={3} align="center">
            <div>
              <div className="net-worth-tile">
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12}>
                    <NetWorthTile view={view} setView={setView} />
                  </Grid>
                  <Grid item xs={12}>
                    <AddAccountsTile />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
          {view === 'tile' ? (
            <>
              <Grid item xs={12} md={6} lg={5} align="center">
                <div>
                  <Grid container direction="column" spacing={2}>
                    <Grid xs={12} item>
                      <LiquidAssetTile />
                    </Grid>
                    <Grid xs={12} item>
                      <InsightsTile />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} align="center">
                <div>
                  <Grid container direction="column" spacing={2}>
                    <Grid xs={12} item>
                      <AlternativeAssetTile />
                    </Grid>
                    <Grid xs={12} item>
                      <PersonalAssetTile />
                    </Grid>
                    <Grid xs={12} item>
                      <ExpenseTrackerTile />
                    </Grid>
                    <Grid xs={12} item>
                      <LiabilitiesTile />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} lg={9}>
              <PersonalFinancialStatement />
            </Grid>
          )}
        </Grid>
      </Container>
      {showSetup && <SetupDialog open={showSetup} setOpen={setShowSetup} />}
    </>
  );
}

export default Dashboard;
