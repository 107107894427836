import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { changeInValue } from 'utils/formatters';

const dayChangeTemplate = (rowData) => {
  if (rowData.diff == null) {
    return <div className="columns"></div>;
  }
  if (rowData.diff === 0) {
    return <div className="columns">-</div>;
  }
  return (
    <div className="columns" style={changeInValue(rowData.diff)}>
      {rowData.dayChangeAmount} ({rowData.dayChangePercent})
    </div>
  );
};
const nameTemplate = (rowData) => {
  return (
    <div>
      <div style={{ fontWeight: 'bold', paddingBottom: '2px' }}>{rowData.name}</div>
      <div>{rowData.description}</div>
    </div>
  );
};

export function AdvisorEquitiesTable({ holdings }) {
  return (
    <>
      <DataTable scrollable className="data-table" value={holdings} rows={holdings.length}>
        <Column className="columns" field="name" header="Name" body={nameTemplate}></Column>
        <Column className="columns" field="shares" header="Shares"></Column>
        <Column className="columns" field="price" header="Price"></Column>
        <Column className="columns" field="market_value" header="Market Value"></Column>
        <Column className="columns" field="dayChangeAmount" header="Day Change" body={dayChangeTemplate}></Column>
      </DataTable>
    </>
  );
}
