import React from 'react';
import { FaSearch } from 'react-icons/fa';

import { makeStyles } from '@material-ui/core';
import { Institution } from 'types/institutions';

interface BaaInstitutionDropdownProps {
  onChange: (event: any) => void;
  onClick: (event: any, item: Institution) => void;
  hasInput: boolean;
  noResults: boolean;
  items: any[];
  searching: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    minWidth: '30rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: '20rem'
    },
    position: 'relative'
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    gap: '1rem'
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginTop: '0.8rem',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  glass: {
    position: 'absolute',
    color: '#ccc',
    fontSize: '1.5rem',
    marginLeft: '0.8rem',
    top: '13px'
  },
  input: {
    width: '440px',
    maxWidth: '100%',
    height: '2rem',
    border: 'none',
    backgroundColor: '#EDEDED',
    padding: '1.5rem 1rem 1.49rem 3rem',
    outline: 'none',
    fontSize: '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem'
    },
    [theme.breakpoints.down('xs')]: {
      width: '340px'
    }
  },
  select: {
    position: 'absolute',
    width: '440px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0
    },
    maxHeight: '14rem',
    overflowY: 'scroll',
    border: 'none',
    outline: 'none',
    backgroundColor: '#EDEDED',
    borderRadius: '0 0 0.5rem 0.5rem',
    fontSize: '1rem'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    gap: '1rem',
    backgroundColor: '#EDEDED',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    transition: 'background-color 0.25s ease',
    '&:hover': {
      backgroundColor: '#ccc'
    }
  },
  optionText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem'
    }
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    color: '#666',
    backgroundColor: '#EDEDED',
    borderRadius: '0.5rem'
  },
  url: {
    whiteSpace: 'nowrap',
    fontSize: '0.75rem',
    color: '#666'
  }
}));

export const BaaInstitutionDropdown = ({
  onChange,
  onClick,
  hasInput,
  noResults,
  items,
  searching
}: BaaInstitutionDropdownProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {/*<h3 className={classes.title}>Connect:</h3>*/}
        <div>
          <FaSearch className={classes.glass} />

          <input
            className={classes.input}
            style={{
              borderBottom: items?.length || noResults ? '1px solid #ccc' : 'none',
              borderRadius: items?.length || noResults ? '0.5rem 0.5rem 0 0' : '0.5rem'
            }}
            type="text"
            onChange={onChange}
            onFocus={onChange}
            placeholder="Search for... or select your institution below."
          />
          <div className={classes.select}>
            {hasInput && noResults && <div className={classes.noResults}>No Results Found</div>}
            {items.map((item: Institution) => (
              <div
                className={classes.option}
                key={item.id}
                onClick={(e) => {
                  onClick(e, item);
                }}
              >
                <img
                  src={item.icon_url}
                  alt={item.name}
                  onError={(e) => {
                    e.currentTarget.style.display = 'none';
                  }}
                  style={{ width: '2rem', height: '2rem' }}
                />
                <div className={classes.optionText}>
                  <div>{item.name}</div>
                  <div className={classes.url}>{item.user_login_url}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
