import React, { useCallback, useEffect, useState } from 'react';
import { useEthereumProvider } from 'react-plaid-link/web3';

import { Avatar } from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import Web3 from 'web3';

import ErrorDialog from 'components/dialogs/ErrorDialog';
import SuccessDialog from 'components/dialogs/SuccessDialog';
import { useAuth } from 'contexts/AuthContext';

const url = process.env.REACT_APP_API_URL;

const PlaidOpen = ({ config }) => {
  const { open, ready, error } = useEthereumProvider(config);
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open, config]);
  if (error) {
    return <div>{error.message}</div>;
  } /* else if (!ready) {
        return <></>
    }*/ else return <></>;
};

export const PlaidWeb3Link = ({ institution, styles, onAccounts, handleMainClose }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [plaidConfig, setPlaidConfig] = useState();
  //const [metadata, setMetaData] = useState({})
  //const [accounts, setAccounts] = useState([])
  const { currentUser } = useAuth();

  const closeSuccess = () => {
    setSuccess(false);
  };

  const createAccounts = useCallback(
    async (data) => {
      const ID = await currentUser.getIdToken();
      await fetch(`${url}/api/v1/web3`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({
          data: data
        })
      }).catch((err) => console.log('create web3 accounts fetch error --> ', err));
    },
    [currentUser]
  );

  const onSuccess = useCallback(
    // provider is an EIP-1193 compatible JavaScript object https://eips.ethereum.org/EIPS/eip-1193
    // provider can be used by other libraries (like web3.js) to request more data
    async (provider, metadata) => {
      //const accounts = await provider.request({
      //    method: 'eth_accounts',
      //});
      const web3 = new Web3(provider);
      const address = (await web3.eth.getAccounts())[0]; //["0x79F0166510226eB4cdC0da21C0019fDc29737B39"] plaid only supports one for now?
      const balance = await web3.eth.getBalance(address); //String - The current balance for the given address in wei.
      createAccounts({
        address: address,
        balance: balance,
        wallet_name: metadata.wallet.name,
        institution_id: institution?.id,
        chain: '0x1'
      });
      setSuccess(true);
      setPlaidConfig(null);
      //setAccounts(accounts);
    },
    [institution, setSuccess, setPlaidConfig, createAccounts]
  );

  const onExit = (plaidLinkError, exitMetadata) => {
    setPlaidConfig(null);
    if (plaidLinkError) {
      console.error(`Error connecting web3 account: ${plaidLinkError.display_message}`);
      setError('There was an error adding your account. Please try again later or contact support@rainbook.com');
    }
  };

  const onClick = async () => {
    const config = {
      token: process.env.REACT_APP_PLAID_LINK_WEB3_TOKEN,
      chain: {
        chainId: process.env.REACT_APP_PLAID_LINK_WEB3_CHAIN, // EVM chain ID in hexadecimal. See chainlist.org for list
        rpcUrl: '' // RPC gateway URL to use for non-wallet methods
      },
      onSuccess,
      onExit
    };
    setPlaidConfig(config);
  };

  return (
    <>
      <ErrorDialog error={error} setError={setError} />
      {success && (
        <SuccessDialog page={'Liquid'} institution={'Web3'} handleClose={handleMainClose} onClose={closeSuccess} />
      )}
      <div style={styles} onClick={onClick}>
        {institution?.logo_url ? (
          <img
            style={{ paddingBottom: 5 }}
            className="link-bank-logo"
            src={institution.logo_url}
            alt={institution.name}
          />
        ) : (
          <>
            <Avatar className="link-bank-icon">
              <InsertChartIcon />
            </Avatar>
            <div>{institution.name}</div>
          </>
        )}
        {plaidConfig && <PlaidOpen config={plaidConfig} />}
      </div>
    </>
  );
};
